export enum INTEGRATIONS_STORE_STATE {
  ACTIVE_INTEGRATION = 'Integrations/ACTIVE_INTEGRATIONS',
  SERVICE_ACTIVATION = 'Integrations/SERVICE_ACTIVATION'
}

export enum INTEGRATIONS_STORE_GETTERS {
  ACTIVE_INTEGRATION = 'Integrations/ACTIVE_INTEGRATIONS',
  SERVICE_ACTIVATION = 'Integrations/SERVICE_ACTIVATION'
}

export enum INTEGRATIONS_STORE_ACTIONS {
  GET_ACTIVE_INTEGRATION = 'Integrations/GET_ACTIVE_INTEGRATION',
  CHECK_SERVICE_ACTIVATION_STATE = 'Integrations/CHECK_SERVICE_ACTIVATION_STATE',
  CONFIRM_SERVICE_ACTIVATION = 'Integrations/CONFIRM_SERVICE_ACTIVATION'
}

export enum INTEGRATIONS_STORE_MUTATIONS {
  SET_ACTIVE_INTEGRATION = 'Integrations/SET_ACTIVE_INTEGRATION',
  UPDATE_ACTIVE_INTEGRATION = 'Integrations/UPDATE_ACTIVE_INTEGRATION',
  SET_SERVICE_ACTIVATION = 'Integrations/SET_SERVICE_ACTIVATION'
}

export enum INTEGRATION {
  CUSTOM = 'api', // TODO: ???
  ASTERI =  'asteri',
  BILLBERRY = 'billberry',
  BRIOX = 'briox',
  DIRECTO = 'directo',
  E_ARVELDAJA = 'earveldaja',
  E_ARVELDAJA_NEW = 'earveldaja-new',
  EMAIL = 'email',
  EMCE_XML = 'emce-xml',
  ERPLYBOOKS = 'erply',
  FENNOA = 'fennoa',
  FINKA = 'finka', 
  FITEK = 'fitek',
  FIKS = 'fiks',
  HANSAWORLD = 'hansaworld',
  I_FIRMA = 'ifirma',
  VISMA_HORIZON = 'visma-horizon',
  JOOSEP_EMAIL = 'joosep-email',
  JOOSEP = 'joosep',
  MERIT = 'merit',
  MONEO = 'moneo-api',
  MONEO_OLD = 'moneo',
  MSBC = 'msbc',
  NOOM = 'noom',
  ODOO = 'odoo',
  ONE_S = 'one-s',
  PAYTRAQ = 'paytraq',
  RAKS = 'raks-sql',
  RAPID = 'rapid',
  SAF = 'saf',
  SIMPLBOOKS = 'simplbooks',
  STANDARDBOOKS = 'standardbooks',
  SMARTACCOUNTS = 'smartaccounts',
  SYSTIM = 'systim',
  INTAXO = 'intaxo',
  TELEMA = 'telema',
  TFO = 'tfo',
  JUMIS_PRO = 'tildesjumis',
  JUMIS_START = 'tildesjumis-standard',
  TRESOOR = 'tresoor',
  VALIDIUS = 'validius',
  VARICO = 'varico',
  VERP = 'verp',
  VISMA_NETVISOR = 'visma-netvisor',
  VISMA_FIVALDI = 'visma-fivaldi',
  VISMA_FIVALDI_CSV = 'visma-fivaldi-csv',
  ZALKTIS = 'zalktis'
}
