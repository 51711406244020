module.exports = {
   "cancel": {
    "est": "Katkesta",
    "eng": "Cancel",
    "rus": "Отменить",
    "fin": "Peruuta",
    "pol": "Anuluj",
    "lav": "Atcelt",
    "lit": "Atšaukti",
    "swe": "Avbryt",
    "esp": "Cancelar"
   },
   "submit": {
    "est": "Esita",
    "eng": "Submit",
    "rus": "Подать",
    "fin": "Lähetä",
    "pol": "Zatwierdź",
    "lav": "Iesniegt",
    "lit": "Pateikti",
    "swe": "Skicka in",
    "esp": "Enviar"
   },
   "save": {
    "est": "Salvesta",
    "eng": "Save",
    "rus": "Сохранить",
    "fin": "Tallenna",
    "pol": "Zapisz",
    "lav": "Saglabāt",
    "lit": "Išsaugoti",
    "swe": "Spara",
    "esp": "Guardar"
   },
   "logOut": {
    "est": "Logi välja",
    "eng": "Log out",
    "rus": "Выйти",
    "fin": "Kirjaudu ulos",
    "pol": "Wyloguj",
    "lav": "Iziet",
    "lit": "Atsijungti",
    "swe": "Logga ut",
    "esp": "Cerrar Sesión"
   },
   "languages": {
    "est": "Keel",
    "eng": "Language",
    "rus": "Язык",
    "fin": "Kielet",
    "pol": "Język",
    "lav": "Valodas",
    "lit": "Kalba",
    "swe": "Språk",
    "esp": "Idioma"
   },
   "saving": {
    "est": "Salvestan",
    "eng": "Saving",
    "rus": "Сохраняется",
    "fin": "Tallennetaan...",
    "pol": "Zapisywanie...",
    "lav": "Tiek saglabāts",
    "lit": "Išsaugoma",
    "swe": "Sparar",
    "esp": "Guardando"
   },
   "pleaseWaitForUpload": {
    "est": "Palun oota...",
    "eng": "Please wait...",
    "rus": "Пожалуйста,  подождите...",
    "fin": "Hetkinen...",
    "pol": "Proszę, zaczekaj...",
    "lav": "Lūdzu, uzgaidiet...",
    "lit": "Palaukite...",
    "swe": "Var god vänta...",
    "esp": "Por favor espere..."
   },
   "youHaveNoReceipts": {
    "est": "Ühtegi dokumenti ei ole veel lisatud",
    "eng": "No documents submitted yet",
    "rus": "Ни одной квитанции не добавлено",
    "fin": "Yhtään kuittia ei ole vielä lähetetty",
    "pol": "Nie dodano jeszcze żadnych dokumentów",
    "lav": "Vēl nav iesniegts neviens dokuments",
    "lit": "Nėra pateiktų dokumentų",
    "swe": "Inga kvitton har skickats in än",
    "esp": "Aún no se han enviado documentos"
   },
   "newCompany": {
    "est": "Uus konto",
    "eng": "New account",
    "rus": "Новый аккаунт",
    "fin": "Uusi tili",
    "pol": "Nowe konto",
    "lav": "Jauns konts",
    "lit": "Nauja paskyra",
    "swe": "Nytt konto",
    "esp": "Nueva cuenta"
   },
   "companyName": {
    "est": "Juriidilise organisatsiooni nimi",
    "eng": "Name of the organisation",
    "rus": "Название организации",
    "fin": "Organisaation nimi",
    "pol": "Nazwa organizacji",
    "lav": "Uzņēmuma nosaukums",
    "swe": "Namn på organisation",
    "lit": "Organizacijos pavadinimas",
    "esp": "Nombre de la organización"
   },
   "editCompany": {
    "eng": "Company information",
    "pol": "Informacje o firmie",
    "est": "Ettevõtte andmed",
    "rus": "Редактировать информацию о компании",
    "fin": "Muokkaa organisaation tietoja",
    "lav": "Rediģēt uzņēmuma informāciju",
    "lit": "Įmonės informacija",
    "swe": "Företagsinformation",
    "esp": "Información de la empresa"
   },
   "commentRepresentationCost": {
    "est": "Kellega kohtuti ja mis eesmärgil?",
    "eng": "Who attended and what was the purpose of the meeting?",
    "rus": "Кто присутствовал и какова была цель встречи?",
    "fin": "Osallistujat ja tapaamisen tarkoitus",
    "pol": "Kto uczestniczył i jaki był cel spotkania?",
    "lav": "Kurš piedalījās un kāds bija sanāksmes mērķis?",
    "lit": "Kas dalyvavo ir koks buvo susitikimo tikslas?",
    "swe": "Vem deltog och vad var syftet med mötet?",
    "esp": "¿Quiénes asistieron y cuál fue el propósito de la reunión?"
   },
   "billingType": {
    "est": "Hüvitamine",
    "eng": "Reimbursement",
    "rus": "Компенсация",
    "fin": "Kulukorvaus",
    "pol": "Zwrot kosztów",
    "lav": "Atmaksa",
    "swe": "Ersättning",
    "lit": "Kompensacija",
    "esp": "Reembolso"
   },
   "reimbursement": {
    "est": "Kuulub hüvitamisele",
    "eng": "Get reimbursed",
    "rus": "Прошу компенсировать",
    "fin": "Hyvitetään",
    "pol": "Uzyskaj zwrot kosztów",
    "lav": "Lūdzu, atmaksāt",
    "lit": "Gauti kompensaciją",
    "swe": "Få ersättning",
    "esp": "Ser reembolsado"
   },
   "paidPersonally": {
    "est": "Maksin eraisikuna",
    "eng": "Paid by personal funds",
    "rus": "Оплачено из личных средств",
    "fin": "Maksettu henkilökohtaisista varoista",
    "pol": "Opłacono z funduszy prywatnych",
    "lav": "Apmaksāts ar personīgajiem līdzekļiem",
    "lit": "Apmokėta privačiomis lėšomis",
    "swe": "Betalt för med privata medel",
    "esp": "Pagado con fondos personales"
   },
   "companyExpense": {
    "est": "Ei kuulu hüvitamisele",
    "eng": "The company paid",
    "rus": "Оплачено компанией",
    "fin": "Ei hyvitetä",
    "pol": "Koszt firmowy",
    "lav": "Uzņēmuma izdevumi",
    "lit": "Apmokėta įmonės",
    "swe": "Företaget betalade",
    "esp": "La empresa pagó"
   },
   "paidByCompanyFunds": {
    "est": "Makstud ettevõtte vahenditest",
    "eng": "Paid by company funds",
    "rus": "Оплачено из средств организации",
    "fin": "Maksettu organisaation varoista",
    "pol": "Opłacony z funduszy firmowych",
    "lav": "Apmaksāts ar uzņēmuma līdzekļiem",
    "swe": "Betalt för med företagsmedel",
    "lit": "Apmokėta įmonės lėšomis",
    "esp": "Pagado con fondos de la empresa"
   },
   "saveAndLeave": {
    "est": "Salvesta & lahku",
    "eng": "Save and close",
    "rus": "Сохранить и выйти",
    "fin": "Tallenna ja poistu",
    "pol": "Zapisz i wyjdź",
    "lav": "Saglabāt un iziet",
    "lit": "Išsaugoti ir uždaryti",
    "swe": "Spara och lämna",
    "esp": "Guardar y cerrar"
   },
   "paidBy": {
    "est": "Makseviis",
    "eng": "Payment method",
    "rus": "Способ оплаты",
    "fin": "Maksutapa",
    "pol": "Metoda płatności",
    "lav": "Maksāts ar",
    "lit": "Mokėjimo būdas",
    "swe": "Betalningsmetod",
    "esp": "Método de pago"
   },
   "cash": {
    "est": "Sularaha",
    "eng": "Cash",
    "rus": "Наличные",
    "fin": "Käteinen",
    "pol": "Gotówka",
    "lav": "Skaidra nauda",
    "lit": "Grynieji",
    "swe": "Kontant",
    "esp": "Efectivo"
   },
   "cc": {
    "est": "Firma kaart",
    "eng": "Company card",
    "rus": "Корпоративная карта",
    "fin": "Yrityskortti",
    "pol": "Karta firmowa",
    "lav": "Uzņēmuma karti",
    "lit": "Įmonės banko kortelė",
    "swe": "Företagskort",
    "esp": "Tarjeta de la empresa"
   },
   "transfer": {
    "est": "Pangaülekanne",
    "eng": "Bank transfer",
    "rus": "Перевод",
    "fin": "Tilisiirto",
    "pol": "Przelew",
    "lav": "Pārskaitījumu",
    "lit": "Pervedimas",
    "swe": "Överföring",
    "esp": "Transferencia bancaria"
   },
   "costType": {
    "est": "Kululiik",
    "eng": "Expense type",
    "rus": "Тип расходов",
    "fin": "Kululaji",
    "pol": "Rodzaj kosztu",
    "lav": "Izdevumu veids",
    "lit": "Išlaidos tipas",
    "swe": "Utgiftstyp",
    "esp": "Tipo de gasto"
   },
   "car": {
    "est": "Auto",
    "eng": "Car",
    "rus": "Автомобиль",
    "fin": "Auto",
    "pol": "Samochód",
    "lav": "Automašīna",
    "swe": "Bil",
    "lit": "Automobilis",
    "esp": "Auto"
   },
   "transport": {
    "est": "Transport",
    "eng": "Transport",
    "rus": "Транспорт",
    "fin": "Kuljetus",
    "pol": "Transport",
    "lav": "Transports",
    "lit": "Transportas",
    "swe": "Transport",
    "esp": "Transporte"
   },
   "representationCost": {
    "est": "Esinduskulu",
    "eng": "Representation cost",
    "rus": "Представительские расходы",
    "fin": "Edustuskulut",
    "pol": "Koszty reprezentacyjne",
    "lav": "Reprezentācijas izdevumi",
    "lit": "Reprezentaciniai kaštai",
    "swe": "Representationskostnad",
    "esp": "Costo de representación"
   },
   "businessCost": {
    "est": "Majanduskulu",
    "eng": "Business cost",
    "rus": "Бизнес-расходы",
    "fin": "Liiketoimintakustannus",
    "pol": "Koszty biznesowe",
    "lav": "Biznesa izdevumi",
    "lit": "Verslo kaštai",
    "swe": "Företagskostnad",
    "esp": "Costo del negocio"
   },
   "otherCost": {
    "est": "Muu kulu",
    "eng": "Other cost",
    "rus": "Другие расходы",
    "fin": "Muut kulut",
    "pol": "Inne koszty",
    "lav": "Citi izdevumi",
    "swe": "Övrig kostnad",
    "lit": "Kiti kaštai",
    "esp": "Otro costo"
   },
   "preview": {
    "est": "Eelvaade",
    "eng": "Preview",
    "rus": "Предпросмотр",
    "fin": "Esikatselu",
    "pol": "Podgląd",
    "lav": "Priekšskatīt",
    "lit": "Peržiūrėti",
    "swe": "Förhandsvisning",
    "esp": "Vista previa"
   },
   "addComment": {
    "est": "Lisa Kommentaar",
    "eng": "Add comment",
    "rus": "Добавить комментарий",
    "fin": "Lisää kommentti",
    "pol": "Dodaj komentarz",
    "lav": "Pievienot komentāru",
    "swe": "Lägg till kommentar",
    "lit": "Pridėti komentarą",
    "esp": "Agregar Comentario"
   },
   "comment": {
    "est": "Kommentaar",
    "eng": "Comment",
    "rus": "Комментарий",
    "fin": "Kommentti",
    "pol": "Komentarz",
    "lav": "Komentārs",
    "lit": "Komentaras",
    "swe": "Kommentar",
    "esp": "Comentario"
   },
   "confirmBusinessRelated": {
    "est": "Kinnitan, et tegemist on selle ettevõttega seotud kulu(de)ga",
    "eng": "I confirm this is a business-related expense",
    "rus": "Я подтверждаю, что это расходы, связанные с бизнесом",
    "fin": "Vahvistan, että tämä on liiketoimintaan liittyvä kustannus.",
    "pol": "Potwierdzam, że jest to wydatek związany z prowadzeniem działalności",
    "lav": "Es apliecinu, ka šie ir ar uzņēmumu saistīti izdevumi",
    "swe": "Jag bekräftar att detta är en företagsrelaterad utgift",
    "lit": "Patvirtinu, jog tai yra su verslu susijusios išlaidos",
    "esp": "Confirmo que este es un gasto relacionado con el negocio."
   },
   "upload": {
    "est": "Üleslaadimine:",
    "eng": "Upload:",
    "rus": "Загрузить:",
    "fin": "Lataa",
    "pol": "Wyślij",
    "lav": "Augšupielāde:",
    "swe": "Ladda upp:",
    "lit": "Įkelti:",
    "esp": "Cargar"
   },
   "or": {
    "est": "või",
    "eng": "or",
    "rus": "или",
    "fin": "tai",
    "pol": "lub",
    "lav": "vai",
    "swe": "eller",
    "lit": "arba",
    "esp": "o"
   },
   "search": {
    "est": "Otsi",
    "eng": "Search",
    "rus": "Поиск",
    "fin": "Etsi",
    "pol": "Wyszukaj",
    "lav": "Meklēt",
    "swe": "Sök",
    "lit": "Ieškoti",
    "esp": "Buscar"
   },
   "loading": {
    "est": "Tegelen",
    "eng": "Loading",
    "rus": "Загрузка",
    "fin": "Ladataan...",
    "pol": "Ładuję",
    "lav": "Ielāde",
    "lit": "Įkeliama",
    "swe": "Laddar",
    "esp": "Cargando"
   },
   "join": {
    "est": "Liitu",
    "eng": "Join",
    "rus": "Присоединиться",
    "fin": "Liity",
    "pol": "Dołącz",
    "lav": "Pievienoties",
    "swe": "Gå med",
    "lit": "Prisijungti",
    "esp": "Unirse"
   },
   "privatePerson": {
    "est": "Eraisiku konto",
    "eng": "Private person account",
    "rus": "Учетная запись частного лица",
    "fin": "Yksityishenkilö",
    "pol": "Konto osoby prywatnej",
    "lav": "Privātpersona",
    "swe": "Privatperson",
    "lit": "Privataus asmens paskyra",
    "esp": "Cuenta de persona privada"
   },
   "whatCountryCompany": {
    "est": "Mis riigis on organisatsioon registreeritud?",
    "eng": "Where is the organisation registered?",
    "rus": "Где зарегистрирована организация?",
    "fin": "Missä maassa organisaatio on rekisteröity?",
    "pol": "Gdzie jest zarejestrowana organizacja?",
    "lav": "Kur organizācija ir reģistrēta?",
    "swe": "Var är organisationen registrerad?",
    "lit": "Kur įmonė yra registruota?",
    "esp": "¿Dónde está registrada la organización?"
   },
   "selectCountry": {
    "est": "Vali riik",
    "eng": "Choose country",
    "rus": "Bыберите страну",
    "fin": "Valitse maa",
    "pol": "Wybierz kraj",
    "lav": "Izvēlieties valsti",
    "lit": "Pasirinkite valstybę",
    "swe": "Välj land",
    "esp": "Seleccione país"
   },
   "estonia": {
    "est": "Eesti",
    "eng": "Estonia",
    "rus": "Эстония",
    "fin": "Viro",
    "pol": "Estonia",
    "lav": "Igaunija",
    "swe": "Estland",
    "lit": "Estija",
    "esp": "Estonia"
   },
   "latvia": {
    "est": "Läti",
    "eng": "Latvia",
    "rus": "Латвия",
    "fin": "Latvia",
    "pol": "Łotwa",
    "lav": "Latvija",
    "swe": "Lettland",
    "lit": "Latvija",
    "esp": "Letonia"
   },
   "regCode": {
    "est": "Reg. kood",
    "eng": "Registry code",
    "rus": "Kод реестра",
    "fin": "Y-tunnus",
    "pol": "REGON",
    "lav": "Reģistrācijas numurs",
    "lit": "Registracijos kodas",
    "swe": "Registreringskod",
    "esp": "Código de registro"
   },
   "city": {
    "est": "Linn",
    "eng": "City",
    "rus": "Город",
    "fin": "Kaupunki",
    "pol": "Miasto",
    "lav": "Pilsēta",
    "swe": "Stad",
    "lit": "Miestas",
    "esp": "Ciudad"
   },
   "address": {
    "est": "Aadress",
    "eng": "Address",
    "rus": "Адрес",
    "fin": "Osoite",
    "pol": "Adres",
    "lav": "Adrese",
    "swe": "Adress",
    "lit": "Adresas",
    "esp": "Dirección"
   },
   "zip": {
    "est": "Indeks",
    "eng": "Zip",
    "rus": "Почтовый индекс",
    "fin": "Postinumero",
    "pol": "Kod pocztowy",
    "lav": "Pasta indekss",
    "lit": "Pašto indeksas",
    "swe": "Postnummer",
    "esp": "Código Postal"
   },
   "firstName": {
    "est": "Eesnimi",
    "eng": "First name",
    "rus": "Имя",
    "fin": "Etunimi",
    "pol": "Imię",
    "lav": "Vārds",
    "swe": "Förnamn",
    "lit": "Vardas",
    "esp": "Nombre"
   },
   "lastName": {
    "est": "Perekonnanimi",
    "eng": "Last name",
    "rus": "Фамилия",
    "fin": "Sukunimi",
    "pol": "Nazwisko",
    "lav": "Uzvārds",
    "swe": "Efternamn",
    "lit": "Pavardė",
    "esp": "Apellido"
   },
   "email": {
    "est": "E-post",
    "eng": "Email",
    "rus": "Эл. почта",
    "fin": "Sähköposti",
    "pol": "E-mail",
    "lav": "E-pasts",
    "lit": "El. paštas",
    "swe": "E-post",
    "esp": "Correo electrónico"
   },
   "clickOnJoin": {
    "est": "Vajuta \"Liitu\", et esitada kuludokumente organisatsioonile",
    "eng": "Click \"Join\" to send receipts to organisation",
    "rus": "Нажмите \"Присоединиться\", чтобы отправлять документы в организацию",
    "fin": "Klikkaa \"Liity\" lähettääksesi kuitteja organisaatiolle",
    "pol": "Kliknij 'Dołącz', aby dodać dokumenty do swojej organizacji",
    "lav": "Noklikšķiniet uz \"Pievienoties\", lai sūtītu dokumentus organizācijai",
    "swe": "Klicka på \"Gå med\" för att skicka kvitton till organisationer",
    "lit": "Spauskite \"Prisijungti\" siųsti dokumentus organizacijai",
    "esp": "Haga clic en \"Unirse\" para enviar los recibos a la organización."
   },
   "supplier": {
    "est": "Tarnija",
    "eng": "Supplier",
    "rus": "Поставщик",
    "fin": "Toimittaja",
    "pol": "Dostawca",
    "lav": "Piegādātājs",
    "swe": "Leverantör",
    "lit": "Tiekėjas",
    "esp": "Proveedor"
   },
   "issued": {
    "est": "Dok. kuupäev",
    "eng": "Issue date",
    "rus": "Дата выпуска",
    "fin": "Päivämäärä",
    "pol": "Data wystawienia",
    "lav": "Dokumenta datums",
    "swe": "Utfärdningsdatum",
    "lit": "Išdavimo data",
    "esp": "Fecha de emisión"
   },
   "documentId": {
    "est": "Dok. number",
    "eng": "Doc. number",
    "rus": "Номер документа",
    "fin": "Tositteen nro.",
    "pol": "Numer dokumentu",
    "lav": "Dokumenta Nr.",
    "lit": "Dokumento nr.",
    "swe": "Dokumentnummer",
    "esp": "Número de Doc."
   },
   "sum": {
    "est": "Summa",
    "eng": "Sum",
    "rus": "Cумма",
    "fin": "Summa",
    "pol": "Suma",
    "lav": "Summa",
    "swe": "Summa",
    "lit": "Suma",
    "esp": "Suma"
   },
   "blurry": {
    "est": "udune pilt",
    "eng": "blurry image",
    "rus": "размытое изображение",
    "fin": "epäselvä kuva",
    "pol": "obraz rozmazany",
    "lav": "Izplūdis attēls",
    "swe": "suddig bild",
    "lit": "neryškus vaizdas",
    "esp": "Imagen borrosa"
   },
   "noPicture": {
    "est": "Pilt puudu",
    "eng": "Missing picture",
    "rus": "Нет изображения",
    "fin": "Kuva puuttuu",
    "pol": "Brak zdjęcia",
    "lav": "Nav attēla",
    "swe": "Ingen bild",
    "lit": "Nuotraukos nėra",
    "esp": "Sin imagen"
   },
   "notValid": {
    "est": "Pole kuludokument",
    "eng": "Not a valid document",
    "rus": "Недействительный документ",
    "fin": "Ei kelpaa tositteeksi",
    "pol": "Nieprawidłowy dokument",
    "lav": "Nav derīgs dokuments",
    "swe": "Inte ett giltligt dokument",
    "lit": "Negaliojantis dokumentas",
    "esp": "No es un documento válido"
   },
   "approved": {
    "est": "Kinnitatud",
    "eng": "Approved",
    "rus": "Утвержденный",
    "fin": "Hyväksytty",
    "pol": "Zatwierdzono",
    "lav": "Apstiprināts",
    "swe": "Godkänt",
    "lit": "Patvirtinta",
    "esp": "Aprobado"
   },
   "logIn": {
    "est": "Logi sisse",
    "eng": "Log in",
    "rus": "Bойти",
    "fin": "Kirjaudu sisään",
    "pol": "Zaloguj się",
    "lav": "Pieslēgties",
    "swe": "Logga in",
    "lit": "Prisijungti",
    "esp": "Iniciar sesión"
   },
   "createAccount": {
    "est": "Loo konto",
    "eng": "Create account",
    "rus": "Регистрация",
    "fin": "Luo käyttäjätunnus",
    "pol": "Stwórz konto",
    "lav": "Izveidot kontu",
    "swe": "Skapa konto",
    "lit": "Sukurti paskyrą",
    "esp": "Crear cuenta"
   },
   "username": {
    "est": "Kasutajanimi",
    "eng": "Username",
    "rus": "Имя пользователя",
    "fin": "Käyttäjätunnus",
    "pol": "Nazwa użytkownika",
    "lav": "Lietotājvārds",
    "swe": "Användarnamn",
    "lit": "Vartotojo vardas",
    "esp": "Nombre de usuario"
   },
   "password": {
    "est": "Parool",
    "eng": "Password",
    "rus": "Пароль",
    "fin": "Salasana",
    "pol": "Hasło",
    "lav": "Parole",
    "swe": "Lösenord",
    "lit": "Slaptažodis",
    "esp": "Contraseña"
   },
   "wrongPassword": {
    "est": "Vale parool",
    "eng": "Wrong password",
    "rus": "Hеправильный пароль",
    "fin": "Väärä salasana",
    "pol": "Nieprawidłowe hasło",
    "lav": "Nepareiza parole",
    "swe": "Fel lösenord",
    "lit": "Neteisingas slaptažodis",
    "esp": "Contraseña incorrecta"
   },
   "loginUnsucsessful": {
    "est": "Sisselogimine ei õnnestunud",
    "eng": "Login unsuccessful",
    "rus": "Ошибка соединение",
    "fin": "Kirjautuminen epäonnistui",
    "pol": "Logowanie się nie powiodło",
    "lav": "Neveiksmīga pieslēgšanās",
    "swe": "Inloggning misslyckades",
    "lit": "Nesėkmingas prisijungimas",
    "esp": "Inicio de sesión fallido"
   },
   "forgotPassword": {
    "est": "Unustasid parooli?",
    "eng": "Forgot your password?",
    "rus": "Забыли пароль?",
    "fin": "Unohtuiko salasana?",
    "pol": "Nie pamiętasz hasła?",
    "lav": "Aizmirsāt paroli?",
    "swe": "Glömt ditt lösenord?",
    "lit": "Pamiršai slaptažodį?",
    "esp": "Olvidó su contraseña?"
   },
   "next": {
    "est": "Edasi",
    "eng": "Next",
    "rus": "Далее",
    "fin": "Seuraava",
    "pol": "Dalej",
    "lav": "Tālāk",
    "swe": "Nästa",
    "lit": "Kitas",
    "esp": "Siguiente"
   },
   "addEmailForPassword": {
    "est": "Saadame teile lingi parooli uuendamiseks e-posti teel",
    "eng": "We will send you a link to reset your password via email",
    "rus": "Мы вышлем вам ссылку для сброса вашего пароля по электронной почте",
    "fin": "Lähetämme sähköpostitse salasanan vaihtolinkin",
    "pol": "Wyślemy ci link do zresetowania hasła za pomocą wiadomości e-mail",
    "lav": "Uz Jūsu epastu tiks nosūtīta paroles atjaunošanas saite",
    "swe": "Vi kommer att skicka en länk via e-post för att återställa ditt lösenord",
    "lit": "Atsiųsime nuorodą slaptažodžio keitimui el. paštu",
    "esp": "Le enviaremos un enlace para restablecer su contraseña por correo electrónico"
   },
   "send": {
    "est": "Saada",
    "eng": "Send",
    "rus": "Отправить",
    "fin": "Lähetä",
    "pol": "Wyślij",
    "lav": "Sūtīt",
    "swe": "Skicka",
    "lit": "Siųsti",
    "esp": "Enviar"
   },
   "pleaseCheckYourEmail": {
    "eng": "Please check your email",
    "est": "Palun vaata oma emaili postkasti",
    "rus": "Пожалуйста, проверьте свою электронную почту",
    "fin": "Käythän viimeistelemässä rekisteröintisi antamassasi sähköpostissa",
    "pol": "Sprawdź swoją skrzynkę e-mail",
    "lav": "Lūdzu, pārbaudiet savu e-pastu",
    "swe": "Var god kontrollera din e-postinkorg",
    "lit": "Pasitikrinkite el. pašto dėžutę",
    "esp": "Por favor revise su correo electrónico"
   },
   "pleaseInsertValidEmail": {
    "est": "Palun sisestage korrektne e-posti aadress",
    "eng": "Please insert a valid email address",
    "rus": "Пожалуйста, введите верный адрес электронной почты",
    "fin": "Kirjoita voimassa oleva sähköpostiosoite",
    "pol": "Podaj poprawny adres e-mail",
    "lav": "Lūdzu, ievadiet pareizu e-pastu",
    "swe": "Var god ange en giltlig e-postadress",
    "lit": "Įveskite galiojantį el. pašto adresą",
    "esp": "Por favor ingrese una dirección de correo electrónico valida"
   },
   "passwordChaingingFailed": {
    "est": "Parooli vahetamine ebaõnnestus",
    "eng": "Changing the password failed",
    "rus": "Ошибка изменения пароля",
    "fin": "Salasanan vaihto epäonnistui",
    "pol": "Zmiana hasła nie powiodła się",
    "lav": "Neizdevās nomainīt paroli",
    "swe": "Ändring av lösenordet misslyckades",
    "lit": "Nepavyko pakeisti slaptažodžio",
    "esp": "Cambio de contraseña falló"
   },
   "newAccount": {
    "est": "Uus konto",
    "eng": "New account",
    "rus": "Новый аккаунт",
    "fin": "Uusi käyttäjätunnus",
    "pol": "Nowe konto",
    "lav": "Jauns konts",
    "swe": "Nytt konto",
    "lit": "Nauja paskyra",
    "esp": "Nueva cuenta"
   },
   "passwordsDiffer": {
    "est": "Paroolid ei ole ühesugused",
    "eng": "Passwords don’t match",
    "rus": "Пароли не совпадают",
    "fin": "Salasanat eivät täsmää",
    "pol": "Hasła różnią się",
    "lav": "Paroles nesakrīt",
    "swe": "Lösenorden överensstämmer inte",
    "lit": "Slaptažodžiai nesutampa",
    "esp": "Las contraseñas no coinciden"
   },
   "userCreatedPleaseLogIn": {
    "est": "Kasutaja loodud! Palun logige sisse",
    "eng": "User created! Please log in",
    "rus": "Пользователь создан! Пожалуйста, войдите",
    "fin": "Käyttäjä on luotu! Kirjaudu sisään",
    "pol": "Utworzono konto! Możesz się zalogować",
    "lav": "Lietotājs izveidots! Lūdzu, pieslēdzieties",
    "swe": "Användare skapad! Var god logga in",
    "lit": "Vartotojas sukurtas! Prisijunkite",
    "esp": "¡Usuario creado! Por favor Iniciar sesión"
   },
   "passwordUpdatedPleaseLogIn": {
    "est": "Parool uuendatud! Palun logige sisse",
    "eng": "Password updated! Please log in",
    "rus": "Пароль обновлен! Пожалуйста, войдите",
    "fin": "Salasana on päivitetty! Kirjaudu sisään",
    "pol": "Hasło zostało zaktualizowane! Możesz się zalogować",
    "lav": "Parole atjaunota! Lūdzu, pieslēdzieties",
    "swe": "Lösenord uppdaterat! Var god logga in",
    "lit": "Slaptažodis atnaujintas! Prisijunkite",
    "esp": "¡Contraseña actualizada! Por favor Iniciar sesión"
   },
   "finland": {
    "est": "Soome",
    "eng": "Finland",
    "rus": "Финляндия",
    "fin": "Suomi",
    "pol": "Finlandia",
    "lav": "Somija",
    "swe": "Finland",
    "lit": "Suomija",
    "esp": "Finlandia"
   },
   "country": {
    "est": "Riik",
    "eng": "Country",
    "rus": "Cтрана",
    "fin": "Maa",
    "pol": "Kraj",
    "lav": "Valsts",
    "swe": "Land",
    "lit": "Valstybė",
    "esp": "País"
   },
   "invoiceEmail": {
    "est": "Arve saaja e-post",
    "eng": "Billing email address",
    "rus": "Электронная почта для выставления счета",
    "fin": "Sähköposti laskutukseen",
    "pol": "E-mail odbiorcy faktury",
    "lav": "Rēķina saņēmēja e-pasts",
    "swe": "E-postadress för fakturering",
    "lit": "El. pašto adresas atsiskaitymams",
    "esp": "Dirección de correo electrónico de facturación"
   },
   "register": {
    "est": "Loo konto",
    "eng": "Create a new account",
    "rus": "Регистрировать",
    "fin": "Luo uusi käyttäjätili",
    "pol": "Zarejestruj",
    "lav": "Reģistrēt",
    "swe": "Skapa nytt konto",
    "lit": "Sukurti naują paskyrą",
    "esp": "Crear una nueva cuenta"
   },
   "pleaseInsertNewPassword": {
    "est": "Palun sisesta uus parool",
    "eng": "Please insert new password",
    "rus": "Укажите новый пароль",
    "fin": "Aseta uusi salasana",
    "pol": "Podaj nowe hasło",
    "lav": "Ievadiet jaunu paroli",
    "swe": "Var god ange nytt lösenord",
    "lit": "Įveskite naują slaptažodį",
    "esp": "Por favor inserte una nueva contraseña"
   },
   "passwordAgain": {
    "est": "Kinnita parool",
    "eng": "Repeat password",
    "rus": "Подтвердите пароль",
    "fin": "Vahvista salasana",
    "pol": "Potwierdź hasło",
    "lav": "Apstipriniet paroli",
    "swe": "Upprepa lösenord",
    "lit": "Pakartokite slaptažodį",
    "esp": "Repita la contraseña"
   },
   "report": {
    "est": "Kuluaruanne",
    "eng": "Expense report",
    "rus": "Oтчет о расходах",
    "fin": "Kuluraportti",
    "pol": "Raport z wydatków",
    "lav": "Izdevumu pārskats",
    "swe": "Utgiftsrapport",
    "lit": "Išlaidų ataskaita",
    "esp": "Informe de gastos"
   },
   "reportAddName": {
    "est": "Aruande nimi",
    "eng": "Report name",
    "rus": "Название отчета",
    "fin": "Raportin nimi",
    "pol": "Nazwa raportu",
    "lav": "Pārskata nosaukums",
    "swe": "Namn på rapport",
    "lit": "Ataskaitos pavadinimas",
    "esp": "Nombre informe"
   },
   "documents": {
    "est": "dokumenti",
    "eng": "documents",
    "rus": "документы",
    "fin": "tositteet",
    "pol": "dokumenty",
    "lav": "dokumenti",
    "swe": "dokument",
    "lit": "dokumentai",
    "esp": "documentos"
   },
   "newReport": {
    "est": "Uus aruanne",
    "eng": "Add report",
    "rus": "Добавить отчет",
    "fin": "Uusi raportti",
    "pol": "Nowy raport",
    "lav": "Pievienot pārskatu",
    "swe": "Ny rapport",
    "lit": "Nauja ataskaita",
    "esp": "Nuevo informe"
   },
   "chooseReport": {
    "est": "Vali aruanne",
    "eng": "Choose report",
    "rus": "Выберите отчет",
    "fin": "Valitse raportti",
    "pol": "Wybierz raport",
    "lav": "Izvēlēties pārskatu",
    "swe": "Välj rapport",
    "lit": "Pasirinkti ataskaitą",
    "esp": "Seleccione el informe"
   },
   "noInternet": {
    "est": "Hoiatus: teil puudub interneti ühendus",
    "eng": "Warning: You have no internet connection",
    "rus": "Внимание: у Вас нет подключения к Интернету.",
    "fin": "Varoitus: Ei internet-yhteyttä",
    "pol": "Uwaga! Nie wykryto połączenia z internetem",
    "lav": "Brīdinājums: Jums nav interneta pieslēguma",
    "swe": "Varning: du har ingen internetuppkoppling",
    "lit": "Perspėjimas: interneto ryšys neaptiktas",
    "esp": "Advertencia: no tiene conexión a Internet"
   },
   "techSupport": {
    "eng": "Contact support",
    "est": "Kirjuta klienditoele",
    "rus": "Поддержка",
    "fin": "Asiakastuki",
    "pol": "Wsparcie",
    "lav": "Sazināties ar atbalstu",
    "swe": "Kontakta kundsupport",
    "lit": "Susisiekti su pagalba",
    "esp": "Contacto de soporte"
   },
   "users": {
    "eng": "Users",
    "est": "Kasutajad",
    "rus": "Пользователи",
    "fin": "Käyttäjät",
    "pol": "Użytkownicy",
    "lav": "Lietotāji",
    "swe": "Användare",
    "lit": "Vartotojai",
    "esp": "Usuarios"
   },
   "inviteUser": {
    "eng": "Add user",
    "est": "Lisa kasutaja",
    "rus": "Пригласить пользователя",
    "fin": "Kutsu",
    "pol": "Dodaj użytkownika",
    "lav": "Uzaicināt lietotāju",
    "swe": "Lägg till användare",
    "lit": "Pridėti vartotoją",
    "esp": "Agregar usuario"
   },
   "suchEmailExists": {
    "eng": "This email is already registered in your organisation",
    "est": "See e-mail on juba teie ettevõttes",
    "rus": "Этот электронный адрес уже зарегистрирован в вашей организации",
    "fin": "Tämä sähköposti on jo käytössä organisaatiossasi",
    "pol": "Ten adres e-mail jest już zarejestrowany w twojej organizacji",
    "lav": "Šāds lietotājs jau eksistē",
    "swe": "Denna e-postadress är redan registrerad inom din organisation",
    "lit": "Šis el. paštas jau registruotas jūsų organizacijoje",
    "esp": "Este correo electrónico ya está registrado en su organización."
   },
   "user": {
    "eng": "User",
    "est": "Kasutaja",
    "rus": "Пользователь",
    "fin": "Käyttäjä",
    "pol": "Użytkownik",
    "lav": "Lietotājs",
    "swe": "Användare",
    "lit": "Vartotojas",
    "esp": "Usuario"
   },
   "administrator": {
    "eng": "Administrator",
    "est": "Administraator",
    "rus": "Aдминистратор",
    "fin": "Ylläpitäjä",
    "pol": "Administrator",
    "lav": "Administrators",
    "swe": "Administratör",
    "lit": "Administratorius",
    "esp": "Administrador"
   },
   "deleted": {
    "eng": "Deleted!",
    "est": "Kustutatud!",
    "rus": "Удалено!",
    "fin": "Poistettu",
    "pol": "Usunięto!",
    "lav": "Dzēsts!",
    "swe": "Raderad!",
    "lit": "Ištrinta!",
    "esp": "Eliminado!"
   },
   "chooseWhoGetsTheInvoice": {
    "eng": "Bill my other company",
    "est": "Lisa minu teise ettevõtte arvele",
    "rus": "Выставить счет на другую мою компанию",
    "fin": "Laskuta toista organisaatiota",
    "pol": "Wystaw fakturę do mojej drugiej firmy",
    "lav": "Adresēt CostPocket rēķinus citai organizācijai",
    "swe": "Fakturera mitt andra företag",
    "lit": "Išrašyti sąskaitą kitai mano kompanijai",
    "esp": "Facturar a mi otra empresa"
   },
   "chooseCompany": {
    "eng": "Choose company",
    "est": "Vali ettevõte",
    "rus": "Bыбрать компанию",
    "fin": "Valitse organisaatio",
    "pol": "Wybierz firmę",
    "lav": "Izvēlēties uzņēmumu",
    "swe": "Välj företag",
    "lit": "Pasirinkti įmonę",
    "esp": "Seleccione compañía"
   },
   "costPocketEmail": {
    "eng": "Forward invoices from your email to:",
    "est": "Saada arved emailile:",
    "rus": "Переслать счета из почтового ящика в:",
    "fin": "Lähetä laskut sähköpostista osoitteeseen:",
    "pol": "Wyślij faktury ze skrzynki e-mail do:",
    "lav": "Pārsūtīt rēķinus no pastkastes uz:",
    "swe": "Vidarebefodra fakturor från din e-postinkorg till:",
    "lit": "Persiųsti sąskaitas į:",
    "esp": "Reenvíe facturas desde su correo electrónico a:"
   },
   "document": {
    "eng": "Document",
    "est": "Dokument",
    "rus": "Документ",
    "fin": "Tosite",
    "pol": "Dokument",
    "lav": "Dokuments",
    "swe": "Dokument",
    "lit": "Dokumentas",
    "esp": "Documento"
   },
   "supplierName": {
    "eng": "Supplier name",
    "est": "Tarnija nimi",
    "rus": "Наименование поставщика",
    "fin": "Toimittajan nimi",
    "pol": "Nazwa dostawcy",
    "lav": "Piegādātāja nosaukums",
    "swe": "Leverantörsnamn",
    "lit": "Tiekėjo pavadinimas",
    "esp": "Nombre del proveedor"
   },
   "currency": {
    "eng": "Currency",
    "est": "Valuuta",
    "rus": "Валюта",
    "fin": "Valuutta",
    "pol": "Waluta",
    "lav": "Valūta",
    "swe": "Valuta",
    "lit": "Valiuta",
    "esp": "Moneda"
   },
   "VAT": {
    "eng": "VAT",
    "est": "KM",
    "rus": "НДС",
    "fin": "ALV",
    "pol": "VAT",
    "lav": "PVN",
    "swe": "VAT",
    "lit": "PVM",
    "esp": "IVA"
   },
   "sumNoVAT": {
    "eng": "Sum without VAT",
    "est": "Summa km-ta",
    "rus": "Сумма без НДС",
    "fin": "Veroton hinta",
    "pol": "Suma bez podatku VAT",
    "lav": "Summa bez PVN",
    "swe": "Summa utan VAT",
    "lit": "Suma be PVM",
    "esp": "Suma sin IVA"
   },
   "fields": {
    "eng": "Fields",
    "est": "Väljad",
    "rus": "Поля",
    "fin": "Kentät",
    "pol": "Pola",
    "lav": "Laukumi",
    "swe": "Fält",
    "lit": "Laukeliai",
    "esp": "Campos"
   },
   "selectPlan": {
    "eng": "Select plan",
    "est": "Vali hinnastamine",
    "rus": "Выберите план",
    "fin": "Valitse paketti",
    "pol": "Wybierz plan",
    "lav": "Izvēlēties plānu",
    "swe": "Välj paket",
    "lit": "Pasirinkite planą",
    "esp": "Seleccione el plan"
   },
   "selfService": {
    "eng": "Self service",
    "est": "Iseteenindus",
    "rus": "Самообслуживание",
    "fin": "Itsepalvelu",
    "pol": "Samoobsługa",
    "lav": "Pašapkalpošanās",
    "swe": "Självservice",
    "lit": "Savitarna",
    "esp": "Autoservicio"
   },
   "month": {
    "eng": "month",
    "est": "kuu",
    "rus": "месяц",
    "fin": "kuukausi",
    "pol": "miesiąc",
    "lav": "mēnesis",
    "swe": "månad",
    "lit": "mėnuo",
    "esp": "mes"
   },
   "automatic": {
    "eng": "Automatic",
    "est": "Automaatne",
    "rus": "Автоматически",
    "fin": "Automaattinen",
    "pol": "Automatycznie",
    "lav": "Automātiski",
    "swe": "Automatisk",
    "lit": "Automatiškai",
    "esp": "Automático"
   },
   "payAsYouGo": {
    "eng": "Pay as you go",
    "est": "Tšekipõhine hinnastamine",
    "rus": "Оплата по факту",
    "fin": "Käytön mukaan",
    "pol": "Pay as you go",
    "lav": "Maksā, kad izmanto",
    "swe": "Pay as you go",
    "lit": "Pay as you go",
    "esp": "Pay as you Go"
   },
   "receipt": {
    "eng": "Receipt",
    "est": "Tšekk",
    "rus": "Kвитанция",
    "fin": "Kuitti",
    "pol": "Paragon",
    "lav": "Čeks",
    "swe": "Kvitto",
    "lit": "Kvitas",
    "esp": "Recibo"
   },
   "integration": {
    "eng": "Integration",
    "est": "Liidestus",
    "rus": "Интеграция",
    "fin": "Integraatio",
    "pol": "Integracja",
    "lav": "Integrācija",
    "swe": "Integrering",
    "lit": "Integracija",
    "esp": "Integración"
   },
   "whatERPAreYouUsing": {
    "eng": "Which accounting software do you use?",
    "est": "Millist raamatupidamistarkvara kasutad?",
    "rus": "Какую бухгалтерскую программу Вы используете?",
    "fin": "Mitä kirjanpito-ohjelmaa käytät?",
    "pol": "Jakiego oprogramowania księgowego używasz?",
    "lav": "Kuru grāmatvedības programmu Jūs lietojat?",
    "swe": "Vilket bokföringsprogram använder du?",
    "lit": "Kuria apskaitos programa naudojatės?",
    "esp": "¿Qué software de contabilidad utilizas?"
   },
   "receiptsWillBeDigitised": {
    "eng": "Document will be digitised",
    "est": "Kuludokument on digiteerimisel",
    "rus": "Документ будет оцифрован",
    "fin": "Tositteet digitoidaan",
    "pol": "Dokumenty zostaną zdigitalizowane",
    "lav": "Dokuments tiks digitalizēts",
    "swe": "Dokumentet kommer digitaliseras",
    "lit": "Dokumentas bus skaitmenintas",
    "esp": "El documento se digitalizará"
   },
   "forwardingReceiptFailed": {
    "eng": "Failed to forward the document",
    "est": "Dokumendi edastamine tarkvarasse ebaõnnestus",
    "rus": "Не удалось отправить документ в программу",
    "fin": "Tositteen lähetys ohjelmistoon epäonnistui",
    "pol": "Nie udało się przekazać dokumentu",
    "lav": "Neizdevās nosūtīt dokumentu uz programmatūru",
    "swe": "Misslyckades med att vidarebefodra dokument till program",
    "lit": "Nepavyko perkelti dokumento į programą",
    "esp": "No se pudo reenviar el documento"
   },
   "tripBeginning": {
    "eng": "Beginning of business trip",
    "est": "Lähetuse algus",
    "rus": "Начало командировки",
    "fin": "Matkan alkupäivä",
    "pol": "Początek podróży biznesowej",
    "lav": "Komandējuma sākums",
    "swe": "Affärsresans start",
    "lit": "Kelionės pradžia",
    "esp": "Inicio del viaje de negocios"
   },
   "tripEnd": {
    "eng": "End of business trip",
    "est": "Lähetuse lõpp",
    "rus": "Окончание командировки",
    "fin": "Matkan päättymispäivä",
    "pol": "Koniec podróży biznesowej",
    "lav": "Komandējuma beigas",
    "swe": "Affärsresans slut",
    "lit": "Kelionės pabaiga",
    "esp": "Fin del viaje de negocios"
   },
   "travelReport": {
    "eng": "Travel report",
    "est": "Lähetusaruanne",
    "rus": "Отчет о командировке",
    "fin": "Matkakuluraportti",
    "pol": "Raport z podróży",
    "lav": "Komandējuma atskaite",
    "swe": "Reserapport",
    "lit": "Kelionės ataskaita",
    "esp": "Informe de Viaje"
   },
   "advancePayment": {
    "eng": "Advance payment",
    "est": "Avanss",
    "rus": "Авансовый платеж",
    "fin": "Palkkaennakko",
    "pol": "Zaliczka",
    "lav": "Avansa maksājums",
    "swe": "Förskottsbetalning",
    "lit": "Išankstinis apmokėjimas",
    "esp": "Pago avanzado"
   },
   "insert": {
    "eng": "Insert",
    "est": "Sisesta",
    "rus": "Вставить",
    "fin": "Syötä",
    "pol": "Wstaw",
    "lav": "Ievietot",
    "swe": "Infoga",
    "lit": "Įterpti",
    "esp": "Insertar"
   },
   "integrationIsSetUp": {
    "eng": "integration is set up",
    "est": "liidestus on seadistatud",
    "rus": "интеграция установлена",
    "fin": "Integraatio on yhdistetty",
    "pol": "integracja została skonfigurowana",
    "lav": "integrācija ir izveidota",
    "swe": "integreringen är upprättad",
    "lit": "integracija sukurta",
    "esp": "la integración está configurad"
   },
   "receiptsAreAutomaticallyForwarded": {
    "eng": "Receipts are automatically forwarded to",
    "est": "Tšekid ja arved edastatakse automaatselt tarkvarasse",
    "rus": "Документы отправляются автоматически в",
    "fin": "Tositteet lähetetään automaattisesti",
    "pol": "Dokumenty zostaną automatycznie przekazane do",
    "lav": "Dokumenti tiek nosūtīti automātiski uz",
    "swe": "Kvitton är automatiskt vidarebefodrade till",
    "lit": "Dokumentai automatiškai siunčiami į",
    "esp": "Los recibos se reenvían automáticamente a"
   },
   "receiptsWillBeForwardedOneByOne": {
    "eng": "Documents will be forwarded one-by-one to",
    "est": "Kuludokumendid saadetakse ükshaaval tarkvarasse",
    "rus": "Документы будут отправлены по одному в",
    "fin": "Tositteet lähetetään yksi kerrallaan",
    "pol": "Dokumenty zostaną przekazane pojedynczo do",
    "lav": "Dokumenti tiks nosūtīti pa vienam uz",
    "swe": "Dokumenten kommer vidarebefodras ett i taget till",
    "lit": "Dokumentai po vieną bus siunčiami į",
    "esp": "Los documentos se remitirán uno por uno a"
   },
   "asSoonAsTheyAreDigitized": {
    "eng": "as soon as they are digitised",
    "est": "niipea, kui kõik kuludokumendid digiteeritud saavad",
    "rus": "как только они будут оцифрованы",
    "fin": "heti, kun ne on digitoitu",
    "pol": "natychmiast po digitalizacji",
    "lav": "tiklīdz tie būs digitalizēti",
    "swe": "så snart de är digitaliserade",
    "lit": "iškart po skaitmeninimo",
    "esp": "tan pronto como estén digitalizados"
   },
   "thisTakesAveragelynHours": {
    "eng": "On average this takes 7 hours",
    "est": "See võtab keskmiselt 7 tundi",
    "rus": "В среднем это занимает 7 часов",
    "fin": "Tämä kestää keskimäärin 7 tuntia",
    "pol": "Średnio zajmuje to 7 godzin",
    "lav": "Vidēji tas aizņem 7 stundas",
    "swe": "I genomsnitt tar det 7 timmar",
    "lit": "Vidutiniškai užtrunka 7 valandas",
    "esp": "En promedio, esto toma 7 horas."
   },
   "whenYouFInishDigitizingThem": {
    "eng": "when you finish digitising them",
    "est": ", kui te andmete sisestamise lõpetate",
    "rus": "когда Вы закончите их оцифровку",
    "fin": "kun olet lopettanut tositteiden digitoinnin",
    "pol": "po zakończeniu ich digitalizacji",
    "lav": "kad Jūs pabeigsiet to digitalizēšanu",
    "swe": "när du är färdig med att digitalisera dem",
    "lit": "kai baigsite juos skaitmeninti",
    "esp": ", cuando termine de digitalizarlos"
   },
   "yourReportsPDFCSVWillBeEmailedToYou": {
    "eng": "Report will be sent to your email",
    "est": "Aruanne saadetakse e-mailile",
    "rus": "Отчёты будут отправлены на указанный адрес",
    "fin": "Raportti lähetetään sähköpostiisi",
    "pol": "Raport zostanie wysłany na twój e-mail",
    "lav": "Pārskati tiks nosūtīti uz norādīto e-pastu",
    "swe": "Din rapport skickas till din e-post",
    "lit": "Ataskaita bus išsiųta į jūsų el. paštą",
    "esp": "El informe se enviará a su correo electrónico"
   },
   "whenYouClickEmailPDFCSVButtonYourReportWillBeEmailed": {
    "eng": "When you click on \"Report ready\", it will be emailed to",
    "est": "Kui vajutad nupule \"Aruanne valmis\", saadame aruande e-mailile",
    "rus": "Когда Вы нажмете на кнопку \"Отчет готов\", отчет будет отправлен по эл. почте",
    "fin": "Napauta 'Raportti valmis', niin se lähetetään sähköpostiin:",
    "pol": "Gdy klikniesz „Raport gotowy”, zostanie on wysłany do",
    "lav": "Kad Jūs noklikšķināsiet uz pogas \"e-mail PDF & CSV\", atskaite tiks nosūtīta Jums pa e-pastu.",
    "swe": "När du klickar på \"Rapport redo\" kommer rapporten skickas till",
    "lit": "Kai paspausite \"Ataskaita paruošta\", ataskaita bus išsiųsta į",
    "esp": "Cuando haga clic en \"Informe listo\", se enviará por correo electrónico a"
   },
   "tripMission": {
    "eng": "Purpose of travel",
    "est": "Lähetuse eesmärk ja sisu",
    "rus": "Цель поездки",
    "fin": "Työmatkan tarkoitus",
    "pol": "Cel podróży",
    "lav": "Komandējuma mērķis",
    "swe": "Syfte med resa",
    "lit": "Kelionės tikslas",
    "esp": "Propósito del viaje"
   },
   "reportIsForwaredToERP": {
    "eng": "Report will be forwarded to accounting",
    "est": "Aruanne edastatakse raamatupidamistarkvarasse",
    "rus": "Отчет будет переадресован бухгалтерскому отделу",
    "fin": "Raportti lähetetään kirjanpito-ohjelmaan",
    "pol": "Raport zostanie przesłany do księgowości",
    "lav": "Atskaite tiks pārsūtīta grāmatvedībai",
    "swe": "Rapport kommer vidarebefodras till bokföring",
    "lit": "Ataskaita bus išsiųsta apskaitai",
    "esp": "El informe se enviará a contabilidad."
   },
   "supplierRegNumber": {
    "eng": "Reg. code",
    "est": "Reg. kood",
    "rus": "Рег. код",
    "fin": "Y-tunnus",
    "pol": "REGON",
    "lav": "Reģ. Nr.",
    "swe": "Reg.-nummer",
    "lit": "Reg. kodas",
    "esp": "Reg. código"
   },
   "readTheTutorial": {
    "eng": "Read the tutorial",
    "est": "Loe õpetust",
    "rus": "Прочитать руководство",
    "fin": "Lue ohjeesta",
    "pol": "Przeczytaj instrukcję",
    "lav": "Lasīt pamācību",
    "swe": "Läs instruktionerna",
    "lit": "Skaityti instrukcijas",
    "esp": "Leer el tutorial"
   },
   "pleaseEnableTheIntegrationIn": {
    "eng": "Please enable the integration in",
    "est": "Palun seadista liidestus tarkvaras",
    "rus": "Разрешите интеграцию в",
    "fin": "Kytke rajapinta käyttöön ohjelmassa:",
    "pol": "Włącz integrację z",
    "lav": "Lūdzu, aktivizējiet integrāciju iekš",
    "swe": "Var god tillåt integrering i",
    "lit": "Įgalinkite integraciją",
    "esp": "Habilite la integración en"
   },
   "VATNumber": {
    "eng": "VAT number",
    "est": "KMKR number",
    "rus": "Номер плательщика НДС",
    "fin": "ALV-tunnus",
    "pol": "NIP",
    "lav": "PVN numurs",
    "swe": "VAT-nummer",
    "lit": "PVM kodas",
    "esp": "Número de Identificación Fiscal"
   },
   "VATAccountable": {
    "eng": "VAT applicable",
    "est": "KM. kohuslane",
    "rus": "Применимый НДС",
    "fin": "ALV-velvollinen",
    "pol": "Płatnik VAT",
    "lav": "Apliekams ar PVN",
    "swe": "VAT tillämpningsbar",
    "lit": "PVM taikytinas",
    "esp": "IVA aplicable"
   },
   "delete": {
    "eng": "Delete",
    "est": "Kustuta",
    "rus": "Удалить",
    "fin": "Poista",
    "pol": "Usuń",
    "lav": "Dzēst",
    "swe": "Radera",
    "lit": "Ištrinti",
    "esp": "Eliminar"
   },
   "addToReport": {
    "eng": "Add to report",
    "est": "Lisa aruandesse",
    "rus": "Добавить в отчет",
    "fin": "Lisää raporttiin",
    "pol": "Dodaj do raportu",
    "lav": "Pievienot pārskatam",
    "swe": "Lägg till i rapporten",
    "lit": "Pridėti prie ataskaitos",
    "esp": "Agregar al informe"
   },
   "control": {
    "eng": "Overview",
    "est": "Ülevaade",
    "rus": "Обзор",
    "fin": "Tarkista",
    "pol": "Przegląd",
    "lav": "Pārskats",
    "swe": "Översikt",
    "lit": "Apžvalga",
    "esp": "Descripción general"
   },
   "registerNewOrganisation": {
    "eng": "New organisation",
    "est": "Uus organisatsioon",
    "rus": "Новая организация",
    "fin": "Lisää tiliin uusi profiili",
    "pol": "Nowa organizacja",
    "lav": "Jauna organizācija",
    "swe": "Registrera ny organisation",
    "lit": "Registruoti naują organizaciją",
    "esp": "Registrar nueva organización"
   },
   "joinOrganisation": {
    "eng": "Join organisation",
    "est": "Liitu organisatsiooniga",
    "rus": "Присоединиться к организации",
    "fin": "Liity organisaatioon",
    "pol": "Dołącz do organizacji",
    "lav": "Pievienoties organizācijai",
    "swe": "Anslut till organisation",
    "lit": "Prisijungti prie organizacijos",
    "esp": "Unirse a la organización"
   },
   "skip": {
    "eng": "Skip",
    "est": "Jäta vahele",
    "rus": "Пропустить",
    "fin": "Ohita",
    "pol": "Pomiń",
    "lav": "Izlaist",
    "swe": "Hoppa över",
    "lit": "Praleisti",
    "esp": "Saltar"
   },
   "settings": {
    "eng": "Settings",
    "est": "Seaded",
    "rus": "Настройки",
    "fin": "Asetukset",
    "pol": "Ustawienia",
    "lav": "Iestatījumi",
    "swe": "Inställningar",
    "lit": "Nustatymai",
    "esp": "Configuración"
   },
   "myAccount": {
    "eng": "My account",
    "est": "Minu konto",
    "rus": "Моя учетная запись",
    "fin": "Oman tilini tiedot",
    "pol": "Moje konto",
    "lav": "Mans konts",
    "swe": "Mitt konto",
    "lit": "Mano paskyra",
    "esp": "Mi cuenta"
   },
   "change": {
    "eng": "Change",
    "est": "Muuda",
    "rus": "Изменить",
    "fin": "Muuta",
    "pol": "Zmień",
    "lav": "Mainīt",
    "swe": "Ändra",
    "lit": "Keisti",
    "esp": "Cambiar"
   },
   "addFromGallery": {
    "eng": "Add from Gallery",
    "est": "Lisa galeriist",
    "rus": "Добавить из галереи",
    "fin": "Lisää kuva galleriasta",
    "pol": "Dodaj z galerii",
    "lav": "Pievienot no galerijas",
    "swe": "Lägg till från galleri",
    "lit": "Pridėti iš Galerijos",
    "esp": "Agregar desde la galeria"
   },
   "takeAPhoto": {
    "eng": "Take a photo",
    "est": "Tee pilt",
    "rus": "Фотографировать",
    "fin": "Ota tositteesta kuva",
    "pol": "Zrób zdjęcie",
    "lav": "Fotografēt",
    "swe": "Ta foto",
    "lit": "Fotografuoti",
    "esp": "Tomar una foto"
   },
   "oopsLooksLikeOcrDidntFind": {
    "eng": "Oops. Looks like the robot cannot find anything on this document",
    "est": "Oih, arvuti ei leidnud selle dokumendi pealt midagi",
    "rus": "Похоже, что OCR (оптическое распознавание)ничего не обнаружило по данной квитанции",
    "fin": "No jopas, OCR-tekstinlukuohjelma ei tunnistanut tositteelta mitään tietoja",
    "pol": "Wygląda na to, że robot nie odczytał niczego w tym dokumencie",
    "lav": "Diemžēl, robots šajā dokumentā nav atradis datus",
    "swe": "Hoppsan, det verkar som att avläsningssystemet inte hittade något på kvittot.",
    "lit": "Atsiprašome, dokumente neradome jokių duomenų",
    "esp": "Oops. Parece que el robot no puede encontrar nada en este documento"
   },
   "destinationCountry": {
    "eng": "Destination (country)",
    "est": "Sihtkoht (riik)",
    "rus": "Пункт назначения (страна)",
    "fin": "Työmatkan kohde (maa)",
    "pol": "Miejsce docelowe (kraj)",
    "lav": "Galamērķis (valsts)",
    "swe": "Destination (land)",
    "lit": "Kelionės tikslas (valstybė)",
    "esp": "Destino (País)"
   },
   "purposeOfTravel": {
    "eng": "Purpose of travel",
    "est": "Lähetuse eesmärk ja sisu",
    "rus": "Цель поездки",
    "fin": "Työmatkan tarkoitus",
    "pol": "Cel podróży",
    "lav": "Komandējuma mērķis",
    "swe": "Syfte med resan",
    "lit": "Kelionės tikslas",
    "esp": "Propósito del viaje"
   },
   "meeting": {
    "eng": "Meeting",
    "est": "Koosolek",
    "rus": "Встреча",
    "fin": "Kokous",
    "pol": "Spotkanie",
    "lav": "Tikšanās",
    "swe": "Möte",
    "lit": "Susitikimas",
    "esp": "Reunión"
   },
   "conference": {
    "eng": "Conference",
    "est": "Konverents",
    "rus": "Конференция",
    "fin": "Konferenssi",
    "pol": "Konferencja",
    "lav": "Konference",
    "swe": "Konferens",
    "lit": "Konferencija",
    "esp": "Conferencia"
   },
   "businessTripStarted": {
    "eng": "Business trip started after 21:00",
    "est": "Lähetus algas pärast 21:00",
    "rus": "Деловая поездка началась после 21:00",
    "fin": "Työmatka alkoi kello 21.00:n jälkeen",
    "pol": "Podróż służbowa rozpoczęta po 21:00",
    "lav": "Komandējums sākās pēc 21:00",
    "swe": "Affärsresa påbörjades efter 21:00",
    "lit": "Verslo kelionė prasidėjo po 21:00",
    "esp": "El viaje de negocios comenzó después de las 21:00"
   },
   "arrivedBefore": {
    "eng": "Arrived before 3:00 AM",
    "est": "Saabusin enne 3:00",
    "rus": "Прибытие до 3:00",
    "fin": "Saapunut ennen klo 03.00:a",
    "pol": "Przyjazd przed godziną 3:00",
    "lav": "Ierašanās pirms 3:00",
    "swe": "Ankom innan 3:00",
    "lit": "Atvykta iki 3:00",
    "esp": "Llegó antes de las 3:00 A.M."
   },
   "noReceipts": {
    "eng": "No documents",
    "est": "Kuludokumente pole",
    "rus": "Нет документов",
    "fin": "Tositteita ei ole",
    "pol": "Brak dokumentów",
    "lav": "Nav dokumentu",
    "swe": "Inga kvitton",
    "lit": "Kvitų nėra",
    "esp": "Sin recibos"
   },
   "resendEmail": {
    "eng": "Resend the email",
    "est": "Saatke email uuesti",
    "rus": "Отправить электронное письмо еще раз",
    "fin": "Lähetä uudelleen sähköpostiin",
    "pol": "Wyślij wiadomość ponownie",
    "lav": "Pārsūtīt e-pastu vēlreiz",
    "swe": "Skicka e-post igen",
    "lit": "Išsiųsti dar kartą",
    "esp": "Vuelva a enviar el correo electrónico"
   },
   "changeEmail": {
    "eng": "Change email",
    "est": "Muuda meiliaadressi",
    "rus": "Изменить эл. почту",
    "fin": "Muuta sähköpostiosoitetta",
    "pol": "Zmień adres e-mail",
    "lav": "Mainīt e-pastu",
    "swe": "Ändra e-post",
    "lit": "Pakeisti el. paštą",
    "esp": "Cambiar e-mail"
   },
   "congratulationYourAccountIsReady": {
    "eng": "Congratulations, your account is ready!",
    "est": "Hurraa! Konto on valmis!",
    "rus": "Поздравляем, Ваша учетная запись создана!",
    "fin": "Onneksi olkoon, tilinne on käyttövalmis!",
    "pol": "Gratulacje, twoje konto jest gotowe!",
    "lav": "Apsveicam, Jūsu konts ir gatavs!",
    "swe": "Grattis, ditt konto är redo!",
    "lit": "Sveikiname! Jūsų paskyra paruošta.",
    "esp": "¡Felicitaciones, su cuenta está lista!"
   },
   "vrt": {
    "eng": "VAT %",
    "est": "Km %",
    "rus": "НДС %",
    "fin": "ALV %",
    "pol": "Stawka VAT %",
    "lav": "PVN %",
    "swe": "VAT %",
    "lit": "PVM dydis %",
    "esp": "Tipo de IVA %"
   },
   "vat": {
    "eng": "VAT",
    "est": "KM",
    "rus": "НДС",
    "fin": "ALV",
    "pol": "VAT",
    "lav": "PVN",
    "swe": "VAT",
    "lit": "PVM",
    "esp": "IVA"
   },
   "snv": {
    "eng": "Net",
    "est": "Neto",
    "rus": "Нетто",
    "fin": "Veroton hinta",
    "pol": "Netto",
    "lav": "Netto",
    "swe": "Netto",
    "lit": "Neto",
    "esp": "Neto"
   },
   "inboxForUnentered": {
    "eng": "Email for the Directo mailbox (example: database_name@data.directo.ee)",
    "est": "Directo postkasti liidese email (näide: andmebaas@data.directo.ee)",
    "rus": "Aдрес для создания интеграции",
    "fin": "Kansio lähettämättömille liitetiedostoille",
    "pol": "Skrzynka dla niewprowadzonych załączników",
    "lav": "Adrese, lai izveidotu integrāciju",
    "swe": "Inkorg för ej angivna bilagor",
    "lit": "Dėžutė neįvestiems priedams",
    "esp": "Bandeja de entrada para archivos adjuntos no ingresados"
   },
   "youHaveToGoTo": {
    "eng": "Go to",
    "est": "Minge tarkvarasse",
    "rus": "Вам необходимо перейти в",
    "fin": "Sinun täytyy mennä",
    "pol": "Idź do",
    "lav": "Jums jādodas uz",
    "swe": "Gå till",
    "lit": "Eikite į",
    "esp": "Ir a"
   },
   "toEnableReceivingEinvoices": {
    "eng": "to enable integration with CostPocket (using e-invoices)",
    "est": ", et aktiveerida liidestus CostPocketiga (kasutades e-arveid)",
    "rus": "для разрешения интеграции с CostPocket(используя электронный счет)",
    "fin": "jotta verkkolaskujen käsittely CostPocketissa onnistuisi",
    "pol": "umożliwienie integracji z CostPocket (za pomocą e-faktur)",
    "lav": "iespējot integrāciju ar CostPocket (izmantojot e-rēķinus)",
    "swe": "möjliggör integrering med CostPocket (med användning av e-fakturor)",
    "lit": "aktyvuoti integraciją su CostPocket (naudojant e. sąskaitas)",
    "esp": "para permitir la integración con CostPocket (usando facturas electrónicas)"
   },
   "operator": {
    "eng": "e-invoicing operator",
    "est": "e-arve operaatorteenus",
    "rus": "оператор",
    "fin": "verkkolaskuoperaattori",
    "pol": "operator e-faktur",
    "lav": "operators",
    "swe": "e-fakturaoperatör",
    "lit": "e. sąskaitų operatorius",
    "esp": "Operador de facturación electrónica"
   },
   "yes": {
    "eng": "Yes",
    "est": "Jah",
    "rus": "Да",
    "fin": "Kyllä",
    "pol": "Tak",
    "lav": "Jā",
    "swe": "Ja",
    "lit": "Taip",
    "esp": "Yes"
   },
   "no": {
    "eng": "No",
    "est": "Ei",
    "rus": "Нет",
    "fin": "Ei",
    "pol": "Nie",
    "lav": "Nē",
    "swe": "Nej",
    "lit": "Ne",
    "esp": "No"
   },
   "termsOfService": {
    "eng": "Terms of Service",
    "est": "Kasutustingimused",
    "rus": "Условия предоставления услуг",
    "fin": "Palvelun käyttöehdot",
    "pol": "Warunki świadczenia usług",
    "lav": "Pakalpojumu sniegšanas noteikumi",
    "swe": "Användarvillkor",
    "lit": "Naudojimo sąlygos",
    "esp": "Términos de servicio"
   },
   "privacyPolicy": {
    "eng": "Privacy Policy",
    "est": "Privaatsuspoliitika",
    "rus": "Политика конфиденциальности",
    "fin": "Tietosuojaseloste",
    "pol": "Polityka prywatności",
    "lav": "Privātuma politika",
    "swe": "Integritetspolicy",
    "lit": "Privatumo politika",
    "esp": "Política de privacidad"
   },
   "readingData": {
    "eng": "Reading data",
    "est": "Otsin andmeid",
    "rus": "Чтение данных",
    "fin": "Luetaan tietoja",
    "pol": "Odczyt danych",
    "lav": "Notiek datu lasīšana",
    "swe": "Läser data",
    "lit": "Skaitomi duomenys",
    "esp": "Lectura de datos"
   },
   "addVAT": {
    "eng": "Add VAT line",
    "est": "Lisa KM rida",
    "rus": "Добавить строку НДС",
    "fin": "Lisää ALV-rivi",
    "pol": "Dodaj VAT",
    "lav": "Pievienot PVN rindu",
    "swe": "Lägg till VAT-rad",
    "lit": "Pridėti PVM",
    "esp": "Agregar línea de IVA"
   },
   "edit": {
    "eng": "Edit",
    "est": "Muuda",
    "rus": "Редактировать",
    "fin": "Muuta",
    "pol": "Edytuj",
    "lav": "Labot",
    "swe": "Ändra",
    "lit": "Redaguoti",
    "esp": "Editar"
   },
   "EmailExpected": {
    "eng": "Please insert a valid email address",
    "est": "Palun sisestage korrektne e-posti aadress",
    "rus": "Пожалуйста, введите верный адрес электронной почты",
    "fin": "Syötä voimassa oleva sähköpostiosoite",
    "pol": "Podaj prawidłowy adres e-mail",
    "lav": "Lūdzu, ievadiet derīgu e-pasta formātu",
    "swe": "Var god fyll i en giltlig e-postadress",
    "lit": "Įveskite galiojantį el. pašto adresą",
    "esp": "Introduzca una dirección de correo electrónico válida."
   },
   "tripProcessing": {
    "eng": "Processing",
    "est": "Töötluses",
    "rus": "Обрабатывается",
    "fin": "Käsitellään",
    "pol": "Przetwarzanie",
    "lav": "Notiek apstrāde",
    "swe": "Bearbetar",
    "lit": "Apdorojama",
    "esp": "Procesando"
   },
   "uploadingWarning": {
    "eng": "Upload in progress, please don't close the app",
    "est": "Dokumenti laaditakse, palun ära sulge äppi",
    "rus": "Квитанция загружается, не закрывайте приложение",
    "fin": "Kuittia ladataan, älä sulje sovellusta",
    "pol": "Dokument jest w trakcie wysyłki, nie zamykaj aplikacji",
    "lav": "Čeks tiek augšuplādēts, lūdzu, neaizveriet lietotni",
    "swe": "Kvittot laddas upp, var god stäng inte appen",
    "lit": "Kvitas įkeliamas, neuždarykite programėlės",
    "esp": "El recibo se está cargando, no cierre la aplicación"
   },
   "sendReceiptToOutbound": {
    "eng": "Submit again",
    "est": "Edasta uuesti",
    "rus": "Отправить документ вашей бухгалтерской программе",
    "fin": "Lähetä kuitti taloushallinto-ohjelmaan",
    "pol": "Prześlij ponownie",
    "lav": "Atkārtoti pārsūtīt dokumentu uz grāmatvedības programmatūru",
    "swe": "Skicka kvitto till ditt bokföringsprogram",
    "lit": "Dar kartą siųsti kvitą apskaitai",
    "esp": "Enviar de nuevo"
   },
   "receiptSent": {
    "eng": "Receipt submitted",
    "est": "Dokument on edukalt edastatud",
    "rus": "Документ отправлен",
    "fin": "Kuitti on lähetetty ohjelmistoon",
    "pol": "Dokument został wysłany",
    "lav": "Dokuments ir nosūtīts",
    "swe": "Kvittot har skickats till program",
    "lit": "Kvitas išsiųstas",
    "esp": "Recibo enviado"
   },
   "acceptRequirement": {
    "eng": "I have read and agree to the",
    "est": "Olen tutvunud ja nõustun programmi",
    "rus": "Я прочитал и согласен с",
    "fin": "Olen lukenut ja hyväksynyt",
    "pol": "Przeczytałem i zgadzam się",
    "lav": "Esmu izlasījis un piekrītu",
    "swe": "Jag har läst och godkänner",
    "lit": "Perskaičiau ir sutinku su",
    "esp": "he leído y estoy de acuerdo con"
   },
   "termsOfServices": {
    "eng": "Terms of Service",
    "est": "Kasutustingimustega",
    "rus": "Условия предоставления услуг",
    "fin": "Palvelun käyttöehdot",
    "pol": "Warunki świadczenia usług",
    "lav": "Pakalpojumu sniegšanas noteikumi",
    "swe": "Användarvillkor",
    "lit": "Naudojimo sąlygos",
    "esp": "Términos de servicio"
   },
   "privacyPolicys": {
    "eng": "Privacy Policy",
    "est": "Privaatsuspoliitikaga",
    "rus": "Политика конфиденциальности",
    "fin": "Tietosuojaseloste",
    "pol": "Polityka Prywatności",
    "lav": "Privātuma politika",
    "swe": "Integritetspolicy",
    "lit": "Privatumo politika",
    "esp": "Política de privacidad"
   },
   "digitized": {
    "eng": "Digitised",
    "est": "Digiteeritud",
    "rus": "Оцифровано",
    "fin": "Muutettu digitaaliseksi",
    "pol": "Zdigitalizowano",
    "lav": "Digitalizēts",
    "swe": "Digitaliserad",
    "lit": "Skaitmeninta",
    "esp": "Digitalizado"
   },
   "reviewResults": {
    "eng": "Review results",
    "est": "Kontrolli andmed",
    "rus": "Обзор результатов",
    "fin": "Tarkista tulokset",
    "pol": "Sprawdź wyniki",
    "lav": "Pārskatiet rezultātus",
    "swe": "Granska resultat",
    "lit": "Peržiūrėti rezultatus",
    "esp": "Revisar resultados"
   },
   "youCanSubmitOffline": {
    "eng": "You can submit receipts now. Images will be uploaded, when you're back online.",
    "est": "Dokumente saab lisada. Pildid saadame serverisse, kui internet taastub.",
    "rus": "Вы можете подать документы сейчас. Изображения будут загружены, когда восстановится соединение с интернетом.",
    "fin": "Voit lähettää kuitit nyt. Kuvat ladataan yhteyden palattua.",
    "pol": "Możesz wysyłać rachunki. Obrazy zostaną pobrane po przywróceniu połączenia z internetem.",
    "lav": "Tagad varat iesniegt dokumentus. Attēli tiks augšupielādēti, kad atgriezīsieties tiešsaistē.",
    "swe": "Du kan skicka in kvitton nu. Bilderna kommer laddas upp nästa gång du är online.",
    "lit": "Galite pateikti kvitus dabar. Nuotraukas įkelsime, kai bus interneto ryšys",
    "esp": "Puede enviar recibos ahora. Las imágenes se cargarán cuando vuelva a estar en línea"
   },
   "eInvoice": {
    "eng": "Self service + invoices",
    "est": "Iseteenindus + arved",
    "rus": "Самообслуживание + счета",
    "fin": "Itsepalvelu + laskut",
    "pol": "Samoobsługa + faktury",
    "lav": "Pašapkalpošanās + rēķini",
    "swe": "Självservice + fakturor",
    "lit": "Savitarna + sąskaitos",
    "esp": "Autoservicio + facturas"
   },
   "digitizeManually": {
    "eng": "Digitise manually",
    "est": "Digiteerin ise",
    "rus": "Оцифровать вручную",
    "fin": "Digitoi manuaalisesti",
    "pol": "Wprowadź dane ręcznie",
    "lav": "Digitalizēt manuāli",
    "swe": "Digitalisera manuellt",
    "lit": "Skaitmeninti rankiniu būdu",
    "esp": "Digitalizar manualmente"
   },
   "first5EveryMonth": {
    "eng": "5 free documents every month",
    "est": "Tasuta kuni 5 tšekki (iga kuu)",
    "rus": "5 бесплатных документов каждый месяц",
    "fin": "5 ilmaista kuittia joka kuukausi",
    "pol": "5 dokumentów za darmo miesięcznie",
    "lav": "5 bezmaksas digitalizācijas katru mēnesi",
    "swe": "5 dokument gratis varje månad",
    "lit": "5 kvitai nemokamai per mėnesį",
    "esp": "5 documentos gratis cada mes"
   },
   "freeDemo": {
    "eng": "Free Demo",
    "est": "Tasuta demo",
    "rus": "Бесплатная демо-версия",
    "fin": "Ilmainen kokeiluversio",
    "pol": "Darmowa wersja Demo",
    "lav": "Bezmaksas izmēģinājuma versija",
    "swe": "Gratis demo",
    "lit": "Nemokamas Demo",
    "esp": "Demo Gratis"
   },
   "thisMonth": {
    "eng": "This month",
    "est": "See kuu",
    "rus": "В этом месяце",
    "fin": "Tässä kuussa",
    "pol": "Ten miesiąc",
    "lav": "Šomēnes",
    "swe": "Denna månad",
    "lit": "Šis mėnuo",
    "esp": "Este Mes"
   },
   "clickToUpgrade": {
    "eng": "Click here to upgrade your plan",
    "est": "Vajuta siia, et uuendada oma hinnastamise plaani",
    "rus": "Нажмите здесь, чтобы выбрать план",
    "fin": "Klikkaa tästä päivittääksesi tilauksesi",
    "pol": "Kliknij tutaj, aby uaktualnić swój plan",
    "lav": "Nospiediet, lai izvēlētos plānu",
    "swe": "Klicka här för att uppgradera ditt paket",
    "lit": "Spustelėkite atnaujinti planą",
    "esp": "Haga clic aquí para actualizar su plan"
   },
   "freeDemoOnly5Receipts": {
    "eng": "Document limit for Free Demo reached",
    "est": "Tasuta demo (5 tasuta dokumenti / kuus)",
    "rus": "Достигнут предел демо-версии",
    "fin": "Ilmainen kokeiluversio (5 kuittia ilmaiseksi / kuukausi)",
    "pol": "Osiągnięto limit dokumentów w bezpłatnej wersji Demo",
    "lav": "Izmēģinājuma dokumentu limits ir sasniegts",
    "swe": "Dokumentgräns för Gratis Demo uppnådd",
    "lit": "Nemokamų dokumentų limitas pasiektas",
    "esp": "Se alcanzó el límite de documentos para la demostración gratuita"
   },
   "ok": {
    "eng": "OK",
    "est": "OK",
    "rus": "OK",
    "fin": "OK",
    "pol": "OK",
    "lav": "OK",
    "swe": "OK",
    "lit": "OK",
    "esp": "Ok"
   },
   "clickMe": {
    "eng": "Click here",
    "est": "Kliki siia",
    "rus": "Нажмите здесь",
    "fin": "Klikkaa minua",
    "pol": "Kliknij tutaj",
    "lav": "Noklikšķināt šeit",
    "swe": "Klicka här",
    "lit": "Spausk čia",
    "esp": "haga clic aquí"
   },
   "receiptNotSynced": {
    "eng": "Receipt not synced",
    "est": "Dokument pole sünkroonitud",
    "rus": "Документ не синхронизирована",
    "fin": "Kuittia ei ole synkronoitu",
    "pol": "Dokumenty nie są zsynchronizowane",
    "lav": "Dokuments nav sinhronizēts",
    "swe": "Kvittot har inte synkroniserats",
    "lit": "Kvitas nesinchronizuotas",
    "esp": "Recibo no sincronizado"
   },
   "offline": {
    "eng": "Offline",
    "est": "Internetiühenduseta",
    "rus": "Нет соединения",
    "fin": "Verkon ulkopuolella",
    "pol": "Offline",
    "lav": "Nav pieslēguma",
    "swe": "Offline",
    "lit": "Nėra ryšio",
    "esp": "Desconectado"
   },
   "UserAlreadyExists": {
    "eng": "User already exists",
    "est": "Selline kasutaja on juba olemas",
    "rus": "Такой пользователь уже существует",
    "fin": "Käyttäjä on jo olemassa",
    "pol": "Użytkownik o tej nazwie już istnieje",
    "lav": "Lietotājs jau eksistē",
    "swe": "Användare finns redan",
    "lit": "Vartotojas jau egzistuoja",
    "esp": "La usuario ya existe"
   },
   "PWRecoveryInvalidToken": {
    "eng": "Password reset link is invalid",
    "est": "Parooli uuendamise link on ebakorrektne",
    "rus": "Ссылка для сброса пароля недействительна",
    "fin": "Salasanan palautuslinkki ei toimi",
    "pol": "Link do resetowania hasła jest nieprawidłowy",
    "lav": "Paroles atiestatīšanas saite ir nederīga",
    "swe": "Länken till lösenordsåterställningen är ogiltlig",
    "lit": "Slaptažodžio keitimo nuoroda netinka",
    "esp": "El enlace de restablecimiento de contraseña no es válido"
   },
   "PWRecoveryTimeout": {
    "eng": "Password recovery link is expired",
    "est": "Parooli taastamise link on kehtetu",
    "rus": "Действие ссылки для восстановления пароля истекло",
    "fin": "Salasanan palautuslinkki on vanhentunut",
    "pol": "Link do resetowania hasła wygasł",
    "lav": "Paroles atgūšanas saites derīguma termiņš ir beidzies",
    "swe": "Länken till lösenordsåterställningen har gått ut",
    "lit": "Slaptažodžio keitimo nuoroda nebegalioja",
    "esp": "El enlace de recuperación de contraseña caducó"
   },
   "newLinkSentToEmail": {
    "eng": "New link has been sent to your email",
    "est": "Uus link on saadetud teie emailile",
    "rus": "На ваш электронный адрес отправлена новая ссылка",
    "fin": "Uusi linkki on lähetetty sähköpostiisi",
    "pol": "Nowy link został wysłany na twój e-mail",
    "lav": "Saite ir nosūtīta uz Jūsu e-pastu",
    "swe": "Ny länk har skickats till din e-post",
    "lit": "Nauja nuoroda išsiųsta į jūsų el. paštą",
    "esp": "Se ha enviado un nuevo enlace a su correo electrónico."
   },
   "sendLinkAgain": {
    "eng": "Error, please send the link again",
    "est": "Tekkis viga, proovige link uuesti saata",
    "rus": "Произошла ошибка, попробуйте отправить ссылку еще раз",
    "fin": "Tapahtui virhe, lähetä linkki uuudestaan.",
    "pol": "Wystąpił błąd, wyślij link ponownie",
    "lav": "Radās kļūda. Lūdzu, vēlreiz nosūtiet saiti.",
    "swe": "Det uppstod ett fel, var god skicka länken igen",
    "lit": "Klaida, bandykite siųsti nuorodą dar kartą",
    "esp": "Error, vuelva a enviar el enlace."
   },
   "emailConfirmed": {
    "eng": "Email confirmed!",
    "est": "Email kinnitatud",
    "rus": "Электронный адрес подтвержден!",
    "fin": "Sähköpostiosoite vahvistettu!",
    "pol": "Adres e-mail został potwierdzony",
    "lav": "E-pasta adrese apstiprināta!",
    "swe": "E-post bekräftad!",
    "lit": "El. paštas patvirtintas!",
    "esp": "Correo Electrónico confirmado!"
   },
   "pleaseContinueInApp": {
    "eng": "Please continue in the app",
    "est": "Palun jätkake äpis",
    "rus": "Продолжить работу в приложении",
    "fin": "Jatka sovelluksessa",
    "pol": "Aby kontynuować, przejdź do aplikacji",
    "lav": "Lūdzu, turpiniet aplikācijā",
    "swe": "Var god fortsätt i appen",
    "lit": "Tęskite programėlėje",
    "esp": "Continúe en la aplicación"
   },
   "logInOrRegister": {
    "eng": "Log in or create account",
    "est": "Logi sisse või registreeri kasutaja",
    "rus": "Войдите или зарегистрируйтесь",
    "fin": "Kirjaudu sisään tai luo tili",
    "pol": "Zaloguj się lub zarejestruj",
    "lav": "Ienākt vai reģistrēties",
    "swe": "Logga in eller skapa konto",
    "lit": "Prisijungti arba sukurti paskyrą",
    "esp": "Iniciar sesión o crear una cuenta"
   },
   "appleGetAppBanner": {
    "eng": "Get the app for FREE!",
    "est": "TASUTA - App Store'is",
    "rus": "Скачать бесплатно в App Store",
    "fin": "ILMAISEKSI - App Storesta",
    "pol": "Pobierz aplikację ZA DARMO!",
    "lav": "Lejuplādējiet bez maksas!",
    "swe": "Ladda ner appen GRATIS!",
    "lit": "Gaukite programėlę NEMOKAMAI!",
    "esp": "¡Obtén la aplicación GRATIS!"
   },
   "androidGetAppBanner": {
    "eng": "FREE - In Google Play",
    "est": "TASUTA - Google Play-s",
    "rus": "БЕСПЛАТНО - в Google Play",
    "fin": "ILMAISEKSI - Google Play -kaupasta",
    "pol": "ZA DARMO - w Google Play",
    "lav": "BEZMAKSAS veikalā Google Play",
    "swe": "GRATIS - i Google Play",
    "lit": "NEMOKAMAI - Google Play",
    "esp": "GRATIS - En Google Play"
   },
   "you": {
    "eng": "You",
    "est": "Sina",
    "rus": "Вы",
    "fin": "Sinä",
    "pol": "Ty",
    "lav": "Jūs",
    "swe": "Du",
    "lit": "Jūs",
    "esp": "Tu"
   },
   "notDistinct": {
    "eng": "Duplicate",
    "est": "Topelt",
    "rus": "Дубликат",
    "fin": "Kopio",
    "pol": "Duplikat",
    "lav": "Dublikāts",
    "swe": "Duplicera",
    "lit": "Duplikatas",
    "esp": "Duplicar"
   },
   "tfo": {
    "eng": "Estonian e-invoice operators (Telema, Fitek, Omniva)",
    "est": "Eesti e-arvete operaatorid (Telema, Fitek, Omniva)",
    "rus": "Эстонские операторы электронных счетов (Telema, Fitek, Omniva)",
    "fin": "Viron e-lasku operaattorit (Telema, Fitek, Omniva)",
    "pol": "Estońscy operatorzy e-faktur (Telema, Fitek, Omniva)",
    "lav": "Igaunijas elektronisko rēķinu operatori (Telema, Fitek, Omniva)",
    "swe": "Estniska e-fakturaoperatörer (Telema, Fitek, Omniva)",
    "lit": "Estijos e. sąskaitų operatoriai (Telema, Fitek, Omniva)",
    "esp": "Operadores de factura electrónica de Estonia (Telema, Fitek, Omniva)"
   },
   "regCodeAlreadyRegistered": {
    "eng": "Organisation with such registration code is already registered",
    "est": "See organisatsioon on juba registreeritud",
    "rus": "Эта организация уже зарегистрирована",
    "fin": "Tämä organisaatio on jo rekisteröity",
    "pol": "Ta firma jest już zarejestrowana",
    "lav": "Organizācija ar šādu reģistrācijas numuru jau ir reģistrēta",
    "swe": "Denna organisation finns redan registrerad",
    "lit": "Organizacija su šiuo registracijos kodu jau yra sistemoje",
    "esp": "Esta organización ya se encuentra registrada"
   },
   "editReport": {
    "eng": "Edit report",
    "pol": "Edytuj raport",
    "est": "Muuda aruannet",
    "rus": "Редактировать отчет",
    "fin": "Muokkaa raporttia",
    "lav": "Rediģēt pārskatu",
    "swe": "Ändra rapport",
    "lit": "Redaguoti ataskaitą",
    "esp": "Editar informe"
   },
   "name": {
    "eng": "Name",
    "pol": "Nazwa",
    "est": "Nimi",
    "rus": "Название",
    "fin": "Nimi",
    "lav": "Nosaukums",
    "swe": "Namn",
    "lit": "Pavadinimas",
    "esp": "Nombre"
   },
   "iban": {
    "eng": "Personal IBAN",
    "pol": "IBAN",
    "est": "Kasutaja pangakontonumber",
    "rus": "Личный IBAN",
    "fin": "Henkilökohtainen pankkitili",
    "lav": "Personīgais IBAN numurs",
    "swe": "Personligt IBAN",
    "lit": "Privatus IBAN",
    "esp": "IBAN Personal"
   },
   "selectUser": {
    "eng": "Select from existing users",
    "pol": "Wybierz spośród istniejących użytkowników",
    "est": "Vali juba registreeritud kasutaja",
    "rus": "Выбрать из существующих пользователей",
    "fin": "Valitse olemassa olevista käyttäjistä",
    "lav": "Izvēlēties no esošajiem lietotājiem",
    "swe": "Välj bland befintliga användare",
    "lit": "Pasirinkti iš egzistuojančių vartotojų",
    "esp": "Seleccionar desde los usuarios existentes"
   },
   "removeUser": {
    "eng": "Remove user",
    "pol": "Usuń użytkownika",
    "est": "Eemalda kasutaja",
    "rus": "Удалить пользователя",
    "fin": "Poista käyttäjä",
    "lav": "Dzēst lietotāju",
    "swe": "Ta bort användare",
    "lit": "Pašalinti vartotoją",
    "esp": "Remover usuario"
   },
   "notValidIban": {
    "eng": "Invalid IBAN",
    "pol": "Nieprawidłowy numer IBAN",
    "est": "IBAN ei ole korrektne",
    "rus": "Недействительный IBAN",
    "fin": "Väärä pankkitili",
    "lav": "Nederīgs IBAN numurs",
    "swe": "Ogiltligt IBAN",
    "lit": "Netinkamas IBAN",
    "esp": "IBAN invalido"
   },
   "ibanMissing": {
    "eng": "IBAN missing",
    "pol": "Brak numeru IBAN",
    "est": "IBAN puudub",
    "rus": "Отсутствует IBAN",
    "fin": "Pankkitili puuttuu",
    "lav": "Trūkst IBAN numura",
    "swe": "IBAN saknas",
    "lit": "Trūksta IBAN",
    "esp": "Falta IBAN"
   },
   "userNameMissing": {
    "eng": "User name missing",
    "pol": "Brak nazwy użytkownika",
    "est": "Kasutaja nimi puudub",
    "rus": "Отсутствует имя пользователя",
    "fin": "Käyttäjänimi puuttuu",
    "lav": "Trūkst lietotāja vārda",
    "swe": "Användarnamn saknas",
    "lit": "Trūksta vartotojo vardo",
    "esp": "Falta Nombre de usuario",
    "nor": "Brukernavn mangler"
   },
   "somethingWentWrong": {
    "eng": "Something went wrong",
    "pol": "Coś poszło nie tak",
    "est": "Miski läks valesti",
    "rus": "Что-то пошло не так",
    "fin": "Jokin meni pieleen",
    "lav": "Kaut kas neizdevās",
    "swe": "Något gick fel",
    "lit": "Kažkas nepavyko",
    "esp": "Algo salió mal",
    "nor": "Noe gikk galt"
   },
   "deleteReport": {
    "eng": "Delete report",
    "pol": "Usuń raport",
    "est": "Kustuta aruanne",
    "rus": "Удалить отчет",
    "fin": "Poista raportti",
    "lav": "Dzēst pārskatu",
    "swe": "Radera rapport.",
    "lit": "Pašalinti ataskaitą",
    "esp": "Eliminar Informe",
    "nor": "Slett rapport"
   },
   "deleteReceipts": {
    "eng": "Would you also like to delete the receipts inside the report?",
    "pol": "Czy chcesz także usunąć dokumenty znajdujące się w raporcie?",
    "est": "Kas soovite kustutada ka dokumendid selle aruande seest?",
    "rus": "Удалить также квитанции, которые были внутри отчета?",
    "fin": "Haluatko myös poistaa raportin sisällä olevat kuitit?",
    "lav": "Vai vēlaties dzēst arī dokumentus, kas atradās pārskatā?",
    "swe": "Vill du även radera kvittona från rapporten?",
    "lit": "Ar pašalinti ataskaitoje buvusius  kvitus?",
    "esp": "¿También le gustaría eliminar los recibos dentro del informe?",
    "nor": "Vil du også slette kvitteringene inne i rapporten?"
   },
   "saved": {
    "eng": "Saved",
    "pol": "Zapisano",
    "est": "Salvestatud",
    "rus": "Сохранено",
    "fin": "Tallennettu",
    "lav": "Saglabāts",
    "swe": "Sparad",
    "lit": "Išsaugota",
    "esp": "Guardado",
    "nor": "Lagret"
   },
   "youSure": {
    "eng": "Are you sure",
    "pol": "Jesteś pewien",
    "est": "Oled kindel",
    "rus": "Вы уверены",
    "fin": "Oletko varma",
    "lav": "Vai esat pārliecināts",
    "swe": "Är du säker",
    "lit": "Ar esate tikras",
    "esp": "Esta seguro",
    "nor": "Er du sikker"
   },
   "lastComments": {
    "eng": "Lately used comments",
    "pol": "Ostatnio używane komentarze",
    "est": "Viimati kasutatud kommentaarid",
    "rus": "Недавно использованные комментарии",
    "fin": "Viimeksi käytetyt kommentit",
    "lav": "Pēdējie lietotie komentāri",
    "swe": "Senast använda kommentarer",
    "lit": "Paskutiniai naudoti komentarai",
    "esp": "Comentarios usados últimamente",
    "nor": "Nylig brukte kommentarer"
   },
   "addReceiptAndComments": {
    "eng": "Capture your receipts, we digitise them",
    "pol": "Zrób zdjęcia paragonów, a my przetworzymy je dla Ciebie",
    "est": "Digiteerime Sinu pildistatud tšekid",
    "rus": "Сфотографируйте чеки, и мы их оцифруем",
    "fin": "Kuvaa kuittisi, me digitoimme ne",
    "lav": "Fotografējiet čekus, lai tos digitalizētu",
    "swe": "Ta en bild på dina kvitton, vi digitaliserar dem",
    "lit": "Fotografuokite kvitus ir mes juos suskaitmeninsime",
    "esp": "Captura tus recibos, los digitalizamos",
    "nor": "Ta vare på kvitteringene dine, vi digitaliserer dem"
   },
   "SendReportWithEmail": {
    "eng": "Email invoices for digitisation",
    "pol": "Wysyślij faktury w celu digitalizacji",
    "est": "Saada arved e-mailiga digiteerimisele",
    "rus": "Отправляйте счета на этот адрес для оцифровки",
    "fin": "Sähköpostilaskut digitoitavaksi",
    "lav": "Pavadzīmju un rēķinu digitalizācija no Jūsu e-pasta",
    "swe": "Skicka fakturor via e-post för digitalisering",
    "lit": "Siųskite sąskaitas skaitmeninimui",
    "esp": "Facturas por correo electrónico para digitalización",
    "nor": "E-postfakturaer for digitalisering"
   },
   "ConnectCpWithIntegration": {
    "eng": "Integrate it with your accounting software",
    "pol": "Zintegruj ze swoim oprogramowaniem księgowym",
    "est": "Ühenda meid oma raamatupidamistarkvaraga täisdigitaalseks kogemuseks",
    "rus": "Интегрируйте CostPocket с вашим бухгалтерским программным обеспечением",
    "fin": "Integroi palvelu kirjanpito-ohjelmaasi ja hyödynnä digitalisoinnin tuomat hyödyt",
    "lav": "Izveidojiet integrāciju ar grāmatvedības sistēmu",
    "swe": "Integrera med ditt bokföringsprogram",
    "lit": "Integruokite su jūsų naudojama apskaitos programa",
    "esp": "Intégralo con tu software de contabilidad",
    "nor": "Integrer den med regnskapsprogramvaren din"
   },
   "fetchReportFromEmail": {
    "eng": "Get digital expense reports to your email",
    "pol": "Otrzymuj raporty wydatków na adres e-mail",
    "est": "Telli valmis aruanne e-mailile",
    "rus": "Получить отчеты о расходах на свой e-mail",
    "fin": "Tilaa sähköinen kuluraportti sähköpostiisi",
    "lav": "Saņemiet digitalizētus izdevumu pārskatus savā e-pastā",
    "swe": "Få digitala utgiftsredovisningar till din e-post",
    "lit": "Gaukite išlaidų ataskaitas el. paštu",
    "esp": "Obtenga informes de gastos digitales en su correo electrónico",
    "nor": "Har du fakturaer i e-posten din? Videresend dokumenter til denne e-posten"
   },
   "sendDocumentsTo": {
    "eng": "Save time and forward invoices from your email to",
    "pol": "Oszczędzaj czas i prześlij faktury ze swojej poczty e-mail na",
    "est": "Säästke aega ja edastage arved oma meilist aadressile",
    "rus": "Пересылайте счета из вашей эл. почты на",
    "fin": "Onko sinulla laskuja sähköpostissasi? Lähetä tositteet tähän sähköpostiosoitteeseen",
    "lav": "Pārsūtiet dokumentus no sava e-pasta uz",
    "swe": "Har du fakturor på din e-post? Vidarebefodra dokument till",
    "lit": "Ar turite sąskaitų el. pašte? Persiųskite juos į",
    "esp": "¿Tiene facturas en su correo electrónico? Reenviar documentos a este correo electrónico",
    "nor": "Har du fakturaer i e-posten din? Videresend dokumenter til denne e-posten"
   },
   "apiKeyPublic": {
    "eng": "API key (public)",
    "pol": "Klucz API (publiczny)",
    "est": "API võti (avalik)",
    "rus": "Ключ API (открытый)",
    "fin": "API-avain (julkinen)",
    "lav": "API parole (publiska)",
    "swe": "API-nyckel (offentlig)",
    "lit": "API raktas (viešas)",
    "esp": "Llave API (pública)",
    "nor": "API nøkkel (offentlig)"
   },
   "apiKeyPrivate": {
    "eng": "API key (private)",
    "pol": "Klucz API (prywatny)",
    "est": "API võti (salajane)",
    "rus": "Ключ API (личный)",
    "fin": "API-avain (yksityinen)",
    "lav": "API parole (privāta)",
    "swe": "API-nyckel (privat)",
    "lit": "API raktas (privatus)",
    "esp": "Llave API (privada)",
    "nor": "API nøkkel (privat)"
   },
   "dailyAllowance": {
    "eng": "Daily allowance",
    "pol": "Dzienna dieta",
    "est": "Päevaraha",
    "rus": "Ежедневное пособие",
    "fin": "Päiväraha",
    "lav": "Dienas nauda",
    "swe": "Dagligt underhåll",
    "lit": "Dienpinigiai",
    "esp": "Subsidio diario"
   },
   "reportsWillBeForwarded": {
    "eng": "Reports will be forwarded",
    "pol": "Raporty zostaną przekazane",
    "est": "Aruanded saadetakse edasi",
    "rus": "Отчеты будут перенаправлены",
    "fin": "Raportit lähetetään edelleen",
    "lav": "Pārskati tiks pārsūtīti",
    "swe": "Rapporter kommer att vidarebefodras",
    "lit": "Ataskaitos bus persiųstos",
    "esp": "Los informes se reenviarán"
   },
   "afterSubmitterReadiesReport": {
    "eng": "after submitter has marked report as ready",
    "pol": "po tym, jak zgłaszający oznaczy raport jako gotowy",
    "est": "pärast seda kui aruanne on esitaja poolt valmis",
    "rus": "после того, как отправитель отметил отчет как готовый",
    "fin": "sen jälkeen, kun raportin lähettäjä on merkinnyt sen valmiiksi",
    "lav": "pēc tam, kad iesniedzējs atzīmējis pārskatu kā gatavu",
    "swe": "efter att avsändaren har markerat rapporten som klar",
    "lit": "po to, kai pateikėjas pažymės ataskaitą kaip paruoštą",
    "esp": "después de que el remitente haya marcado el informe como listo"
   },
   "days": {
    "eng": "days",
    "pol": "dni",
    "est": "päeva",
    "rus": "дни",
    "fin": "päivät",
    "lav": "dienas",
    "swe": "dagar",
    "lit": "dienos",
    "esp": "días"
   },
   "areYouSureOpenAgain": {
    "eng": "Are you sure you want to open it again?",
    "pol": "Czy na pewno chcesz otworzyć raport ponownie?",
    "est": "Kas oled kindel, et tahad aruande taas avada?",
    "rus": "Вы уверены, что хотите деархивировать этот отчет?",
    "fin": "Oletko varma, että haluat avata raportin uudestaan?",
    "lav": "Vai vēlaties atarhivēt šo pārskatu?",
    "swe": "Är du säker att du vill öppna rapporten igen?",
    "lit": "Ar tikrai norite atidaryti ataskaitą darkart?",
    "esp": "¿Estás seguro de que desea abrirlo de nuevo?"
   },
   "reportIsAlreadySent": {
    "eng": "This report is archived / sent to software.",
    "pol": "Ten raport jest zarchiwizowany / wysłany do oprogramowania.",
    "est": "Sellega seotud aruanne on saadetud tarkvarasse.",
    "rus": "Этот отчет заархивирован/отправлен в программу.",
    "fin": "Tämä raportti on arkistoitu/lähetetty ohjelmistoon.",
    "lav": "Šis pārskats ir arhivēts/nosūtīts programmatūrai.",
    "swe": "Denna rapport är arkiverad/skickad till bokföringsprogram.",
    "lit": "Ši ataskaita arhyvuota / išsiųsta į programą.",
    "esp": "Este reporte está archivado / envíado al software."
   },
   "openAlreadyJoinedCompany": {
    "eng": "You're already registered in this company. Redirect to the company?",
    "pol": "Jesteś już zarejestrowany w tej firmie. Przekierować do firmy?",
    "est": "Olete juba seotud antud ettevõttega. Suuname sind edasi sellesse ettevõtesse?",
    "rus": "Вы уже зарегистрированы в этой компании. Перенаправить в компанию?",
    "fin": "Olet jo rekisteröitynyt organisaatioon. Siirrytäänkö sen profiiliin?",
    "lav": "Jūs jau esat reģistrēts šajā uzņēmumā. Vai doties uz uzņēmumu?",
    "swe": "Du är redan registrerad inom detta företag. Omdirigera till företaget?",
    "lit": "Jūs jau registruotas šioje įmonėje. Nukreipti į įmonę?",
    "esp": "Ya estás registrado en esta empresa. ¿Redirigir a la empresa?"
   },
   "nrOfDocuments": {
    "eng": "Documents",
    "pol": "Dokumenty",
    "est": "Dokumente",
    "rus": "Документы",
    "fin": "Tositteet",
    "lav": "Dokumenti",
    "swe": "Dokument",
    "lit": "Dokumentai",
    "esp": "Documentos"
   },
   "today": {
    "eng": "Today",
    "pol": "Dzisiaj",
    "est": "Täna",
    "rus": "Сегодня",
    "fin": "Tänään",
    "lav": "Šodien",
    "swe": "Idag",
    "lit": "Šiandien",
    "esp": "Hoy"
   },
   "yesterday": {
    "eng": "Yesterday",
    "pol": "Wczoraj",
    "est": "Eile",
    "rus": "Вчера",
    "fin": "eilen",
    "lav": "Vakar",
    "swe": "Igår",
    "lit": "Vakar",
    "esp": "Ayer"
   },
   "beforeYesterday": {
    "eng": "Day before yesterday",
    "pol": "Przedwczoraj",
    "est": "Üleeile",
    "rus": "Позавчера",
    "fin": "Toissapäivänä",
    "lav": "Aizvakar",
    "swe": "Förrgår",
    "lit": "Užvakar",
    "esp": "Anteayer"
   },
   "INVALID_VALUE": {
    "eng": "Added value is incorrect",
    "pol": "Wartość dodana jest nieprawidłowa",
    "est": "Sisestatud väärtus on ebakorrektne",
    "rus": "Добавленное значение неверно",
    "fin": "Annettu arvo on virheellinen",
    "lav": "Pievienotā vērtība ir nepareiza",
    "swe": "Det angivna beloppet är inkorrekt",
    "lit": "Pridėta reikšmė yra netinkama",
    "esp": "Valor agregado es incorrecto"
   },
   "UNAVAILABLE": {
    "eng": "Value unavailable or already in use",
    "pol": "Wartość jest niedostępna lub już używana",
    "est": "Sisestatud väli ei ole saadaval",
    "rus": "Значение недоступно или уже используется",
    "fin": "Arvo ei saatavilla tai jo käytössä",
    "lav": "Vērtība ir nederīga vai jau ir aizņemta",
    "swe": "Beloppet otillgängligt eller används redan",
    "lit": "Reikšmė nepasiekiama arba jau naudojama",
    "esp": "Valor no disponible o en uso"
   },
   "TOO_LONG": {
    "eng": "Inserted value is too long",
    "pol": "Wprowadzona wartość jest zbyt długa",
    "est": "Sisestatud väärtus on liiga pikk",
    "rus": "Вставленное значение слишком длинное",
    "fin": "Annettu arvo on liian pitkä",
    "lav": "Ievadītā vērtība ir pārāk gara",
    "swe": "Det angivna beloppet är för långt",
    "lit": "Įvesta reikšmė yra per ilga",
    "esp": "Valor ingresado es muy largo"
   },
   "sureSendEmptyReport": {
    "eng": "Are you sure you want to submit a report without cost documents?",
    "pol": "Czy jesteś pewien, że chcesz wysłać pusty raport?",
    "est": "Kindel, et soovite esitada ilma kuludokumentideta aruannet?",
    "rus": "Вы уверены, что хотите отправить отчет без расходных документов?",
    "fin": "Haluatko varmasti lähettää tyhjän raportin?",
    "lav": "Vai tiešām vēlaties sūtīt pārskatu bez pievienotiem izdevumu dokumentiem?",
    "swe": "Är du säker att du vill skicka en tom rapport?",
    "lit": "Ar tikrai norite siųsti tuščią ataskaitą?",
    "esp": "¿Está seguro de que desea enviar un informe sin documentos de costos?"
   },
   "showArchivedReports": {
    "eng": "Show archived reports",
    "pol": "Pokaż zarchiwizowane raporty",
    "est": "Näita arhiveeritud aruandeid",
    "rus": "Показать заархивированные отчеты",
    "fin": "Näytä arkistoidut raportit",
    "lav": "Parādīt arhivētos pārskatus",
    "swe": "Visa arkiverade rapporter",
    "lit": "Rodyti arhyvuotas ataskaitas",
    "esp": "Mostrar reportes archivados"
   },
   "archivedReports": {
    "eng": "Archived reports",
    "pol": "Zarchiwizowane raporty",
    "est": "Arhiveeritud Aruanded",
    "rus": "Заархивированные отчеты",
    "fin": "Arkistoidut raportit",
    "lav": "Arhivētie pārskati",
    "swe": "Arkiverade rapporter",
    "lit": "Arhyvuotos ataskaitos",
    "esp": "Reportes archivados"
   },
   "enterSum": {
    "eng": "Enter sum",
    "pol": "Wprowadź sumę",
    "est": "Sisesta summa",
    "rus": "Ввести сумму",
    "fin": "Syötä summa",
    "lav": "Ievadiet summu",
    "swe": "Ange summa",
    "lit": "Įvesti sumą",
    "esp": "Ingrese suma"
   },
   "enterComment": {
    "eng": "Enter comment",
    "pol": "Dodaj komentarz",
    "est": "Sisesta kommentaar",
    "rus": "Ввести комментарий",
    "fin": "Lisää kommentti",
    "lav": "Ievadiet komentāru",
    "swe": "Infoga kommentar",
    "lit": "Įvesti komentarą",
    "esp": "Ingrese comentario"
   },
   "editReceipt": {
    "eng": "Cost without document",
    "pol": "Koszt bez dokumentu",
    "est": "Ilma failita kulu",
    "rus": "Cтоимость без документа",
    "fin": "Kulu ilman tositetta",
    "lav": "Izmaksas bez dokumenta",
    "swe": "Kostnad utan dokument",
    "lit": "Kaštai be dokumento",
    "esp": "Costo sin documento"
   },
   "emptyReceipt": {
    "eng": "Add cost without document",
    "pol": "Dodaj koszt bez dokumentu",
    "est": "Lisa ilma dokumendita kulu",
    "rus": "Добавить стоимость без документа",
    "fin": "Lisää kulu ilman tositetta",
    "lav": "Ievadiet maksu bez dokumenta",
    "swe": "Lägg till kostnad utan dokument",
    "lit": "Pridėti išlaidas be dokumento",
    "esp": "Agregar costo sin documento"
   },
   "enterCurrency": {
    "eng": "Enter currency",
    "pol": "Wprowadź walutę",
    "est": "Sisesta valuuta",
    "rus": "Введите валюту",
    "fin": "Syötä valuutta",
    "lav": "Ievadiet valūtu",
    "swe": "Ange valuta",
    "lit": "Įvesti valiutą",
    "esp": "Ingrese moneda"
   },
   "enterIssuedDate": {
    "eng": "Enter document date",
    "pol": "Wprowadź datę dokumentu",
    "est": "Sisesta dokumendi kuupäev",
    "rus": "Введите дату документа",
    "fin": "Syötä tositteen päivämäärä",
    "lav": "Ievadiet dokumenta datumu",
    "swe": "Ange dokumentets datum",
    "lit": "Įveskite dokumento datą",
    "esp": "Ingrese fecha del documento"
   },
   "newDocumentAdded": {
    "eng": "Document successfully added!",
    "pol": "Dokument został pomyślnie dodany!",
    "est": "Uus dokument lisatud!",
    "rus": "Документ успешно добавлен!",
    "fin": "Uusi tosite lisätty!",
    "lav": "Dokuments veiksmīgi pievienots!",
    "swe": "Nytt dokument tillagt!",
    "lit": "Dokumentas pridėtas sėkmingai!",
    "esp": "Documento agregado satisfactoriamente"
   },
   "reports": {
    "eng": "Reports",
    "pol": "Raporty",
    "est": "Aruanded",
    "rus": "Отчеты",
    "fin": "Raportit",
    "lav": "Pārskati",
    "swe": "Rapporter",
    "lit": "Ataskaitos",
    "esp": "Reportes"
   },
   "enterCompanyName": {
    "eng": "Enter organisation name",
    "pol": "Wpisz nazwę organizacji",
    "est": "Sisesta ettevõtte nimi",
    "rus": "Введите название организации",
    "fin": "Kirjoita yrityksen nimi",
    "lav": "Ievadiet organizācijas nosaukumu",
    "swe": "Ange företagets namn",
    "lit": "Įvesti organizacijos pavadinimą",
    "esp": "Ingrese nombre de la organización"
   },
   "registered": {
    "eng": "Registered",
    "pol": "Zarejestrowany",
    "est": "Registeeritud",
    "rus": "Зарегистрировано",
    "fin": "Kirjattu",
    "lav": "Reģistrēts",
    "swe": "Registrerad",
    "lit": "Užregistruota",
    "esp": "Registrado",
    "nor": "Registrert"
   },
   "companyNameShort": {
    "eng": "Company name",
    "pol": "Nazwa firmy",
    "est": "Ettevõtte nimi",
    "rus": "Название компании",
    "fin": "Yrityksen nimi",
    "lav": "Uzņēmuma nosaukums",
    "swe": "Företagets namn",
    "lit": "Įmonės pavadinimas",
    "esp": "Nombre de empresa",
    "nor": "Firmanavn"
   },
   "close": {
    "eng": "Close",
    "pol": "Zamknij",
    "est": "Sulge",
    "rus": "Закрыть",
    "fin": "Sulje",
    "lav": "Aizvērt",
    "swe": "Stäng",
    "lit": "Uždaryti",
    "esp": "Cerrar",
    "nor": "Lukk"
   },
   "createPrivatePersonAccount": {
    "eng": "Create private person account",
    "pol": "Utwórz konto osoby prywatnej",
    "est": "Loo eraisiku konto",
    "rus": "Создать частный аккаунт",
    "fin": "Luo yksityishenkilön tili",
    "lav": "Izveidot privātpersonas kontu",
    "swe": "Skapa konto för privatperson",
    "lit": "Sukurti privačią paskyrą",
    "esp": "Crear cuenta de persona privada",
    "nor": "Opprett privatpersonkonto"
   },
   "Privateaccountislimited": {
    "eng": "Private person account is limited",
    "pol": "Konto osoby prywatnej jest ograniczone",
    "est": "Eraisiku konto on limiteeritud",
    "rus": "Частный аккаунт ограничен",
    "fin": "Yksityishenkilön tili on rajoitettu",
    "lav": "Privātpersonas konts ir ierobežots",
    "swe": "Konto för privatperson är begränsat",
    "lit": "Privati paskyra yra ribota",
    "esp": "La cuenta de persona privada es limitada",
    "nor": "Privatpersonkonto er begrenset"
   },
   "Nointegrationwithaccountingsoftware": {
    "eng": "Can't add integration with accounting software",
    "pol": "Nie jest możliwa integracja z systemem księgowym",
    "est": "Raamatupidamistarkvaraga ei saa liidestada",
    "rus": "Невозможно создать интеграцию с бухгалтерским программным обеспечением.",
    "fin": "Ei integraatiota kirjanpito-ohjelmistoon",
    "lav": "Nevar izveidot integrāciju ar grāmatvedības sistēmu.",
    "swe": "Kan inte lägga till integrationen med bokföringsprogram",
    "lit": "Integracija su apskaitos sistema negalima",
    "esp": "No se puede agregar la integración con el software de contabilidad",
    "nor": "Kan ikke legge til integrasjon med regnskapsprogramvare"
   },
   "somethingWentWrongRedirectingBack": {
    "eng": "Something went wrong. Redirecting to homepage",
    "pol": "Coś poszło nie tak. Zostaniesz przekierowany do strony głównej",
    "est": "Miski läks viltu. Suuname sind tagasi koju",
    "rus": "Что-то пошло не так. Перенаправляем вас обратно на главную страницу.",
    "fin": "Jokin meni pieleen. Siirrytään takaisin etusivulle",
    "lav": "Darbība neizdevās. Dodas atpakaļ uz sākuma ekrānu.",
    "swe": "Något gick fel. Omdirigerar dig till hemskärmen.",
    "lit": "Kažkas nepavyko. Nukreipiame jus atgal",
    "esp": "Algo salió mal. Redirigiendo a la página de inicio",
    "nor": "Noe gikk galt. Omdirigerer til hjemmesiden"
   },
   "documentswillbesenttoyouremail": {
    "eng": "Reports and documents will be sent to your email (PDF and Excel)",
    "pol": "Raporty i dokumenty zostaną wysłane na Twój adres e-mail (PDF i Excel)",
    "est": "Aruanded saadetakse teile emailile (PDF ning Excel)",
    "rus": "Отчеты и документы будут отправлены на вашу электронную почту (PDF/CSV(Excel))",
    "fin": "Raportit ja tositteet lähetetään sähköpostiisi (PDF/Excel)",
    "lav": "Pārskati un dokumenti tiks nosūtīti uz Jūsu e-pastu (PDF/CSV(Excel))",
    "swe": "Rapporter och dokument kommer skickas till din e-post (PDF och Excel).",
    "lit": "Ataskaitos ir dokumentai bus atsiųsti į jūsų el. paštą (PDF ir Excel)",
    "esp": "Los informes y documentos se enviarán a su correo electrónico (PDF y Excel)",
    "nor": "Rapporter og dokumenter vil bli sendt til din e-post (PDF og Excel)"
   },
   "cannotaddusers": {
    "eng": "Can't add another user.",
    "pol": "Nie można dodać kolejnego użytkownika",
    "est": "Kasutajaid ei saa juurde lisada",
    "rus": "Невозможно добавить нескольких пользователей.",
    "fin": "Käyttäjiä ei voi lisätä",
    "lav": "Nevar pievienot vairākus lietotājus.",
    "swe": "Kan inte lägga till användare.",
    "lit": "Negalima pridėti dar vieno vartotojo",
    "esp": "No se puede agregar otro usuario",
    "nor": "Kan ikke legge til en annen bruker."
   },
   "selectBankAccount": {
    "eng": "Select bank account",
    "pol": "Wybierz konto bankowe",
    "est": "Vali pangakonto",
    "rus": "Выберите банковский счет",
    "fin": "Valitse pankkitili",
    "lav": "Atlasiet bankas kontu",
    "swe": "Välj bankkonto",
    "lit": "Pasirinkti banko sąskaitą",
    "esp": "Seleccionar cuenta bancaria",
    "nor": "Velg bankkonto"
   },
   "pwdUnderUserSettings": {
    "eng": "Long password under user settings in Jumis",
    "pol": "Długie hasło w ustawieniach użytkownika Jumis",
    "est": "Parool kasutaja seadete alt Jumises",
    "rus": "Ищите длинный пароль в настройках пользователя в Jumis",
    "fin": "Pitkä salasana Jumisin käyttäjäasetuksissa",
    "lav": "Skatīt garo paroli Jumis lietotāja iestatījumos",
    "lit": "Ilgas slaptažodis Jumis vartotojo nustatymuose",
    "swe": "Långt lösenord under användarinställningar i Jumis",
    "esp": "Contraseña larga en la configuración de usuario en Jumis",
    "nor": "Langt passord under brukerinnstillinger i Jumis"
   },
   "deleteReceipt": {
    "eng": "Delete document",
    "pol": "Usuń dokument",
    "est": "Kustuta dokument",
    "rus": "Удалить документ",
    "fin": "Poista tosite",
    "lav": "Dzēst dokumentu",
    "swe": "Ta bort dokument",
    "lit": "Pašalinti dokumentą",
    "esp": "Eliminar documento",
    "nor": "Slett dokument"
   },
   "uploadingDocument": {
    "eng": "Uploading document",
    "pol": "Prześlij dokument",
    "est": "Dokumendi üleslaadimine",
    "rus": "Загрузка документа",
    "fin": "Lähetetään asiakirjaa",
    "lav": "Notiek dokumenta augšupielāde",
    "swe": "Laddar upp dokument",
    "lit": "Dokumentas įkeliamas",
    "esp": "Subiendo documento",
    "nor": "Laster opp dokument"
   },
   "tryAgain": {
    "eng": "Try again",
    "pol": "Spróbuj ponownie",
    "est": "Proovi uuesti",
    "rus": "Попробуйте снова",
    "fin": "Yritä uudelleen",
    "lav": "Mēģiniet vēlreiz",
    "swe": "Försök igen",
    "lit": "Bandykite dar kartą",
    "esp": "Intentar otra vez",
    "nor": "Prøv igjen"
   },
   "pleaseAllowAccessToGallery": {
    "eng": "Allow access to Gallery in privacy settings to use this feature.",
    "pol": "Aby korzystać z tej funkcji, należy w ustawieniach prywatności zezwolić na dostęp do Galerii.",
    "est": "Palun võimaldage ligipääs galeriile (Gallery), kui soovite kasutada vastavat funktsiooni. Seda saab teha telefoni privaatsussätete alt.",
    "rus": "Пожалуйста, разрешите доступ к галерее (Gallery), если вы хотите использовать функцию приложения. Это можно сделать в настройках конфиденциальности.",
    "fin": "Salli pääsy galleriaan, jos haluat käyttää ominaisuutta. Tämä voidaan sallia laitteesi tietosuoja-asetuksissa.",
    "lav": "Lūdzu, atļaujiet piekļuvi galerijai (Gallery), ja vēlaties turpmāk izmantot lietotnes funkciju. To var izdarīt tālruņa iestatījumos.",
    "swe": "Var god aktivera tillgång till Bilder om du vill använda den funktionen. Detta går att göra i integritetsinställningarna.",
    "lit": "Leiskite prieigą prie Galerijos norėdami ja naudotis. Tai galite padaryti telefono privatumo nustatymuose.",
    "esp": "Permita el acceso a la Galería en la configuración de privacidad para usar esta función.",
    "nor": "Gi tilgang til Galleri i personverninnstillingene for å bruke denne funksjonen."
   },
   "pleaseAllowAccessToCamera": {
    "eng": "Allow access to Camera in privacy settings to use the feature.",
    "pol": "Aby korzystać z tej funkcji, należy w ustawieniach prywatności zezwolić na dostęp do kamery.",
    "est": "Palun võimaldage ligipääs kaamerale (camera), kui soovite kasutada vastavat funktsiooni. Seda saab teha telefoni privaatsussätete alt.",
    "rus": "Пожалуйста, разрешите доступ к камере (Camera) если вы хотите использовать функцию приложения. Это можно сделать в настройках конфиденциальности.",
    "fin": "Salli pääsy kameraan, jos haluat käyttää ominaisuutta. Tämä voidaan sallia laitteesi tietosuoja-asetuksissa.",
    "lav": "Lūdzu, atļaujiet piekļuvi kamerai (Camera), ja vēlaties turpmāk izmantot lietotnes funkciju. To var izdarīt iestatījumos.",
    "swe": "Var god aktivera tillgång till kameran i integritetsinställningarna om du önskar använda denna funktion.",
    "lit": "Leiskite prieigą prie Kameros norėdami ja naudotis. Tai galite padaryti telefono privatumo nustatymuose.",
    "esp": "Permita el acceso a la cámara en la configuración de privacidad para usar la función.",
    "nor": "Gi tilgang til Kamera i personverninnstillingene for å bruke funksjonen."
   },
   "firstNameMustBeLonger": {
    "eng": "First name must be longer",
    "pol": "Imię musi być dłuższe",
    "est": "Eesnimi peab olema pikem",
    "rus": "Имя должно быть длиннее",
    "fin": "Etunimen tulee olla pidempi",
    "lav": "Vārdam jābūt garākam",
    "swe": "Förnamn måste vara längre",
    "lit": "Vardas turi būti ilgesnis",
    "esp": "El nombre debe ser más largo",
    "nor": "Fornavnet må være lengre"
   },
   "lastNameMustBeLonger": {
    "eng": "Last name must be longer",
    "pol": "Nazwisko musi być dłuższe",
    "est": "Perekonnanimi peab olema pikem",
    "rus": "Фамилия должна быть длиннее",
    "fin": "Sukunimen tulee olla pidempi",
    "lav": "Uzvārdam jābūt garākam",
    "swe": "Efternamn måste vara längre",
    "lit": "Pavardė turi būti ilgesnė",
    "esp": "El apellido debe ser más largo",
    "nor": "Etternavnet må være lengre"
   },
   "administrators": {
    "eng": "Administrators",
    "pol": "Administratorzy",
    "est": "Administraatorid",
    "rus": "Администраторы",
    "fin": "Ylläpitäjät",
    "lav": "Administratori",
    "swe": "Administratörer",
    "lit": "Administratoriai",
    "esp": "Administradores",
    "nor": "Administratorer"
   },
   "remove": {
    "eng": "Remove",
    "pol": "Usuń",
    "est": "Eemalda",
    "rus": "Удалить",
    "fin": "Poista",
    "lav": "Noņemt",
    "swe": "Ta bort",
    "lit": "Pašalinti",
    "esp": "Remover",
    "nor": "Fjerne"
   },
   "serverAddress": {
    "eng": "Server address",
    "pol": "Adres serwera",
    "est": "Serveri aadress",
    "rus": "Адрес сервера",
    "fin": "Palvelimen osoite",
    "lav": "Servera adrese",
    "swe": "Serveradress",
    "lit": "Serverio adresas",
    "esp": "Dirección del servidor",
    "nor": "Server adresse"
   },
   "idCode": {
    "eng": "Personal ID code",
    "pol": "PESEL",
    "est": "Isikukood",
    "rus": "Персональный код",
    "fin": "Henkilökohtainen tunnus",
    "lav": "Personas kods",
    "swe": "Personnummer",
    "lit": "Asmens kodas",
    "esp": "Código de identificación personal",
    "nor": "Personlig ID-kode"
   },
   "can_not_go_back_to_free_plan": {
    "eng": "After choosing a plan you can't go back to Free Demo",
    "pol": "Po wybraniu nowego planu nie ma możliwości powrotu do wersji Demo.",
    "est": "Pärast paketi valimist ei saa minna tagasi tasuta demole",
    "rus": "После выбора плана вы не можете вернуться к бесплатному демо-плану",
    "fin": "Valittuasi maksullisen paketin et voi palata ilmaiseen kokeiluversioon",
    "lav": "Pēc pakalpojuma plāna izvēles, bezmaksas izmēģinājuma versiju atjaunot nav iespējams",
    "swe": "Efter att du har valt ett paket kan du inte återvända till Gratis Demo-versionen",
    "lit": "Pasirinkus planą, nebegalėsite gryžti prie Nemokamo Demo",
    "esp": "Después de elegir un plan, no puede volver a la demostración gratuita",
    "nor": "Etter å ha valgt en plan kan du ikke gå tilbake til gratis demo"
   },
   "databaseName": {
    "eng": "Database name",
    "pol": "Nazwa bazy danych",
    "est": "Andmebaasi nimi",
    "rus": "Имя базы данных",
    "fin": "Tietokannan nimi",
    "lav": "Datu bāzes nosaukums",
    "swe": "Databasnamn",
    "lit": "Duombazės pavadinimas",
    "esp": "Nombre de la base de datos",
    "nor": "Databasenavn"
   },
   "ogranization": {
    "eng": "Organisation",
    "pol": "Organizacja",
    "est": "Organisatsioon",
    "rus": "Организация",
    "fin": "Organisaatio",
    "lav": "Uzņēmums",
    "swe": "Organisation",
    "lit": "Organizacija",
    "esp": "Organización",
    "nor": "Organisasjon"
   },
   "plans": {
    "eng": "Plans",
    "pol": "Plany cenowe",
    "est": "Hinnastamine",
    "rus": "Планы",
    "fin": "Pakettivaihtoehdot",
    "lav": "Abonēšana",
    "swe": "Betalningspaket",
    "lit": "Planai",
    "esp": "Planes",
    "nor": "Planer"
   },
   "general": {
    "eng": "General",
    "pol": "Ogólne",
    "est": "Üldine",
    "rus": "Дополнительное",
    "fin": "Yleistä",
    "lav": "Vispārīgi",
    "swe": "Allmän",
    "lit": "Bendras",
    "esp": "General",
    "nor": "Generell"
   },
   "about": {
    "eng": "About us",
    "pol": "O nas",
    "est": "Meist",
    "rus": "О нас",
    "fin": "Meistä",
    "lav": "Par mums",
    "swe": "Om oss",
    "lit": "Apie",
    "esp": "Sobre nosotros",
    "nor": "Om oss"
   },
   "signout": {
    "eng": "Sign out",
    "pol": "Wyloguj",
    "est": "Logi välja",
    "rus": "Выйти",
    "fin": "Kirjaudu ulos",
    "lav": "Iziet",
    "swe": "Logga ut",
    "lit": "Atsijungti",
    "esp": "Desconectar",
    "nor": "Logg ut"
   },
   "deleteUser": {
    "eng": "Delete user",
    "pol": "Usuń użytkownika",
    "est": "Kustuta kasutaja",
    "rus": "Удалить пользователя",
    "fin": "Poista käyttäjä",
    "lav": "Dzēst lietotāju",
    "swe": "Ta bort användare",
    "lit": "Pašalinti vartotoją",
    "esp": "Borrar usuario",
    "nor": "Slett bruker"
   },
   "sendInvite": {
    "eng": "Add user",
    "pol": "Wyślij zaproszenie",
    "est": "Lisa kasutaja",
    "rus": "Отправить приглашение",
    "fin": "Lähetä kutsu",
    "lav": "Nosūtīt uzaicinājumu",
    "swe": "Skicka inbjudan",
    "lit": "Siųsti kvietimą",
    "esp": "Enviar invitación",
    "nor": "Legg til bruker"
   },
   "feeWillBeRecalculated": {
    "eng": "Your monthly fee will be recalculated accordingly",
    "pol": "Twoja miesięczna opłata zostanie odpowiednio przeliczona.",
    "est": "Kui kutsute uusi kasutajaid, muutub teie kuutasu",
    "rus": "После добавления нового пользователя ваша ежемесячная плата будет пересчитана",
    "fin": "Kuukausiveloituksesi päivitetään automaattisesti sitä mukaa, kun kutsut uusia käyttäjiä",
    "lav": "Pievienojot jaunu lietotāju, Jūsu mēneša maksa tiks atbilstoši pārrēķināta",
    "swe": "Din månadskostnad kommer omräknas",
    "lit": "Mėnesinis mokestis bus perskaičiuotas atitinkamai.",
    "esp": "Su tarifa mensual se volverá a calcular en consecuencia",
    "nor": "Din månedlige avgift vil bli beregnet på nytt tilsvarende"
   },
   "pricing": {
    "eng": "Pricing",
    "pol": "Cennik",
    "est": "Hind",
    "rus": "Цены",
    "fin": "Hinnoittelu",
    "lav": "Cenrādis",
    "swe": "Prissättning",
    "lit": "Kainos",
    "esp": "Precios",
    "nor": "Prissetting"
   },
   "maxAllowance": {
    "eng": "Maximum daily allowance",
    "pol": "Maksymalna dzienna dieta",
    "est": "Päevarahade piirmäär",
    "rus": "Максимальные суточные для командировок",
    "fin": "Päivärahan enimmäismäärä",
    "lav": "Maksimālā dienas nauda komandējumiem",
    "swe": "Maximalt dagligt underhåll",
    "lit": "Maksimalūs dienpinigiai",
    "esp": "Subsidio máximo diario",
    "nor": "Maksimal dagpenger"
   },
   "emailCopiedToClipboard": {
    "eng": "Email copied to clipboard",
    "pol": "E-mail skopiowany do schowka",
    "est": "Aadress kopeeritud!",
    "rus": "Адрес эл-почты скопирован",
    "fin": "Sähköposti kopioitu leikepöydälle",
    "lav": "E-pasta adrese nokopēta",
    "swe": "E-post kopierad till urklipp!",
    "lit": "El. paštas nukopijuotas",
    "esp": "Correo electrónico copiado al portapapeles",
    "nor": "E-post kopiert til utklippstavlen"
   },
   "changePlan": {
    "eng": "Change plan",
    "pol": "Zmień plan",
    "est": "Muuda hinnastamist",
    "rus": "Изменить тариф",
    "fin": "Muuta suunnitelmaa",
    "lav": "Mainīt plānu",
    "swe": "Ändra paket",
    "lit": "Keisti planą",
    "esp": "Cambiar plan",
    "nor": "Endre plan"
   },
   "perUserPerMonth": {
    "eng": "per user per month",
    "pol": "za użytkownika miesięcznie",
    "est": "kasutaja / kuus",
    "rus": "на пользователя в месяц",
    "fin": "kuukaudessa per käyttäjä",
    "lav": "mēnesī par lietotāju",
    "swe": "per användare per månad",
    "lit": "vartotojui per mėnesį",
    "esp": "por usuario por mes",
    "nor": "per bruker per måned"
   },
   "robotDigitization": {
    "eng": "Robot digitisation",
    "pol": "Zrobotyzowana digitalizacja",
    "est": "Robot digiteerib",
    "rus": "Роботизированная оцифровка",
    "fin": "Automaattinen digitalisointi",
    "lav": "Robotizētā digitalizācija",
    "swe": "Robotdigitalisering",
    "lit": "Robotizuotas skaitmeninimas",
    "esp": "Digitalización de robots",
    "nor": "Robot digitalisering"
   },
   "n30DocumentsPerUserPerMonth": {
    "eng": "30 documents per user per month",
    "pol": "30 dokumentów na użytkownika miesięcznie",
    "est": "30 dokumenti / kasutaja / kuus",
    "rus": "30 документов на пользователя в месяц",
    "fin": "30 dokumenttia kuukaudessa per käyttäjä",
    "lav": "30 dokumentu mēnesī katram lietotājam",
    "swe": "30 dokument per användare per månad",
    "lit": "30 dokumentų vartotojui per mėnesį",
    "esp": "30 documentos por usuario al mes",
    "nor": "30 dokumenter per bruker per måned"
   },
   "humanVerifiedDigitzation": {
    "eng": "Human verified digitisation",
    "pol": "Digitalizacja zweryfikowana przez człowieka",
    "est": "Inimese poolt kontrollitud",
    "rus": "Оцифровка, подтвержденная человеком",
    "fin": "Ihmisen varmistava digitalisointi",
    "lav": "Pilna servisa digitalizācija",
    "swe": "Mänskligt verifierad digitalisering",
    "lit": "Žmogaus tikrinamas skaitmeninimas",
    "esp": "Digitalización verificada por humanos",
    "nor": "Menneskelig bekreftet digitalisering"
   },
   "unlimitedDocuments": {
    "eng": "Flexible payment, pay only for usage",
    "pol": "Nieograniczona ilość dokumentów",
    "est": "Paindlik plaan, tasud ainult teenuse kasutamise eest",
    "rus": "Неограниченные документы",
    "fin": "Rajoittamaton määrä dokumentteja",
    "lav": "Neierobežots dokumentu skaits",
    "swe": "Obegränsat antal dokument",
    "lit": "Lankstus mokestis, mokėkite tik už naudojimą",
    "esp": "Documentos Ilimitados",
    "nor": "Ubegrensede dokumenter"
   },
   "unlimitedUsers": {
    "eng": "Unlimited users",
    "pol": "Nieograniczona liczba użytkowników",
    "est": "Piiramatult kasutajaid",
    "rus": "Неограниченные пользователи",
    "fin": "Rajoittamaton määrä käyttäjiä",
    "lav": "Neierobežots lietotāju skaits",
    "swe": "Obegränsat antal användare",
    "lit": "Neribotas vartotojų skaičius",
    "esp": "Usuarios Ilimitados",
    "nor": "Ubegrenset antall brukere"
   },
   "platinum": {
    "eng": "Precision",
    "pol": "Precision",
    "est": "Täpsus",
    "rus": "Точность",
    "fin": "Tarkkuus",
    "lav": "Precizitāte",
    "swe": "Platina",
    "lit": "Tikslumas",
    "esp": "Platino",
    "nor": "Platinum"
   },
   "gold": {
    "eng": "Robo",
    "pol": "Robo",
    "est": "Robo",
    "rus": "Робо",
    "fin": "Robo",
    "lav": "Robo",
    "swe": "Guld",
    "lit": "Robo",
    "esp": "Oro",
    "nor": "Gull"
   },
   "perDocument": {
    "eng": "per document",
    "pol": "za dokument",
    "est": "dokument",
    "rus": "за документ",
    "fin": "per dokumentti",
    "lav": "par dokumentu",
    "swe": "per dokument",
    "lit": "per dokumentą",
    "esp": "por documento",
    "nor": "per dokument"
   },
   "100DocumentsPerUserPerMonth": {
    "eng": "100 documents per user per month",
    "pol": "100 dokumentów na użytkownika miesięcznie, 24,99 za kolejne 100 dokumentów",
    "est": "100 dokumenti / kasutaja / kuus",
    "rus": "100 документов на пользователя в месяц",
    "fin": "100 dokumenttia kuukaudessa per käyttäjä",
    "lav": "100 dokumentu mēnesī katram lietotājam",
    "swe": "100 dokument per användare per månad",
    "lit": "100 dokumentų vartotojui per mėnesį",
    "esp": "100 documentos por usuario al mes",
    "nor": "100 dokumenter per bruker per måned"
   },
   "5DocumentsPerUserPerMonth": {
    "eng": "5 documents, only one user",
    "pol": "5 dokumentów, tylko jeden użytkownik",
    "est": "5 dokumenti, ainult üks kasutaja",
    "rus": "5 документов, только один пользователь",
    "fin": "5 dokumenttia kuukaudessa per käyttäjä",
    "lav": "5 dokumenti mēnesī, viens lietotājs",
    "swe": "5 dokument, en användare",
    "lit": "5 dokumentai, tik vienas vartotojas",
    "esp": "5 documentos, solo un usuario",
    "nor": "5 dokumenter, kun én bruker"
   },
   "currentPlan": {
    "eng": "Current plan",
    "pol": "Aktualny plan",
    "est": "Valitud hinnastamine",
    "rus": "Текущий план",
    "fin": "Nykyinen suunnitelma",
    "lav": "Šobrīd aktīvs",
    "swe": "Nuvarande paket",
    "lit": "Dabartinis planas",
    "esp": "Plan Actual",
    "nor": "Gjeldende plan"
   },
   "startTime": {
    "eng": "Start of travel time",
    "pol": "Początek podróży",
    "est": "Lähetuse alguse kellaaeg",
    "rus": "Начало командировки",
    "fin": "Alkamisaika",
    "swe": "Resans starttid",
    "lit": "Kelionės pradžia",
    "lav": "Sākuma laiks",
    "esp": "Inicio del tiempo de viaje",
    "nor": "Start av reisetid"
   },
   "endTime": {
    "eng": "End of travel time",
    "pol": "Koniec podróży",
    "est": "Lähetuse lõpu kellaaeg",
    "rus": "Конец командировки",
    "fin": "Päättymisaika",
    "swe": "Resans sluttid",
    "lit": "Kelionės pabaiga",
    "lav": "Beigu laiks",
    "esp": "Fin del tiempo de viaje",
    "nor": "Slutt på reisetid"
   },
   "freeMealIncluded": {
    "eng": "1 free meal included a day",
    "pol": "1 posiłek dziennie",
    "est": "Sisaldab 1 tasuta lõunat päevas",
    "rus": "Включено 1 питание в день",
    "fin": "1 ilmainen ateria päivässä",
    "swe": "1 gratis måltid per dag inkluderad",
    "lit": "1 nemokamas patiekalas per dieną",
    "lav": "Iekļauta 1 ēdināšanas reize dienā",
    "esp": "1 comida gratis incluida al día",
    "nor": "1 gratis måltid inkludert per dag"
   },
   "confirm": {
    "eng": "Confirm",
    "pol": "Potwierdź",
    "est": "Kinnita",
    "rus": "Подтвердить",
    "fin": "Hyväksy",
    "lav": "Apstiprināt",
    "swe": "Bekräfta",
    "lit": "Patvirtinti",
    "esp": "Confirmar",
    "nor": "Bekrefte"
   },
   "upgradeToGold": {
    "eng": "Upgrade to Robo",
    "pol": "Przejdź na plan Robo",
    "est": "Uuendage Robo plaaniks",
    "rus": "Выбрать план Робо",
    "fin": "Vaihda Robo-pakettiin",
    "lav": "Pāriet uz Robo plānu",
    "swe": "Uppgradera till Guld-paketet",
    "lit": "Pereiti prie plano Robo",
    "esp": "Actualizar al plan Gold",
    "nor": "Oppgrader til Gull-plan"
   },
   "upgradeToPlatinum": {
    "eng": "Upgrade to Precision",
    "pol": "Przejdź na plan Precision",
    "est": "Uuendage Täpsus plaaniks",
    "rus": "Выбрать план Точность",
    "fin": "Vaihda Tarkkuus-pakettiin",
    "lav": "Pāriet uz Precizitātes plānu",
    "swe": "Uppgradera till Platina-paketet",
    "lit": "Pereiti prie plano Tikslumas",
    "esp": "Actualice al plan Platinum",
    "nor": "Oppgrader til Platinum-abonnement"
   },
   "upgradeToPayAsYouGo": {
    "eng": "Upgrade to plan Pay as you go",
    "pol": "Przejdź na plan: Pay as you go",
    "est": "Uuendasite oma paketi Tükipõhiseks paketiks",
    "rus": "Перейти на план \"Оплата по факту\"",
    "fin": "Vaihda Käytön mukaan -pakettiin",
    "lav": "Pāriet uz plānu \"Maksā, kad izmanto\"",
    "swe": "Uppgradera till Pay as you go-paketet",
    "lit": "Pereiti prie plano Pay as you go",
    "esp": "Actualice al plan Pay as you go",
    "nor": "Oppgrader til plan Betal mens du går"
   },
   "onceConfirmedCannotGoBack": {
    "eng": "Once confirmed, you cannot go back to Free Demo.",
    "pol": "Po potwierdzeniu nie można powrócić do darmowej wersji Demo.",
    "est": "Pärast paketi valimist ei saa minna tagasi tasuta paketile",
    "rus": "После того, как вы утвердите изменение плана, вы не сможете вернуться к бесплатной пробной версии.",
    "fin": "Kun hyväksyt maksusuunnitelman et voi enää käyttää ilmaisversiota",
    "lav": "Kolīdz apstiprināsiet plāna maiņu, vairs nevarēsiet atgriezties pie bezmaksas izmēģinājuma versijas.",
    "swe": "När du väl har bekräftat kan du inte återvända till gratisdemon.",
    "lit": "Patvirtinus nebegalėsite grįžti prie Nemokamo Demo",
    "esp": "Una vez confirmado, no puede volver a la demostración gratuita.",
    "nor": "Når du er bekreftet, kan du ikke gå tilbake til gratis demo."
   },
   "planChanged": {
    "eng": "Plan changed!",
    "pol": "Twój plan cenowy został zmieniony!",
    "est": "Hinnastamine muudetud!",
    "rus": "План изменен!",
    "fin": "Maksusuunnitelma muutettu!",
    "lav": "Plāns nomainīts!",
    "swe": "Betalningspaket har ändrats!",
    "lit": "Planas pakeistas!",
    "esp": "¡Plan cambiado!",
    "nor": "Plan endret!"
   },
   "howCanWeHelp": {
    "eng": "How can we help you?",
    "pol": "Jak możemy pomóc?",
    "est": "Kuidas me saame Teid aidata?",
    "rus": "Как мы можем помочь?",
    "fin": "Miten voimme auttaa?",
    "lav": "Kā varam Jums palīdzēt?",
    "swe": "Hur kan vi hjälpa dig?",
    "lit": "Kaip galėtume jums padėti?",
    "esp": "Cómo podemos ayudarte?",
    "nor": "Hvordan kan vi hjelpe deg?"
   },
   "weWillGetBackToYou": {
    "eng": "Message sent. We'll get back to you within 24h.",
    "pol": "Wiadomość wysłana. Odpowiemy w ciągu 24h.",
    "est": "Teade esitatud. Võtame ühendust 24 tunni jooksul.",
    "rus": "Сообщение отправлено! Мы свяжемся с вами в течении 24 часов.",
    "fin": "Viestisi on lähetetty. Olemme yhteydessä seuraavan 24 tunnin aikana",
    "lav": "Ziņojums nosūtīts! Mēs ar Jums sazināsimies 24h laikā.",
    "swe": "Meddelande skickat. Vi återkommer inom 24h.",
    "lit": "Žinutė išsiųsta. Atsakysime per 24 valandas.",
    "esp": "Mensaje enviado. Nos pondremos en contacto contigo en un plazo de 24 horas.",
    "nor": "Melding sendt. Vi kommer tilbake til deg innen 24 timer."
   },
   "invitationSent": {
    "eng": "Invitation sent!",
    "pol": "Zaproszenie wysłane!",
    "est": "Kutse on edukalt saadetud!",
    "rus": "Приглашение отправлено!",
    "fin": "Kutsu lähetetty!",
    "lav": "Uzaicinājums nosūtīts!",
    "swe": "Inbjudan skickad!",
    "lit": "Pakvietimas išsiųstas!",
    "esp": "¡Invitación enviada!",
    "nor": "Invitasjon sendt!"
   },
   "invitationLanguage": {
    "eng": "Invitation language",
    "pol": "Język zaproszenia",
    "est": "Kutse edastamise keel",
    "rus": "Язык приглашения",
    "fin": "Kutsun kieli",
    "lav": "Uzaicinājuma valoda",
    "swe": "Inbjudningsspråk",
    "lit": "Pakvietimo kalba",
    "esp": "Idioma de la invitación",
    "nor": "Invitasjonsspråk"
   },
   "onlyAdminsCanEditUsers": {
    "eng": "You are not an admin. Only admins can see and edit users.",
    "pol": "Nie jesteś administratorem. Tylko administratorzy mogą dodawać i usuwać użytkowników.",
    "est": "Sa ei ole admin. Teisi kasutajaid saavad näha ja muuta vaid adminid.",
    "rus": "Только администратор может добавлять или удалять пользователей.",
    "fin": "Vain ylläpitäjä voi lisätä ja poistaa käyttäjiä",
    "lav": "Tikai administrators var pievienot vai dzēst lietotājus.",
    "swe": "Du är inte en administratör. Endast administratörer kan se och ändra användare.",
    "lit": "Jūs nesate administratorius. Tik administratoriai gali matyti ir keisti naudotojus.",
    "esp": "¿Necesita agregar o eliminar usuarios? Solo los administradores pueden agregar o eliminar usuarios.",
    "nor": "Du er ikke administrator. Bare administratorer kan se og redigere brukere."
   },
   "add": {
    "eng": "Add",
    "pol": "Dodaj",
    "est": "Lisa",
    "rus": "Добавить",
    "fin": "Lisää",
    "lav": "Pievienot",
    "swe": "Lägg till",
    "lit": "Pridėti",
    "esp": "Agregar",
    "nor": "Legge til"
   },
   "continue": {
    "eng": "Continue",
    "pol": "Kontynuuj",
    "est": "Jätka",
    "rus": "Продолжить",
    "fin": "Jatka",
    "lav": "Turpināt",
    "swe": "Fortsätt",
    "lit": "Tęsti",
    "esp": "Continuar",
    "nor": "Fortsette"
   },
   "alreadyMemberOf": {
    "eng": "You're already a member of",
    "pol": "Jesteś już użytkownikiem",
    "est": "Sa oled juba liitunud ettevõttega",
    "rus": "Вы уже зарегистрированы в компании",
    "fin": "Olet jo jäsen",
    "lav": "Jūs jau esat reģistrēts uzņēmumā",
    "swe": "Du är redan medlem av",
    "lit": "Jūs jau esate narys",
    "esp": "Ya eres miembro de",
    "nor": "Du er allerede medlem av"
   },
   "redirectToCompany": {
    "eng": "Redirect to company?",
    "pol": "Przekierować do firmy?",
    "est": "Suuna edasi ettevõttesse?",
    "rus": "Перенаправить в компанию?",
    "fin": "Uudelleenohjaa yritykseen?",
    "lav": "Vai doties uz uzņēmumu?",
    "swe": "Omdirigera till företaget?",
    "lit": "Persiųsti įmonei?",
    "esp": "¿Redirigir a la empresa?",
    "nor": "Omdirigere til selskapet?"
   },
   "selectExistingCountry": {
    "eng": "Please select existing country",
    "pol": "Wybierz kraj",
    "est": "Palun vali",
    "rus": "Пожалуйста, выберите страну",
    "fin": "Valitse",
    "lav": "Lūdzu, izvēlieties valsti",
    "swe": "Var god välj befintligt land",
    "lit": "Pasirinkite egzistuojančią valstybę",
    "esp": "Seleccione un país existente",
    "nor": "Velg eksisterende land"
   },
   "activate": {
    "eng": "Activate",
    "pol": "Aktywuj",
    "est": "Aktiveeri",
    "rus": "Активировать",
    "fin": "Aktivoi",
    "lav": "Aktivizēt",
    "swe": "Aktivera",
    "lit": "Aktyvuoti",
    "esp": "Activar",
    "nor": "Aktiver"
   },
   "einvoicingPendingRIK": {
    "eng": "E-invoicing confirmation pending in Company Registration Portal",
    "pol": "E-fakturowanie oczekuje na potwierdzenie w Portalu Rejestracji Firmy",
    "est": "E-arvete vastuvõtmine ootab kinnitamist Ettevõtjaportaalis.",
    "rus": "Электронное выставление счетов ожидает подтверждения на портале регистрации компаний",
    "fin": "Verkkolaskutus odottaa hyväksyntää yritysrekisteriportaalissa",
    "swe": "E-fakturering väntar på bekräftelse i Företagsregistreringsportalen.",
    "lit": "E. sąskaitos laukia patvirtinimo Įmonių Registravimo Portale",
    "lav": "E-rēķinu aktivizēšana gaida apstiprinājumu Uzņēmumu Reģistra portālā.",
    "esp": "Confirmación de facturación electrónica pendiente en el Portal de registro de la empresa",
    "nor": "E-faktureringsbekreftelse venter i Firmaregistreringsportal"
   },
   "einvoicingInactiveRIK": {
    "eng": "E-invoicing activation is available. Company representative needs to confirm it on the Company Registration Portal.",
    "pol": "Aktywacja e-fakturowania jest dostępna. Przedstawiciel firmy musi to potwierdzić na Portalu Rejestracji Firm.",
    "est": "Teil on võimalik aktiveerida E-arvete vastuvõtmine. Pärast aktiveerimist tuleb ettevõtte juhatuse liikmel kinnitada Ettevõtjaportaalis E-arvete vastuvõtmine.",
    "rus": "Вы можете запросить активацию электронного выставления счетов. Вам потребуется, чтобы представитель компании зашел на портал регистрации компании и подтвердил это.",
    "fin": "Voit pyytää verkkolaskutuksen aktivointia. Yrityksesi edustajan tulee hyväksyä aktivointi yritysrekisteriportaalissa.",
    "swe": "Aktivering av e-fakturor är tillgängligt. En företagsrepresent behöver bekräfta detta i Företagsregistreringsportalen.",
    "lit": "Galite aktyvuoti e. sąskaitų išrašymą. Įmonės atstovas turi tai patvirtinti Įmonių Registravimo Portale.",
    "lav": "Jūs varat pieprasīt e-rēķinu aktivizēšanu. Jums būs nepieciešams apstiprināt pieprasījumu Uzņēmumu Reģistra portālā.",
    "esp": "La activación de facturación electrónica está disponible. El representante de la empresa debe confirmarlo en el Portal de registro de la empresa.",
    "nor": "Aktivering av e-faktura er tilgjengelig. Firmarepresentant må bekrefte det på firmaregistreringsportalen."
   },
   "einvoicingActiveRIK": {
    "eng": "E-invoicing is activated! E-invoices sent to your company will be automatically forwarded to your accounting software.",
    "pol": "E-fakturowanie jest aktywne! E-faktury wysyłane do Twojej firmy będą automatycznie przekazywane do Twojego programu księgowego.",
    "est": "E-arvete vastuvõtmine on aktiveeritud! CostPocket võtab vastu ettevõttele saadetud e-arved ja saadab need automaatselt edasi teie raamatupidamistarkvarasse.",
    "rus": "Электронное выставление счетов активировано! Э-счета, отправленные вашей компании, будут получены CostPocket и автоматически отправлены в вашу бухгалтерскую программу.",
    "fin": "Verkkolaskutus on aktivoitu! CostPocket vastaanottaa yrityksellesi lähetetyt sähköiset laskut ja välittää ne automaattisesti kirjanpito-ohjelmistoosi.\"",
    "lav": "E-rēķini ir aktivizēti! Jūsu izsūtītie e-rēķini tiks automātiski pārsūtīti uz Jūsu grāmatvedību.",
    "swe": "E-fakturering är aktiverat! E-fakturor som skickats till ditt företag kommer att automatiskt vidarebefodras till ditt bokföringsprogram.",
    "lit": "E. sąskaitų išrašymas aktyvuotas! Jūsų įmonei siunčiamos e. sąskaitos bus automatiškai persiųstos į jūsų apskaitos programą.",
    "esp": "¡La facturación electrónica está activada! Las facturas electrónicas enviadas a su empresa se reenviarán automáticamente a su software de contabilidad.",
    "nor": "E-fakturering er aktivert! E-fakturaer sendt til din bedrift blir automatisk videresendt til regnskapsprogrammet ditt."
   },
   "einvoices": {
    "eng": "E-invoices",
    "pol": "E-faktury",
    "est": "E-arved",
    "rus": "Э-счета",
    "fin": "E-laskut",
    "swe": "E-fakturor",
    "lit": "E. sąskaitos",
    "lav": "E-rēķini",
    "esp": "Facturas electrónicas",
    "nor": "E-fakturaer"
   },
   "100invoicesMonthly": {
    "eng": "100 e-invoices/month",
    "pol": "100 e-faktur / miesiąc",
    "est": "100 e-arvet/kuus",
    "rus": "100 э-счетов в месяц",
    "fin": "100 e-laskua/kuussa",
    "swe": "100 e-fakturor/månad",
    "lit": "100 e. sąskaitų per mėnesį",
    "lav": "100 e-rēķini/mēnesī",
    "esp": "100 Facturas electrónicas/mes",
    "nor": "100 e-fakturaer/mnd"
   },
   "perMonth": {
    "eng": "month",
    "pol": "miesiąc",
    "est": "kuus",
    "rus": "в месяц",
    "fin": "kuussa",
    "lav": "mēnesī",
    "swe": "månad",
    "lit": "mėnuo",
    "esp": "mes",
    "nor": "måned"
   },
   "einvoicing": {
    "eng": "E-invoicing",
    "pol": "e-fakturowanie",
    "est": "E-arved",
    "rus": "Электронные счета-фактуры",
    "fin": "Verkkolaskutus",
    "swe": "E-fakturering",
    "lit": "E. sąskaitos",
    "lav": "E-rēķini",
    "esp": "Facturación Electrónica",
    "nor": "E-fakturering"
   },
   "getStarted": {
    "eng": "Let's get started",
    "pol": "Zaczynamy",
    "est": "Hakkame peale",
    "rus": "Начнем",
    "fin": "Aloitetaan",
    "lav": "Sākam",
    "swe": "Sätt igång",
    "lit": "Pradėkime",
    "esp": "Empecemos",
    "nor": "La oss komme i gang"
   },
   "pleaseConfirmEmail": {
    "eng": "Enter your email to continue",
    "pol": "Aby kontynuować potwierdź swój adres e-mail",
    "est": "Palun kinnitage oma e-maili aadress",
    "rus": "Пожалуйста, подтвердите свой адрес электронной почты, чтобы продолжить",
    "fin": "Vahvista sähköpostiosoitteesi",
    "lav": "Lai turpinātu, ievadiet savu e-pastu un nospiediet \"Turpināt\"",
    "swe": "Var god bekräfta din e-post för att fortsätta",
    "lit": "Įveskite savo el. pašto adresą",
    "esp": "Confirme su correo electrónico para continuar",
    "nor": "Vennligst bekreft e-posten din for å fortsette"
   },
   "whenContinuingAcceptTerms": {
    "eng": "By tapping continue, you accept our",
    "pol": "Klikając Kontynuuj akceptujesz",
    "est": "Jätkates kinnitate, et teile sobivad meie",
    "rus": "Продолжая регистрироваться, вы подтверждаете, что соглашаетесь с нашими",
    "fin": "Painaessasi jatka, hyväksyt meidän",
    "lav": "Turpinot reģistrāciju, Jūs apstiprināt, ka piekrītat mūsu",
    "swe": "Genom att fortsätta accepter du vår",
    "lit": "Paspausdami Tęsti, jūs sutinkate su",
    "esp": "Al tocar continuar, acepta nuestro",
    "nor": "Ved å trykke på fortsett godtar du vår"
   },
   "toTermsOfService": {
    "eng": "Terms of Service",
    "pol": "Warunki świadczenia usług",
    "est": "Kasutustingimused",
    "rus": "условиями использования",
    "fin": "Käyttöehdot",
    "lav": "pakalpojumu sniegšanas noteikumiem",
    "swe": "Användarvillkor",
    "lit": "Naudojimo sąlygomis",
    "esp": "Términos de Servicio",
    "nor": "Vilkår for bruk"
   },
   "and": {
    "eng": "and",
    "pol": "i",
    "est": "ja",
    "rus": "и",
    "fin": "ja",
    "lav": "un",
    "swe": "och",
    "lit": "ir",
    "esp": "y",
    "nor": "og"
   },
   "toPrivacyPolicy": {
    "eng": "Privacy Policy",
    "pol": "Politykę prywatności",
    "est": "Privaatsuspoliitika",
    "rus": "политикой конфиденциальности",
    "fin": "Tietosuojakäytäntö",
    "lav": "privātuma politikai",
    "swe": "Integritetspolicy",
    "lit": "Privatumo politika",
    "esp": "Política de privacidad",
    "nor": "Personvernerklæring"
   },
   "checkEmail": {
    "eng": "Check your email",
    "pol": "Sprawdź swój email",
    "est": "Vaadake oma e-maili",
    "rus": "Проверьте свою электронную почту",
    "fin": "Tarkista sähköpostisi",
    "lav": "Pārbaudiet e-pastu",
    "swe": "Kontrollera din e-post",
    "lit": "Pasitikrinkite el. paštą",
    "esp": "Revise su correo electrónico",
    "nor": "Sjekk e-posten"
   },
   "weSentInstructionsHowToConfirmEmail": {
    "eng": "Check your inbox to confirm the email address.",
    "pol": "Sprawdź swoją skrzynkę, aby potwierdzić adres e-mail.",
    "est": "Saatsime teile e-maili. Seal on link, millega tõestategi, et see on teie aadress.",
    "rus": "Мы отправили эл-письмо с инструкциями по подтверждению вашего электронного адреса.",
    "fin": "Lähetimme sinulle sähköpostin, jonka avulla voit todistaa sähköpostiosoitteesi",
    "lav": "Mēs Jums nosūtījām e-pastu ar instrukciju, kā apstiprināt e-pasta adresi.",
    "swe": "Kolla din inkorg för att bekräfta e-postadressen.",
    "lit": "Patikrinkite savo pašto dėžutę ir patvirtinkite el. pašto adresą.",
    "esp": "Revise su bandeja de entrada para confirmar la dirección de correo electrónico.",
    "nor": "Sjekk innboksen for å bekrefte e-postadressen."
   },
   "didntGetEmail": {
    "eng": "Didn't get the email?",
    "pol": "Wiadomość e-mail nie dotarła?",
    "est": "Ei saanud e-maili?",
    "rus": "Не получили письмо?",
    "fin": "Etkö saanutkaan sähköpostia?",
    "lav": "Nesaņēmāt e-pastu?",
    "swe": "Har du inte fått e-posten?",
    "lit": "Negavote el. laiško?",
    "esp": "¿No ha recibido el correo electrónico?",
    "nor": "Fikk du ikke e-posten?"
   },
   "almostDone": {
    "eng": "Email address is confirmed. Let us know your name:",
    "pol": "Adres e-mail został potwierdzony. Daj nam znać, jak się nazywasz:",
    "est": "E-posti aadress on kinnitatud. Andke meile teada oma nimi:",
    "rus": "Адрес электронной почты подтвержден. Введите ваше имя:",
    "fin": "Melkein valmista",
    "lav": "E-pasta adrese ir apstiprināta. Norādiet savu vārdu:",
    "swe": "Nästan färdig...",
    "lit": "E-pašto adresas patvirtintas. Įveskite vardą ir pavardę:",
    "esp": "Casi terminado ...",
    "nor": "E-postadressen er bekreftet. Gi oss beskjed om navnet ditt:"
   },
   "choosePassword": {
    "eng": "Set a password of at least 10 characters",
    "pol": "Ustaw hasło składające się z co najmniej 10 znaków",
    "est": "Määrake vähemalt 10 tähemärgi pikkune parool",
    "rus": "Создайте пароль, состоящий не менее чем из 10 символов",
    "fin": "Valitse salasana",
    "lav": "Izveidojiet paroli ar vismaz 10 simboliem",
    "swe": "Välj lösenord",
    "lit": "Įveskite slaptažodį iš mažiausiai 10 simbolių",
    "esp": "Y elija una contraseña",
    "nor": "Angi et passord på minst 10 tegn"
   },
   "signupCompleted": {
    "eng": "Sign up completed!",
    "pol": "Rejestracja zakończona!",
    "est": "Registreerumine õnnestus!",
    "rus": "Регистрация завершена!",
    "fin": "Rekisteröityminen onnistui!",
    "lav": "Reģistrācija ir galā!",
    "swe": "Registrering klar!",
    "lit": "Registracija baigta!",
    "esp": "¡Registro completado!",
    "nor": "Påmelding fullført!"
   },
   "enterPasswordToLogin": {
    "eng": "Enter password",
    "pol": "Wprowadź hasło",
    "est": "Sisesta parool",
    "rus": "Введите свой пароль",
    "fin": "Salasana",
    "lav": "Ievadiet Jūsu paroli",
    "swe": "Ange lösenord",
    "lit": "Įveskite slaptažodį",
    "esp": "Introducir la contraseña",
    "nor": "Oppgi passord"
   },
   "resetPassword": {
    "eng": "Reset password",
    "pol": "Zresetuj hasło",
    "est": "Uuenda parooli",
    "rus": "Сброс пароля",
    "fin": "Nollaa salasana",
    "lav": "Atjaunot paroli",
    "swe": "Återställ lösenord",
    "lit": "Nustatyti slaptažodį iš naujo",
    "esp": "Restablecer la contraseña",
    "nor": "tilbakestille passord"
   },
   "recoveryLinkSent": {
    "eng": "Recovery link sent",
    "pol": "Wysłano link do odzyskania hasła",
    "est": "Parooli uuendamise link on saadetud",
    "rus": "Ссылка для восстановления пароля отправлена",
    "fin": "Linkki salasanan muuttamiseen on lähetetty",
    "lav": "Paroles atjaunošanas saite ir nosūtīta",
    "swe": "Återställningslänk skickad",
    "lit": "Atkūrimo nuoroda išsiųsta",
    "esp": "Enlace de recuperación enviado",
    "nor": "Gjenopprettingslenken er sendt"
   },
   "signupWith": {
    "eng": "Sign up with",
    "pol": "Zaloguj się przy pomocy",
    "est": "Registreeru",
    "rus": "Войти с",
    "fin": "Liity organisaatioon",
    "lav": "Ienākt ar",
    "swe": "Registrera med",
    "lit": "Registruokitės su",
    "esp": "Registrarse con",
    "nor": "Sign up with"
   },
   "changePassword": {
    "eng": "Change password",
    "pol": "Zmień hasło",
    "est": "Vaheta parooli",
    "rus": "Изменить пароль",
    "fin": "Muuta salasana",
    "lav": "Mainīt paroli",
    "swe": "Ändra lösenord",
    "lit": "Keisti slaptažodį",
    "esp": "Cambiar contraseña",
    "nor": "Bytt passord"
   },
   "unableToResetPasswordContactSupport": {
    "eng": "Unable to reset password. Please contact support.",
    "pol": "Nie można zresetować hasła. Skontaktuj się z pomocą techniczną.",
    "est": "Parooli uuendamine ei õnnestu. Palun pöörduge klienditoe poole.",
    "rus": "Не удалось восстановить пароль. Обратитесь в службу поддержки.",
    "fin": "Salasanan vaihtaminen ei onnistunut. Ota yhteyttä asiakastukeen.",
    "lav": "Paroles atjaunošana neizdevās. Lūdzu, sazinieties ar atbalstu.",
    "swe": "Kan inte återställa lösenordet. Var god kontakta kundtjänst.",
    "lit": "Nepavyksta iš naujo nustatyti slaptažodžio. Kreipkitės į klientų aptarnavimo komandą",
    "esp": "No es posible resetear contraseña. Por favor contacte a soporte",
    "nor": "Kan ikke tilbakestille passordet. Vennligst kontakt support."
   },
   "createalocalpassword": {
    "eng": "Create a password",
    "pol": "Utwórz hasło",
    "est": "Loo parool",
    "rus": "Создать пароль",
    "fin": "Luo salasana",
    "lav": "Izveidot paroli",
    "swe": "Skapa lösenord",
    "lit": "Sukurti slaptažodį",
    "esp": "Crear contraseña",
    "nor": "Lag et passord"
   },
   "revokeAdmin": {
    "eng": "Revoke admin",
    "pol": "Cofnięcie praw administratora",
    "est": "Muuda administraator kasutajaks",
    "rus": "Удалить права администратора",
    "fin": "Tee ylläpitäjästä käyttäjä",
    "lav": "Noņemt administratora tiesības",
    "swe": "Ta bort som administratör",
    "lit": "Atšaukti administratoriaus teises",
    "esp": "Revocar administrador",
    "nor": "Opphev admin"
   },
   "makeAdmin": {
    "eng": "Make admin",
    "pol": "Nadaj rolę administratora",
    "est": "Muuda kasutaja administraatoriks",
    "rus": "Предоставить права администратора",
    "fin": "tee käyttäjästä ylläpitäjä",
    "lav": "Piešķirt administratora tiesības",
    "swe": "Lägg till som administratör",
    "lit": "Suteikti administratoriaus teises",
    "esp": "Hacerlo administrador",
    "nor": "Gjør admin"
   },
   "roleChanged": {
    "eng": "User role changed",
    "pol": "Rola użytkownika została zmieniona",
    "est": "Kasutaja roll on muudetud",
    "rus": "Права пользователя изменены",
    "fin": "Käyttäjän rooli muutettu",
    "lav": "Lietotāja tiesības nomainītas",
    "swe": "Användarroll har ändrats",
    "lit": "Vartotojo rolė pakeista",
    "esp": "Cambio de rol de usuario",
    "nor": "Brukerrolle endret"
   },
   "invoice": {
    "eng": "Invoice",
    "pol": "Faktura",
    "est": "Arve",
    "rus": "Счет",
    "fin": "Lasku",
    "lav": "Rēķins",
    "swe": "Faktura",
    "lit": "Sąskaita",
    "esp": "Factura",
    "nor": "Faktura"
   },
   "status": {
    "eng": "Status",
    "pol": "Status",
    "est": "Dokumendi staatus",
    "rus": "Статус",
    "fin": "Tila",
    "lav": "Statuss",
    "swe": "Status",
    "lit": "Statusas",
    "esp": "Estado",
    "nor": "Status"
   },
   "einvoice": {
    "eng": "E-invoice",
    "pol": "e-faktura",
    "est": "E-arve",
    "rus": "Эл-счет",
    "fin": "E-lasku",
    "lav": "E-rēķins",
    "swe": "E-faktura",
    "lit": "E. sąskaita",
    "esp": "Factura Electrónica",
    "nor": "e-faktura"
   },
   "docType": {
    "eng": "Document type",
    "pol": "Typ dokumentu",
    "est": "Dokumendi tüüp",
    "rus": "Тип документа",
    "fin": "Dokumentin luonne",
    "lav": "Dokumenta tips",
    "swe": "Dokumenttyp",
    "lit": "Dokumento tipas",
    "esp": "Tipo de documento"
   },
   "open": {
    "eng": "Open",
    "pol": "Otwórz",
    "est": "Ava",
    "rus": "Открыть",
    "fin": "Avaa",
    "lav": "Atvērt",
    "swe": "Öppna",
    "lit": "Atidaryti",
    "esp": "Abierto",
    "nor": "Åpen"
   },
   "FromEmail": {
    "eng": "Sent with email",
    "pol": "Wyślij pocztą e-mail",
    "est": "Saadetud emailiga",
    "rus": "Отправлено по эл-почте",
    "fin": "Lähetetty sähköpostilla",
    "lav": "Nosūtīts e-pastā",
    "swe": "Skicka med e-post",
    "lit": "Išsiųsta su el. laišku",
    "esp": "Enviar con email",
    "nor": "Sendes med e-post"
   },
   "sendToSoftware": {
    "eng": "Send to software",
    "pol": "Wyślij do oprogramowania",
    "est": "Saada tarkvarasse",
    "rus": "Отправить в бухгалтерию",
    "fin": "Lähetä ohjelmistoon",
    "lav": "Nosūtīt grāmatvedībai",
    "swe": "Skicka till bokföringsprogram",
    "lit": "Išsiųsti į programą",
    "esp": "Enviar al software",
    "nor": "Send til programvare"
   },
   "receipts": {
    "eng": "Documents",
    "pol": "Dokumenty",
    "est": "Dokumendid",
    "rus": "Документы",
    "fin": "Dokumentit",
    "lav": "Dokumenti",
    "swe": "Dokument",
    "lit": "Dokumentai",
    "esp": "Documentos",
    "nor": "Dokumenter"
   },
   "IBAN": {
    "eng": "IBAN",
    "pol": "IBAN",
    "est": "Pangakonto number",
    "rus": "Банковский счет",
    "fin": "IBAN",
    "lav": "Bankas konta nr.",
    "swe": "IBAN",
    "lit": "IBAN",
    "esp": "IBAN",
    "nor": "IBAN"
   },
   "dueDate": {
    "eng": "Due date",
    "pol": "Termin płatności",
    "est": "Maksetähtaeg",
    "rus": "Условия оплаты",
    "fin": "Eräpäivä",
    "lav": "Apmaksas termiņš",
    "swe": "Förfallodatum",
    "lit": "Apmokėjimo terminas",
    "esp": "Fecha de Vencimiento",
    "nor": "Tidsfrist"
   },
   "select": {
    "eng": "Select",
    "pol": "Wybierz",
    "est": "Vali",
    "rus": "Выбрать",
    "fin": "Valitse",
    "lav": "Izvēlēties",
    "swe": "Välj",
    "lit": "Pasirinkti",
    "esp": "Seleccionar",
    "nor": "Velg"
   },
   "beingPrepared": {
    "eng": "Being prepared",
    "pol": "W przygotowaniu",
    "est": "Ettevalmistamisel",
    "rus": "Готовится",
    "fin": "Valmistautuminen",
    "lav": "Tiek gatavots",
    "swe": "Förbereds",
    "lit": "Ruošiama",
    "esp": "Estar preparado",
    "nor": "Forbredes"
   },
   "done": {
    "eng": "Done",
    "pol": "Gotowe",
    "est": "Tehtud",
    "rus": "Готово",
    "fin": "Valmis",
    "lav": "Gatavs",
    "swe": "Färdig",
    "lit": "Atlikta",
    "esp": "Hecho",
    "nor": "Ferdig"
   },
   "confirmed": {
    "eng": "Submitted",
    "pol": "Dodano",
    "est": "Esitatud",
    "rus": "Подтвержден",
    "fin": "Vahvistettu",
    "lav": "Apstiprināts",
    "swe": "Inskickad",
    "lit": "Pateikta",
    "esp": "Presentado",
    "nor": "Sendt inn"
   },
   "updated": {
    "eng": "Updated",
    "pol": "Zaktualizowano",
    "est": "Uuendatud",
    "rus": "Обновлено",
    "fin": "Päivitetty",
    "lav": "Saglabāts",
    "swe": "Uppdaterad",
    "lit": "Atnaujinta",
    "esp": "Actualizado",
    "nor": "Oppdatert"
   },
   "canAddExpenseToReportLater": {
    "eng": "Add an expense to a report now or later.",
    "pol": "Dodaj wydatek do raportu teraz lub później.",
    "est": "Siin saab selle dokumendi lisada aruandesse võite seda teha ka hiljem",
    "rus": "Вы можете добавить документы о расходах в свой отчет позже.",
    "fin": "Voit lisätä kulut raporttiin nyt tai tehdä sen myöhemmin.",
    "lav": "Izdevumu dokumentus pārskatam varēsiet pievienot arī vēlāk.",
    "swe": "Lägg till en utgift till en rapport nu eller senare.",
    "lit": "Išlaidas į ataskaitą galite įtraukti dabar arba vėliau.",
    "esp": "Agregue un gasto a un informe ahora o más tarde.",
    "nor": "Legg til en utgift i en rapport nå eller senere."
   },
   "createNewReport": {
    "eng": "Create new report",
    "pol": "Utwórz nowy raport",
    "est": "Loo uus aruanne",
    "rus": "Создать новый отчет",
    "fin": "Luo uusi raportti",
    "lav": "Izveidot jaunu pārskatu",
    "swe": "Skapa ny rapport",
    "lit": "Sukurti naują ataskaitą",
    "esp": "Crear nuevo informe",
    "nor": "Opprett ny rapport"
   },
   "continueWithoutReport": {
    "eng": "Continue without report",
    "pol": "Kontynuuj bez raportu",
    "est": "Jätka ilma aruandesse lisamata",
    "rus": "Добавить без отчета",
    "fin": "Jatka ilman raporttia",
    "lav": "Pievienot bez pārskata",
    "swe": "Fortsätt utan rapport",
    "lit": "Tęsti be ataskaitos",
    "esp": "Continuar sin informe",
    "nor": "Fortsett uten rapport"
   },
   "skipAndForward": {
    "eng": "Skip & forward",
    "pol": "Pomiń i wyślij",
    "est": "Jäta vahele ja edasta raamatupidamisse",
    "rus": "Пропустить и отправить",
    "fin": "Ohita & eteenpäin",
    "lav": "Izlaist un nosūtīt programmatūrai",
    "swe": "Hoppa över & fortsätt",
    "lit": "Praleisti ir pateikti",
    "esp": "Saltar y continuar",
    "nor": "Hopp og fremover"
   },
   "supplierVATNumber": {
    "eng": "VAT Number",
    "pol": "NIP",
    "est": "KMKR number",
    "rus": "Номер плательщика НДС",
    "fin": "ALV-tunnus",
    "lav": "PVN numurs",
    "swe": "VAT-nummer",
    "lit": "PVM kodas",
    "esp": "Número de valor agregado",
    "nor": "MVA-nummer"
   },
   "total": {
    "eng": "Total",
    "pol": "Razem",
    "est": "Kokku",
    "rus": "Общее",
    "fin": "Yhteensä",
    "lav": "Kopā",
    "swe": "Totalt",
    "lit": "Iš viso",
    "esp": "Total",
    "nor": "Total"
   },
   "totalDistance": {
    "eng": "Total distance (km)",
    "pol": "Całkowita odległość (km)",
    "est": "Kogu distants (km)",
    "rus": "Общее расстояние (км)",
    "fin": "Matka yhteensä (km)",
    "lav": "Kopējā distance (km)",
    "swe": "Totalt avstånd (km)",
    "lit": "Bendras atstumas (km)",
    "esp": "Distancia total (km)",
    "nor": "Total avstand (km)"
   },
   "roundTrip": {
    "eng": "Round trip",
    "pol": "Podróż w obie strony",
    "est": "Edasi-tagasi sõit",
    "rus": "Поездка в оба конца",
    "fin": "Meno-paluu matka",
    "lav": "Turp un atpakaļ",
    "swe": "Tur- och returresa",
    "lit": "Kelionė į abi puses",
    "esp": "Viaje ida y vuelta",
    "nor": "Rundtur"
   },
   "vehicle": {
    "eng": "Vehicle",
    "pol": "Pojazd",
    "est": "Sõiduk",
    "rus": "Транспортное средство",
    "fin": "Ajoneuvo",
    "lav": "Transportlīdzeklis",
    "swe": "Fordon",
    "lit": "Transporto priemonė",
    "esp": "Vehicle",
    "nor": "Kjøretøy"
   },
   "companyCar": {
    "eng": "Company car",
    "pol": "Samochód służbowy",
    "est": "Ettevõtte auto",
    "rus": "Машина компании",
    "fin": "Yrityksen auto",
    "lav": "Uzņēmuma auto",
    "swe": "Företagsbil",
    "lit": "Kompanijos automobilis",
    "esp": "Vehículo de la empresa",
    "nor": "Firmabil"
   },
   "motorboat": {
    "eng": "Motorboat",
    "pol": "Motorówka",
    "est": "Mootorpaat",
    "rus": "Моторная лодка",
    "fin": "Moottorivene",
    "swe": "Motorbåt",
    "lit": "Motorinė valtis",
    "lav": "Motorlaiva",
    "esp": "Bote de Motor",
    "nor": "Motorbåt"
   },
   "motorcycle": {
    "eng": "Motorcycle",
    "pol": "Motocykl",
    "est": "Mootorratas",
    "rus": "Мотоцикл",
    "fin": "Moottoripyörä",
    "swe": "Motorcykel",
    "lit": "Motociklas",
    "lav": "Motocikls",
    "esp": "Motocicleta",
    "nor": "Motorsykkel"
   },
   "moped": {
    "eng": "Moped",
    "pol": "Motorower",
    "est": "Mopeed",
    "rus": "Мопед",
    "fin": "Mopo",
    "swe": "Moped",
    "lit": "Mopedas",
    "lav": "Mopēds",
    "esp": "Ciclomotor",
    "nor": "Moped"
   },
   "snowmobile": {
    "eng": "Snowmobile",
    "pol": "Skuter śnieżny",
    "est": "Mootorsaan",
    "rus": "Снегоход",
    "fin": "Moottorikelkka",
    "swe": "Snöskoter",
    "lit": "Sniego motociklas",
    "lav": "Sniega motocikls",
    "esp": "Moto de Nieve",
    "nor": "Snøscooter"
   },
   "quadbike": {
    "eng": "All-terrain quadbike",
    "pol": "ATV",
    "est": "ATV",
    "rus": "Вездеходный квадроцикл",
    "fin": "Mönkijä",
    "swe": "Fyrhjuling",
    "lit": "Keturratis",
    "lav": "Kvadracikls",
    "esp": "Cuatriciclo todoterreno",
    "nor": "Quadbike"
   },
   "other": {
    "eng": "Other",
    "pol": "Inne",
    "est": "Muu",
    "rus": "Другое",
    "fin": "Muu",
    "lav": "Cits",
    "swe": "Övrigt",
    "lit": "Kita",
    "esp": "Otro",
    "nor": "Andre"
   },
   "moreThan50hp": {
    "eng": "More than 50 hp",
    "pol": "Ponad 50 km",
    "est": "Üle 50 hj",
    "rus": "Более 50 л.с.",
    "fin": "Enemmän kuin 50 hv",
    "lit": "Daugiau nei 50 AG",
    "swe": "Över 50 hk",
    "lav": "Vairāk par 50 zs",
    "esp": "Más de 50 km",
    "nor": "Mer enn 50 hk"
   },
   "numberOfCoworkers": {
    "eng": "Number of coworkers",
    "pol": "Liczba współpracowników",
    "est": "Töökaaslaste arv autos",
    "rus": "Количество сослуживцев",
    "fin": "Työtovereiden määrä",
    "swe": "Antal medarbetare",
    "lit": "Kolegų skaičius",
    "lav": "Kolēģu skaits",
    "esp": "Numero de compañeros de trabajo",
    "nor": "Antall medarbeidere"
   },
   "trailer": {
    "eng": "trailer",
    "pol": "przyczepa",
    "est": "järelhaagis",
    "rus": "трейлер",
    "fin": "perävaunu",
    "lit": "priekaba",
    "swe": "släpvagn",
    "lav": "treileris",
    "esp": "remolque",
    "nor": "trailer"
   },
   "caravan": {
    "eng": "caravan",
    "pol": "przyczepa kempingowa",
    "est": "haagissuvila",
    "rus": "караван",
    "fin": "asuntovaunu",
    "lit": "kemperis",
    "swe": "husvagn",
    "lav": "karavāna",
    "esp": "caravana",
    "nor": "campingvogn"
   },
   "canteenOrEqual": {
    "eng": "mobile canteen or towing equally heavy load",
    "pol": "kantyna lub holowanie równie ciężkiego ładunku",
    "est": "mobiilse söökla või sama raske koorma pukseerimine",
    "rus": "передвижная столовая или буксировка столь же тяжелого груза",
    "fin": "taukotuvan tai vastaavan raskan kuorman kuljettamista",
    "lav": "pārvietojamā virtuve vai līdzīgas masas kravas vilkšana",
    "lit": "mobili valgykla ar vienodai sunkaus krovinio tempimas",
    "swe": "raststuga eller släp med lika tung last",
    "esp": "comedor móvil o remolque de carga igualmente pesada",
    "nor": "mobil kantine eller tauing av like tung last"
   },
   "machineryOrLoad": {
    "eng": "machinery or load over 80 kg inside the car",
    "pol": "ciężkie ładunki wewnątrz samochodu (> 80 kg)",
    "est": "masinad või muu raske koorem autos (> 80 kg)",
    "rus": "оборудование или другой груз внутри автомобиля (>80 кг)",
    "fin": "raskas kuorma auton mukana (>80kg)",
    "lav": "aprīkojums vai cita veida krava automašīnā (> 80 kg)",
    "swe": "maskiner eller andra föremål över 80 kg i bilen",
    "lit": "technika ar kitas sunkus krovinys automobilyje (>80 kg)",
    "esp": "maquinaria o carga superior a 80 kg en el interior del coche",
    "nor": "maskineri eller last over 80 kg inne i bilen"
   },
   "serviceDog": {
    "eng": "service dog",
    "pol": "pies przewodnik",
    "est": "teenistuskoer",
    "rus": "служебная собака",
    "fin": "palvelukoira",
    "swe": "servicehund",
    "lit": "tarnybinis šuo",
    "lav": "dienesta suns",
    "esp": "perro de servicio",
    "nor": "servicehund"
   },
   "travelStart": {
    "eng": "Travel start",
    "pol": "Start podróży",
    "est": "Lähetuse algus",
    "rus": "Начало поездки",
    "fin": "Matkan aloitus",
    "swe": "Resestart",
    "lit": "Kelionės pradžia",
    "lav": "Komandējuma sākums",
    "esp": "Inicio del viaje",
    "nor": "Reisestart"
   },
   "destination": {
    "eng": "Destination",
    "pol": "Miejsce docelowe",
    "est": "Sihtkoht",
    "rus": "Место назначения",
    "fin": "Määränpää",
    "swe": "Destination",
    "lit": "Tikslas",
    "lav": "Galamērķis",
    "esp": "Destino",
    "nor": "Destinasjon"
   },
   "startingPointAddress": {
    "eng": "Starting address",
    "pol": "Adres początkowy",
    "est": "Lähtepunkti aadress",
    "rus": "Адрес начальной точки",
    "fin": "Lähtöpisteen osoite",
    "lav": "Izbraukšanas vietas adrese",
    "swe": "Startpunktsadress",
    "lit": "Pradinis adresas",
    "esp": "Dirección de inicio",
    "nor": "Startadresse"
   },
   "destinationAddress": {
    "eng": "Destination address",
    "pol": "Adres miejsca docelowego",
    "est": "Sihtkoha aadress",
    "rus": "Адрес назначения",
    "fin": "Määränpään osoite",
    "lav": "Galamērķa adrese",
    "swe": "Destinationsadress",
    "lit": "Galutinis adresas",
    "esp": "Dirección de destino",
    "nor": "Ankomstadresse"
   },
   "insertValidAddress": {
    "eng": "Please insert valid address!",
    "pol": "Proszę podać prawidłowy adres!",
    "est": "Palun sisestage korrektne aadress!",
    "rus": "Введите действительный адрес!",
    "fin": "Syötä osoite",
    "swe": "Var god infoga giltlig adress!",
    "lit": "Įveskite galiojantį adresą!",
    "lav": "Norādiet derīgu adresi!",
    "esp": "Introduzca una dirección válida.",
    "nor": "Vennligst legg inn gyldig adresse!"
   },
   "chooseVehicle": {
    "eng": "Choose vehicle",
    "pol": "Wybierz pojazd",
    "est": "Valige sõiduk",
    "rus": "Выберите тип транспортного средства",
    "fin": "Valitse ajoneuvo",
    "swe": "Välj fordon",
    "lav": "Izvēlieties transportlīdzekli",
    "lit": "Pasirinkite transporto priemonę",
    "esp": "Seleccione vehiculo",
    "nor": "Velg kjøretøy"
   },
   "forrestRoadDistance": {
    "eng": "Distance on forest road or construction site closed to other traffic, km from total",
    "pol": "Odległość pokonana na drodze leśnej lub placu budowy zamkniętym dla innego ruchu (km od całości)",
    "est": "Distants läbitud metsateel või ehituses oleval teel, mis on suletud teistele liiklejatele (kokku kilomeetreid)",
    "rus": "Расстояние проезда по лесной дороге или строительной площадке, закрытой для других транспортных средств (км от общего количества)",
    "fin": "Metsäautotiellä tai muulta liikenteeltä suljetulla tienrakennustyömaalla ajettu matka(km yhteensä)",
    "lav": "Nobrauktā distance pa meža ceļiem vai celtniecības laukumiem (km no kopējā)",
    "lit": "Atstumas nuvažiuotas miško keliu arba uždaroje statybvietėje (km nuo bendro atstumo)",
    "swe": "Avstånd kört längs skogsbilvägar eller på andra för annan trafik avstängda vägbyggnadsarbetsplatser, km av totalantal",
    "esp": "Distancia en camino forestal o sitio de construcción cerrado al resto del tráfico, km del total",
    "nor": "Avstand på skogsvei eller byggeplass stengt for annen trafikk, km fra totalt"
   },
   "selectDate": {
    "eng": "Select date",
    "pol": "Wybierz datę",
    "est": "Valige kuupäev",
    "rus": "Выберите дату",
    "fin": "Valitse päivämäärä",
    "lav": "Izvēlieties datumu",
    "swe": "Välj datum",
    "lit": "Pasirinkti datą",
    "esp": "Seleccione fecha",
    "nor": "Velg dato"
   },
   "whyDidYouDriveThere": {
    "eng": "Purpose of the trip",
    "pol": "Cel podróży",
    "est": "Sõidu eesmärk",
    "rus": "Какова была цель командировки?",
    "fin": "Matkan tarkoitus",
    "lav": "Kāds bija komandejuma mērķis?",
    "swe": "Syfte med resan",
    "lit": "Kelionės tikslas",
    "esp": "Propósito del viaje",
    "nor": "Formålet med turen"
   },
   "noReports": {
    "eng": "No reports",
    "pol": "Brak raportów",
    "est": "Aruanded puuduvad",
    "rus": "Готовых отчетов нет",
    "fin": "Ei raportteja",
    "lav": "Gatavu pārskatu nav",
    "swe": "Inga rapporter",
    "lit": "Nėra ataskaitų",
    "esp": "Sin informes",
    "nor": "Ingen rapporter"
   },
   "createNewReportOrSkipStep": {
    "eng": "Create a new report or skip this step",
    "pol": "Utwórz nowy raport lub pomiń",
    "est": "Looge aruanne või jätke see samm vahele",
    "rus": "Создайте новый отчет или пропустите этот шаг",
    "fin": "Luo uusi raportti tai unohda tämä vaihe",
    "lav": "Izveidojiet jaunu pārskatu vai izlaidiet šo soli",
    "swe": "Skapa en ny rapport eller hoppa över detta steg",
    "lit": "Sukurti naują ataskaitą arba praleisti šį žingsnį",
    "esp": "Cree un nuevo informe u omita este paso",
    "nor": "Opprett en ny rapport eller hopp over dette trinnet"
   },
   "addNew": {
    "eng": "Add new",
    "pol": "Dodaj nowe",
    "est": "Lisa uus",
    "rus": "Добавить новый",
    "fin": "Lisää uusi",
    "lav": "Pievienot jaunu",
    "swe": "Lägg till ny",
    "lit": "Pridėti naują",
    "esp": "Agregar nuevo",
    "nor": "Legg til ny"
   },
   "flowCustomization": {
    "eng": "Manage steps for submitting expenses",
    "pol": "Spersonalizuj swój przepływ pracy",
    "est": "Halda dokumendi esitamise sätteid",
    "rus": "Персонализируйте свой рабочий процесс",
    "fin": "Hallinnoi kulukirjauksen vaiheita",
    "lav": "Personalizēt darbības plūsmu",
    "swe": "Hantera steg för att skicka in utgifter",
    "lit": "Tvarkykite išlaidų pateikimo veiksmus",
    "esp": "Gestionar pasos para enviar gastos",
    "nor": "Administrer trinn for å sende utgifter"
   },
   "enableUsersToJoinCompany": {
    "eng": "Enable users to join company",
    "pol": "Zezwalaj użytkownikom na dołączanie do organizacji",
    "est": "Luba kasutajatel liituda ettevõttega",
    "rus": "Разрешить пользователям присоединяться к организации",
    "fin": "Salli käyttäjien liittyminen",
    "lav": "Atļaut lietotājiem pašiem pievienoties organizācijai",
    "swe": "Tillåt användare att ansluta sig till företaget",
    "lit": "Leisti vartotojams prisijungti prie kompanijos",
    "esp": "Permitir a los usuarios unirse a la empresa",
    "nor": "Gjør det mulig for brukere å bli med i selskapet"
   },
   "usersCanJoinAutomatically": {
    "eng": "Users can automatically join this company",
    "pol": "Użytkownicy mogą automatycznie dołączyć do tej organizacji",
    "est": "Luba kasutajal ise liituda ettevõttega",
    "rus": "Пользователи могутприсоединиться к этой организации без разрешения администратора",
    "fin": "käyttäjät voivat liittyä organisaation jäseniksi ilman erillistä hyväksyntää",
    "lav": "Lietotāji var pievienoties šai organizācijai bez administratora atļaujas",
    "swe": "Användare kan automatiskt ansluta sig till detta företag",
    "lit": "Vartotojai gali automatiškai prisijungti prie kompanijos",
    "esp": "Los usuarios pueden unirse automáticamente a esta empresa.",
    "nor": "Brukere kan automatisk bli med i dette selskapet"
   },
   "autoForwardDocuments": {
    "eng": "Auto-forward documents from email",
    "pol": "Automatyczne przesyłanie dokumentów",
    "est": "Automaatne dokumentide edastamine",
    "rus": "Автоматический перенос документов",
    "fin": "Dokumenttien automaattinen lähetys",
    "lav": "Automātiski pārsūtīt dokumentus no epasta",
    "swe": "Vidarebefodra automatiskt dokument",
    "lit": "Automatinis dokumentų persiuntimas",
    "esp": "Reenvío automático de documentos",
    "nor": "Automatisk videresending av dokumenter"
   },
   "forwardDocumentsToSoftwareAutomatically": {
    "eng": "Documents sent from email are forwarded to accounting without submitter input",
    "pol": "Automatyczne przekazywanie dokumentów z poczty e-mail do oprogramowania księgowego",
    "est": "Edasta e-postilt esitatud dokumendid automaatselt raamatupidamistarkvarasse",
    "rus": "Автоматически передавать документы в бухгалтерское программное обеспечение после их оцифровки из эл-почты",
    "fin": "välitä dokumentit sähköpostista kirjanpitoon automaattisesti",
    "lav": "Dokumenti no epasta tiek pārsūtīti uz grāmatvedības programmatūru bez lietotāja datu ievades tūlīt pēc to digitalizēšanas",
    "swe": "Automatiskt vidarebefodra dokument från e-post till bokföringsprogram",
    "lit": "Automatiškai persiųsti dokumentus iš el. pašto į apskaitos programą",
    "esp": "Reenvíe documentos automáticamente desde el correo electrónico al software de contabilidad",
    "nor": "Videresend automatisk dokumenter fra e-post til regnskapsprogramvare"
   },
   "areYouSureYouWantToDelete": {
    "eng": "Are you sure you want to delete",
    "pol": "Czy jesteś pewny że chcesz usunąć",
    "est": "Kas soovite kindlasti kustutada",
    "rus": "Вы уверены, что хотите удалить?",
    "fin": "Haluatko varmasti poistaa?",
    "lav": "Vai tiešām dzēst?",
    "swe": "Är du säker att du vill radera",
    "lit": "Ar tikrai norite pašalinti?",
    "esp": "¿Está seguro de que quiere eliminar?",
    "nor": "Er du sikker på at du vil slette"
   },
   "useCustomExpenseTypes": {
    "eng": "Use custom expense types",
    "pol": "Użyj spersonalizowanych rodzajów kosztów",
    "est": "Kasuta kohandatud kululiike",
    "rus": "Используйте персонализированные типы затрат",
    "fin": "Käytä mukautettuja kululajeja",
    "lav": "Lietot personalizētus izdevumu veidus",
    "swe": "Använd anpassade utgiftskategorier.",
    "lit": "Naudokite personalizuotus išlaidų tipus",
    "esp": "Utilice tipos de gastos personalizados",
    "nor": "Bruk egendefinerte utgiftstyper"
   },
   "manageExpenseTypes": {
    "eng": "Expense types",
    "pol": "Zarządzaj rodzajami kosztów",
    "est": "Kululiigid",
    "rus": "Типы расходов",
    "fin": "Hallinnoi kululajeja",
    "lav": "Izdevumu veidi",
    "swe": "Hantera utgiftstyper",
    "lit": "Išlaidų tipai",
    "esp": "Gestionar tipos de gastos",
    "nor": "Administrer utgiftstyper"
   },
   "addExpenseType": {
    "eng": "Add expense type",
    "pol": "Dodaj nowy rodzaj kosztu",
    "est": "Lisa kululiik",
    "rus": "Добавить новый тип затрат",
    "fin": "lisää kululaji",
    "lav": "Pievienot jaunu izdevumu veidu",
    "swe": "Lägg till utgiftstyp",
    "lit": "Pridėti išlaidų tipą",
    "esp": "Agregar tipo de gasto",
    "nor": "Legg til utgiftstype"
   },
   "addTo": {
    "eng": "Add to",
    "pol": "Dodaj do",
    "est": "Lisa alamkategooriasse",
    "rus": "Добавить",
    "fin": "lisää",
    "lav": "Pievienot",
    "swe": "Lägg till",
    "lit": "Pridėti prie",
    "esp": "Añadir",
    "nor": "Legg til"
   },
   "expenseTypes": {
    "eng": "Expense types",
    "pol": "Rodzaje kosztów",
    "est": "Kululiigid",
    "rus": "Виды затрат",
    "fin": "Kululajit",
    "lav": "Izdevumu veidi",
    "swe": "Utgiftstyper",
    "lit": "Išlaidų tipai",
    "esp": "Tipos de gastos",
    "nor": "Utgiftstyper"
   },
   "addSubcategory": {
    "eng": "Add subcategory",
    "pol": "Dodaj podkategorię",
    "est": "Lisa alamkategooria (valikuline)",
    "rus": "Добавить подкатегорию",
    "fin": "lisää alakategoria",
    "lav": "Pievienot apakškategoriju",
    "swe": "Lägg till underkategori",
    "lit": "Pridėti subkategoriją",
    "esp": "Agregar subcategoría",
    "nor": "Legg til underkategori"
   },
   "editExpenseType": {
    "eng": "Edit expense type",
    "pol": "Edytuj rodzaj kosztu",
    "est": "Muuda kululiiki",
    "rus": "Изменить тип расходов",
    "fin": "Muokkaa kululajia",
    "lav": "Labot izdevumu veidu",
    "swe": "Redigera utgiftstyp",
    "lit": "Redaguoti išlaidų tipą",
    "esp": "Editar tipo de gasto",
    "nor": "Rediger utgiftstype"
   },
   "skipUserInput": {
    "eng": "Express mode",
    "pol": "Tryb ekspresowy",
    "est": "Ekspressrežiim",
    "rus": "Экспресс режим",
    "fin": "Pikakirjaus-tila",
    "lav": "Ekspress režīms",
    "swe": "Expressläge.",
    "lit": "Express režimas",
    "esp": "Modo express",
    "nor": "Ekspressmodus"
   },
   "skipAllUserInputOnDocSubmission": {
    "eng": "Skip all user input when submitting documents",
    "pol": "Pomiń wprowadzanie danych przez użytkownika podczas digitalizacji dokumentów",
    "est": "Pärast tšeki pildistamist ei küsita kasutajalt lisaküsimusi",
    "rus": "Не запрашивать у пользователя вопросы при подаче документов.",
    "fin": "Ohita tietojen syöttövaiheet dokumenttien kirjauksessa",
    "lav": "Digitalizējot, lietotājam netiks prasīts norādīt izdevumu detaļas.",
    "swe": "Hoppa över informationsinmatning vid inskickande av dokument.",
    "lit": "Praleisti vartotojo įvestus duomenis pateikiant dokumentus",
    "esp": "Omita todas las entradas del usuario al enviar documentos",
    "nor": "Hopp over alle brukerinnspill når du sender inn dokumenter"
   },
   "tapToCopyEmail": {
    "eng": "Tap to copy email",
    "pol": "Kliknij, aby skopiować adres e-mail",
    "est": "Vajuta emaili kopeerimiseks",
    "rus": "Нажмите, чтобы скопировать эл-почту",
    "fin": "Napauta kopioidaksesi sähköpostin",
    "lav": "Noklikšķiniet, lai nokopētu e-pastu",
    "swe": "Klicka här för att kopiera e-post.",
    "lit": "Bakstelėkite, kad nukopijuotumėte el. pašto adresą",
    "esp": "Toca para copiar el correo electrónico",
    "nor": "Trykk for å kopiere e-post"
   },
   "availableOnlyWithPAYG": {
    "eng": "This service is available only with Pay as you go plan.",
    "pol": "Ta funkcja jest dostępna jedynie dla planu „Pay as you go”.",
    "est": "See funktsionaalsus on kättesaadav vaid Tükipõhise hinnastamisega",
    "rus": "Данная услуга доступна только на тарифном плане \"Оплата по факту\".",
    "fin": "Toiminnallisuus on saatavilla ainoastaan \"Käytön mukaan\"-paketissa",
    "lav": "Šis pakalpojums pieejams tikai ar \"Maksā, kad izmanto\" plānu.",
    "swe": "Denna funktion är endast tillgänglig tillsammans med vår \"Pay as you go\"-plan.",
    "lit": "Šia funkcija galima tik su Pay as you go planu.",
    "esp": "Esta función solo está disponible con el plan Pay as you go.",
    "nor": "Denne funksjonen er kun tilgjengelig med Pay as you go-plan."
   },
   "allRowsOfPDFAreDigitized": {
    "eng": "All PDF invoice item lines are digitised by CostPocket. Each line 0.02€.",
    "pol": "Wszystkie wiersze faktury zostaną zdigitalizowane. Cena: 0,02 € za wiersz.",
    "est": "Kõik PDF arvel olevad arve read digiteeritakse. Hind: 0.02€ arve rida",
    "rus": "Все строки элементов счета в формате PDF будут оцифрованы. Цена каждой оцифрованной линии составляет 0,02€.",
    "fin": "Kaikki PDF:n rivitiedot on digitalisoitu, hinta 0,02€/rivi.",
    "lav": "Visas rēķinu preču/pakalpojumu rindas tiks digitalizētas. 0.02€ par katru digitalizēto rindu.",
    "swe": "Alla PDF artikelrader digitaliseras av CostPocket. Varje rad kostar €0.02",
    "lit": "CostPocket skaitmenina visas PDF sąskaitos elementų eilutes. Kiekviena eilutė po 0.02 €.",
    "esp": "CostPocket digitaliza todas las líneas de artículos de facturas en PDF. Cada línea 0,02 €.",
    "nor": "Alle PDF-fakturaposter er digitalisert av CostPocket. Hver linje 0,02€."
   },
   "invoiceLineDigitization": {
    "eng": "Digitise invoice lines",
    "pol": "Digitalizacja pozycji faktury",
    "est": "Digiteeri arve read",
    "rus": "Оцифровка строк счетов",
    "fin": "Laskun rivitietojen digitalisointi",
    "lav": "Rēķinu rindu digitalizācija",
    "swe": "Digitalisera fakturarader",
    "lit": "Skaitmeninti sąskaitos eilutes",
    "esp": "Digitalizar líneas de factura",
    "nor": "Digitaliser fakturalinjer"
   },
   "supplierNameVATNrOrRegNr": {
    "eng": "Supplier name, VAT number or reg. number",
    "pol": "Nazwa dostawcy, NIP, REGON",
    "est": "Tarnija nimi",
    "rus": "Название поставщика, нр.НДС или рег. номер",
    "fin": "Toimittajan nimi, ALV-numero tai yritystunnus",
    "lav": "Piegādātāja nosaukums, PVN vai Reģ. numurs",
    "swe": "Leverantörsnamn, VAT-nummer, eller registreringsnummer.",
    "lit": "Tiekėjo pavadinimas, PVM arba registracijos kodas",
    "esp": "Nombre del proveedor, número de IVA o registro. número",
    "nor": "Leverandørnavn, MVA-nummer eller reg. nummer"
   },
   "addManually": {
    "eng": "Add manually",
    "pol": "Wprowadź ręcznie",
    "est": "Lisa manuaalselt",
    "rus": "Добавить вручную",
    "fin": "Lisää manuaalisesti",
    "lav": "Pievienot manuāli",
    "swe": "Lägg till manuellt",
    "lit": "Pridėti rankiniu būdu",
    "esp": "Agregar manualmente",
    "nor": "Legg til manuelt"
   },
   "removeIntegration": {
    "eng": "Remove integration",
    "pol": "Usuń integrację",
    "est": "Eemalda liidestus",
    "rus": "Удалить интеграцию",
    "fin": "Poista integraatio",
    "lav": "Atslēgt integrāciju",
    "swe": "Ta bort integrering",
    "lit": "Pašalinti integraciją",
    "esp": "Eliminar integración",
    "nor": "Fjern integrasjon"
   },
   "reportDetails": {
    "eng": "Report details",
    "pol": "Szczegóły raportu",
    "est": "Aruande üksikasjad",
    "rus": "Содержание отчета",
    "fin": "Raportin tiedot",
    "lav": "Pārskata saturs",
    "swe": "Rapportdetaljer.",
    "lit": "Ataskaitos informacija",
    "esp": "Detalles del informe",
    "nor": "Rapporter detaljer"
   },
   "mileage": {
    "eng": "Mileage",
    "pol": "Przebieg pojazdu",
    "est": "Sõidupäevik",
    "rus": "Километри",
    "fin": "Kilometrit",
    "lav": "Kilometri",
    "swe": "Kilometerantal",
    "lit": "Nuvažiuota rida",
    "esp": "Kilometraje",
    "nor": "Kilometerstand"
   },
   "addMileage": {
    "eng": "Add mileage",
    "pol": "Dodaj przebieg pojazdu",
    "est": "Lisa kilomeetrid",
    "rus": "Добавить километры",
    "fin": "Lisää kilometrit",
    "lav": "Pievienot kilometrus",
    "swe": "Lägg till kilometerantal.",
    "lit": "Pridėti ridą",
    "esp": "Agregar kilometraje",
    "nor": "Legg til kjørelengde"
   },
   "submitReport": {
    "eng": "Submit report",
    "pol": "Wyślij raport",
    "est": "Esita aruanne",
    "rus": "Отправить отчет",
    "fin": "Lähetä raportti",
    "lav": "Iesniegt pārskatu",
    "swe": "Skicka in rapport.",
    "lit": "Pateikti ataskaitą",
    "esp": "Enviar reporte",
    "nor": "Send inn rapport"
   },
   "operatorBounce": {
    "eng": "The e-invoice operator is unable to service you.",
    "pol": "Operator usługi e-faktura nie może Cię obsłużyć.",
    "est": "E-arvete operaator ei ole võimeline seda ettevõtet teenindama.",
    "rus": "Оператор службы электронных счетов не может вас обслужить.",
    "fin": "Verkkolaskuoperaattori ei ole käytettävissä",
    "lav": "E-rēķinu operators nevar Jūs apkalpot.",
    "swe": "E-fakturaoperatören kan inte serva dig",
    "lit": "E. sąskaitų operatorius negali jūsų aptarnauti.",
    "esp": "El operador de facturas electrónicas no puede atenderlo.",
    "nor": "E-fakturaoperatøren kan ikke betjene deg."
   },
   "invalidCredentials": {
    "eng": "Integration is not working. Please reactivate.",
    "pol": "Integracja nie działa. Aktywuj ponownie.",
    "est": "Liidestus ei tööta. Palun deaktiveeri ja aktiveeri liidestus uuesti.",
    "rus": "Интеграция не работает. Пожалуйста, активируйте интеграцию повторно.",
    "fin": "Integraatio ei toimi. Aktivoi integraatio uudelleen.",
    "lav": "Integrācija nedarbojās. Lūdzu, aktivizējiet to vēlreiz.",
    "swe": "Integreringen fungerar inte. Var god återaktivera.",
    "lit": "Integracija neveikia. Prašome iš naujo įjungti integraciją.",
    "esp": "La integración no funciona. Por favor reactivar",
    "nor": "Integrering fungerer ikke. Vennligst reaktiver."
   },
   "invalidContent": {
    "eng": "Accounting software is not accepting this document:",
    "pol": "Oprogramowanie księgowe nie akceptuje dokumentu:",
    "est": "Raamatupidamistarkvara ei võta seda dokumenti vastu",
    "rus": "Бухгалтерское программное обеспечение не принимает этот документ:",
    "fin": "Kirjanpito-ohjelmisto ei hyväksy tätä dokumenttia:",
    "lav": "Grāmatvedības programma nepieņem šo dokumentu:",
    "swe": "Bokföringsprogrammet godkänner inte detta dokument.",
    "lit": "Apskaitos programa nepriima šio dokumento:",
    "esp": "El software de contabilidad no acepta este documento:",
    "nor": "Regnskapsprogramvare godtar ikke dette dokumentet:"
   },
   "submittedBy": {
    "eng": "Submitted by",
    "pol": "Przesłane przez",
    "est": "Esitas",
    "rus": "Отправил",
    "fin": "kirjannut",
    "lav": "Iesniedza",
    "swe": "Inskickad av",
    "lit": "Pateikėjas",
    "esp": "Presentado por",
    "nor": "Innsendt av"
   },
   "useReports": {
    "eng": "Enable reports",
    "pol": "Korzystaj z raportów",
    "est": "Luba aruannete esitamine",
    "rus": "Использовать отчеты",
    "fin": "Salli raportit",
    "lav": "Iespējot pārskatu veidošanu",
    "swe": "Möjliggör att skicka in rapporter.",
    "lit": "Įgalinti ataskaitų pateikimą",
    "esp": "Habilitar el envío de informes",
    "nor": "Aktiver innsending av rapporter"
   },
   "integrationUsesOnlyReports": {
    "eng": "integration uses only reports",
    "pol": "integracja wykorzystuje tylko raporty",
    "est": "Liidestus töötab ainult aruannetega",
    "rus": "интеграция использует только отчеты",
    "fin": "Integraatio käyttää ainoastaan raportteja",
    "lav": "integrācija izmanto tikai pārskatus",
    "swe": "integreringen använder endast rapporter",
    "lit": "integracija naudoja tik ataskaitas",
    "esp": "integración utiliza solo informes",
    "nor": "integrasjon bruker kun rapporter"
   },
   "integrationDoesNotSupportReports": {
    "eng": "Integration does not support reports",
    "pol": "integracja nie obsługuje raportów",
    "est": "Antud tarkvara liidestusega ei saa aruandeid kasutada",
    "rus": "интеграция не поддерживает отчеты",
    "fin": "integraatio ei tue raportteja",
    "lav": "integrācija neatbalsta pārskatus",
    "swe": "integreringen har inte stöd för rapporter",
    "lit": "integracija nepalaiko ataskaitų",
    "esp": "integración no admite informes",
    "nor": "Integrasjon støtter ikke rapporter"
   },
   "usersCanMakeExpenseAndTravelReports": {
    "eng": "Users can submit travel, mileage, and expense reports",
    "pol": "Użytkownicy mogą sporządzać raporty z wydatków i podróży.",
    "est": "Kasutajad saavad esitada sõidupäevikut, kulu- ja lähetusaruandeid",
    "rus": "Пользователи могут составлять отчеты о расходах и командировках.",
    "fin": "käyttäjät voivat tehdä kulu- ja matkaraportteja",
    "lav": "Lietotāji var veidot izdevumu un komandējumu pārskatus.",
    "swe": "Användare kan skicka in rese- och utgiftsrapporter",
    "lit": "Vartotojai gali pateikti kelionių ir išlaidų ataskaitas",
    "esp": "Los usuarios pueden enviar informes de viajes y gastos",
    "nor": "Brukere kan sende inn reise- og utgiftsrapporter"
   },
   "pleaseTryAgain": {
    "eng": "Please try again!",
    "pol": "Spróbuj ponownie!",
    "est": "Palun proovige uuesti!",
    "rus": "Пожалуйста, попробуйте еще раз!",
    "fin": "Yritä uudelleen.",
    "lav": "Lūdzu, mēģiniet vēlreiz!",
    "swe": "Var god försök igen!",
    "lit": "Bandykite dar kartą!",
    "esp": "¡Inténtalo de nuevo!",
    "nor": "Vær så snill, prøv på nytt!"
   },
   "documentUploadFailed": {
    "eng": "Document upload failed",
    "pol": "Przesłanie dokumentu nie powiodło się",
    "est": "Dokumendi üleslaadimine ebaõnnestus",
    "rus": "Не удалось загрузить документ",
    "fin": "Dokumentin lataus epäonnistui",
    "lav": "Dokumenta augšupielāde neizdevās",
    "swe": "Uppladdning av dokument misslyckades",
    "lit": "Nepavyko įkelti dokumento",
    "esp": "Error al cargar el documento",
    "nor": "Dokumentopplasting mislyktes"
   },
   "notAuthorizedPleaseLogIn": {
    "eng": "You have been logged out, please log in!",
    "pol": "Zostałeś wylogowany, zaloguj się!",
    "est": "Teid on süsteemsit välja logitud, palun logige uuesti sisse!",
    "rus": "Вы вышли из системы. Пожалуйста, войдите снова!",
    "fin": "Edellinen istunto suljettu, kirjaudu uudelleen sisään",
    "lav": "Jūs izgājāt no sistēmas. Lūdzu, pieslēdzieties no jauna!",
    "swe": "Du har blivit utloggad, var god logga in igen!",
    "lit": "Jūs buvote išregistruotas, prašome prisijungti!",
    "esp": "¡Se ha desconectado, por favor inicie sesión!",
    "nor": "Du har blitt logget ut, vennligst logg inn!"
   },
   "automaticallySendEInvoicesToSoftware": {
    "eng": "Automatically send e-invoices to accounting software.",
    "pol": "Automatycznie wysyłaj e-faktury do oprogramowania księgowego.",
    "est": "Saada kõik e-arved automaatselt edasi raamatupidamistarkvarasse",
    "rus": "Автоматически отправлять эл-счета в бухгалтерское программное обеспечение.",
    "fin": "Lähetä sähköiset laskut automaattisesti kirjanpitoon",
    "lav": "Automātiski pārsūtīt e-rēķinus uz grāmatvedības programmu.",
    "swe": "Skicka e-fakturor automatiskt till bokföringsprogram.",
    "lit": "Automatiškai siųsti e. sąskaitas į apskaitos programą.",
    "esp": "Envíe automáticamente facturas electrónicas al software de contabilidad.",
    "nor": "Send e-faktura automatisk til regnskapsprogramvare."
   },
   "addAnotherEmail": {
    "eng": "Add another email",
    "pol": "Dodaj kolejny adres e-mail",
    "est": "Lisa veel üks e-posti aadress",
    "rus": "Добавить еще один адрес эл.почты",
    "fin": "Lisää toinen sähköposti",
    "lav": "Pievienot vēl vienu e-pastu",
    "swe": "Lägg till ytterligare e-postadress",
    "lit": "Pridėti kitą el. paštą",
    "esp": "Agregar otro correo electrónico",
    "nor": "Legg til en annen e-post"
   },
   "sendNotificationsAboutEInvoicesTo": {
    "eng": "Send e-invoice copy to:",
    "pol": "Wyślij kopię e-faktury na adres:",
    "est": "Saada e-arve koopia emailile:",
    "rus": "Отправьте копию эл-счета по адресу:",
    "fin": "Lähetä e-laskun kopio osoitteeseen:",
    "lav": "Sūtīt e-rēķina kopiju uz:",
    "swe": "Skicka kopia av e-faktura till:",
    "lit": "Siųsti e. sąskaitos kopiją į:",
    "esp": "Envíe una copia de la factura electrónica a:",
    "nor": "Send kopi av e-faktura til:"
   },
   "autoForwardEInvoices": {
    "eng": "Auto-forward e-invoices",
    "pol": "Automatyczne przekazywanie e-faktur",
    "est": "E-arvete automaatne edastamine",
    "rus": "Автоматическая пересылка эл-счетов",
    "fin": "Välitä sähköiset laskut automaattisesti",
    "lav": "Automātiska e-rēķinu pārsūtīšana",
    "swe": "Vidarebefodra automatiskt e-fakturor",
    "lit": "Automatinis e. sąskaitų persiuntimas",
    "esp": "Reenvío automático de facturas electrónicas",
    "nor": "Automatisk videresending av e-fakturaer"
   },
   "organizationEmailInErplyBooks": {
    "eng": "Company email in ERPLY Books:",
    "pol": "Adres e-mail organizacji w ERPLY Books:",
    "est": "ERPLY Booksis olev ettevõtte email:",
    "rus": "Адрес эл.почты организации в ERPLY Books:",
    "fin": "Organisaation sähköposti ERPLY Booksissa",
    "lav": "Uzņēmuma e-pasts sistēmā ERPLY Books:",
    "swe": "Organisationens e-post i RPLY Books:",
    "lit": "Organizacijos el. paštas ERPLY Books:",
    "esp": "Correo electrónico de la organización en ERPLY books:",
    "nor": "Firmaets e-post i ERPLY Books:"
   },
   "fromEmailLabel": {
    "eng": "From email",
    "pol": "Z e-maila",
    "est": "Saadetud emailiga",
    "rus": "Из эл.почты",
    "fin": "Sähköpostista",
    "lav": "No e-pasta",
    "swe": "Från e-post",
    "lit": "Iš el. pašto",
    "esp": "Desde el email",
    "nor": "Fra e-post"
   },
   "useCloudForEditingPDFs": {
    "eng": "For PDF files we recommend using the cloud environment. Mobile app shows only the first page of PDF file.",
    "pol": "Do edycji plików PDF zalecamy korzystanie z chmury. Aplikacja mobilna wyświetla tylko pierwszą stronę pliku.",
    "est": "PDF-failide vaatamiseks soovitame kasutada meie pilvekeskkonda. Mobiilirakendus näitab ainult PDF-faili esimest lehte.",
    "rus": "Для редактирования PDF-файлов мы рекомендуем использовать нашу облачную среду. Мобильное приложение показывает только первую страницу PDF-файла.",
    "fin": "PDF-tiedostojen muokkaamiseen suosittelemme pilviympäristöämme. Mobiilisovellus näyttää vain PDF-tiedoston ensimmäisen sivun.",
    "lav": "Mobilajā lietotnē dokumentiem, kas iesnigti PDF formātā, ir redzama tikai pirmā lapa. Tādēļ šādu dokumentu rediģēšanai iesakām izmantot mākoņpakalpojumu.",
    "swe": "För PDF-filer rekommenderar vi att ni använder cloud-miljön. Mobilappen visar endast första sidan av PDF:en.",
    "lit": "PDF failams rekomenduojame naudoti mūsų debesijos aplinką. Mobilioji programėlė rodo tik pirmąjį PDF failo puslapį.",
    "esp": "Para archivos PDF, recomendamos utilizar el entorno de nube. La aplicación móvil muestra solo la primera página del archivo PDF",
    "nor": "For PDF-filer anbefaler vi å bruke skymiljøet. Mobilappen viser bare den første siden av PDF-filen."
   },
   "reportWillBeSentAfterAllDocumentsDigitized": {
    "eng": "Report will be sent to software after all documents are digitised.",
    "pol": "Raport zostanie wysłany po zdigitalizowaniu wszystkich dokumentów.",
    "est": "Aruanne saadetakse raamatupidamistarkvarasse, kui kõik dokumendid on digiteeritud.",
    "rus": "Отчет будет отправлен после того, как все документы будут оцифрованы.",
    "fin": "Raportti lähetetään, kun kaikki asiakirjat on digitoitu.",
    "lav": "Atskaite tiks nosūtīta, kad visi pievienotie dokumenti būs digitalizēti.",
    "swe": "Rapporten kommer skickas till bokföringsprogrammet efter att alla dokument har digitaliserats.",
    "lit": "Ataskaita bus išsiųsta į programą, kai visi dokumentai bus suskaitmeninti.",
    "esp": "El reporte se enviará al software después de que todos los documentos estén digitalizados.",
    "nor": "Rapport vil bli sendt til programvare etter at alle dokumenter er digitalisert."
   },
   "forSomeDatesDailyAllowanceIsAlreadyCalculated": {
    "eng": "Daily allowance for these dates has already been calculated in another travel report:",
    "pol": "Diety dzienne dla tych dat zostały już obliczone w innym raporcie z podróży:",
    "est": "Nende kuupäevadega on päevarahad juba arvutatud teises lähetusaruandes",
    "rus": "На эти даты суточные уже рассчитаны в другом отчете о поездке:",
    "fin": "Näille päiville on jo laskettu päiväraha toisessa raportissa:",
    "lav": "Šiem datumiem dienas nauda jau ir aprēķināta citā ceļojuma pārskatā:",
    "swe": "Det dagliga underhållet för dessa datum har beräknats i en annan rapport:",
    "lit": "Dienpinigiai šioms datoms jau buvo apskaičiuoti kitoje kelionės ataskaitoje:",
    "esp": "La asignación diaria para estas fechas ya se ha calculado en otro reporte de viaje:",
    "nor": "Dagpenger for disse datoene er allerede beregnet i en annen reiserapport:"
   },
   "twoFreeMealsIncluded": {
    "eng": "2 free meals included a day",
    "pol": "2 posiłki dziennie",
    "est": "2 tasuta sööki päevas on hinna sees",
    "rus": "Включены 2 бесплатных обеда в день",
    "fin": "2 ilmaista ateriaa päivässä",
    "lav": "Iekļautas 2 bezmaksas ēdienreizes dienā",
    "swe": "2 gratis måltider inkluderade per dag",
    "lit": "2 nemokami patiekalai per dieną įskaičiuoti",
    "esp": "2 comidas gratis incluidas al día",
    "nor": "2 gratis måltider inkludert om dagen"
   },
   "approve": {
    "eng": "Approve",
    "pol": "Zatwierdź",
    "est": "Kinnita",
    "rus": "Утвердить",
    "fin": "Hyväksy",
    "lav": "Apstiprināt",
    "swe": "Godkänn",
    "lit": "Patvirtinti",
    "esp": "Aprobado",
    "nor": "Vedta"
   },
   "toSaveNatureWeDoNotSupportPaperInvoices": {
    "eng": "We love nature and do not send paper invoices.",
    "pol": "Z troski o środowisko nie wysyłamy papierowych faktur.",
    "est": "Looduse säästmiseks ei toeta me paberarvete saatmist.",
    "rus": "В целях сохранения природы мы не поддерживаем отправку бумажных счетов.",
    "fin": "Ympäristösyistä emme kannata paperilaskujen lähettämistä.",
    "lav": "Lai saudzētu dabu, mēs neatbalstām papīra dokumentu sūtīšanu.",
    "swe": "För miljöns skull har vi valt att inte skicka pappersfakturor.",
    "lit": "Tausodami gamtą, popierinių sąskaitų nesiunčiame.",
    "esp": "Amamos la naturaleza y no enviamos facturas en papel.",
    "nor": "Vi elsker naturen og sender ikke papirfakturaer."
   },
   "einvoicesIncluded": {
    "eng": "e-invoices",
    "pol": "e-faktury",
    "est": "e-arvet hinna sees",
    "rus": "эл-счета",
    "fin": "kpl verkkolaskuja",
    "lav": "e-rēķini",
    "swe": "e-fakturor",
    "lit": "e. sąskaitos",
    "esp": "facturas electrónicas",
    "nor": "e-fakturaer"
   },
   "eachDocumentOverLimit": {
    "eng": "Each document over limit",
    "pol": "Każdy dokument przekraczający limit",
    "est": "Iga dokument, mis läheb üle paketi limiidi",
    "rus": "Каждый документ, превышающий лимит",
    "fin": "Jokainen lisäasiakirja",
    "lav": "Katrs dokuments pāri limitam",
    "swe": "Alla dokument över gränsen",
    "lit": "Kiekvienas dokumentas virš limito",
    "esp": "Cada documento sobrepasa el límite",
    "nor": "Hvert dokument overskrider grensen"
   },
   "emailInvoicesAndReminders": {
    "eng": "Email invoices and reminders: free (from your accounting software).",
    "pol": "Wysyłanie faktur i przypomnień: za darmo (z Twojego programu księgowego).",
    "est": "Arvete ja meeldetuletuste saatmine emailiga: Tasuta (seda saab teha raamatupidamistarkvarast)",
    "rus": "Счета и напоминания по электронной почте: бесплатно (из вашей бухгалтерской программы).",
    "fin": "Sähköpostilaskut ja huomautukset: Ilmaisia (kirjanpito-ohjelmistostasi)",
    "lav": "E-pasta rēķini un atgādinājumi: bezmaksas (no jūsu grāmatvedības sistēmas).",
    "swe": "Skicka fakturor och påminnelse över e-post: gratis (från ditt bokföringsprogram).",
    "lit": "Siųsti sąskaitas ir priminimus: nemokamai (iš jūsų apskaitos programos).",
    "esp": "Facturas y recordatorios por correo electrónico: gratis (desde su software de contabilidad).",
    "nor": "E-postfakturaer og påminnelser: gratis (fra regnskapsprogramvaren)."
   },
   "sendAndReceiveEinvoices": {
    "eng": "Send and receive e‑invoices:",
    "pol": "Wysyłaj i odbieraj e-faktury:",
    "est": "Saada ja võta vastu e-arveid",
    "rus": "Отправлять и получать электронные счета:",
    "fin": "Vastaanota verkkolaskuja",
    "lav": "Sūtīt un saņemt e-rēķinus:",
    "swe": "Skicka och motta e-fakturor.",
    "lit": "Siųsti ir gauti e. sąskaitas:",
    "esp": "Envíe y reciba facturas electrónicas:",
    "nor": "Send og motta e-fakturaer:"
   },
   "jumisDocTypeEkaCeks": {
    "eng": "EKA Čeks",
    "pol": "",
    "est": "ESA kontroll",
    "rus": "Квитанция EKA",
    "fin": "ESA kontrolli",
    "lav": "EKA Čeks"
   },
   "jumisDocTypeEkaZAtskaite": {
    "eng": "EKA Z atskaite",
    "pol": "",
    "est": "ESA Z aruanne",
    "rus": "Отчет EKA Z",
    "fin": "ESA Z raportti",
    "lav": "EKA Z atskaite"
   },
   "jumisDocTypePavadzime": {
    "eng": "Iepirkšanas pavadzīmes kontējums",
    "pol": "Dokument dostawy zakupu",
    "est": "Ostu saateleht",
    "rus": "Ввод заказа на покупку",
    "fin": "Saapumisilmoitus",
    "lav": "Iepirkšanas pavadzīmes kontējums",
    "esp": "Albarán de compra"
   },
   "jumisDocTypeKreditrekins": {
    "eng": "Kredītrēķins",
    "pol": "",
    "est": "Krediidiarve",
    "rus": "Кредитный счет",
    "fin": "Luottokuitti",
    "lav": "Kredītrēķins",
    "esp": "Factura de crédito",
    "lit": "Kreditinė sąskaita"
   },
   "jumisDocTypeRekins": {
    "eng": "Rēķina kontējums",
    "pol": "Księgowanie faktur",
    "est": "Arvete konteerimine",
    "rus": "Проводка счетов-фактур",
    "fin": "Kuittien koontaminen",
    "lav": "Rēķina kontējums",
    "esp": "Contabilización de facturas"
   },
   "userNameNotSentToSoftware": {
    "eng": "User name is not sent to software",
    "pol": "Nazwa użytkownika nie jest wysyłana do oprogramowania",
    "est": "Kasutaja nime ei edastata programmi",
    "rus": "Имя пользователя не отправляется в программное обеспечение",
    "fin": "Käyttäjätunnusta ei lähetetä eteenpäin",
    "lav": "Nesūtīt uz grāmatvedības programmatūru lietotāja vārdu",
    "swe": "Användarens namn skickas inte till bokföringsprogrammet.",
    "lit": "Vartotojo vardas nėra siunčiamas į programą",
    "esp": "El nombre de usuario no se envía al software",
    "nor": "Brukernavn sendes ikke til programvare"
   },
   "removeSubmitterNameWhenDocSentToSoftware": {
    "eng": "Remove submitter name when document is sent to accounting software. Example: accountant is submitting receipts",
    "pol": "Usunięcie nazwy wysyłającego, gdy dokument jest wysyłany do programu księgowego. Przykład: gdy księgowy przesyła dokumenty",
    "est": "Dokument saadetakse raamatupidamistarkvarasse ilma esitaja nimeta. Näide: raamatupidaja esitab kviitungeid",
    "rus": "Удалите имя отправителя при отправке документа в бухгалтерское программное обеспечение. Пример: бухгалтер подает квитанции",
    "fin": "Poista kirjaajan nimi, kun asiakirja lähetetään kirjanpito-ohjelmistoon. Esimerkki: Kirjanpitäjä kirjaa kuitteja",
    "lav": "Noņemt dokumentu iesniedzēja vārdu, kad dokuments tiek sūtīts uz grāmatvedības programmatūru",
    "swe": "Ta bort användarens namn då dokumentet skickas till bokföringsprogrammet. Exempel: kvitton inskickade av revisor",
    "lit": "Pašalinti pateikėjo vardą, kai dokumentas siunčiamas į apskaitos programą. Pavyzdžiui, kai buhalteris pateikia kvitus",
    "esp": "Elimine el nombre del remitente cuando el documento se envíe al software de contabilidad. Ejemplo: el contador está enviando recibos",
    "nor": "Fjern innsendernavn når dokument sendes til regnskapsprogramvare. Eksempel: regnskapsfører sender inn kvitteringer"
   },
   "jumisDocType": {
    "eng": "Optional: Document type for Finanšu Dokumenti",
    "pol": "Nieobowiązkowe: Rodzaj dokumentu dla Finanšu Dokumenti",
    "est": "Valikuline: Documendi tüüp Finanšu Dokumenti jaoks",
    "rus": "Необязательно: Тип документа для Finanšu Dokumenti",
    "fin": "Valinnainen: Dokumenttityyppi Finanšu Dokumenti",
    "lav": "Neobligāti: Dokumentu tips priekš Finanšu Dokumentiem",
    "esp": "Opcional: tipo de documento para Finanšu Dokumenti",
    "nor": "Valgfritt: Dokumenttype for Finanšu Dokumenti",
    "lit": "Pasirenkama: Finanšu Dokumenti dokumentų tipas"
   },
   "calculateAllowanceWeekends": {
    "eng": "Calculate allowance for weekends",
    "pol": "Oblicz dietę za weekendy",
    "est": "Arvuta nädalavahetuse päevarahad",
    "rus": "Рассчитать надбавку за выходные дни",
    "fin": "Laske päiväraha la-su",
    "lav": "Iekļaut dienas naudas aprēķinā nedēļas nogales",
    "swe": "Beräkna underhåll för veckoslutet",
    "lit": "Apskaičiuoti išmoką už savaitgalius",
    "esp": "Calcular la asignación para los fines de semana",
    "nor": "Beregn godtgjørelse for helger"
   },
   "returnedHomeOvernight": {
    "eng": "Returned home overnight",
    "pol": "Powrót do domu na noc",
    "est": "Jõuan tagasi koju järgmisel päeval",
    "rus": "Вернулся домой на ночь",
    "fin": "Kotiinpaluu yöllä",
    "lav": "Nakšņošana pastāvīgajā dzīvesvietā",
    "swe": "Återvände hem under natten.",
    "lit": "Grįžta namo per naktį",
    "esp": "Regresó a casa durante la noche",
    "nor": "Kom hjem over natten"
   },
   "accomodationAndFreeMeal": {
    "eng": "Accommodation and 3 free meals per day included",
    "pol": "Zakwaterowanie i 3 posiłki dziennie wliczone",
    "est": "Majutus ja 3 tasuta sööki päevas on juba tasutud",
    "rus": "В стоимость включено проживание и 3-х разовое питание.",
    "fin": "Majoitus ja 3 ilmaista ateriaa päivässä",
    "lav": "Bija nodrošināta naktsmītne un 3 ēdienreizes",
    "swe": "Boende samt 3 måltider per dag är inkluderade.",
    "lit": "Apgyvendinimas ir 3 nemokami patiekalai per dieną įskaičiuoti",
    "esp": "Alojamiento y 3 comidas gratis por día incluidas.",
    "nor": "Overnatting og 3 gratis måltider per dag inkludert"
   },
   "efExReportsNotEnabled": {
    "eng": "Report cannot be submitted. Administrator can enable reports in settings.",
    "pol": "Raport nie może zostać przesłany. Administrator może aktywować funkcję raportów w ustawieniach.",
    "est": "Sellel ettevõttel ei ole aruannete tegemine lubatud, administraator saab aruanded sisse lülitada seadete alt.",
    "rus": "Отчеты для этой компании не включены. Этот отчет нельзя отправить в бухгалтерскую систему.",
    "fin": "Yritys ei ole ottanut käyttöön Raportit-toimintoa, raporttia ei voida lähettää",
    "swe": "Rapport kan inte skickas in. Administratören kan aktivera rapporter i inställningar.",
    "lav": "Šim uzņēmumam nav iespējota pārskatu lietošana. Šo pārskatu nevar nosūtīt uz grāmatvedības sistēmu.",
    "lit": "Ataskaita negali būti pateikta. Administratoriai ataskaitas turėtų įgalinti nustatymuose.",
    "esp": "No se puede enviar el informe. El administrador puede habilitar reportes en la configuración",
    "nor": "Rapport kan ikke sendes inn. Administrator kan aktivere rapporter i innstillinger."
   },
   "efExDocNotSubmitted": {
    "eng": "Document has not been submitted by user, cannot send to software",
    "pol": "Użytkownik nie przesłał tego dokumentu, nie można go wysłać do oprogramowania",
    "est": "Kasutaja ei ole veel seda dokumenti ära esitanud, ei saa raamatupidamistarkvarasse saata",
    "rus": "Документ не загружен - не может быть отправлен в программу. Попробуйте позже!",
    "fin": "Tositetta ei ole kirjattu, ei voida lähettää kirjanpitoon",
    "swe": "Dokumentet har inte skickats in av användare, kan inte skickas till bokföringsprogrammet.",
    "lav": "Lietotājam neizdevās augšupielādēt dokumentu. To nevar nosūtīt uz grāmatvedības sistēmu. Mēģiniet vēlreiz!",
    "lit": "Vartotojas nepateikė dokumento, negalima siųsti į programą",
    "esp": "El usuario no ha enviado el documento, no se puede enviar al software",
    "nor": "Dokument er ikke sendt inn av bruker, kan ikke sendes til programvare"
   },
   "efExReportNotApproved": {
    "eng": "Report has not been approved, cannot send to software",
    "pol": "Raport nie został zatwierdzony, nie można go wysłać do oprogramowania",
    "est": "Aruanne ei ole veel kinnitatud, ei saa raamatupidamistarkvarasse saata",
    "rus": "Отчет не утвержден - не может быть отправлен в программу.",
    "fin": "Raporttia ei ole hyväksytty, ei voida lähettää kirjanpitoon",
    "swe": "Rapporten har inte blivit godkänd, kan inte skickas till bokföringsprogrammet.",
    "lav": "Pārskats nav apstiprināts. To nevar nosūtīt uz grāmatvedības sistēmu.",
    "lit": "Ataskaita nebuvo patvirtinta, negalima siųsti į programą",
    "esp": "El reporte no ha sido aprobado, no se puede enviar al software",
    "nor": "Rapporten er ikke godkjent, kan ikke sendes til programvare"
   },
   "efExDocNotApproved": {
    "eng": "Document has not been approved by user, cannot send to software",
    "pol": "Dokument nie został zatwierdzony przez użytkownika, nie można go wysłać do oprogramowania",
    "est": "Kasutaja ei ole veel dokumenti kinnitatud, ei saa raamatupidamistarkvarasse saata",
    "rus": "Документ еще не утвержден пользователем - не может быть отправлен в программу.",
    "fin": "Dokumenttia ei ole hyväksytty, ei voida lähettää kirjanpitoon",
    "swe": "Dokumentet har inte blivit godkänt av användare, kan inte skicka till bokföringsprogrammet.",
    "lav": "Lietotājs nav apstiprinājis šo dokumentu. To nevar nosūtīt uz grāmatvedības sistēmu.",
    "lit": "Vartotojas nepatvirtino dokumento, negalima siųsti į programą",
    "esp": "El documento no ha sido aprobado por el usuario, no se puede enviar al software",
    "nor": "Dokument er ikke godkjent av bruker, kan ikke sendes til programvare"
   },
   "efExDocPartOfReport": {
    "eng": "Document cannot be sent separately. Submit the full report instead.",
    "pol": "Dokument nie może być wysłany oddzielnie. Należy przesłać pełny raport.",
    "est": "See dokument on aruande sees, saatke terve aruanne korraga raamatupidamisse",
    "rus": "Документ является частью отчета, его нельзя отправить в программу.",
    "fin": "Tosite on osa raporttia, ei voida lähettää ohjelmistoon",
    "swe": "Dokument kan inte skickas separat. Skicka in hela rapporten istället.",
    "lav": "Šis dokuments ir daļa no pārskata. To nevar pārsūtīt uz grāmatvedības sistēmu.",
    "lit": "Negalima siųsti dokumento atskirai. Vietoj to pateikite visą ataskaitą.",
    "esp": "El documento no se puede enviar por separado. Envíe el reporte completo.",
    "nor": "Dokument kan ikke sendes separat. Send inn hele rapporten i stedet."
   },
   "efExSendingDocsNotAllowed": {
    "eng": "Sending single documents has been disabled, please add this document to a report",
    "pol": "Wysyłanie pojedynczych dokumentów zostało wyłączone, dodaj ten dokument do raportu",
    "est": "Üksikute dokumentide saatmine on keelatud, palun lisage see dokument aruandesse",
    "rus": "Отправка отдельных документов отключена, добавьте этот документ в отчет.",
    "fin": "Yksittäisten tositteiden lähettäminen ei ole käytössä. Lisää tosite raporttiin.",
    "lav": "Individuālu dokumentu sūtīšana ir atslēgta. Lūdzu, pievienojiet šo dokumentu pārskatam.",
    "swe": "Att skicka enskilda dokument har inaktiverats, var god lägg till dokumentet i en rapport.",
    "lit": "Atskirų dokumentų siuntimas yra išjungtas. Pridėkite šį dokumentą prie ataskaitos",
    "esp": "Se inhabilitó el envío de documentos individuales, agregue este documento a un reporte",
    "nor": "Sending av enkeltdokumenter har blitt deaktivert. Legg til dette dokumentet i en rapport"
   },
   "efExReportNotSubmitted": {
    "eng": "Report has not been submitted by user, cannot send to software",
    "pol": "Raport nie został jeszcze przesłany przez użytkownika, nie można go przesłać do oprogramowania księgowego",
    "est": "Aruanne ei ole veel kasutaja poolt esitatud, ei saa raamatupidamistarkvarasse saata",
    "rus": "Отчет не был отправлен пользователем, невозможно отправить в программное обеспечение",
    "fin": "Raporttia ei ole kirjattu, tietoja ei voida lähettää kirjanpitoon",
    "lav": "Neizdevās nosūtīt pārskatu",
    "swe": "Rapporten har inte skickats in av användare, kan inte skickas till bokföringsprogram",
    "lit": "Vartotojas nepateikė ataskaitos, negalima siųsti į programą",
    "esp": "El usuario no ha enviado el reporte, no se puede enviar al software",
    "nor": "Rapport er ikke sendt av bruker, kan ikke sendes til programvare"
   },
   "removeAndEditStepsNotNeccessary": {
    "eng": "Remove and edit input steps according to your company's needs.",
    "pol": "Usuń i edytuj etapy wprowadzania danych zgodnie z potrzebami Twojej organizacji.",
    "est": "Muutke dokumendi lisamise protsessi vastavalt teie ettevõtte nõuetele",
    "rus": "Отключение и редактирование шагов ввода данных пользователем, которые запрашиваются при оцифровке документа.",
    "fin": "Poista ja muokkaa kirjausvaiheita, jotka eivät ole välttämättömiä organisaatiollesi",
    "lav": "Noņemiet un personalizējiet jautājumus kuri tiek uzdoti pie dokumentu digitalizēšanas.",
    "swe": "Ta bort och ändra inmatningssteg enligt din organisations behov.",
    "lit": "Pašalinti ir redaguoti vartotojo įvesties veiksmus, kurie jūsų organizacijai nebūtini.",
    "esp": "Elimine y edite los pasos de ingreso de acuerdo con las necesidades de su organización.",
    "nor": "Fjern og rediger inndatatrinn i henhold til bedriftens behov."
   },
   "allowUsersSelectPaymentType": {
    "eng": "Allow users to select whether they paid by personal or company funds",
    "pol": "Umożliwienie użytkownikom wyboru, czy płacą z funduszy osobistych czy firmowych",
    "est": "Luba kasutajatel valida, kas maksti eraisikuna või ettevõtte vahenditest",
    "rus": "Разрешить пользователям сообщать, платили ли они из личных или коммерческих средств.",
    "fin": "Salli käyttäjän kirjata, maksoivatko he henkilökohtaisilla vai yrityksen varoilla",
    "lav": "Ļaut lietotājiem norādīt vai tika maksāts ar personīgajiem, vai uzņēmuma līdzekļiem.",
    "swe": "Tillåt användare att fylla i om de betalt med privata eller företagspengar",
    "lit": "Leisti vartotojams pasirinkti ar jie mokėjo asmeninėmis ar įmonės lėšomis",
    "esp": "Permitir a los usuarios seleccionar si pagaron con fondos personales o de la empresa",
    "nor": "Tillat brukere å velge om de betalte med personlige eller bedriftsmidler"
   },
   "youCanCreateAndSelectCustomExpenseTypes": {
    "eng": "Create and select expense types for your company",
    "pol": "Twórz i wybieraj rodzaje kosztów dla swojej firmy",
    "est": "Looge kululiigid, mis vastavad teie ettevõtte vajadustele",
    "rus": "Создавайте и выбирайте собственные типы расходов.",
    "fin": "Voit luoda ja valita omia kululajeja",
    "lav": "Veidojiet un atlasiet savus izdevumu veidus.",
    "swe": "Du kan skapa och välja dina egna utgiftstyper",
    "lit": "Galite sukurti ir pasirinkti išlaidų tipus",
    "esp": "Cree y seleccione tipos de gastos para su empresa",
    "nor": "Opprett og velg utgiftstyper for din bedrift"
   },
   "addNextDestination": {
    "eng": "Add destination",
    "pol": "Dodaj miejsce docelowe",
    "est": "Lisa järgmine sihtkoht",
    "rus": "Добавить пункт назначения",
    "fin": "Lisää määränpää",
    "lav": "Pievienot galamērķi",
    "swe": "Lägg till destination",
    "lit": "Pridėti kelionės šalį",
    "esp": "Agregar destino",
    "nor": "Legg til destinasjon"
   },
   "enableForwardingSingleDocuments": {
    "eng": "Enable single documents",
    "pol": "Możliwość przesyłania pojedynczych dokumentów",
    "est": "Luba üksikute dokumentide saatmine raamatupidamistarkvarasse",
    "rus": "Разрешить подачу отдельных документов",
    "fin": "Salli yksittäisten tositteiden lähetys",
    "lav": "Atļaut individuālu dokumentu iesniegšanu",
    "swe": "Möjliggör vidarebefodring av enstaka dokument",
    "lit": "Įgalinti atskirų dokumentų siuntimą",
    "esp": "Habilitar el reenvío de documentos individuales",
    "nor": "Aktiver videresending av enkeltdokumenter"
   },
   "documentsCanBeForwardedOneByOne": {
    "eng": "Documents can be forwarded one by one without a report",
    "pol": "Dokumenty mogą być przekazywane pojedynczo, bez raportów",
    "est": "Ilma aruandeta dokumente on võimalik saata raamatupidamistarkvarasse",
    "rus": "Документы можно пересылать по одному (без отчетов).",
    "fin": "Tositteet voidaan lähettää yksitellen (ilman raportteja)",
    "lav": "Dokumenti var tikt pārsūtīti pa vienam (bez pārskata).",
    "swe": "Dokument kan vidarebefodras ett åt gången (utan rapporter)",
    "lit": "Dokumentai gali būti persiųsti po vieną be ataskaitų",
    "esp": "Los documentos se pueden reenviar uno por uno (sin reportes).",
    "nor": "Dokumenter kan videresendes ett etter ett (uten rapporter)."
   },
   "userInputSteps": {
    "eng": "Choose input steps",
    "pol": "Etapy wprowadzania danych",
    "est": "Kasutajalt küsitavad sammud",
    "rus": "Шаги ввода",
    "fin": "Kirjausvaiheet",
    "lav": "Veicot digitalizēšanu likt norādīt:",
    "swe": "Inmatningssteg",
    "lit": "Įvesties žingsniai",
    "esp": "Agregar pasos",
    "nor": "Legg inn trinn"
   },
   "digitizing": {
    "eng": "Being digitised",
    "pol": "W trakcie digitalizacji",
    "est": "Digiteerimisel",
    "rus": "Оцифровывается",
    "fin": "Digitoidaan",
    "lav": "Tiek digitalizēts",
    "swe": "Digitaliseras",
    "lit": "Skaitmeninama",
    "esp": "En digitalización",
    "nor": "Blir digitalisert"
   },
   "submitted": {
    "eng": "Submitted",
    "pol": "Wysłano",
    "est": "Esitatud",
    "rus": "Подано",
    "fin": "Toimitettu",
    "lav": "Iesniegts",
    "swe": "Inlämnad",
    "lit": "Pateikta",
    "esp": "Enviado",
    "nor": "Sendt inn"
   },
   "approving": {
    "eng": "Being approved",
    "pol": "Oczekuje na zatwierdzenie",
    "est": "Kinnitamisel",
    "rus": "Утверждается",
    "fin": "Hyväksytään",
    "lav": "Gaida apstiprinājumu",
    "swe": "Bekräftas",
    "lit": "Tvirtinama",
    "esp": "En aprobación",
    "nor": "Blir godkjent"
   },
   "reportIsAlreadySubmitted": {
    "eng": "This report has already been submitted",
    "pol": "Ten raport został już przesłany",
    "est": "Aruanne on juba esitatud",
    "rus": "Отчет уже отправлен",
    "fin": "Tämä raportti on jo lähetetty",
    "lav": "Šis pārskats jau ir iesniegts",
    "swe": "Denna rapport har redan skickats in",
    "lit": "Ši ataskaita jau buvo pateikta",
    "esp": "Este informe ya ha sido enviado",
    "nor": "Denne rapporten er allerede sendt inn"
   },
   "efExDocPendingApproval": {
    "eng": "Document is still waiting for approval.",
    "pol": "Dokument czeka na zatwierdzenie",
    "est": "Kuludokument ootab veel kinnitamist",
    "rus": "Документ все еще ожидает утверждения.",
    "fin": "Dokumentti odottaa edelleen hyväksymistä",
    "lav": "Dokuments vēl nav apstiprināts.",
    "swe": "Dokumentet avvaktar fortfarande bekräftelse",
    "lit": "Dokumentas vis dar laukia patvirtinimo",
    "esp": "Documento en espera de aprobación",
    "nor": "Dokumentet venter fortsatt på godkjenning."
   },
   "efExReportPendingApproval": {
    "eng": "Report is still waiting for approval",
    "pol": "Raport czeka na zatwierdzenie",
    "est": "Aruanne ootab veel kinnitamist",
    "rus": "Отчет все еще ожидает утверждения",
    "fin": "Raportti odottaa edelleen hyväksymistä",
    "lav": "Pārskats vēl nav apstiprināts",
    "swe": "Rapporten avvaktar fortfarande bekräftelse",
    "lit": "Ataskaita vis dar laukia patvirtinimo",
    "esp": "Reporte en espera de aprobación",
    "nor": "Rapporten venter fortsatt på godkjenning"
   },
   "efExDocNotDigitized": {
    "eng": "Document is not digitised. Do it manually or wait until it is digitised by CostPocket (usually in 7-9 hours).",
    "pol": "Dokument nie jest jeszcze zdigitalizowany. Możesz zdigitalizować go ręcznie lub poczekać (zwykle zajmuje to 7-9 godzin)",
    "est": "Dokument on digiteerimisel. Saate selle digiteerida manuaalselt või oodata, kuni see digiteeritakse (tavaliselt kulub 7–9 tundi)",
    "rus": "Документ не оцифрован. Вы можете оцифровать его вручную или дождаться оцифровки (обычно это занимает 7-9 часов)",
    "fin": "Asiakirjaa ei ole digitoitu. Voit tehdä sen manuaalisesti tai odottaa prosessin valmistumista ( kesto 7-9 tuntia).",
    "lav": "Dokuments nav digitalizēts. Jūs varat to digitalizēt manuāli vai sagaidīt līdz tas tiks digitalizēts (vidēji tas notiek 7-9 stundu laikā)",
    "swe": "Dokumentet är inte digitaliserat. Gör det manuellt eller vänta tills det blir digitaliserat av CostPocket (tar vanligtvis 7-9 timmar).",
    "lit": "Dokumentas nėra skaitmenintas. Skaitmeninkite rankiniu būdu arba palaukite, kol jis bus suskaitmenintas (trunka apie 7-9 valandas)",
    "esp": "El documento no está digitalizado. Hágalo manualmente o espere hasta que sea digitalizado por CostPocket (generalmente en 7-9 horas).",
    "nor": "Dokumentet er ikke digitalisert. Gjør det manuelt eller vent til det er digitalisert av CostPocket (vanligvis 7-9 timer)."
   },
   "erpDocNr": {
    "eng": "ERP document nr.",
    "pol": "Nr dokumentu ERP.",
    "est": "ERP Dokumendi nr.",
    "rus": "Номер документа ERP",
    "fin": "ERP-dokumentin numero",
    "lav": "ERP Dokumenta nr.",
    "swe": "ERP dokumentnummer",
    "lit": "ERP dokumento nr.",
    "esp": "Nro. Documento ERP",
    "nor": "ERP dokument nr."
   },
   "addAnotherCar": {
    "eng": "Add another car",
    "pol": "Dodaj kolejny samochód",
    "est": "Lisa uus sõiduk",
    "rus": "Добавить другую машину",
    "fin": "Lisää toinen auto",
    "lav": "Pievienot citu automašīnu",
    "swe": "Lägg till ytterligare bil",
    "lit": "Pridėti kitą automobilį",
    "esp": "Agregar otro auto",
    "nor": "Legg til en annen bil"
   },
   "privateCar": {
    "eng": "Private car",
    "pol": "Prywatny samochód",
    "est": "Isiklik sõiduauto",
    "rus": "Личный автомобиль",
    "fin": "Yksityisauto",
    "lav": "Privātais auto",
    "swe": "Privatbil",
    "lit": "Privatus automobilis",
    "esp": "Auto privado",
    "nor": "Privat bil"
   },
   "addCarPlateNumber": {
    "eng": "Add car plate number",
    "pol": "Dodaj numer rejestracyjny samochodu",
    "est": "Lisa sõiduki numbrimärk",
    "rus": "Добавьте регистрационный номер автомобиля",
    "fin": "Lisää auton rekisterinumero",
    "lav": "Pievienot automašīnas reģistrācijas numuru",
    "swe": "Lägg till bilens registreringsnummer",
    "lit": "Pridėti automobilio valstybinį numerį",
    "esp": "Agregar Número de placa del auto",
    "nor": "Legg til bilskiltnummer"
   },
   "plateNumber": {
    "eng": "Plate number",
    "pol": "Numer rejestracyjny",
    "est": "Sõiduki numbrimärk",
    "rus": "Регистрационный номер автомобиля",
    "fin": "Rekisterinumero",
    "lav": "Transportlīdzekļa reģistrācijas numurs",
    "swe": "Registreringsnummer",
    "lit": "Valstybinis numeris",
    "esp": "Número de placa",
    "nor": "Skiltnummer"
   },
   "addOdometerReadings": {
    "eng": "Add odometer readings",
    "pol": "Dodaj odczyty z licznika",
    "est": "Lisa odomeetri näidud",
    "rus": "Добавить показания одометра",
    "fin": "Lisää matkamittarin lukemat",
    "lav": "Pievienot odometra rādījumus",
    "swe": "Lägg till vägmätaravläsning",
    "lit": "Pridėti odometro rodmenis",
    "esp": "Agregar lecturas del odómetro",
    "nor": "Legg til kilometertelleravlesninger"
   },
   "odometerReadings": {
    "eng": "Odometer readings",
    "pol": "Odczyty z licznika",
    "est": "Odomeetri näit",
    "rus": "Показания одометра",
    "fin": "Matkamittarin lukemat",
    "lav": "Odometra rādījumi",
    "swe": "Vägmätaravläsning",
    "lit": "Odometro rodmenys",
    "esp": "Lecturas del odómetro",
    "nor": "Kilometertelleravlesninger"
   },
   "remainingCompensation": {
    "eng": "Remaining amount that can be compensated this month",
    "pol": "Pozostała kwota, która może być wyrównana w tym miesiącu",
    "est": "Sellel kuul on võimalik veel saada sõiduhüvitist summas",
    "rus": "Оставшаяся сумма, которую можно компенсировать в этом месяце",
    "fin": "Jäljellä oleva summa, joka voidaan korvata tässä kuussa",
    "lav": "Atlikusī summa, kas šomēnes var tikt kompensēta",
    "swe": "Kvarvarande summa som kan användas denna månad",
    "lit": "Likusi suma, kurią galima kompensuoti šį mėnesį",
    "esp": "Importe restante que se puede compensar este mes",
    "nor": "Resterende beløp som kan kompenseres denne måneden"
   },
   "odometerInitial": {
    "eng": "Initial odometer reading",
    "pol": "Wstępny odczyt licznika",
    "est": "Odomeetri algnäit",
    "rus": "Первоначальное показание одометра",
    "fin": "Matkamittarin alkulukema",
    "lav": "Odometra sākuma rādījums",
    "swe": "Vägmätare innan start",
    "lit": "Pradiniai odometro rodmenys",
    "esp": "Lectura inicial del odómetro",
    "nor": "Første kilometertelleravlesning"
   },
   "odometerEnd": {
    "eng": "Odometer end reading",
    "pol": "Końcowy odczyt licznika",
    "est": "Odomeetri lõppnäit",
    "rus": "Окончательное показание одометра",
    "fin": "Matkamittarin loppulukema",
    "lav": "Odometra beigu rādījums",
    "swe": "Slutläsning vägmätare",
    "lit": "Galutiniai odometro rodmenys",
    "esp": "Lectura final del odómetro",
    "nor": "Kilometerteller sluttavlesning"
   },
   "markAsNonCostDocument": {
    "eng": "Mark as attachment",
    "pol": "Oznacz jako załącznik",
    "est": "Muuda aruande manuseks",
    "rus": "Преобразовать во приложение",
    "fin": "Merkitse muuksi liitteeksi",
    "lav": "Pārveidot par pielikumu",
    "swe": "Markera som bilaga",
    "lit": "Pažymėti kaip priedą",
    "esp": "Marcar como adjunto",
    "nor": "Merk som vedlegg"
   },
   "markAsCostDocument": {
    "eng": "Mark as cost document",
    "pol": "Oznacz jako dokument kosztowy",
    "est": "Muuda kuludokumendiks",
    "rus": "Преобразовать в документ затрат",
    "fin": "Merkitse kulutositteeksi",
    "lav": "Pārveidot par izdevumu dokumentu",
    "swe": "Markera som kostnadsdokument",
    "lit": "Pažymėti kaip išlaidų dokumentą",
    "esp": "Marcar como documento de costos",
    "nor": "Merk som kostnadsdokument"
   },
   "nonCostDocument": {
    "eng": "Attachment",
    "pol": "Załącznik",
    "est": "Manus",
    "rus": "Приложение",
    "fin": "Muu liite",
    "lav": "Pielikums",
    "swe": "Bilaga",
    "lit": "Priedas",
    "esp": "Adjunto",
    "nor": "Vedlegg"
   },
   "omitForeignVAT": {
    "eng": "Remove foreign VAT",
    "pol": "Usuń zagraniczny podatek VAT",
    "est": "Eemalda välismaa dokumentide KM",
    "rus": "Игнорировать иностранный НДС",
    "fin": "Poista ulkomainen ALV",
    "lav": "Ignorēt ārzemju PVN",
    "swe": "Ta bort utländsk VAT",
    "lit": "Pašalinti užsienio PVM",
    "esp": "Elimine IVA extranjero",
    "nor": "Fjern utenlandsk MVA"
   },
   "removeForeignVATWhenForwardingDocument": {
    "eng": "Remove foreign VAT when document is sent to accounting software",
    "pol": "Usuń zagraniczny VAT podczas wysyłania dokumentu do programu księgowego",
    "est": "Raamatupidamistarkvarasse ei saadeta välismaa dokumentide KM-u",
    "rus": "Не отправлять данные иностранного НДС в бухгалтерскую программу",
    "fin": "Poista ulkomainen ALV ennen tositteen lähettämistä kirjanpitoon",
    "lav": "Nesūtīt ārvalstu PVN datus uz grāmatvedības programmatūru",
    "swe": "Ta bort utländsk VAT när dokumentet skickas till bokföringsprogrammet",
    "lit": "Pašalinti užsienio PVM prieš siunčiant dokumentą į apskaitos programą",
    "esp": "Elimine el IVA extranjero cuando el documento se envíe al software de contabilidad",
    "nor": "Fjern utenlandsk merverdiavgift når dokument sendes til regnskapsprogram"
   },
   "singleDocuments": {
    "eng": "Single documents",
    "pol": "Pojedyncze dokumenty",
    "est": "Üksikud dokumendid",
    "rus": "Документы",
    "fin": "Dokumentit",
    "lav": "Dokumenti",
    "swe": "Enskilda dokument",
    "lit": "Atskiri dokumentai",
    "esp": "Documentos individuales",
    "nor": "Enkeltdokumenter"
   },
   "reportNameAlreadyExists": {
    "eng": "Report with such name already exists. Please choose a different name!",
    "pol": "Raport o takiej nazwie już istnieje. Wybierz inną nazwę.",
    "est": "Sellise nimega aruanne on juba olemas. Palun muutke aruande nime!",
    "rus": "Отчет с таким названием уже существует. Пожалуйста, выберите другое название!",
    "fin": "Raportti on jo olemassa, anna toinen nimi.",
    "lav": "Pārskats ar šādu nosaukumu jau eksistē. Lūdzu, izveidojiet citu nosaukumu!",
    "swe": "En rapport med detta namn finns redan. Var god välj ett annat namn!",
    "lit": "Ataskaita tokiu pavadinimu jau egzistuoja. Pasirinkti kitą pavadinimą.",
    "esp": "Reporte con ese nombre ya existe. ¡Elija un nombre diferente!",
    "nor": "Rapport med et slikt navn finnes allerede. Vennligst velg et annet navn!"
   },
   "receiptWaitingForApproval": {
    "eng": "Document submitted! Waiting for approval.",
    "pol": "Dokument przesłany i czeka na zatwierdzenie.",
    "est": "Dokument lisatud! Ootan kinnitamist.",
    "rus": "Документ отправлен! Ожидает одобрения.",
    "fin": "Tosite lähetetty, odottaa hyväksymistä.",
    "lav": "Dokuments ir iesniegts! Gaida apstiprinājumu.",
    "swe": "Dokument inskickat! Avväntar bekräftelse.",
    "lit": "Dokumentas pateiktas! Laukiama patvirtinimo.",
    "esp": "Documento enviado A la espera de la aprobación.",
    "nor": "Dokument sendt! Venter på godkjenning."
   },
   "reportWaitingForApproval": {
    "eng": "Report submitted! Waiting for approval.",
    "pol": "Raport przesłany i czeka na zatwierdzenie.",
    "est": "Aruanne lisatud! Ootan kinnitamist.",
    "rus": "Отчет сдан! Ожидает одобрения.",
    "fin": "Raportti lähetetty, odottaa hyväksymistä.",
    "lav": "Pārskats ir iesniegts! Gaida apstiprinājumu.",
    "swe": "Rapport inskickad! Avväntar bekräftelse.",
    "lit": "Ataskaita pateikta! Laukiama patvirtinimo.",
    "esp": "Reporte enviado A la espera de la aprobación.",
    "nor": "Rapport sendt! Venter på godkjenning."
   },
   "MARK_FOR_VERIFIED_DIGITIZATION": {
    "eng": "Document(s) are not digitised",
    "pol": "Dokument(y) nie są zdigitalizowane",
    "est": "Dokumendid on digiteerimisel",
    "rus": "Документы не оцифрованы",
    "fin": "Digitoimaton dokumentti",
    "lav": "Dokuments(i) nav digitalizēts(i)",
    "swe": "Dokumenten är inte digitaliserade",
    "lit": "Dokumentas(-ai) nėra skaitmenintas(-i)",
    "esp": "Los documentos no están digitalizados",
    "nor": "Dokument(er) er ikke digitalisert"
   },
   "moveReceipts": {
    "eng": "Move receipts to another report",
    "pol": "Przenieś paragony do innego raportu",
    "est": "Liiguta dokumendid teise aruandesse",
    "rus": "Перенести чеки в другой отчет",
    "fin": "Liitä tositteet toiseen raporttiin",
    "lav": "Pārvietot čekus uz citu pārskatu",
    "swe": "Flytta kvitton till annan rapport",
    "lit": "Perkelti kvitus į kitą ataskaitą",
    "esp": "Mover recibos a otro reporte",
    "nor": "Flytt kvitteringer til en annen rapport"
   },
   "companyMaxAllowance": {
    "eng": "Company’s max daily allowance rate",
    "pol": "Maksymalna dzienna dieta",
    "est": "Ettevõtte päevaraha piirmäär",
    "rus": "Максимальное дневное пособие компании",
    "fin": "Yrityksen päivärahan yläraja",
    "lav": "Uzņēmuma maksimālā dienas naudas likme",
    "swe": "Företagets dagliga ersättning",
    "lit": "Maksimalūs kompanijos dienpinigiai",
    "esp": "Asignación diaria máxima de la empresa",
    "nor": "Selskapets maksimale dagpenger"
   },
   "pastMont": {
    "eng": "Previous month",
    "pol": "Poprzedni miesiąc",
    "est": "Eelmine kuu",
    "rus": "Предыдущий месяц",
    "fin": "Edellinen kuukausi",
    "lav": "Iepriekšējais mēnesis",
    "swe": "Föregående månad",
    "lit": "Praėjęs mėnuo",
    "esp": "Mes previo",
    "nor": "Forrige måned"
   },
   "travelApplications": {
    "eng": "Travel applications",
    "lav": "Komandējumu pieteikumi",
    "est": "Lähetustaotlus",
    "swe": "Reseansökningar",
    "pol": "Wniosek o wyjazd służbowy",
    "lit": "Kelionių paraiškos",
    "fin": "Matkavaraukset",
    "rus": "Рабочие поездки",
    "esp": "Solicitud de viajes",
    "nor": "Reiseapplikasjoner"
   },
   "hasAdvancePayment": {
    "eng": "Has advance payment",
    "lav": "Ar avansu",
    "est": "On ettemakse",
    "pol": "Z zaliczką",
    "swe": "Har förskottsbetalning",
    "lit": "Turi išankstinį mokėjimą",
    "fin": "Maksetaan ennakkoon",
    "rus": "Имеет авансовый платеж",
    "esp": "Tiene pago adelantado",
    "nor": "Har forskuddsbetaling"
   },
   "noAdvancePayment": {
    "eng": "No advance payment",
    "lav": "Bez avansa",
    "est": "Pole ettemakset",
    "swe": "Ingen förskottsbetalning",
    "pol": "Bez zaliczki",
    "lit": "Nėra išankstinio mokėjimo",
    "fin": "Ei makseta ennakkoon",
    "rus": "Без авансового платежа",
    "esp": "Sin pago por adelantado",
    "nor": "Ingen forskuddsbetaling"
   },
   "all": {
    "eng": "All",
    "lav": "Visi",
    "est": "Kõik",
    "swe": "Allt",
    "pol": "Wszystko",
    "lit": "Visi",
    "fin": "Kaikki",
    "rus": "Все",
    "esp": "Todo",
    "nor": "Alle"
   },
   "date": {
    "eng": "Date",
    "lav": "Datums",
    "est": "Kuupäev",
    "swe": "Datum",
    "pol": "Data",
    "lit": "Data",
    "fin": "Päivämäärä",
    "rus": "Дата",
    "esp": "Fecha",
    "nor": "Dato"
   },
   "rejected": {
    "eng": "Rejected",
    "lav": "Noraidīts",
    "est": "Tagasi lükatud",
    "swe": "Avvisad",
    "pol": "Odrzucony",
    "lit": "Atmesta",
    "fin": "Hylätty",
    "rus": "Отклонено",
    "esp": "Rechazado",
    "nor": "Avvist"
   },
   "thisWeek": {
    "eng": "This week",
    "lav": "Šonedēļ",
    "est": "See nädal",
    "swe": "Denna vecka",
    "pol": "W tym tygodniu",
    "lit": "Šią savaitę",
    "fin": "Kuluva viikko",
    "rus": "На этой неделе",
    "esp": "Esta semana",
    "nor": "Denne uken"
   },
   "typeEmployeeName": {
    "eng": "Type employee name",
    "lav": "Rakstiet darbinieka vārdu",
    "est": "Sisesta töötaja nimi",
    "swe": "Fyll i namn på anställd",
    "pol": "Wpisz imię i nazwisko pracownika",
    "lit": "Įrašykite darbuotojo vardą",
    "fin": "Anna työntekijän nimi",
    "rus": "Введите имя сотрудника",
    "esp": "Ingrese nombre empleado",
    "nor": "Skriv inn ansattes navn"
   },
   "startsAt": {
    "eng": "Starts",
    "lav": "Sākas",
    "est": "Algab",
    "swe": "Börjar",
    "pol": "Rozpoczyna się",
    "lit": "Prasideda",
    "fin": "Alkaa",
    "rus": "Начало",
    "esp": "Comienza",
    "nor": "Starter"
   },
   "endsAt": {
    "eng": "Ends",
    "lav": "Beidzas",
    "est": "Lõpeb",
    "swe": "Slutar",
    "pol": "Kończy się",
    "lit": "Baigiasi",
    "fin": "Päättyy",
    "rus": "Окончание",
    "esp": "Culmina",
    "nor": "Slutter"
   },
   "projectCode": {
    "eng": "Project code",
    "lav": "Projekta kods",
    "est": "Projektikood",
    "swe": "Projektkod",
    "pol": "Kod projektu",
    "lit": "Projekto kodas",
    "fin": "Projektitunnus",
    "rus": "Код проекта",
    "esp": "Código proyecto",
    "nor": "Prosjektkode"
   },
   "approvalProgress": {
    "eng": "Approval progress",
    "lav": "Lēmumu pieņemšanas gaita",
    "est": "Kinnitamise seis",
    "swe": "Bekräftelsestatus",
    "pol": "Postęp w zatwierdzaniu wniosku",
    "lit": "Patvirtinimo eiga",
    "fin": "Hyväksyntäpyynnön tila",
    "rus": "Ход утверждения",
    "esp": "Progreso de la aprobación",
    "nor": "Godkjenningsstatus"
   },
   "didReject": {
    "eng": "rejected",
    "lav": "noraidīja",
    "est": "tagasi lükatud",
    "swe": "avslaget",
    "pol": ":odrzucono",
    "lit": "atmesta",
    "fin": "hylkäsi",
    "rus": "отклонено",
    "esp": "rechazado",
    "nor": "avvist"
   },
   "didApprove": {
    "eng": "approved",
    "lav": "apstiprināja",
    "est": "kinnitas",
    "swe": "godkänt",
    "pol": ":zatwierdzono",
    "lit": "patvirtinta",
    "fin": "hyväksyi",
    "rus": "одобрено",
    "esp": "aprobado",
    "nor": "godkjent"
   },
   "isDeciding": {
    "eng": "is approving",
    "lav": "pieņem lēmumu",
    "est": "otsustab",
    "swe": "beslutar",
    "pol": "decyduje",
    "lit": "sprendžia",
    "fin": "tekee päätöksen",
    "rus": "утверждает",
    "esp": "en aprobación",
    "nor": "godkjenner"
   },
   "reason": {
    "eng": "Reason",
    "lav": "Iemesls",
    "est": "Põhjus",
    "swe": "Anledning",
    "pol": "Powód",
    "lit": "Priežastis",
    "fin": "Syy",
    "rus": "Причина",
    "esp": "Razón",
    "nor": "Årsaken"
   },
   "substituteEmployee": {
    "eng": "Substitute employee",
    "lav": "Aizvietotājs",
    "est": "Asendaja",
    "pol": "Zastępstwo",
    "swe": "Ersättningsarbetare",
    "lit": "Pakaitinis darbuotojas",
    "fin": "Sijainen",
    "rus": "Замещающий работник",
    "esp": "Empleado sustituto",
    "nor": "Vikar ansatt"
   },
   "whoOrderedTrip": {
    "eng": "Who ordered trip",
    "lav": "Nosūtītājs",
    "est": "Lähetusele saatja",
    "swe": "Vem beställde resan",
    "pol": "Osoba aplikująca o podróż",
    "lit": "Kas užsakė kelionę",
    "fin": "Matkan varaaja/määrääjä",
    "rus": "Кто заказал поездку",
    "esp": "Quién ordenó el viaje",
    "nor": "Hvem bestilte turem"
   },
   "tripType": {
    "eng": "Travel type",
    "lav": "Komandējuma veids",
    "est": "Lähetuse liik",
    "swe": "Typ av resa",
    "pol": "Rodzaj podróży",
    "lit": "Kelionės tipas",
    "fin": "Matkan tyyppi",
    "rus": "Тип поездки",
    "esp": "Tipo de viaje",
    "nor": "Reisetype"
   },
   "external": {
    "eng": "External",
    "lav": "Ārējs",
    "est": "Väline",
    "swe": "Extern",
    "pol": "Zewnętrzny",
    "lit": "Išorinis",
    "fin": "Ulkoinen",
    "rus": "Внешний",
    "esp": "Externo",
    "nor": "Ekstern"
   },
   "internal": {
    "eng": "Internal",
    "lav": "Iekšējs",
    "est": "Sisene",
    "swe": "Intern",
    "pol": "Wewnętrzny",
    "lit": "Vidinis",
    "fin": "Sisäinen",
    "rus": "Внутренний",
    "esp": "Interno",
    "nor": "Intern"
   },
   "training": {
    "eng": "Training",
    "lav": "Apmācības",
    "est": "Koolitus",
    "swe": "Träning",
    "pol": "Szkolenie",
    "lit": "Mokymai",
    "fin": "Koulutus",
    "rus": "Обучение",
    "esp": "Entrenamiento",
    "nor": "Opplæring"
   },
   "cancelled": {
    "eng": "Cancelled",
    "lav": "Atcelts",
    "est": "Tühistatud",
    "swe": "Avbrutet",
    "pol": "Anulowany",
    "lit": "Atšaukta",
    "fin": "Hylätty/Peruutettu",
    "rus": "Отменено",
    "esp": "Cancelado",
    "nor": "Avbrutt"
   },
   "advancePaymentReason": {
    "eng": "Advance payment reason",
    "lav": "Avansa maksājuma iemesls",
    "est": "Avansi põhjus",
    "swe": "Anledning till förskottsbetalning",
    "pol": "Powód wypłaty zaliczki",
    "lit": "Išankstinio mokėjimo priežastis",
    "fin": "Ennakkomaksun peruste",
    "rus": "Основание авансового платежа",
    "esp": "Razón de pago adelantado",
    "nor": "Årsak til forskuddsbetaling"
   },
   "rejectionReason": {
    "eng": "Rejection reason",
    "lav": "Noraidījuma iemesls",
    "est": "Tagasilükkamise põhjus",
    "swe": "Skäl för avslaget",
    "pol": "Powód odrzucenia",
    "lit": "Atmetimo priežastis",
    "fin": "Hylkäysperuste",
    "rus": "Причина отказа",
    "esp": "Razón de rechazo",
    "nor": "Grunn til avvisning"
   },
   "rejectAdvancePayment": {
    "eng": "Reject advance payment",
    "lav": "Noraidīt avansa maksājumu",
    "est": "Lükka avanss tagasi",
    "swe": "Avvisa förskottsbetalning",
    "pol": "Odrzuć zaliczkę",
    "lit": "Atmesti išankstinį mokėjimą",
    "fin": "Hylkää ennakkomaksupyyntö",
    "rus": "Отклонить авансовый платеж",
    "esp": "Rechazar pago por adelantado",
    "nor": "Avslå forskuddsbetaling"
   },
   "approveAdvancePayment": {
    "eng": "Approve advance payment",
    "lav": "Apstiprināt avansa maksājumu",
    "est": "Kinnita avanss",
    "swe": "Godkänn förskottsbetalning",
    "pol": "Zatwierdź zaliczkę",
    "lit": "Patvirtinti išankstinį mokėjimą",
    "fin": "Hyväksy ennakkomaksupyyntö",
    "rus": "Утвердить авансовый платеж",
    "esp": "Aprobación pago adelantado",
    "nor": "Godkjenne forskuddsbetaling"
   },
   "pending": {
    "eng": "Pending",
    "lav": "Neizlemts",
    "est": "Ootel",
    "swe": "Väntar",
    "pol": "Oczekuje",
    "lit": "Laukiama",
    "fin": "Jonossa",
    "rus": "На рассмотрении",
    "esp": "Pendiente",
    "nor": "Avventer"
   },
   "applicationForAdvancePayment": {
    "eng": "Application for advance payment",
    "lav": "Avansa maksājuma pieteikums",
    "est": "Avaldus avansiks",
    "swe": "Ansökan för förskottsbetalning",
    "pol": "Wniosek o zaliczkę",
    "lit": "Paraiška išankstiniam mokėjimui",
    "fin": "Ennakkomaksupyyntö",
    "rus": "Заявление на авансовый платеж",
    "esp": "Solicitud de pago por adelantado",
    "nor": "Søknad om forskuddsbetaling"
   },
   "travelDetails": {
    "eng": "Travel details",
    "lav": "Informācija par komadējuma galamērķi",
    "est": "Lähetuse info",
    "swe": "Resedetaljer",
    "pol": "Szczegóły podróży",
    "lit": "Kelionės informacija",
    "fin": "Matkakuvaus",
    "rus": "Подробности поездки",
    "esp": "Detalles del viaje",
    "nor": "Reisedetaljer"
   },
   "locationAndPurposeOfTravel": {
    "eng": "Destination location and purpose of travel",
    "lav": "Galamērķa atrašanās vieta un komandējuma iemesls",
    "est": "Lähetuse sihtkoht ja põhjus",
    "swe": "Destination samt syfte med resa",
    "pol": "Miejsce docelowe i cel podróży",
    "lit": "Kelionės tikslas ir vieta",
    "fin": "Matkakohde ja matkan tarkoitus",
    "rus": "Место назначения и цель поездки",
    "esp": "Ubicación de destino y propósito de viaje",
    "nor": "Destinasjonssted og formål med reisen"
   },
   "projectDetails": {
    "eng": "Project details",
    "lav": "Informācija par projektu",
    "est": "Projekti info",
    "swe": "Projektdetaljer",
    "pol": "Szczegóły projektu",
    "lit": "Projekto informacija",
    "fin": "Projektikuvaus",
    "rus": "Подробности проекта",
    "esp": "Detalles del proyecto",
    "nor": "Prosjektdetaljer"
   },
   "missingValue": {
    "eng": "Missing / incorrect value",
    "lav": "Iztrūkstošs / nepareizi aizpildīts lauks",
    "est": "Puuduv / vale väärtus",
    "swe": "Värde saknas/inkorrekt värde",
    "pol": "Brakująca/nieprawidłowa wartość",
    "lit": "Trūkstama / neteisinga vertė",
    "fin": "Puuttuva/ virheellinen arvo",
    "rus": "Отсутствующее/неправильное значение",
    "esp": "Falta/Valor incorrecto",
    "nor": "Manglende/feil verdi"
   },
   "cannotAssignAsSubstitute": {
    "eng": "You cannot assign yourself as substitute employee",
    "lav": "Jūs nevarat izvēlēties sevi kā aizstājēju",
    "est": "Iseennast ei saa lisada asendajaks",
    "pol": "Nie można wyznaczyć siebie jako zastępstwa",
    "swe": "Du kan inte välja dig själv som ersättningsarbetare",
    "lit": "Negalite priskirti savęs kaip pakaitinio darbuotojo",
    "fin": "Et voi määrittää sijaiseksesi itseäsi",
    "rus": "Вы не можете назначить себя в качестве замещающего работника",
    "esp": "No puede asignarse a sí mismo un empleado substituto",
    "nor": "Du kan ikke ansette deg selv som vikar"
   },
   "cannotAssignAsOrderer": {
    "eng": "You cannot be the person, who's sending you",
    "lav": "Jūs nevarat izvēlēties sevi kā nosūtītāju",
    "est": "Iseennast ei saa lisada lähetusele saatjaks",
    "swe": "Du själv kan inte vara personen som sänder dig",
    "pol": "Nie można wybrać siebie jako wysyłającego",
    "lit": "Negalite būti asmuo, siunčiantis jums",
    "fin": "Et voi olla tämän matkan määrännyt henkilö",
    "rus": "Вы не можете быть человеком, который посылает вас",
    "esp": "Usted no puede ser la persona quien esta enviado",
    "nor": "Du kan ikke være personen som sender deg"
   },
   "employeeNotRecognized": {
    "eng": "Selected substitute/sender employee is not recognized",
    "lav": "Izvēlētais aizvietotājs/nosūtītājs nav atpazīts kā uzņēmuma darbinieks",
    "est": "Ei leidnud valitud asendajat/saatjat",
    "pol": "Wybrana osoba na zastępstwo/wysyłająca nie jest rozpoznawana jako pracownik firmy",
    "swe": "Hittade inte den valda vikarien/utskickade anställda",
    "lit": "Parinktas pakaitinis/siunčiantis darbuotojas neatpažintas",
    "fin": "Valittua työntekijää ei tunnistettu",
    "rus": "Выбранный замещающий/отправляющий сотрудник не распознан",
    "esp": "No se reconoce al sustituto/empleado remitente seleccionado",
    "nor": "Valgt vikar/avsendermedarbeider gjenkjennes ikke"
   },
   "stringTooShort": {
    "eng": "Inserted text is too short",
    "lav": "Ievadītais teksts ir par īsu",
    "est": "Sisestatud tekst on liiga lühike",
    "swe": "Den infogade texten är för kort",
    "pol": "Wprowadzony tekst jest za krótki",
    "lit": "Įvestas tekstas yra per trumpas",
    "fin": "Syöttämäsi teksti on liian lyhyt",
    "rus": "Вставленный текст слишком короткий",
    "esp": "Texto ingresado muy corto",
    "nor": "Den innsatte teksten er for kort"
   },
   "stringTooLong": {
    "eng": "Inserted text is too long",
    "lav": "Ievadītais teksts ir par garu",
    "est": "Sisestatud tekst on liiga pikk",
    "swe": "Den infogade texten är för lång",
    "pol": "Wprowadzony tekst jest za długi",
    "lit": "Įvestas tekstas yra per ilgas",
    "fin": "Syöttämäsi teksti on liian pitkä",
    "rus": "Вставленный текст слишком длинный",
    "esp": "Texto ingresado es muy largo",
    "nor": "Den innsatte teksten er for lang"
   },
   "numberTooSmall": {
    "eng": "Inserted number is too small",
    "lav": "Ievadītais skaitlis ir par mazu",
    "est": "Sisestatud arv on liiga väike",
    "swe": "Det infogade numret är för litet",
    "pol": "Wprowadzona liczba jest za mała",
    "lit": "Įvestas skaičius yra per mažas",
    "fin": "Syöttämäsi luku on liian pieni",
    "rus": "Вставленное число слишком мало",
    "esp": "Número ingresado es muy corto",
    "nor": "Det innsatte tallet er for lite"
   },
   "numberTooLarge": {
    "eng": "Inserted number is too large",
    "lav": "Ievadītais skaitlis ir par lielu",
    "est": "Sisestatud arv on liiga suur",
    "swe": "Det infogade numret är för stort",
    "pol": "Wprowadzona liczba jest za duża",
    "lit": "Įvestas skaičius yra per didelis",
    "fin": "Syöttämäsi luku on liian suuri",
    "rus": "Вставленное число слишком велико",
    "esp": "Número ingresado es muy largo",
    "nor": "Det innsatte tallet er for stort"
   },
   "deleteTravelRequest": {
    "eng": "Are you sure you want to delete this travel application?",
    "lav": "Vai patiešām vēlaties dzēst šo komandējuma pieteikumu?",
    "est": "Oled kindel, et soovid selle lähetustaotluse kustutada?",
    "pol": "Czy na pewno chcesz usunąć ten wniosek?",
    "swe": "Är du säker att du vill radera denna reseansökan?",
    "lit": "Ar tikrai norite ištrinti šią kelionės paraišką?",
    "fin": "Haluatko varmasti poistaa matkavarauksen?",
    "rus": "Вы уверены, что хотите удалить это приложение для поездок?",
    "esp": "Esta seguro que desea eliminar esta solicitud de viaje?",
    "nor": "Er du sikker på at du vil slette denne reiseapplikasjonen?"
   },
   "travelApplication": {
    "eng": "Travel application",
    "lav": "Komandējuma pieteikums",
    "est": "Lähetustaotlus",
    "pol": "Wniosek o wyjazd służbowy",
    "swe": "Reseansökan",
    "lit": "Kelionės paraiška",
    "fin": "Matkavaraus",
    "rus": "Заявка на поездку",
    "esp": "Solicitud de viaje",
    "nor": "Reisesøknad"
   },
   "yourAdvPaymentWasCancelled": {
    "eng": "Your advance payment was cancelled!",
    "lav": "Jūsu avansa maksājums ir noraidīts!",
    "est": "Sinu avanss tühistati!",
    "swe": "Din förskottsbetalning var avbruten!",
    "pol": "Twoja zaliczka została anulowana!",
    "lit": "Jūsų išankstinis mokėjimas buvo atšauktas!",
    "fin": "Ennakkomaksatus sinulle peruttiin",
    "rus": "Ваш авансовый платеж был отменен!",
    "esp": "Su pago por adelantado fue cancelado",
    "nor": "Forskuddsbetalingen din ble kansellert!"
   },
   "archive": {
    "eng": "Archive",
    "lav": "Arhīvs",
    "est": "Arhiiv",
    "swe": "Arkiv",
    "pol": "Archiwum",
    "lit": "Arhyvas",
    "fin": "Arkisto",
    "rus": "Архив",
    "esp": "Archivo",
    "nor": "Arkiv"
   },
   "approver": {
    "eng": "Approver",
    "lav": "Apstiprina",
    "est": "Kinnitaja",
    "swe": "Godkännare",
    "pol": "Osoba zatwierdzająca",
    "lit": "Tvirtintojas",
    "fin": "Hyväksyjä",
    "rus": "Утверждающий",
    "esp": "Aprobador",
    "nor": "Godkjenner"
   },
   "approvalRequest": {
    "eng": "Approval request",
    "lav": "Apstiprinājuma pieteikums",
    "est": "Kinnitamise taotlus",
    "swe": "Godkännelsebegäran",
    "pol": "Prośba o zatwierdzenie",
    "lit": "Patvirtinimo prašymas",
    "fin": "Hyväksymispyyntö",
    "rus": "Запрос на утверждение",
    "esp": "Solicitud de aprobación",
    "nor": "Godkjenningsforespørsel"
   },
   "youWereAddedAsApprover": {
    "eng": "added you as approver",
    "lav": "pievienoja Jūs kā lēmuma pieņēmēju",
    "est": "lisas sind kinnitajaks",
    "swe": "du har blivit tillagd som godkännare",
    "pol": "dodano Cię jako zatwierdzającego",
    "lit": "pridėjo jus kaip tvirtintoją",
    "fin": "lisäsi sinulle hyväksyjän roolin",
    "rus": "добавил вас в качестве утверждающего",
    "esp": "Ha sido agregado como aprobador",
    "nor": "la deg til som godkjenner"
   },
   "addedAsApprover": {
    "eng": "added as approver",
    "lav": "pievienoja kā lēmuma pieņēmēju",
    "est": "lisatud kinnitajaks",
    "swe": "tillagd som godkännare",
    "pol": "dodany/a jako zatwierdzający/a",
    "lit": "pridėtas kaip tvirtintojas",
    "fin": "lisättiin hyväksyjäksi",
    "rus": "добавлен как утверждающий",
    "esp": "Agregado como aprobador",
    "nor": "lagt til som godkjenner"
   },
   "youApproved": {
    "eng": "You approved",
    "lav": "Jūs apstiprinājāt",
    "est": "Sina kinnitasid",
    "swe": "Du har godkänt",
    "pol": "Zatwierdzono",
    "lit": "Jūs patvirtinote",
    "fin": "Hyväksyit",
    "rus": "Вы одобрили",
    "esp": "Usted aprobó",
    "nor": "Du godkjente"
   },
   "youRejected": {
    "eng": "You rejected",
    "lav": "Jūs noraidījāt",
    "est": "Sina lükkasid tagasi",
    "pol": "Odrzucono",
    "swe": "Du avbröt",
    "lit": "Jūs atmetėte",
    "fin": "Hylkäsit",
    "rus": "Вы отвергли",
    "esp": "Usted denegó",
    "nor": "Du avviste"
   },
   "waitingForYourApproval": {
    "eng": "Waiting for your approval",
    "lav": "Gaida Jūsu apstiprinājumu",
    "est": "Ootab sinu kinnitamist",
    "swe": "Väntar på ditt godkännande",
    "pol": "Czeka na akceptację",
    "lit": "Laukiama jūsų patvirtinimo",
    "fin": "Odottaa hyväksyntääsi",
    "rus": "В ожидании вашего одобрения",
    "esp": "Esperando aprobación",
    "nor": "Venter på din godkjenning"
   },
   "yourDecisionWasCancelled": {
    "eng": "Your decision was cancelled",
    "lav": "Jūsu lēmums ir atcelts",
    "est": "Sinu otsus tühistati",
    "swe": "Ditt beslut avbröts",
    "pol": "Twoja decyzja została anulowana",
    "lit": "Jūsų sprendimas buvo atšauktas",
    "fin": "Tekemäsi valinta peruutettiin",
    "rus": "Ваше решение было отменено",
    "esp": "Su decisión fue cancelada",
    "nor": "Avgjørelsen din ble kansellert"
   },
   "decisionWasCancelled": {
    "eng": "decision was cancelled",
    "lav": "lēmums tika atcelts",
    "est": "otsus tühistati",
    "swe": "beslut avbröts",
    "pol": ":decyzja anulowana",
    "lit": "sprendimas buvo atšauktas",
    "fin": "peruutti aiemman valintansa",
    "rus": "решение было отменено",
    "esp": "decisión fue cancelada",
    "nor": "vedtaket ble kansellert"
   },
   "requiresApproval": {
    "swe": "Kräver godkännande",
    "eng": "Requires approval",
    "lav": "Gaida apstiprinājumu",
    "pol": "Wymaga zatwierdzenia",
    "est": "Vajab kinnitamist",
    "lit": "Reikalingas patvirtinimas",
    "fin": "Vaatii hyväksynnän",
    "rus": "Требуется утверждение",
    "esp": "Requiere aprobación",
    "nor": "Krever godkjenning"
   },
   "submitter": {
    "eng": "Submitter",
    "lav": "Lietotājs",
    "pol": "Użytkownik",
    "est": "Kasutaja",
    "swe": "Avsändare",
    "lit": "Pateikėjas",
    "fin": "Kirjaaja",
    "rus": "Податель",
    "esp": "Remitente",
    "nor": "Innsender"
   },
   "accountant": {
    "eng": "Accountant",
    "lav": "Grāmatvedis",
    "pol": "Księgowość",
    "est": "Raamatupidaja",
    "swe": "Revisor",
    "lit": "Apskaitininkas",
    "fin": "Kirjanpitäjä",
    "rus": "Бухгалтер",
    "esp": "Contador",
    "nor": "Regnskapsfører"
   },
   "admin": {
    "eng": "Administrator",
    "fin": "Ylläpitäjä",
    "lav": "Administrators",
    "est": "Administraator",
    "pol": "Administrator",
    "rus": "Aдминистратор",
    "lit": "Administratorius",
    "swe": "Administratör",
    "esp": "Administrador",
    "nor": "Administrator"
   },
   "employeeAbsent": {
    "eng": "You are absent on these dates",
    "lav": "Jūs šajos datumos neesat pieejams",
    "est": "Sa puudud valitud kuupäevadel",
    "swe": "Du är frånvarande följande datum",
    "lit": "Šiomis datomis jūsų nėra",
    "fin": "Olet poissa seuraavina päivinä",
    "pol": "Osoba nieobecna w tych dniach",
    "rus": "Вы отсутствуете в эти дни",
    "esp": "Usted se encuentra ausente los días seleccionados",
    "nor": "Du er fraværende på disse datoene"
   },
   "advancePaymentApproved": {
    "eng": "Advance payment approved!",
    "lav": "Avansa maksājums apstiprināts!",
    "est": "Avanss kinnitatud",
    "swe": "Förskottsbetalning bekräftad!",
    "lit": "Išankstinis apmokėjimas patvirtintas!",
    "fin": "Ennakkomaksu hyväksytty",
    "pol": "Zaliczka zatwierdzona!",
    "rus": "Авансовый платеж одобрен!",
    "esp": "Pago adelantado aprobado!",
    "nor": "Forskuddsbetaling godkjent!"
   },
   "advancePaymentRejected": {
    "eng": "Advance payment rejected!",
    "lav": "Avansa maksājums noraidīts!",
    "est": "Avanss tagasi lükatud",
    "swe": "Förskottsbetalning avvisad!",
    "lit": "Išankstinis apmokėjimas atmestas!",
    "fin": "Ennakkomaksu hylätty",
    "pol": "Zaliczka odrzucona!",
    "rus": "Авансовый платеж отклонен!",
    "esp": "Pago adelantado denegado!",
    "nor": "Forskuddsbetaling avvist!"
   },
   "autoCancelled": {
    "eng": "Auto-cancelled",
    "lav": "Automātiski atcelts",
    "est": "Automaatselt tühistatud",
    "swe": "Automatiskt avbrutet",
    "lit": "Automatiškai atšaukta",
    "fin": "Peruutettu automaattisesti",
    "pol": "Automatycznie anulowane",
    "rus": "Автоматическая отмена",
    "esp": "Auto cancelado",
    "nor": "Automatisk kansellert"
   },
   "nothingToDisplay": {
    "eng": "Nothing to display",
    "lav": "Nav ierakstu",
    "est": "Pole midagi kuvada",
    "swe": "Inget att visa",
    "lit": "Nėra ką rodyti",
    "fin": "Ei näytettävää",
    "pol": "Nic do wyświetlenia",
    "rus": "Нечего показывать",
    "esp": "Nada para mostrar",
    "nor": "Ingenting å vise"
   },
   "reportSubmittedForApproval": {
    "eng": "Report submitted! Waiting for approval.",
    "lav": "Atskaite iesniegta! Gaida apstiprinājumu.",
    "est": "Aruanne edastatud! Ootan kinnitamist.",
    "swe": "Rapport inskickad! Avväntar bekräftelse.",
    "lit": "Ataskaita pateikta! Laukiama patvirtinimo.",
    "fin": "Raportti lähetetty, odottaa hyväksyntää.",
    "pol": "Raport przesłany! Czeka na zatwierdzenie.",
    "rus": "Отчет сдан! Ожидает одобрения.",
    "esp": "Reporte enviado! esperando aprobación",
    "nor": "Rapport sendt! Venter på godkjenning."
   },
   "youCreatedThisOnBehalfOf": {
    "eng": "You created this travel application for",
    "lav": "Jūs izveidojāt šo komandējuma pieteikumu priekš",
    "est": "Kelle nimele lõid selle lähetustaotluse:",
    "swe": "Du skapade denna reseansökan åt",
    "lit": "Sukūrėte šią kelionių paraišką",
    "fin": "Loit matkavarauksen työntekijälle",
    "pol": "Wniosek o podróż służbową stworzony przez ciebie dla:",
    "rus": "Вы создали это заявление для",
    "esp": "Ha creado esta solicitud de viaje para",
    "nor": "Du opprettet denne reiseapplikasjonen for"
   },
   "tripRequestCreatedForYou": {
    "eng": "created this travel application on your behalf",
    "lav": "izveidoja šo komandējuma pieteikumu Jūsu vārdā",
    "est": "lõi lähetustaotluse teie eest",
    "swe": "skapade denna reseansökan åt dig",
    "lit": "sukūrė šią kelionės paraišką už jus.",
    "fin": "loi sinulle matkavarauksen",
    "pol": "wniosek o podróż służbową został stworzony w twoim imieniu przez:",
    "rus": "создал это заявление на поездку от вашего имени",
    "esp": "Crear esta solicitud de viaje a su nombre",
    "nor": "opprettet denne reiseapplikasjonen på dine vegne"
   },
   "createTripReqForOtherEmployee": {
    "eng": "Create this travel application for another employee",
    "lav": "Izveidot šo komandējuma pieteikumu cita darbinieka vārdā",
    "rus": "Создать это заявление на поездку для другого сотрудника",
    "est": "Loo see lähetustaotlus teise töötaja nimele",
    "lit": "Sukurti šią kelionės paraišką kitam darbuotojui",
    "swe": "Skapa denna reseansökan för en annan anställd",
    "fin": "Tee matkavaraus toiselle työntekijälle",
    "pol": "Utwórz ten sam wniosek o podróż służbową dla innego pracownika",
    "esp": "Crear solicitud de viaje para otro empleado",
    "nor": "Opprett denne reiseapplikasjonen for en annen ansatt"
   },
   "goToAccountantView": {
    "eng": "Accountant dashboard",
    "lav": "Doties uz grāmatveža darba virsmu",
    "lit": "Eiti į apskaitininko skydelį",
    "swe": "Gå till kontrollpanelen för revisor",
    "fin": "Siirry kirjanpidon hallintapaneeliin",
    "est": "Mine raamatupidaja vaatesse",
    "pol": "Przejdź do interfejsu dla księgowych",
    "rus": "Перейти на панель бухгалтера",
    "esp": "Panel de contadores",
    "nor": "Regnskapsfører dashbord"
   },
   "travelReports": {
    "eng": "Travel reports",
    "lav": "Komandējumu atskaites",
    "lit": "Kelionių ataskaitos",
    "swe": "Reserapporter",
    "fin": "Työmatkaraportit",
    "est": "Lähetusaruanne",
    "pol": "Raporty z podróży",
    "rus": "Отчеты о поездках",
    "esp": "Reporte de viajes",
    "nor": "Reiserapporter"
   },
   "expenseReports": {
    "eng": "Expense reports",
    "lav": "Izdevumu pārskati",
    "lit": "Išlaidų ataskaitos",
    "swe": "Utgiftsrapporter",
    "fin": "Kuluraportit",
    "est": "Kuluaruanne",
    "pol": "Raporty z wydatków",
    "rus": "Отчеты о расходах",
    "esp": "Reporte de gastos",
    "nor": "Utgiftsrapporter"
   },
   "iConfirmAdvancePayment": {
    "eng": "I hereby confirm that business trip report is completed and I authorise the deduction of overpaid advance payments from my payroll",
    "est": "Kinnitan, et lähetusaruanne on valmis ja nõustun avansina saadud enammakstud osa palgast kinnipidamisega",
    "fin": "Vahvistan, että työmatkaraportti on täytetty, ja annan luvan vähentää liikaa maksetut ennakkomaksut palkastani.",
    "swe": "Jag bekräftar att affärsreserapporten har slutförts och jag godkänner avdrag för överbetalda förskott från min lön",
    "lit": "Patvirtinu, kad komandiruotės ataskaita yra pilnai užpildyta ir leidžiu išskaičiuoti permokėtus išankstinius mokėjimus iš mano darbo užmokesčio",
    "lav": "Ar šo es apstiprinu, ka komandējuma atskaite ir aizpildīta, un es atļauju atskaitīt pārmaksātos avansa maksājumus no manas algas.",
    "pol": "Potwierdzam prawidłowość wypełnienia raportu z podróży służbowej i wyrażam zgodę na potrącenie nadpłaconych zaliczek z mojego wynagrdzenia za pracę",
    "rus": "Я подтверждаю, что отчет о командировке заполнен, и разрешаю вычесть из моей заработной платы излишне уплаченные авансовые платежи.",
    "esp": "Por la presente confirmo que se completó el reporte de viaje de negocios y autorizo la deducción de los anticipos pagados en exceso de mi nómina",
    "nor": "Jeg bekrefter herved at forretningsreiserapporten er fullført og jeg godkjenner trekk av for mye betalte forskuddsbetalinger fra min lønn"
   },
   "customAllowanceRate": {
    "eng": "Custom daily allowance rate",
    "lav": "Pielāgota dienas naudas likme",
    "est": "Kohandatud päevaraha suurus",
    "lit": "Individualizuota dienpinigių norma",
    "swe": "Anpassa dagliga underhållstorleken",
    "fin": "Mukautettu päivärahan määrä",
    "pol": "Zindywidualizowana stawka diety dziennej",
    "rus": "Индивидуальная норма суточных",
    "esp": "Tasa de asignación diaria personalizada",
    "nor": "Tilpasset dagpengersats"
   },
   "addApprover": {
    "eng": "Add approver",
    "lav": "Pievienot lēmuma pieņēmēju",
    "lit": "Pridėti tvirtintoją",
    "swe": "Lägg till godkännare",
    "fin": "Lisää hyväksyjä",
    "est": "Lisa kinnitaja",
    "pol": "Dodaj osobę zatwierdzającą",
    "rus": "Добавить утверждающего",
    "esp": "Agregar aprobador",
    "nor": "Legg til godkjenner"
   },
   "tripRequestRejected": {
    "eng": "Your travel application was rejected!",
    "lav": "Jūsu komandējuma pieprasījums tika noraidīts!",
    "lit": "Jūsų kelionės paraiška buvo atmesta!",
    "swe": "Din reseansökan har blivit avvisad!",
    "fin": "Matkavarauksesi hylättiin",
    "est": "Teie lähetustaotlus on tagasi lükatud",
    "pol": "Twój wniosek o podróż służbową został odrzucony!",
    "rus": "Ваша заявка на поездку была отвергнутa!",
    "esp": "Su solicitud de viaje fue rechazada!",
    "nor": "Din reisesøknad ble avvist!"
   },
   "approvingAdvPayment": {
    "eng": "Advance payment is being approved",
    "lav": "Tiek apstiprināts avansa maksājums",
    "fin": "Ennakkomaksu on hyväksyttävänä",
    "est": "Avanss on kinnitamisel",
    "swe": "Förskottsbetalning håller på att godkännas",
    "pol": "Zaliczka jest zatwierdzana",
    "rus": "Авансовый платеж утверждается",
    "esp": "Se está aprobando el pago por adelantado",
    "lit": "Išankstinis apmokėjimas yra tvirtinamas",
    "nor": "Forskuddsbetaling godkjennes"
   },
   "resubmit": {
    "eng": "Submit again",
    "lav": "Iesniegt vēlreiz",
    "fin": "Lähetä uudelleen",
    "est": "Edasta uuesti",
    "swe": "Skicka in igen",
    "pol": "Prześlij ponownie",
    "rus": "Отправить снова",
    "esp": "Enviar de nuevo",
    "lit": "Pateikti dar kartą",
    "nor": "Send inn igjen"
   },
   "createdForEmployee": {
    "eng": "Created for employee",
    "lav": "Izveidots priekš darbinieka",
    "est": "Loodud töötajale",
    "swe": "Skapad åt anställd",
    "pol": "Stworzony dla pracownika",
    "rus": "Создано для сотрудника",
    "esp": "Creado para empleados",
    "lit": "Sukurta darbuotojui",
    "fin": "Luotu henkilölle",
    "nor": "Laget for ansatte"
   },
   "copyKeysTo1CSystem": {
    "eng": "Copy the keys to $integration software",
    "lav": "Pārkopējiet norādītās atslēgas $integration sistēmā",
    "rus": "Скопируйте эти ключи в систему $integration",
    "est": "Kopeeri võtmed $integration tarkvarasse",
    "esp": "Copiar claves a $integration software",
    "lit": "Kopijuoti raktus į $integration programą",
    "pol": "Skopiuj klucze do oprogramowania $integration",
    "fin": "Kopioi avaimet $integration sovellukseen",
    "nor": "Kopier nøklene til $integration-programvaren"
   },
   "copied": {
    "eng": "copied",
    "lav": "nokopēts",
    "pol": "skopiowano",
    "est": "kopeeritud",
    "rus": "скопировано",
    "swe": "kopierat",
    "esp": "copiado",
    "lit": "nukopijuota",
    "fin": "kopioitu",
    "nor": "kopiert"
   },
   "additionalPayment": {
    "eng": "Additional payment",
    "lav": "Piemaksa",
    "est": "Välja maksmiseks",
    "pol": "Dodatkowa opłata",
    "rus": "Дополнительная оплата",
    "swe": "Ytterligare betalning",
    "esp": "Pago adicional",
    "lit": "Papildomas mokėjimas",
    "fin": "Lisämaksu",
    "nor": "Tilleggsbetaling"
   },
   "sumDeducted": {
    "eng": "Sum deducted",
    "lav": "Atmaksājams",
    "est": "Kinnipeetav summa",
    "pol": "Odliczona kwota",
    "rus": "Вычитаемая сумма",
    "swe": "Avdraget belopp",
    "esp": "Cantidad deducida",
    "lit": "Suma išskaičiuota",
    "fin": "Vähennykset yhteensä",
    "nor": "Sum trukket fra"
   },
   "sumPayable": {
    "eng": "Sum payable",
    "lav": "Piemaksājams",
    "est": "Väljamakstav summa",
    "pol": "Kwota do zapłaty",
    "rus": "Сумма к оплате",
    "swe": "Belopp att betala",
    "esp": "Cantidad a pagar",
    "lit": "Mokėtina suma",
    "fin": "Maksettavaa yhteensä",
    "nor": "Sum som skal betales"
   },
   "sent": {
    "eng": "Sent",
    "lav": "Nosūtīts",
    "est": "Saadetud",
    "rus": "Отправлено",
    "swe": "Skickat",
    "esp": "Enviado",
    "lit": "Išsiųsta",
    "pol": "Wysłano",
    "fin": "Lähetetty",
    "nor": "Sendt"
   },
   "reject": {
    "eng": "Reject",
    "lav": "Noraidīt",
    "est": "Lükka tagasi",
    "rus": "Отвергнуть",
    "swe": "Avvisa",
    "esp": "Rechazar",
    "lit": "Atmesti",
    "pol": "Odrzucono",
    "fin": "Hylkää",
    "nor": "Avvis"
   },
   "createTripForOtherEmployee": {
    "eng": "Travel application for other employee",
    "lav": "Komandējuma pieteikums citam darbiniekam",
    "est": "Lähetustaotlus teisele töötajale",
    "rus": "Заявление на командировку для другого сотрудника",
    "swe": "Reseansökan för annan anställd",
    "esp": "Solicitud de viaje para otro empleado",
    "lit": "Kelionės paraiška kitam darbuotojui",
    "pol": "Wniosek o podróż dla innego pracownika",
    "fin": "Matkahakemus toiselle työntekijälle",
    "nor": "Reisesøknad for annen ansatt"
   },
   "efExUnsubmittingNotAllowed": {
    "eng": "Document is approved and in view-only mode",
    "lav": "Dokuments ir apstiprināts un vairs nevar tikt rediģēts",
    "est": "Sul pole õigusi seda dokumenti muuta",
    "rus": "Документ утвержден и находится в формате только для просмотра",
    "swe": "Du har inte tillåtelse att redigera detta dokument",
    "esp": "Usted no tiene permisos para editar este documento",
    "lit": "Jūs neturite prieigos redaguoti šį dokumentą",
    "pol": "Nie masz uprawnień do edycji tego dokumentu",
    "fin": "Sinulla ei ole oikeuksia muokata tätä dokumenttia",
    "nor": "Du har ikke lov til å redigere dette dokumentet"
   },
   "breakfastIncluded": {
    "eng": "Breakfast included",
    "lav": "Iekļautas brokastis",
    "est": "Hommikusöök hinna sees",
    "rus": "Завтрак включен",
    "swe": "Frukost inkluderad",
    "esp": "Desayuno incluido",
    "lit": "Pusryčiai įskaičiuoti",
    "pol": "Śniadanie wliczone",
    "fin": "Sisältää aamupalan",
    "nor": "Frokost inkludert"
   },
   "dinnerIncluded": {
    "eng": "Dinner included",
    "lav": "Iekļautas vakariņas",
    "est": "Õhtusöök hinna sees",
    "rus": "Ужин включен",
    "swe": "Middag inkluderad",
    "esp": "Cena incluida",
    "lit": "Vakarienė įskaičiuota",
    "pol": "Kolacja wliczona",
    "fin": "Sisältää illallisen",
    "nor": "Middag inkludert"
   },
   "lunchIncluded": {
    "eng": "Lunch included",
    "lav": "Iekļautas pusdienas",
    "est": "Lõunasöök hinna sees",
    "rus": "Обед включен",
    "swe": "Lunch inkluderad",
    "esp": "Almuerzo incluido",
    "lit": "Pietūs įskaičiuoti",
    "pol": "Obiad wliczony",
    "fin": "Sisältää lounaan",
    "nor": "Lunsj inkludert"
   },
   "auditor": {
    "eng": "Auditor",
    "lav": "Auditors",
    "est": "Audiitor",
    "rus": "Аудитор",
    "swe": "Revisor",
    "esp": "Auditor",
    "lit": "Auditorius",
    "pol": "Audytor",
    "fin": "Tarkastaja",
    "nor": "Revisor"
   },
   "assignNewRole": {
    "eng": "Assign new role",
    "lav": "Piešķirt jaunas lietotāja tiesības",
    "est": "Lisa uus roll",
    "rus": "Назначить новую роль",
    "swe": "Tilldela ny roll",
    "esp": "Asignar un nuevo rol",
    "lit": "Priskirti naują rolę",
    "pol": "Przypisz nową rolę",
    "fin": "Anna uusi rooli",
    "nor": "Tildel ny rolle"
   },
   "chooseRole": {
    "eng": "Select role",
    "lav": "Izvēlēties lietotāja tiesības",
    "est": "Vali roll",
    "rus": "Выбор роли",
    "swe": "Välj roll",
    "esp": "Seleccione el rol",
    "lit": "Parinkti rolę",
    "pol": "Wybierz rolę",
    "fin": "Valitse rooli",
    "nor": "Velg rolle"
   },
   "userHasOtherRoles": {
    "eng": "This user still has other roles assigned",
    "lav": "Šim lietotājam/-ai joprojām paliks citas lietotāja tiesības",
    "est": "Sellel kasutajal on veel teisi rolle",
    "rus": "Этот пользователь все еще имеет другие назначенные ему роли",
    "swe": "Denna användare har fortfarande andra roller",
    "esp": "Este usuario aún tiene otros roles asignados",
    "lit": "Šis vartotojas dar turi ir kitas priskirtas roles",
    "pol": "Ten użytkownik ma przypisane jeszcze inne role",
    "fin": "Tällä käyttäjällä on vielä muita rooleja",
    "nor": "Denne brukeren har fortsatt andre roller tildelt"
   },
   "removeRoleForUser": {
    "eng": "Remove current role for user $email?",
    "lav": "Noņemt lietotājam/-ai $email piešķirtās tiesības?",
    "est": "Eemalda praegune roll kasutajalt $email?",
    "rus": "Удалить текущую роль для пользователя $email?",
    "swe": "Ta bort nuvarande roll för användare $email?",
    "esp": "Remover rol actual para el usuario $email?",
    "lit": "Pašalinti šią rolę vartotojui $email?",
    "pol": "Usunąć aktualną rolę dla użytkownika: $email?",
    "fin": "Poista nykyinen rooli käyttäjältä $email?",
    "nor": "Vil du fjerne gjeldende rolle for brukeren $email?"
   },
   "havePromo": {
    "eng": "Have a promo code?",
    "est": "Sul on sooduskood?",
    "lav": "Vai Jums ir promo kods?",
    "rus": "У вас есть промокод?",
    "swe": "Har du en rabattkod?",
    "esp": "Tiene un código de promoción?",
    "lit": "Ar turite promo kodą?",
    "pol": "Posiadasz kod promocyjny?",
    "fin": "Saitko promokoodin?",
    "nor": "Har du en promo-kode?"
   },
   "promoCode": {
    "eng": "Enter code here",
    "est": "Sisesta sooduskood siia",
    "lav": "Ievadiet kodu",
    "rus": "Введите код",
    "swe": "Ange kod här",
    "esp": "Ingrese en código aquí",
    "pol": "Wprowadź kod tutaj",
    "fin": "Kirjoita koodi tähän",
    "nor": "Skriv kode her",
    "lit": "Įveskite kodą čia"
   },
   "noBeneficiariesThisMonth": {
    "eng": "No beneficiaries this month",
    "lav": "Šajā mēnesī nav nodokļu deklarācijas",
    "esp": "Sin beneficiarios este mes",
    "swe": "Inga förmånstagare denna månad",
    "lit": "Šį mėnesį naudos gavėjų nėra",
    "pol": "Brak beneficjentów w tym miesiącu",
    "rus": "Нет налоговой декларации в этом месяце",
    "est": "Sellel kuul deklareeritavaid pole",
    "fin": "Ei edunsaajia tässä kuussa",
    "nor": "Ingen begunstigede denne måneden"
   },
   "beneficiaries": {
    "eng": "Beneficiaries",
    "lav": "Deklarācijas",
    "esp": "Beneficiarios",
    "swe": "Förmånstagare",
    "lit": "Naudos gavėjai",
    "est": "Deklareeritavad",
    "pol": "Beneficjenci",
    "rus": "Декларации",
    "fin": "Edunsaajat",
    "nor": "Begunstigede"
   },
   "forwardingFailed": {
    "eng": "Forwarding failed",
    "lav": "Pārsūtīšana neizdevās",
    "esp": "Reenvío fallido",
    "swe": "Vidarebefodring misslyckades",
    "lit": "Persiuntimas nepavyko",
    "est": "Edastamine ebaõnnestus",
    "pol": "Przesyłanie nie powiodło się",
    "rus": "Переадресация не удалась",
    "fin": "Lähettäminen epäonnistui",
    "nor": "Videresending mislyktes"
   },
   "forwardingSuccess": {
    "eng": "Forwarded",
    "lav": "Nosūtīts",
    "esp": "Reenviado",
    "swe": "Vidarebefodrad",
    "lit": "Persiųsta",
    "est": "Edastatud",
    "pol": "Przesłano",
    "rus": "Передано",
    "fin": "Lähetetty",
    "nor": "Videresendt"
   },
   "prepared": {
    "eng": "Prepared",
    "lav": "Sagatavots",
    "esp": "Preparado",
    "swe": "Förberedd",
    "lit": "Paruošta",
    "est": "Valmis",
    "pol": "Przygotowano",
    "rus": "Подготовлено",
    "fin": "Täytetty",
    "nor": "Forberedt"
   },
   "taxDeclaration": {
    "eng": "Tax declaration",
    "lav": "Nodokļu deklarācija",
    "esp": "Declaración de impuestos",
    "swe": "Skattedeklaration",
    "lit": "Mokesčių deklaracija",
    "est": "Maksudeklaratsioon",
    "pol": "Deklaracja podatkowa",
    "rus": "Налоговая декларация",
    "fin": "Veroilmoitus",
    "nor": "Selvangivelse"
   },
   "paymentDate": {
    "eng": "Payment date",
    "lav": "Samaksas datums",
    "esp": "Fecha de pago",
    "swe": "Betalningsdatum",
    "lit": "Mokėjimo data",
    "est": "Maksekuupäev",
    "pol": "Data płatności",
    "rus": "Дата платежа",
    "fin": "Maksupäivä",
    "nor": "Betalingsdato"
   },
   "submitAll": {
    "eng": "Submit all",
    "lav": "Iesniegt visu",
    "esp": "Enviar todo",
    "swe": "Skicka in alla",
    "lit": "Pateikti visus",
    "est": "Edasta kõik",
    "pol": "Prześlij wszystko",
    "rus": "Подать все",
    "fin": "Lähetä kaikki",
    "nor": "Send inn alle"
   },
   "selectPaymentDate": {
    "eng": "Select payment date",
    "lav": "Izvēlieties samaksas datumu",
    "esp": "Seleccione fecha de pago",
    "swe": "Välj betalningsdatum",
    "lit": "Parinkite mokėjimo datą",
    "est": "Vali makse kuupäev",
    "pol": "Wybierz datę płatności",
    "rus": "Выберите дату платежа",
    "fin": "Valitse maksupäivä",
    "nor": "Velg betalingsdato"
   },
   "incomesRegisterIntegration": {
    "eng": "Incomes Register Integration",
    "esp": "Integración del registro de ingresos",
    "lit": "Pajamų registro integracija",
    "est": "Tuluregistri integratsioon",
    "pol": "Integracja Rejestru Dochodów",
    "rus": "Интеграция регистра доходов",
    "lav": "Ienākumu Reģistra Integrācija",
    "fin": "Tulorekisteri-integraatio",
    "swe": "Inkomstregisterintegrering",
    "nor": "Inntektsregisterintegrering"
   },
   "renew": {
    "eng": "Renew",
    "lav": "Atjaunot",
    "esp": "Renovar",
    "swe": "Förnya",
    "lit": "Atnaujinti",
    "est": "Uuenda",
    "pol": "Odnów",
    "rus": "Обновить",
    "fin": "Uusi",
    "nor": "Fornye"
   },
   "deactivate": {
    "eng": "Deactivate",
    "lav": "Atspējot",
    "esp": "Desactivar",
    "swe": "Avaktivera",
    "lit": "Deaktyvuoti",
    "est": "Deaktiveeri",
    "pol": "Dezaktywuj",
    "rus": "Отключить",
    "fin": "Poista käytöstä",
    "nor": "Deaktiver"
   },
   "transferId": {
    "eng": "Transfer Id",
    "lav": "Transfer Id",
    "esp": "Identificación de Transferencia",
    "swe": "Överförings-ID",
    "lit": "Pervedimo ID",
    "est": "Ülekande ID",
    "pol": "Identyfikator przelewu",
    "fin": "Siirtotunnus (TransferID)",
    "rus": "Идентификатор передачи",
    "nor": "Overførings-ID"
   },
   "certificate": {
    "eng": "Certificate",
    "lav": "Sertifikāts",
    "esp": "Certificado",
    "swe": "Certifikat",
    "lit": "Sertifikatas",
    "est": "Sertifikaat",
    "pol": "Certyfikat",
    "rus": "Сертификат",
    "fin": "Varmenne",
    "nor": "Sertifikat"
   },
   "certificateIsActive": {
    "eng": "Certificate is active",
    "lav": "Sertifikāts ir iespējots",
    "esp": "El Certificado está activo",
    "swe": "Certifikat är aktivt",
    "lit": "Sertifikatas aktyvus",
    "est": "Sertifikaat on aktiivne",
    "pol": "Certyfikat jest aktywny",
    "rus": "Сертификат активен",
    "fin": "Varmenne on voimassa",
    "nor": "Sertifikatet er aktivt"
   },
   "createCertificate": {
    "eng": "Create certificate",
    "lav": "Izveidot sertifikātu",
    "esp": "Crear certificado",
    "swe": "Skapa certifikat",
    "lit": "Sukurti sertifikatą",
    "est": "Loo sertifikaat",
    "pol": "Utwórz certyfikat",
    "rus": "Создать сертификат",
    "fin": "Luo varmenne",
    "nor": "Opprett sertifikat"
   },
   "contactPerson": {
    "eng": "Contact person",
    "lav": "Kontaktpersona",
    "esp": "Persona de contacto",
    "swe": "Kontaktperson",
    "lit": "Kontaktinis asmuo",
    "est": "Kontaktisik",
    "pol": "Osoba kontaktowa",
    "rus": "Контактное лицо",
    "fin": "Yhteyshenkilö",
    "nor": "Kontaktperson"
   },
   "telephone": {
    "eng": "Telephone",
    "lav": "Telefons",
    "esp": "Teléfono",
    "swe": "Telefon",
    "lit": "Telefonas",
    "est": "Telefon",
    "pol": "Telefon",
    "rus": "Телефон",
    "fin": "Puhelin",
    "nor": "Telefon"
   },
   "certificateExpires": {
    "eng": "Incomes Register integration certificate expires on $date",
    "lav": "Incomes Register integrācijas sertifikāts beidzas $date",
    "esp": "El certificado de integración de registro de ingresos expira el $date",
    "lit": "Pajamų registro integracijos sertifikatas baigia galioti $date",
    "est": "Tuluregistri liidestuse sertifikaat aegub $date",
    "pol": "Certyfikat integracji Rejestru Przychodów wygasa w dniu $date",
    "rus": "Срок действия интеграционного сертификата регистра доходов истекает в $date",
    "fin": "Tulorekisteri-integraation sertifikaatti vanhenee $date",
    "swe": "Inkomstregistrets integrationscertifikat löper ut $date",
    "nor": "Integrasjonssertifikat for inntektsregister utløper på $date"
   },
   "certificateExpired": {
    "eng": "Incomes Register integration certificate expired on $date. To continue using integration please renew certificate!",
    "lav": "Incomes Register integrācijas sertifikāta derīguma termiņš ir iztecējis $date. Lai turpinātu lietot integrāciju, lūdzu atjaunojiet sertifikātu!",
    "esp": "El certificado de integración de registro de ingresos venció el $date. Para continuar usando la integración, renueve el certificado!",
    "lit": "Registro integracijos sertifikatas baigė galioti $date. Norėdami naudotis integracija, atnaujinkite sertifikatą.",
    "est": "Tuluregistri liidestuse sertifikaat aegus $date. Kasutamise jätkamiseks uuendage sertifikaati!",
    "swe": "Registrera integrationscertifikatet som gick ut $date. För att fortsätta använda integreringen behövs certifikatet förnyas.",
    "pol": "Certyfikat integracji Rejestru Przychodów wygasł w dniu $date. Aby kontynuować korzystanie z integracji prosimy o odnowienie certyfikatu!",
    "rus": "Срок действия сертификата интеграции Incomes Register истек в $date. Чтобы продолжить использование интеграции, пожалуйста, обновите сертификат!",
    "fin": "Tulorekisteri-integraation sertifikaatti on vanhentunut $date. Ole hyvä ja uusi sertifikaatti jatkaaksesi integraation käyttöä.",
    "nor": "Integrasjonssertifikat for inntektsregister utløp $date. For å fortsette å bruke integrasjon, vennligst forny sertifikatet!"
   },
   "active": {
    "eng": "Active",
    "lav": "Aktīvs",
    "esp": "Activo",
    "swe": "Aktiv",
    "lit": "Aktyvus",
    "est": "Aktiivne",
    "pol": "Aktywny",
    "rus": "Активный",
    "fin": "Voimassa",
    "nor": "Aktiv"
   },
   "inactive": {
    "eng": "Inactive",
    "lav": "Neaktīvs",
    "esp": "Inactivo",
    "swe": "Inaktiv",
    "lit": "Neaktyvus",
    "est": "Mitteaktiivne",
    "pol": "Nieaktywny",
    "rus": "Неактивный",
    "fin": "Vanhentunut tai ei ole olemassa",
    "nor": "Inaktiv"
   },
   "new": {
    "eng": "New",
    "lav": "Jauns",
    "esp": "Nuevo",
    "swe": "Ny",
    "lit": "Naujas",
    "est": "Uus",
    "pol": "Nowy",
    "rus": "Новый",
    "fin": "Uusi",
    "nor": "Ny"
   },
   "expires": {
    "eng": "Expires:",
    "lav": "Beidzas:",
    "esp": "Expira",
    "swe": "Går ut",
    "lit": "Baigiasi:",
    "est": "Aegub:",
    "pol": "Wygasa:",
    "rus": "Истекает:",
    "fin": "Vanhenee:",
    "nor": "Utløper:"
   },
   "previousVersions": {
    "eng": "Previous versions",
    "lav": "Iepriekšējās versijas",
    "esp": "Versiones anteriores",
    "swe": "Tidigare versioner",
    "lit": "Ankstesnės versijos",
    "est": "Eelmised versioonid",
    "pol": "Poprzednie wersje",
    "rus": "Предыдущие версии",
    "fin": "Aiemmat versiot",
    "nor": "Tidligere versjoner"
   },
   "selectApproverEmail": {
    "eng": "Select approver email from existing ones",
    "lav": "Izvēlieties lēmuma pieņēmēja epastu no saraksta",
    "est": "Kinnitaja email tuleb valida olemasolevate hulgast",
    "swe": "Välj från redan befintliga e-postadresser",
    "esp": "Seleccione el correo electrónico del aprobador de los existentes",
    "pol": "Wybierz z listy adres e-mail osoby podejmującej decyzję",
    "rus": "Выберите электронную почту утвердителя из существующих",
    "fin": "Valitse hyväksyjän sähköposti listalta",
    "lit": "Pasirinkite tvirtintojo e-paštą iš sąrašo",
    "nor": "Velg godkjenner-e-post fra eksisterende"
   },
   "external-travel-approver": {
    "eng": "Travel approver",
    "est": "Lähetuste kinnitaja",
    "esp": "Aprobador del viaje",
    "pol": "Osoba zatwierdzająca podróż",
    "rus": "Утверждающий командировки",
    "fin": "Matkojen hyväksyjä",
    "swe": "Resegodkännare",
    "lav": "Komandējuma apstiprinātājs",
    "lit": "Kelionės tvirtintojas",
    "nor": "Reisegodkjenner"
   },
   "approverAdded": {
    "eng": "Approver added",
    "lav": "Lēmuma pieņēmējs pievienots",
    "est": "Kinnitaja on lisatud",
    "esp": "Aprobador agregado",
    "pol": "Dodano osobę zatwierdzającą",
    "rus": "Утвердитель добавлен",
    "fin": "Uusi hyväksyjä lisätty",
    "swe": "Godkännare tillagd",
    "lit": "Tvirtintojas pridėtas",
    "nor": "Godkjenner lagt til"
   },
   "failedToLoadDocs": {
    "eng": "Failed to load documents",
    "lav": "Dokumentu ielāde neizdevās",
    "est": "Dokumentide laadimine ebaõnnestus",
    "esp": "No se pudieron cargar los documentos",
    "pol": "Nie udało się załadować dokumentów",
    "rus": "Не удалось загрузить документы",
    "fin": "Dokumenttien lataaminen epäonnistui",
    "swe": "Misslyckades med att ladda dokumenten",
    "lit": "Nepavyko įkelti dokumentų",
    "nor": "Kunne ikke laste inn dokumenter"
   },
   "nextTripOnLastDay": {
    "eng": "Next travel is starting on the last day of this work trip",
    "esp": "El próximo viaje comienza el último día de este viaje de trabajo",
    "pol": "Następna podróż zaczyna się w ostatnim dniu tej podróży służbowej.",
    "rus": "Следующая рабочая поездка начинается в последний день этой рабочей поездки",
    "est": "Järgmine lähetus algab selle lähetuse viimasel päeval",
    "fin": "Seuraava matka alkaa tämän työmatkan viimeisenä päivänä",
    "swe": "Nästa resa påbörjas sista dagen av denna jobbresa",
    "lav": "Nākamais komandējums sākas šī komandējuma pēdējā dienā",
    "lit": "Kita kelionė prasideda paskutinę šios kelionės dieną",
    "nor": "Neste reise starter siste dag av denne arbeidsreisen"
   },
   "reportDocsWillBeDeleted": {
    "eng": "All documents will be deleted along with the report",
    "lav": "Visi pievienotie dokumenti tiks dzēsti kopā ar šo atskaiti",
    "pol": "Wraz z raportem zostaną usunięte wszystkie dokumenty",
    "rus": "Все документы будут удалены вместе с отчетом",
    "est": "Kõik dokumendid kustutatakse",
    "fin": "Kaikki dokumentit poistetaan yhdessä raportin kanssa",
    "swe": "Alla dokument kommer raderas tillsammans med rapporten",
    "lit": "Visi dokumentai ataskaitoje bus ištrinti",
    "nor": "Alle dokumenter vil bli slettet sammen med rapporten"
   },
   "PasswordTooLong": {
    "eng": "Password should not contain more than 120 characters",
    "lav": "Parole nedrīkst būt garāka par 120 rakstzīmēm",
    "swe": "Lösenordet kan inte innehålla fler än 120 tecken",
    "est": "Parool peab olema alla 120 tähemärgi",
    "pol": "Hasło nie powinno zawierać więcej niż 120 znaków",
    "rus": "Пароль не должен содержать более 120 символов",
    "fin": "Salasana ei voi olla pidempi kuin 120 merkkiä",
    "lit": "Slaptažodis negali turėti daugiau nei 120 simbolių",
    "nor": "Passord bør ikke inneholde mer enn 120 tegn"
   },
   "PasswordTooShort": {
    "est": "Parooli pikkus peab olema vähemalt 10 tähemärki",
    "eng": "Please insert password at least 10 characters",
    "rus": "Вставьте пароль не менее 10 символов",
    "fin": "Salasanan pituus vähintään 10 merkkiä",
    "pol": "Proszę podaj hasło składające się z minimum 10 liter",
    "lav": "Lūdzu, ievadiet paroli, kas garāka par 10 simboliem",
    "swe": "Var god ange ett lösenord med minst 10 tecken",
    "lit": "Įveskite mažiausiai 10 simbolių slaptažodį",
    "esp": "Introduzca la contraseña de al menos 10 caracteres",
    "nor": "Vennligst skriv inn passord på minst 10 tegn"
   },
   "onlyYourDocumentsInApp": {
    "eng": "Only your own documents are visible in the app. Visit $cloud to manage all documents.",
    "est": "Äpis näevad kasutajad ainult enda dokumente. Kõiki ettevõtte dokumente näeb siin: $cloud.",
    "pol": "W aplikacji dostępne są tylko dokumenty przesłane przez ciebie. Zobacz wszystkie dokumenty w chmurze: $cloud.",
    "rus": "В приложении видны только ваши собственные документы. Для управления всеми документами посетите $cloud.",
    "lav": "Aplikācijā iespējams apskatīt tikai savus dokumentus. Visus uzņēmuma dokumentus administratori var apskatīt $cloud.",
    "swe": "Du kan endast se dina egna dokument i appen. Hitta alla företagets dokument här: $cloud",
    "fin": "Näet sovelluksessa vain omat dokumentit. Kaikki dokumentit löytyvät $cloud.",
    "lit": "Programėlėje matomi tik jūsų dokumentai. Valdykite visus kompanijos dokumentus $cloud.",
    "nor": "Du kan kun se dine egne dokumenter I APPEN. Se alle selskapets dokumenter på $cloud.",
    "esp": "Sólo puede ver sus propios documentos EN LA APP. Ver todos los documentos de la organización en $cloud"
   },
   "mileageReport": {
    "eng": "Mileage report",
    "est": "Sõidupäevik",
    "fin": "Kilometriraportti",
    "pol": "Ewidencja przebiegu pojazdu",
    "rus": "Отчет о километраже",
    "lav": "Maršruta lapa",
    "swe": "Miltalsrapport",
    "lit": "Kilometražo ataskaita",
    "nor": "Kilometerrapport"
   },
   "noMileage": {
    "eng": "No mileage",
    "est": "Ühtegi sõitu pole lisatud",
    "fin": "Ei kilometrilukemia",
    "pol": "Brak przebiegu pojazdu",
    "rus": "Без километража",
    "lav": "Bez nobraukuma",
    "swe": "Inget miltal",
    "lit": "Be kilometražo",
    "nor": "Ingen kilometerstand"
   },
   "routeAndPurpose": {
    "est": "Sõidu eesmärk, marsruut",
    "eng": "Purpose of the trip, route",
    "fin": "Matkan tarkoitus, reitti",
    "pol": "Cel podróży, trasa",
    "rus": "Цель поездки, маршрут",
    "lav": "Brauciena mērķis, maršruts",
    "swe": "Ändamål med resan, rutt",
    "lit": "Kelionės tikslas, maršrutas",
    "nor": "Formål med turen, rute"
   },
   "deny": {
    "eng": "Deny",
    "lav": "Noraidīt",
    "est": "Ei kinnita",
    "fin": "Hylkää",
    "pol": "Odrzuć",
    "rus": "Отклонить",
    "swe": "Neka",
    "lit": "Paneigti",
    "nor": "Benekte"
   },
   "confirmIntegration": {
    "eng": "Confirm integration",
    "lav": "Apstiprināt integrāciju",
    "est": "Kinnita liidestus",
    "fin": "Vahvista integraatio",
    "pol": "Potwierdź integrację",
    "rus": "Подтвердите интеграцию",
    "swe": "Bekräfta integrering",
    "lit": "Patvirtinti integraciją",
    "nor": "Bekreft integrasjon"
   },
   "pleaseConfirmIntegration": {
    "eng": "Please confirm that you wish to integrate CostPocket with $integration for $company",
    "lav": "Lūdzu, apstipriniet, ka vēlaties integrēt CostPocket ar $integration organizācijai $company",
    "est": "Palun kinnita, et soovid liidestada CostPocketi ja $integration ettevõttele $company",
    "fin": "Ole hyvä ja vahvista toiminto: Integroidaan organisaation $company CostPocket ja $integration",
    "pol": "Potwierdź integrację CostPocket z $integration dla $company",
    "rus": "Пожалуйста, подтвердите, что вы хотите интегрировать CostPocket с $integration для $company",
    "swe": "Var god bekräfta att du önskar integrera CostPocket med $integration åt $company",
    "lit": "Patvirtinkite norą integruoti CostPocket su $integration kompanijai $company",
    "nor": "Vennligst bekreft at du ønsker å integrere CostPocket med $integration for $company"
   },
   "invalidConfirmCode": {
    "eng": "Service confirmation code is not valid",
    "lav": "Servisa apstiprinājuma kods nav pareizs",
    "est": "Teenuse kinnituskood ei ole kehtiv",
    "fin": "Palvelun vahvistuskoodi ei ole oikein tai voimassa.",
    "pol": "Kod potwierdzenia usługi jest nieważny",
    "rus": "Код подтверждения услуги недействителен",
    "swe": "Koden för servicegodkännande är inte giltlig",
    "lit": "Paslaugos patvirtinimo kodas negalioja",
    "nor": "Tjenestebekreftelseskoden er ikke gyldig"
   },
   "confirmActionFailed": {
    "eng": "Service confirmation action failed",
    "lav": "Apstiprināt vai noraidīt integrāciju neizdevās",
    "est": "Teenuse kinnitamine ebaõnnestus",
    "fin": "Palvelun vahvistaminen epäonnistui",
    "pol": "Potwierdzenie usługi nie powiodło się",
    "rus": "Действие подтверждения услуги не удалось",
    "swe": "Åtgärd för servicegodkännanden misslyckades",
    "lit": "Paslaugos patvirtinimo veiksmas nepavyko",
    "nor": "Tjenestebekreftelseshandling mislyktes"
   },
   "confirmActionDone": {
    "eng": "Integration is already confirmed by $name",
    "lav": "$name jau apstiprināja integrāciju",
    "est": "Liidestuse on juba kinnitatud $name",
    "fin": "$name on jo vahvistanut integraation.",
    "pol": "Integracja jest już potwierdzona przez: $name",
    "rus": "$name уже подтвердили интеграцию",
    "swe": "Integration har redan bekräftats av $name",
    "lit": "$name jau patvirtino integraciją",
    "nor": "Integrasjon er allerede bekreftet av $name"
   },
   "ignore": {
    "eng": "Ignore",
    "lav": "Ignorēt",
    "est": "Ignoreeri",
    "fin": "Jätä huomiotta",
    "pol": "Zignoruj",
    "rus": "Игнорировать",
    "swe": "Ignorera",
    "lit": "Ignoruoti",
    "nor": "Ignorer"
   },
   "onlyReceipts": {
    "eng": "Receipts",
    "lav": "Čeki",
    "est": "Tšekid",
    "fin": "Kuitit",
    "pol": "Rachunki",
    "rus": "Квитанции",
    "swe": "Kvitton",
    "lit": "Kvitai",
    "nor": "Kvitteringer"
   },
   "invoices": {
    "eng": "Purchase invoices",
    "lav": "Rēķini",
    "est": "Ostuarved",
    "fin": "Laskut",
    "pol": "Faktury",
    "rus": "Счета",
    "swe": "Fakturor",
    "lit": "Sąskaitos",
    "nor": "Fakturaer"
   },
   "allSubmitters": {
    "eng": "All users",
    "lav": "Visi lietotāji",
    "est": "Kõik kasutajad",
    "fin": "Kaikki kirjaajat",
    "pol": "Wszyscy użytkownicy",
    "rus": "Все пользователи",
    "swe": "Alla användare",
    "lit": "Visi vartotojai",
    "nor": "Alle innsendere"
   },
   "allAdmins": {
    "eng": "All admins",
    "lav": "Visi administratori",
    "est": "Kõik adminid",
    "fin": "Kaikki ylläpitäjät",
    "pol": "Wszyscy administratorzy",
    "rus": "Все администраторы",
    "swe": "Alla administratörer",
    "lit": "Visi administratoriai",
    "nor": "Alle administratorer"
   },
   "minAutoApprovalSum": {
    "eng": "Auto-approvable sum",
    "lav": "Automātiski apstiprināmā summa",
    "est": "Max summa, mis kinnitatakse automaatselt",
    "fin": "Automaattisesti hyväksyttävissä oleva maksimisumma",
    "pol": "Limit kwoty zatwierdzanej automatycznie",
    "rus": "Максимальная автоматически утверждаемая сумма",
    "swe": "Maximal automatiskt godkännbar summa",
    "lit": "Maksimali automatiškai patvirtinama suma",
    "nor": "Maksimal auto-godkjenningsbeløp"
   },
   "maxApprovableSum": {
    "eng": "Maximum approvable sum",
    "lav": "Automātiski noraidāmās summas",
    "est": "Max summa, mida on võimalik kinnitada",
    "fin": "Hyväksyttävissä oleva maksimisumma",
    "pol": "Maksymalna kwota, która może zostać zatwierdzona",
    "rus": "Автоматически отклоняемые суммы",
    "swe": "Maximal godkännbar summa",
    "lit": "Maksimali tvirtinama suma",
    "nor": "Maksimal godkjent sum"
   },
   "disableNotifications": {
    "eng": "Send email notifications to approvers",
    "lav": "Sūtīt paziņojumus uz e-pastu",
    "est": "Edasta teavitusi e-postile",
    "fin": "Poista sähköpostimuistutukset käytöstä",
    "pol": "Wyłącz powiadomienia e-mail",
    "rus": "Отправлять уведомления по эл.почте",
    "swe": "Avaktivera e-postnotifikationer",
    "lit": "Siųsti pranešimus e-paštu",
    "nor": "Send e-postvarsler"
   },
   "outOfWorkflowAction": {
    "eng": "Action",
    "lav": "Darbība",
    "est": "Vaikimisi toiming",
    "fin": "Oletustoiminto",
    "pol": "Działanie domyślne",
    "rus": "Действие",
    "swe": "Standardåtgärd",
    "lit": "Veiksmas pagal nutylėjimą",
    "nor": "Standard handling"
   },
   "numOfRequiredApprovals": {
    "eng": "Number of required approvals",
    "lav": "Nepieciešamo apstiprinājumu skaits",
    "est": "Vajalike kinnitamiste arv",
    "fin": "Vaadittujen hyväksyntöjen määrä",
    "pol": "Liczba wymaganych zatwierdzeń",
    "rus": "Число необходимых утверждений",
    "swe": "Antal nödvändiga godkännanden",
    "lit": "Reikalingų patvirtinimų skaičius",
    "nor": "Antall nødvendige godkjenninger"
   },
   "allowAnyoneAsDelegate": {
    "eng": "Allow anyone as delegate",
    "lav": "Atļaut deleģēt lēmumu visiem",
    "est": "Luba kasutajatel delegeerimine",
    "pol": "Deleguj zatwierdzenie dowolnej osobie",
    "rus": "Разрешить любому лицу быть утверждающим",
    "swe": "Tillåt vem som helst som delegat",
    "fin": "Lisää käyttäjä",
    "lit": "Leisti deleguoti visiems",
    "nor": "Tillat hvem som helst som delegat"
   },
   "targetType": {
    "eng": "Document type",
    "lav": "Apstiprināmā dokumenta veids",
    "est": "Dokumendi tüüp",
    "fin": "Hyväksyttävän kulun tyyppi",
    "pol": "Rodzaj wydatków możliwych do zatwierdzenia",
    "rus": "Тип утверждаемого документа",
    "swe": "Typ av godkänt dokument",
    "lit": "Tvirtinamo dokumento tipas",
    "nor": "Type godkjent dokument"
   },
   "approvalDefault": {
    "eng": "Automated decision",
    "lav": "Automātisks lēmums",
    "est": "Vaikimisi toiming",
    "fin": "Oletuspäätös",
    "pol": "Domyślna decyzja",
    "rus": "Автоматическое решение",
    "swe": "Standardbeslut",
    "lit": "Sprendimas pagal nutylėjimą",
    "nor": "Standardbeslutning"
   },
   "approvalRounds": {
    "eng": "Approval rounds",
    "lav": "Izdevumu apstiprināšana",
    "est": "Kinnitusring",
    "fin": "Hyväksymiskierrokset",
    "pol": "Zatwierdzanie wydatków",
    "rus": "Утверждение расходов",
    "swe": "Bekräftelseomgångar",
    "lit": "Tvirtinimo etapai",
    "nor": "Godkjenningsrunder"
   },
   "turnOnRounds": {
    "eng": "Click ADD WORKFLOW before turning approval rounds on",
    "lav": "Spiediet PIEVIENOT SHĒMU, lai sāktu lietot izdevumu apstiprināšanas funkciju",
    "est": "Enne aktiveerimist vali LISA KINNITUSRING",
    "pol": "Aby włączyć rundy zatwierdzania, należy dodać nowy proces zatwierdzania.",
    "rus": "Необходимо добавить рабочий процесс утверждения перед включением раундов утверждения",
    "lit": "Spauskite Pridėti Eigą prieš įgalindami tvirtinimo procesus",
    "swe": "Klicka LÄGG TILL ARBETSFLÖDE innan du aktiverar bekräftelseomgångar",
    "fin": "Paina LISÄÄ TYÖJÄRJESTYS ennen kuin käynnistät hyväksymisprosessin",
    "nor": "Klikk på LEGG TIL ARBEIDSFLYT før du slår på godkjenningsrunder"
   },
   "docsWillNeedApproval": {
    "eng": "If turned on, documents and reports will move to accounting only after approval",
    "lav": "Pēc aktivizēšanas iesniegtie dokumenti tiks novirzīti uz grāmatvedību tikai pēc to apstiprināšanas",
    "est": "Aktiveerituna edastatakse dokumendid ja aruanded raamatupidamisse alles pärast kinnitamist",
    "fin": "Jos aktiivinen, dokumentit siirtyvät hyväksynnästä suoraan kirjanpitoon.",
    "pol": "Po aktywacji tej funkcji, złożone dokumenty będą kierowane do księgowości dopiero po ich zatwierdzeniu.",
    "rus": "Если включено, документы и отчеты будут отправляться в бухгалтерию только после утверждения",
    "swe": "Om aktiverad kommer dokument och rapporter endast skickas till bokföring efter bekräftelse",
    "lit": "Jei įjungta, dokumentai ir ataskaitos bus siunčiami apskaitai tik po patvirtinimo",
    "nor": "Hvis slått på, vil dokumenter og rapporter flyttes til regnskap først etter godkjenning"
   },
   "policies": {
    "eng": "Settings",
    "lav": "Iestatījumi",
    "fin": "Käytännöt",
    "est": "Seaded",
    "pol": "Warunki",
    "rus": "Условия",
    "swe": "Villkor",
    "lit": "Nustatymai",
    "nor": "Innstillinger"
   },
   "workflows": {
    "eng": "Workflows",
    "lav": "Darbību shēmas",
    "est": "Kinnitusringide nimekiri",
    "pol": "Proces zatwierdzania",
    "rus": "Список рабочих процессов",
    "swe": "Lista på arbetsflöden",
    "fin": "Työjärjestyslista",
    "lit": "Eigų sąrašas",
    "nor": "Liste over arbeidsflyter"
   },
   "addPolicy": {
    "eng": "Add settings",
    "lav": "Pievienot iestatījumus",
    "fin": "Lisää käytäntö",
    "est": "Lisa seade",
    "pol": "Dodaj warunki zatwierdzania",
    "rus": "Добавить условие",
    "swe": "Lägg till villkor",
    "lit": "Pridėti nustatymus",
    "nor": "Legg til innstillinger"
   },
   "addWorkflow": {
    "eng": "Add Workflow",
    "lav": "Pievienot shēmu",
    "est": "Lisa kinnitusring",
    "pol": "Dodaj proces zatwierdzania",
    "rus": "Добавить схему утверждения расходов",
    "swe": "Lägg till arbetsflöde",
    "fin": "Lisää työjärjestys",
    "lit": "Pridėti Eigą",
    "nor": "Legg til arbeidsflyt"
   },
   "roundWithoutApprovers": {
    "eng": "If there are no approvers, all documents will be automatically rejected",
    "lav": "Ja apstiprināšanas kārtai nebūs pievienots neviens lēmuma pieņēmējs, visi iesniegtie izdevumu dokumenti tiks automātiski noraidīti",
    "est": "Kinnitajate puudumisel lükatakse kõik dokumendid automaatselt tagasi",
    "fin": "Jos hyväksyjiä ei ole, kaikki dokumentit hylätään automaattisesti.",
    "pol": "Jeśli nie ma wskazanych osób zatwierdzających, wszystkie dokumenty zostaną automatycznie odrzucone.",
    "rus": "Если нет утверждающих, все документы будут автоматически отклонены",
    "swe": "Om det inte finns några bekräftare, kommer alla dokument att automatiskt avvisas",
    "lit": "Jei nėra tvirtintojų, visi dokumentai bus automatiškai atmetami",
    "nor": "Hvis det ikke er noen godkjennere, vil alle dokumenter automatisk bli avvist"
   },
   "roundsOfApproval": {
    "eng": "Approval steps",
    "lav": "Apstiprināšanas kārtas",
    "est": "Kinnitusring",
    "fin": "Hyväksymiskierrokset",
    "pol": "Rundy zatwierdzające",
    "rus": "Этапы утверждения",
    "swe": "Bekräftningssteg",
    "lit": "Tvirtinimo žingsniai",
    "nor": "Godkjenningstrinn"
   },
   "round": {
    "eng": "Step #",
    "lav": "Kārtas Nr.",
    "fin": "Kierrosnro",
    "est": "Voor nr",
    "pol": "Runda numer",
    "rus": "Этап Нр.",
    "swe": "Steg #",
    "lit": "Žingsnio #",
    "nor": "Steg #"
   },
   "addRound": {
    "eng": "Add step",
    "lav": "Pievienot kārtu",
    "fin": "Lisää kierros",
    "est": "Lisa voor",
    "pol": "Dodaj rundę",
    "rus": "Добавить этап",
    "swe": "Lägg till steg",
    "lit": "Pridėti žingsnį",
    "nor": "Legg til trinn"
   },
   "selectedApprovers": {
    "eng": "Selected approvers",
    "lav": "Personas, kas pieņems lēmumu",
    "est": "Juba valitud kinnitajad",
    "fin": "Valitut hyväksyjät",
    "pol": "Wybrani zatwierdzający",
    "rus": "Выбранные утверждающие лица",
    "swe": "Valda bekräftare",
    "lit": "Parinkti tvirtintojai",
    "nor": "Utvalgte godkjennere"
   },
   "inputMustBeLonger": {
    "eng": "Input must be at least $number characters long",
    "lav": "Ievadītais teksts nedrīkst būt īsāks par $number rakstzīmēm",
    "est": "Miinumum tähemärkide arv on $number",
    "fin": "Syötteen pituuden tulee olla vähintään $number merkkiä.",
    "pol": "Minimalna liczba znaków to $number",
    "rus": "Ввод должен быть длиной не менее $number символов",
    "swe": "Inmatningen måste vara minst $number tecken",
    "lit": "Įvestis turi būti ne trumpesnė nei $number simboliai",
    "nor": "Inndata må være minst $number tegn langt"
   },
   "roundName": {
    "eng": "Add step name",
    "lav": "Ievadiet kārtas nosaukumu",
    "fin": "Lisää kierrosnimike",
    "est": "Lisa vooru nimi",
    "pol": "Dodaj nazwę rundy zatwierdzającej",
    "rus": "Добавьте название этапа",
    "swe": "Lägg till steg-namn",
    "lit": "Pridėti žingsnio pavadinimą",
    "nor": "Legg til trinnnavn"
   },
   "workflowName": {
    "eng": "Edit workflow name",
    "lav": "Mainīt shēmas nosaukumu",
    "est": "Lisa töövoo nimi",
    "pol": "Dodaj nazwę procesu zatwierdzania",
    "rus": "Изменить название схемы",
    "swe": "Lägg till namn på arbetsflödet",
    "fin": "Lisää työjärjestyksen nimi",
    "lit": "Pridėti Eigos pavadinimą",
    "nor": "Legg til arbeidsflytnavn"
   },
   "workflow": {
    "eng": "Workflow",
    "lav": "Darbību shēma",
    "est": "Kinnitusring",
    "pol": "Proces zatwierdzania",
    "rus": "Рабочий процесс утверждения",
    "lit": "Tvirtinimo eiga",
    "swe": "Arbetsflöde",
    "fin": "Työjärjestys",
    "nor": "Arbeidsflyt"
   },
   "noOptionSelected": {
    "eng": "You haven't selected any option",
    "lav": "Nav izvēlēta neviena opcija",
    "est": "Sa pole midagi valinud",
    "fin": "Mikään vaihtoehto ei ole valittuna.",
    "pol": "Nie wybrano żadnej opcji",
    "rus": "Вы не выбрали ни одного варианта",
    "swe": "Du har inte valt ett alternativ",
    "lit": "Nepasirinkote jokių variantų",
    "nor": "Du har ikke valgt noe alternativ"
   },
   "changePlanToAddUser": {
    "eng": "You are currently on a Free Demo plan which allows only one user. To add more users, please select different plan.",
    "lav": "Jūs šobrīd izmantojat Bezmaksas izmēģinājuma versiju, kas pieļauj  tikai vienu lietotāju. Lai pievienotu Jūsu organizācijai vēl lietotājus, lūdzu, izvēlieties citu plānu.",
    "fin": "Käytössäsi on tällä hetkellä ilmainen demo, joka sallii vain yhden käyttäjän. Jos organisaatiosi tarvitsee enemmän käyttäjiä, sinun on valittava joku muu pakettivaihtoehdoistamme.",
    "est": "Ettevõte on hetkel tasuta demo plaani peal, mis lubab vaid ühe kasutaja. Kasutajate lisamiseks vali tasuline hinnastamine.",
    "pol": "Obecnie korzystasz z darmowego planu Demo, który obejmuje tylko jednego użytkownika. Aby dodać więcej użytkowników wybierz inny plan.",
    "rus": "В настоящее время вы используете бесплатный тарифный план \"Демонстрация\", который предоставляет только одного пользователя. Чтобы добавить пользователей в, пожалуйста, выберите другой тарифный план.",
    "swe": "Du är för tillfället på en Gratis Demo-plan, vilket endast tillåter en användare. För att lägga till fler användare, var god välj en annan plan.",
    "lit": "Jūs naudojatės nemokamu Demo planu, kuris leidžia tik vieną naudotoją. Jei norite pridėti daugiau naudotojų, pasirinkite kitą planą.",
    "nor": "Du har for øyeblikket en gratis demo-plan som kun tillater én bruker. For å legge til flere brukere, velg en annen plan."
   },
   "minAutoApprSumDescr": {
    "eng": "If the expense sum is smaller or equal, it will be auto-approved.",
    "lav": "Ja izdevumu dokumenta summa būs mazāka vai vienāda ar norādīto, tas tiks automātiski apstiprināts. Ja summa būs lielāka - dokumentu vajadzēs apstiprināt lēmuma pieņēmējam.",
    "fin": "Jos kulusumma on tämä tai pienempi, hyväksytään se automaattisesti. Muussa tapauksessa kulu lähetetään hyväksyntäkierrokselle.",
    "est": "Kui kulutuse summa on sellest väiksem või võrdne, kinnitatakse see automaatselt.",
    "pol": "Jeśli suma wydatków jest mniejsza lub równa, zostanie ona automatycznie zatwierdzona.",
    "rus": "Если сумма расходного документа будет меньше или равна указанной, документ будет автоматически утвержден.",
    "swe": "Om utgiftssumman är mindre eller samma, kommer den automatiskt godkännas.",
    "lit": "Jei išlaidos suma mažesnė ar lygi, ji bus automatiškai patvirtinta.",
    "nor": "Hvis utgiftssummen er mindre eller lik, blir den automatisk godkjent."
   },
   "maxApprSumDescr": {
    "eng": "If the expense sum is larger, it will be auto-rejected.",
    "lav": "Ja izdevumu dokumenta summa būs lielāka par norādīto, tas tiks automātiski noraidīts.",
    "fin": "Jos kulusumma on tätä suurempi, se hylätään automaattisesti.",
    "est": "Kui kulutuse summa on sellest suurem, siis lükatakse see automaatselt tagasi.",
    "pol": "Jeśli suma wydatków jest większa, zostanie automatycznie odrzucona.",
    "rus": "Если сумма расходного документа будет выше указанной, документ будет автоматически отклонен.",
    "swe": "Om utgiftssumman är större, kommer den automatiskt avslås.",
    "lit": "Jei išlaidos suma didesnė, ji bus automatiškai atmesta.",
    "nor": "Hvis utgiftssummen er større, blir den automatisk avvist."
   },
   "disableNotificDescr": {
    "eng": "Sending notifications to approvers.",
    "lav": "Ieslēgt/izslēgt paziņojumu sūtīšanu lēmumu pieņēmējiem.",
    "fin": "Poistaa käytöstä hyväksyjille lähtevien viestien lähetyksen.",
    "est": "Teavituste saatmine kinnitajatele.",
    "pol": "Wyłącza wysyłanie powiadomień do osób zatwierdzających -gdy jest tylko jeden Zatwierdzający.",
    "rus": "Активировать/отключить отправку уведомлений утверждающим.",
    "lit": "Siųsti pranešimus tvirtintojams",
    "swe": "Avaktivera att skicka notifikationer till bekräftare",
    "nor": "Sende varsler til godkjennere."
   },
   "nonWorkflowActionDescr": {
    "eng": "Applies for cases outside of defined workflows",
    "lav": "Tiek attiecināta uz gadījumiem, kuros nav piemērojama neviena cita darbību shēma",
    "fin": "Aktivoituu, jos sopivaa kierrosta ei ole määritelty ja hyväksyntää ei voida toteuttaa.",
    "est": "Siin saab valida, mis juhtub dokumentidega, millele ei kehti ükski kinnitusring.",
    "rus": "Если пользователь подаст документ, который не соответствует ни одному рабочему процессу утверждения, автоматически произойдет выбранное действие.",
    "swe": "Om användare skickar in ett dokument som inte passar med något arbetsflöde, vald",
    "lit": "Jei pateiktas dokumentas neatitinka jokios Eigos, pasirinktas veiksmas bus atliktas automatiškai.",
    "nor": "Hvis brukeren sender inn et dokument som ikke samsvarer med noen arbeidsflyt, vil den valgte handlingen automatisk finne sted.",
    "pol": "Jeśli użytkownik prześle dokument, który nie pasuje do żadnego obiegu, wybrana czynność zostanie wykonana automatycznie."
   },
   "allowAnyoneDelegateDescr": {
    "eng": "Allows any user with active role in the company to be delegated to or appended to an Approval.",
    "lav": "Atļaut/aizliegt deleģēt lēmuma pieņemšanu jebkuram organizācijā reģistrētam lietotājam.",
    "fin": "Sallii kenen tahansa käyttäjän liittämisen hyväksyntäkierrokseen.",
    "est": "Lubab kasutajatel delegeerida kinnitamise igale töötajale.",
    "pol": "Umożliwia oddelegowanie lub dołączenie do autoryzacji zatwierdzenia dowolnemu użytkownikowi z aktywną rolą w organizacji.",
    "rus": "Позволяет любому пользователю с активной ролью в компании быть назначенным или добавленным к утверждению.",
    "swe": "Tillåt alla användare med en aktiv roll i företaget att delegeras eller bli tillagd till ett godkännande",
    "lit": "Leisti naudotojams, turintiems aktyvų vaidmenį įmonėje, būti deleguotiems arba prijungtiems prie Patvirtinimo.",
    "nor": "Lar enhver bruker med aktiv rolle i selskapet delegeres til eller legges til en godkjenning."
   },
   "numRequiredApprDescr": {
    "eng": "Number of approvals needed to pass this step. If not selected, all chosen approvers must approve.",
    "lav": "Nepieciešamo lēmumu skaits, lai Apstiprināšanas kārta tiktu uzskatīta par noslēgtu. Pēc noklusējuma Apstiprināšanas kārta ir noslēgta, kad visi lēmumu pieņēmēji ir apstiprinājuši dokumentu un/vai pārskatu.",
    "fin": "Vaadittujen hyväksyntöjen vähimmäismäärä hyväksymiskierroksella. Jos vähimmäismäärää ei ole määritelty, tulee kaikkien nimettyjen hyväksyjien osallistuttava kierrokselle.",
    "est": "Vajalik arv kinnitajaid selle vooru läbimiseks. Kui see on jäetud valimata, peavad kinnitama kõik kinnitajad.",
    "rus": "Количество необходимых одобрений для завершения этапа утверждения. Если не указано, все утверждающие лица должны утвердить.",
    "pol": "Liczba wymaganych akceptacji w celu przejścia procesu zatwierdzania. Jeśli nie określono, wszyscy odpowiedni zatwierdzający w danej rundzie muszą zaakceptować.",
    "swe": "Antal bekräftelser som krävs. Om inget annat anges måste alla godkännare bekräfta.",
    "lit": "Parašų skaičius reikalingas tvirtinimui. Jei nepasirinkta, visi tvirtintojai turi pasirašyti.",
    "nor": "Antall godkjenninger som trengs for å bestå dette trinnet. Hvis ikke valgt, må alle valgte godkjennere godkjenne."
   },
   "targetTypeDescr": {
    "eng": "If not chosen, this Workflow applies to all types of documents.",
    "lav": "Dokumentu veids, kuram tiks piemērota Darbību shēma. Pēc noklusējuma Darbību shēma attieksies uz visiem dokumentu veidiem.",
    "est": "Kui dokumendi tüüp on jäetud valimata, siis kehtib see kinnitusring kõigile dokumentidele.",
    "rus": "Тип документов, на которые действует схема утверждения. По умолчанию схема применяется ко всем типам документов.",
    "pol": "Jeśli nie wybrano, to ten proces zatwierdzania odnosi się do wszystkich typów dokumentów.",
    "swe": "Om inte annat anges, appliceras detta arbetsflöde för alla dokument.",
    "lit": "Jei nepažymėti dokumentų tipai, Tvirtinimo Eiga taikoma visiems dokumentams.",
    "fin": "Mikäli ei valittu, tämä työjärjestys pätee kaikkiin tiedostotyyppeihin",
    "nor": "Hvis ikke valgt, gjelder denne arbeidsflyten for alle typer dokumenter."
   },
   "approvalDefaultDescr": {
    "eng": "Automates approval decision for this Workflow. Gets activated if no approvers are specified.",
    "pol": "Automatyzuje decyzje o zatwierdzeniu. Stosowane, gdy nie są wybrani żadni zatwierdzający.",
    "fin": "Automatisoi hyväksyntä. Aktivoituu, jos hyväksyjiä ei ole määritelty.",
    "est": "Automeerib kinnitamise otsuseid. Kasutatakse siis, kui kinnitajaid pole valitud.",
    "lav": "Automātiska lēmuma pieņemšana. Stājas spēkā, ja attiecīgajā darbību shēmā nav norādīts neviens lēmuma pieņēmējs.",
    "rus": "Автоматизирует принятие решения об утверждении для соответствующего рабочего процесса. Активируется, если не указаны утверждающие лица.",
    "swe": "Automatiserar bekräftelsebeslut för denna runda. Aktiveras om inga godkännare specificeras.",
    "lit": "Automatizuoja tvirtinimo sprendimą šiai Eigai. Aktyvuojama, jei nenurodomi tvirtintojai",
    "nor": "Automatiserer godkjenningsbeslutning for denne arbeidsflyten. Aktiveres hvis ingen godkjennere er spesifisert."
   },
   "addApprovers": {
    "eng": "Add approvers",
    "lav": "Pievienot lēmuma pieņēmējus",
    "fin": "Lisää hyväksyjiä",
    "est": "Lisa kinnitajaid",
    "rus": "Добавить утвердителей",
    "pol": "Dodaj zatwierdzających",
    "swe": "Lägg till godkännare",
    "lit": "Pridėti tvirtintojus",
    "nor": "Legg til godkjennere"
   },
   "eeSentToITSupport": {
    "est": "Teade saadetud ITabi-sse",
    "eng": "Message sent to IT support",
    "lav": "Ziņa nosūtīta IT atbalsta speciāistiem",
    "fin": "Viesti lähetetty tekniseen tukeen",
    "rus": "Сообщение отправлено в службу поддержки ИТ",
    "pol": "Wiadomość wysłana do działu wsparcia IT",
    "swe": "Meddelande skickat till IT-support",
    "lit": "Žinutė išsiųsta pagalbos skyriui",
    "nor": "Melding sendt til IT-Support"
   },
   "cancelApproval": {
    "eng": "Cancel approval",
    "lav": "Atsaukt apstiprinājuma pieteikumu",
    "est": "Tühista kinnitamine",
    "rus": "Отменить запрос на утверждение",
    "pol": "Anuluj zatwierdzenie",
    "swe": "Avbryt bekräftelse",
    "lit": "Atšaukti patvirtinimą",
    "fin": "Epää hyväksyntä",
    "nor": "Avbryt godkjenning"
   },
   "approvalVoid": {
    "eng": "Approval has already completed",
    "lav": "Apstiprinājuma kārta ir noslēgta",
    "est": "Kinnitamine on juba tehtud",
    "rus": "Утверждение уже завершено",
    "pol": "Proces zatwierdzania został zakończony",
    "swe": "Bekräftelse är redan färdig",
    "lit": "Tvirtinimas jau yra užbaigtas",
    "fin": "Hyväksyntä on valmis",
    "nor": "Godkjenningen er allerede fullført"
   },
   "approvalDecisionVoid": {
    "eng": "Approval decision has already been made",
    "lav": "Lēmums jau ir pieņemts",
    "est": "Kinnitamise otsus on juba tehtud",
    "rus": "Решение уже принято",
    "pol": "Decyzja o zatwierdzeniu została już podjęta",
    "swe": "Bekräftelsebeslut har redan gjorts",
    "lit": "Sprendimas jau yra priimtas",
    "fin": "Hyväksymispäätös on suoritettu",
    "nor": "Godkjenningsvedtaket er allerede tatt"
   },
   "rejectReasonExpected": {
    "eng": "Approval rejection reason must be provided",
    "lav": "Ir jānorāda noraidījuma iemesls",
    "est": "Sisesta tagasi lükkamise põhjus",
    "rus": "Необходимо указать причину отклонения",
    "pol": "Należy podać powód odmowy zatwierdzenia",
    "swe": "Orsak till bekräftelseavslag måste anges",
    "lit": "Atmetimo priežastis turi būti nurodyta",
    "fin": "Hyväksynnän epäämisen syy on ilmoitettava",
    "nor": "Årsak til avvisning av godkjenning må oppgis"
   },
   "approvalNonExistent": {
    "eng": "Approval does not exist",
    "lav": "Apstiprināšanas kārta neeksistē",
    "est": "Kinnitamine ei eksisteeri",
    "rus": "Утверждение не существует",
    "pol": "Brak potwierdzenia",
    "swe": "Bekräftelse saknas",
    "lit": "Patvirtinimas neegzistuoja",
    "fin": "Ei hyväksyntää",
    "nor": "Godkjenning finnes ikke"
   },
   "reimbursable": {
    "eng": "Reimbursable",
    "lav": "Kompensējams",
    "rus": "Возмещаемые",
    "pol": "Podlega zwrotowi kosztów",
    "swe": "Återbetalningsbar",
    "est": "Hüvitamisele kuuluv",
    "lit": "Kompensuojama",
    "fin": "Hyvitettävä",
    "nor": "Refusjonsberettiget"
   },
   "approvedAndSent": {
    "eng": "Approved and forwarded",
    "lav": "Apstiprināts un nosūtīts",
    "est": "Kinnitatud ja saadetud raamatupidamisse",
    "lit": "Patvirtinta ir išsiųsta apskaitai",
    "swe": "Godkänt och skickat till mjukvara",
    "fin": "Hyväksytty ja lähetetty sovellukseen",
    "rus": "Утверждено и отправлено в бухгалтерскую программу",
    "nor": "Godkjent og sendt til programvare",
    "pol": "Zatwierdzone i wysłane do oprogramowania"
   },
   "showMore": {
    "eng": "Show all",
    "lav": "Rādīt visus",
    "est": "Näita rohkem",
    "lit": "Rodyti daugiau",
    "swe": "Visa mer",
    "fin": "Näytä lisää",
    "rus": "Показать все",
    "nor": "Vis mer",
    "pol": "Pokaż więcej"
   },
   "submitDocsOnlyInReports": {
    "eng": "Single documents can only be submitted inside reports for this company",
    "est": "Üksikuid dokumente saab selles ettevõttes esitada vaid koos aruannetega",
    "rus": "Единичные документы могут быть представлены только внутри отчетов для данной компании",
    "lav": "Šim uzņēmumam dokumentus var iesniegt tikai ar izdevumu pārskatiem",
    "swe": "Enskilda dokument kan endast skickas inom rapporter för detta företag",
    "fin": "Yksittäisiä dokumentteja voi lähettää vain osana raporttia",
    "lit": "Šiai kompanijai pavieniai dokumentai gali būti teikiami tik ataskaitose",
    "nor": "Enkeltdokumenter kan bare sendes inn i rapporter for dette selskapet",
    "pol": "Pojedyncze dokumenty mogą być składane tylko wewnątrz raportów tej firmy"
   },
   "withoutCosts": {
    "eng": "Without costs",
    "lav": "Bez izmaksām",
    "est": "Ilma kuludeta",
    "swe": "Utan kostnader",
    "fin": "Maksutta",
    "rus": "Без затрат",
    "lit": "Be išlaidų",
    "nor": "Uten kostnader",
    "pol": "Bez kosztów"
   },
   "defEmailNotificationsOn": {
    "eng": "Email notifications are on",
    "est": "Emaili teavitused on sisse lülitatud",
    "rus": "Уведомления по электронной почте включены",
    "lav": "Epasta paziņojumi ir aktīvi",
    "swe": "Epost-notifikationer är aktiverade",
    "fin": "Sähköposti-ilmoitukset ovat päällä",
    "lit": "E-pašto pranešimai įjungti",
    "nor": "E-postvarsler er på",
    "pol": "Powiadomienia e-mail są włączone"
   },
   "defMustBeApprovedByAll": {
    "eng": "Must be approved by all",
    "est": "Kõik peavad kinnitama",
    "rus": "Должны быть одобрены всеми",
    "lav": "Ir jāapstiprina visiem",
    "swe": "Måste godkännas av alla",
    "fin": "Edellyttää kaikkien hyväksyntää",
    "lit": "Privalo būti patvirtinta visų",
    "nor": "Må godkjennes av alle",
    "pol": "Musi być zatwierdzony przez wszystkich"
   },
   "defAllexpensesApproved": {
    "eng": "All types of expenses are going through approval",
    "est": "Kõiki dokumenditüüpe peab kinnitama",
    "rus": "Все виды расходов проходят процесс утверждения",
    "lav": "Apstiprināšanai tiks virzīti visu veidu izdevumi",
    "swe": "Alla typer av utgifter går igenom godkännande",
    "fin": "Kaikki kulut lähetetään hyväksyttäväksi",
    "lit": "Visų tipų išlaidos privalo būti patvirtintos",
    "nor": "Alle typer utgifter går gjennom godkjenning",
    "pol": "Wszystkie rodzaje wydatków są zatwierdzane"
   },
   "defAutoReject": {
    "eng": "Auto-reject if no approvers are specified",
    "est": "Lükka automaatselt tagasi kui kinnitajad puuduvad",
    "rus": "Автоотклонение, если не указаны утверждающие лица",
    "lav": "Automātisks noraidījums, ja nav norādīti apstiprinātāji",
    "swe": "Automatiskt avvisa om inga godkännare har valts",
    "fin": "Hylkää automaattisesti, jos hyväksyjää ei ole valittu",
    "lit": "Automatinis atmetimas, jei nėra nurodytas bent vienas tvirtintojas",
    "nor": "Autoavvis hvis ingen godkjennere er spesifisert",
    "pol": "Odrzuć automatycznie, jeśli nie określono osób zatwierdzających"
   },
   "createReportForEmployee": {
    "eng": "Create this report for another employee",
    "lav": "Izveidot šo pārskatu cita darbinieka vārdā",
    "est": "Loo see aruanne teise kasutaja nimel",
    "swe": "Skapa denna rapport åt en annan anställd",
    "rus": "Создать этот отчет для другого сотрудника",
    "fin": "Luo raportti toiselle työntekijälle",
    "lit": "Sukurti šią ataskaitą kitam darbuotojui",
    "nor": "Opprett denne rapporten for en annen ansatt",
    "pol": "Utwórz ten raport dla innego pracownika"
   },
   "lastTravelExport": {
    "eng": "Last travel info export",
    "lav": "Komandējuma informācija pēdējoreiz eksportēta",
    "est": "Viimase reisi info eksport",
    "swe": "Senaste reseinfoexport",
    "rus": "Последний экспорт информации о командировках",
    "fin": "Viimeisin matkustustietoraportti",
    "lit": "Paskutinis kelionės informacijos eksportas",
    "nor": "Siste eksport av reiseinformasjon",
    "pol": "Eksport informacji z poprzedniej podróży"
   },
   "exportTravelInfo": {
    "eng": "Export travel info",
    "lav": "Exportēt komandējuma informāciju",
    "est": "Ekpordi reisiinfo",
    "swe": "Exportera reseinfo",
    "rus": "Экспорт информации о командировках",
    "fin": "Tuo matkustustiedot",
    "lit": "Eksportuoti kelionės info",
    "nor": "Eksporter reiseinformasjon",
    "pol": "Eksport informacji z podróży"
   },
   "customerId": {
    "eng": "User ID",
    "est": "Kliendi ID",
    "swe": "",
    "fin": "Käyttäjätunnus",
    "rus": "ID пользователя",
    "lav": "Lietotāja ID",
    "lit": "Kliento ID",
    "nor": "bruker-ID",
    "pol": "ID użytkownika"
   },
   "customerKey": {
    "eng": "Key",
    "est": "Kliendivõti",
    "swe": "",
    "fin": "Avain",
    "lav": "Atslēga",
    "rus": "Ключ",
    "lit": "Raktas",
    "nor": "Nøkkel",
    "pol": "Klucz"
   },
   "customerBusinessId": {
    "eng": "Business ID",
    "est": "Ettevõtte ID",
    "swe": "Företags-ID",
    "fin": "Y-tunnus",
    "rus": "Бизнес ID",
    "lav": "Uzņēmuma ID",
    "lit": "Verslo kodas",
    "nor": "Bedrifts-ID",
    "pol": "ID firmy"
   },
   "expenseReportForEmployee": {
    "eng": "Expense report for other employee",
    "lav": "Izdevumu pārskats citam darbiniekam",
    "rus": "Отчет о расходах для другого сотрудника",
    "swe": "Utgiftsrapport för annan anställd",
    "est": "Kuluaruanne teise töötaja nimele",
    "fin": "Kuluraportti työntekijälle",
    "lit": "Išlaidų ataskaita kitam darbuotojui",
    "nor": "Utgiftsrapport for annen ansatt",
    "pol": "Raport z wydatków dla innego pracownika"
   },
   "selectedSubmitters": {
    "eng": "Selected submitters",
    "rus": "Отобранные податели",
    "lav": "Norādītie iesniedzēji",
    "swe": "Valda inlämnare",
    "est": "Valitud kasutajad",
    "fin": "Valitut lähettäjät",
    "lit": "Parinkti teikėjai",
    "nor": "Utvalgte innsendere",
    "pol": "Wybrani użytkownicy"
   },
   "targetSubmittters": {
    "eng": "Target submitters",
    "lav": "Iesniedzēji",
    "rus": "Податели",
    "swe": "Målinlämnare",
    "est": "Kasutajad, kelle kulud peab kinnitama",
    "fin": "Kohdelähettäjä",
    "lit": "Teikėjai, kurių išlaidos yra tvirtinamos",
    "nor": "Mål innsendere",
    "pol": "Docelowi użytkownicy"
   },
   "addTargetSubmitters": {
    "eng": "Add target submitters",
    "rus": "Укажите подателей",
    "lav": "Norādiet iesniedzējus",
    "swe": "Lägg till målinlämnare",
    "est": "Lisa kasutajad",
    "fin": "Lisää kohdelähettäjiä",
    "lit": "Pridėti teikėjus",
    "nor": "Legg til målinnsendere",
    "pol": "Dodaj docelowych użytkowników"
   },
   "targetSubmittersDescription": {
    "eng": "Submitter/-s who'se documents are going to be subject for approval in this Workflow.",
    "rus": "Податель/-ы, чьи расходы будут подлежать утверждению в данном рабочем процессе.",
    "lav": "Iesniedzēji, kuru izdevumi tiks virzīti apstiprināšanai dotajā darbību shēmā.",
    "swe": "Inskickare vars utgifter ska godkännas i detta arbetsflöde",
    "est": "Vali kasutajad, kelle dokumendid lähevad kinnitusringi.",
    "fin": "Lähettäjän dokumentit jotka odottavat hyväksyntää",
    "lit": "Teikėjai, kurių dokumentai bus tvirtinami pagal šią Eigą",
    "nor": "Innsender/-er hvis dokumenter skal godkjennes i denne arbeidsflyten.",
    "pol": "Użytkownik (użytkownicy), którego dokumenty będą podlegały zatwierdzeniu w tym obiegu."
   },
   "accountantComment": {
    "eng": "Accountant comment",
    "lav": "Grāmatveža komentārs",
    "est": "Raamatupidaja kommentaar",
    "swe": "Revisors kommentar",
    "fin": "Kirjanpitäjän kommentti",
    "rus": "Комментарий бухгалтера",
    "lit": "Apskaitininko komentaras",
    "nor": "Regnskapsførerkommentar",
    "pol": "Komentarz księgowego"
   },
   "decisionWasDelegatedTo": {
    "eng": "$approver delegated approval to $delegate",
    "lav": "$approver deleģēja lēmuma pieņemšanu $delegate",
    "est": "$approver delegeeris kinnitamise kasutajale $delegate",
    "fin": "$approver siirsi hyväksymisen $delegate",
    "rus": "$approver делегировал утверждение $delegate",
    "lit": "$approver delegavo patvirtinimą $delegate",
    "nor": "$approver delegerte godkjenning til $delegate",
    "pol": "$approver przekazał zatwierdzenie do $delegate"
   },
   "youDelegatedDecision": {
    "eng": "You delegated approval to $delegate",
    "lav": "Jūs deleģējāt lēmuma pieņemšanu $delegate",
    "est": "Sina delegeerisid kinnitamise kasutajale $delegate",
    "fin": "Siirsit hyväksynnän $delegate",
    "rus": "Вы делегировали утверждение $delegate",
    "lit": "Jūs delegavote patvirtinimą $delegate",
    "nor": "Du delegerte godkjenning til $delegate",
    "pol": "Przekazałeś zatwierdzenie do $delegate"
   },
   "delegateDecision": {
    "eng": "Delegate approval",
    "lav": "Deleģēt lēmumu",
    "est": "Delegeeri kinnitamine",
    "fin": "Siirrä hyväksyntä",
    "rus": "Делегировать утверждение",
    "lit": "Deleguoti patvirtinimą",
    "nor": "Delegert godkjenning",
    "pol": "Delegowanie zatwierdzenia"
   },
   "addAndApprove": {
    "eng": "Add and approve",
    "lav": "Pievienot un apstiprināt",
    "est": "Lisa ja kinnita",
    "fin": "Lisää ja hyväksy",
    "rus": "Добавить и утвердить",
    "lit": "Pridėti ir patvirtinti",
    "nor": "Legg til og godkjenn",
    "pol": "Dodaj i zatwierdź"
   },
   "justAddApprover": {
    "eng": "Add an extra approver",
    "lav": "Tikai pievienot",
    "est": "Lisa veel üks kinnitaja",
    "fin": "Lisäkäyttäjä",
    "rus": "Добавить дополнительного утверждающего",
    "lit": "Pridėti papildomą tvirtintoją",
    "nor": "Legg til en ekstra godkjenner",
    "pol": "Dodaj dodatkowego zatwierdzającego"
   },
   "approvalDelegated": {
    "eng": "Approval delegated",
    "lav": "Lēmums ir deleģēts",
    "est": "Kinnitamine on delegeeritud",
    "fin": "Hyväksyminen delegoitu",
    "rus": "Утверждение делегировано",
    "lit": "Patvirtinimas deleguotas",
    "nor": "Godkjenning delegert",
    "pol": "Zatwierdzenie oddelegowane"
   },
   "docIsNotSentToDigitization": {
    "eng": "Document is not sent to digitisation",
    "lav": "Dokuments nav nosūtīts digitalizācijai",
    "est": "Dokument ei ole saadetud digiteerimisele",
    "fin": "Dokumenttia ei lähetetty",
    "rus": "Документ не отправлен на оцифровку",
    "lit": "Dokumentas nėra išsiųstas skaitmeninimui",
    "nor": "Dokument sendes ikke til digitalisering",
    "pol": "Dokument nie jest wysyłany do digitalizacji"
   },
   "docIsDigitizedButNotRetrieved": {
    "eng": "Document is digitised but not retrieved",
    "lav": "Dokuments ir digitalizēts, bet nav saņemts",
    "est": "Dokument on digiteeritud, aga mitte kätte saadud",
    "fin": "Dokumentti on sähköistetty, muttei tietokannassa",
    "rus": "Документ оцифрован, но не извлечен",
    "lit": "Dokumentas suskaitmenintas, tačiau dar neišsiųstas",
    "nor": "Dokumentet er digitalisert, men ikke hentet",
    "pol": "Dokument jest zdigitalizowany, ale nie został odnaleziony"
   },
   "docIsDigitized": {
    "eng": "Document is digitised",
    "lav": "Dokuments ir digitalizēts",
    "est": "Dokument on digiteeritud",
    "fin": "Tiedoston lähetys onnistui",
    "rus": "Документ оцифрован",
    "lit": "Dokumentas suskaitmenintas",
    "nor": "Dokumentet er digitalisert",
    "pol": "Dokument jest zdigitalizowany"
   },
   "checkDigitization": {
    "eng": "Check digitisation status",
    "lav": "Pārbaudīt digitalizācijas statusu",
    "est": "Vaata digiteerimise staatust",
    "fin": "Tarkista dokumentin tila",
    "rus": "Проверка статуса оцифровки",
    "lit": "Patikrinkite skaitmeninimo būseną",
    "nor": "Sjekk digitaliseringsstatus",
    "pol": "Sprawdź status digitalizacji"
   },
   "resendToDigitization": {
    "eng": "(Re)Send to digitisation",
    "lav": "(Vēlreiz)Nosūtīt digitalizācijai",
    "est": "Saada (uuesti) digiteerimisele",
    "fin": "Uudelleenlähetä sähköisesti",
    "rus": "(Пере)отправка на оцифровку",
    "lit": "Siųsti (dar kartą) skaitmeninimui",
    "nor": "Send(igjen) til digitalisering",
    "pol": "Prześlij (ponownie) do digitalizacji"
   },
   "emailNotAllowed": {
    "eng": "CostPocket email is not allowed to be used here",
    "lav": "Šeit nav atļauts izmantot CostPocket e-pasta adresi",
    "est": "Siin ei saa kasutada CostPocketi e-posti aadressi",
    "fin": "CostPocket sähköpostia ei voi käyttää täällä",
    "rus": "Электронная почта CostPocket не может быть использована здесь",
    "lit": "CostPocket e-paštas negali būti naudojamas čia",
    "nor": "CostPocket e-post er ikke tillatt å bruke her",
    "pol": "Email CostPocket nie może być tutaj użyty"
   },
   "writeToIntegrateWithVerp": {
    "eng": "To enable integration with Verp, write to $email and ask for API id and API token",
    "est": "Verp tarkvara liidese aktiveerimiseks küsi API ID-d ja API tokenit siit: $email",
    "fin": "Integroidaksesi Verpin, lähetä sähköpostia $email ja kysy API-tunnus ja API-token",
    "rus": "Чтобы включить интеграцию с Verp, напишите на адрес $email и запросите API id и API token",
    "lav": "Lai aktivizētu integrāciju ar Verp, rakstiet uz $email un pieprasiet API id un API tokenu",
    "lit": "Rašykite $email dėl API ID ir API token norėdami integruoti su Verp",
    "nor": "For å aktivere integrasjon med Verp, skriv til $email og be om API-id og API-token",
    "pol": "Aby włączyć integrację z Verp, napisz do $email i poproś o identyfikator API i token API"
   },
   "writeToIntegrateWithTelema": {
    "eng": "To integrate CostPocket with Telema eFlow, write to us at $email or $email",
    "est": "CostPocketi ja Telema eFlow liidese tegemiseks kirjuta $email or $email",
    "fin": "Integroidaksesi CostPocketin Teleman eFlowssa, lähetä meille sähköpostia $email tai $email",
    "rus": "Чтобы интегрировать CostPocket с Telema eFlow, напишите нам по адресу $email или $email",
    "lav": "Lai izveidotu savienojumu starp CostPocket un Telema eFlow, rakstiet mums uz $email vai $email",
    "lit": "Rašykite mums $email arba $email norėdami integruoti CostPocket su Telema eFlow",
    "nor": "For å integrere CostPocket med Telema eFlow, skriv til oss på $email eller $email",
    "pol": "Aby zintegrować CostPocket z Telema eFlow, napisz do nas na adres $email lub $email"
   },
   "unsubmit": {
    "eng": "Unsubmit",
    "lav": "Atarhivēt",
    "est": "Võta tagasi",
    "fin": "Poista",
    "rus": "Отменить",
    "lit": "Atšaukti",
    "nor": "Avbryt innsending",
    "pol": "Odrzuć"
   },
   "customMileageRate": {
    "eng": "Company's mileage rate",
    "lav": "Uzņēmuma kilometrāžas likme",
    "est": "Ettevõtte sõidupäeviku määr",
    "fin": "Yrityksen kilometrikorvaukset",
    "rus": "Ставка компании за пробег",
    "lit": "Kompanijos kilometražo norma",
    "nor": "Firmaets kjøregodtgjørelse",
    "pol": "Firmowa stawka za kilometr"
   },
   "sendEmailToReport": {
    "eng": "To add documents to this report, send them to the following email:",
    "est": "Aruandele dokumendi lisamiseks, edasta dokumendid järgmisele emailile:",
    "lav": "Lai pievienotu dokumentus šim pārskatam, nosūtiet tos uz epastu:",
    "fin": "Lisätäksesi tiedostoja raporttiin, lähetä ne sähköpostiosoitteeseen:",
    "rus": "Чтобы добавить документы в этот отчет, отправьте их на следующий адрес электронной почты:",
    "lit": "Pridėkite dokumentus prie ataskaitos išsiųsdami juos į šį e-paštą:",
    "nor": "For å legge til dokumenter i denne rapporten, send dem til følgende e-post:",
    "pol": "Aby dodać dokumenty do tego raportu, należy je przesłać na następujący e-mail:"
   },
   "download": {
    "eng": "Download",
    "pol": "Pobierz",
    "est": "Lae alla",
    "rus": "Скачать",
    "fin": "Lataa",
    "lav": "Lejuplādēt",
    "lit": "Parsisiųsti",
    "nor": "Last ned"
   },
   "verifiedByHuman": {
    "eng": "Verified by human",
    "pol": "Zweryfikowany przez człowieka",
    "est": "Inimese poolt digiteeritud",
    "rus": "Проверено человеком",
    "fin": "Ihmisen varmentama",
    "lav": "Cilvēka pārbaudīts",
    "lit": "Patikrinta žmogaus",
    "nor": "Verifisert av menneske"
   },
   "fromPastMonth": {
    "pol": "Z poprzedniego miesiąca",
    "eng": "From previous month",
    "est": "Eelmise kuu algusest",
    "rus": "С прошлого месяца",
    "fin": "Edelliseltä kuukaudelta",
    "lav": "No pagājušā mēneša",
    "lit": "Iš ankstesnio mėnesio",
    "nor": "Fra forrige måned"
   },
   "ongoingMonth": {
    "eng": "Current month",
    "pol": "Obecny miesiąc",
    "est": "Jooksev kuu",
    "rus": "Текущий месяц",
    "fin": "Kuluva kuukausi",
    "lav": "Tekošais mēnesis",
    "lit": "Šis mėnuo",
    "nor": "Denne måneden"
   },
   "pastMonth": {
    "est": "Eelmine kuu",
    "eng": "Previous month",
    "lav": "Iepriekšējais mēnesis",
    "fin": "Edellinen kuukausi",
    "pol": "Poprzedni miesiąc",
    "rus": "Предыдущий месяц",
    "lit": "Ankstesnis mėnuo",
    "nor": "Forrige måned"
   },
   "activateFivaldi": {
    "eng": "Activate the integration based on your company Business ID. Make sure to set up the permissions for CostPocket on your Fivaldi account.",
    "lav": "Aktivizējiet integrāciju izmantojot Jūsu uzņēmuma reģistrācijas numuru. Pārliecinieties, ka Jūsu Fivaldi kontā CostPocket ir iespējotas atļaujas.",
    "pol": "Aktywuj integrację na podstawie identyfikatora Business ID swojej firmy. Upewnij się, że skonfigurowałeś uprawnienia dla CostPocket na swoim koncie Fivaldi.",
    "fin": "Aktivoi integraatio Y-tunnuksella. Varmista tiedonsiirto-oikeudet Costpocket appin ja Fivaldi-tilin välillä.",
    "est": "Liidestuse aktiveerimiseks on vaja teie ettevõtte Business ID-d. Kindlasti veendu, et Fivaldi kasutajas on lubatud CostPocketi kasutamine.",
    "rus": "Активируйте интеграцию на основе Business ID вашей компании. Обязательно настройте разрешения для CostPocket в вашей учетной записи Fivaldi.",
    "lit": "Aktyvuok integraciją su kompanijos Business ID. Įsitikinkite, kad Fivaldi paskyroje nustatėte leidimus CostPocket.",
    "nor": "Aktiver integrasjonen basert på bedriftens Business ID. Sørg for å sette opp tillatelsene for CostPocket på Fivaldi-kontoen din."
   },
   "findBusinessId": {
    "eng": "Find it in Netvisor dashboard: click on Company name > Company basic data and settings > Basic data",
    "lav": "Meklējiet Netvisor darba panelī: klikšķiniet uz Company name > Company basic data and settings > Basic data",
    "est": "Mine Netvisori töölauale: vali Ettevõtte nimi > Ettevõtte üldised andmed ja seaded > Üldised andmed",
    "fin": "Mene Netvisor asetukset - valikkoon: Klikkaa yrityksen nimeä > Perustiedot ja asetukset > Perustiedot",
    "pol": "Znajdź ją w panelu Netvisor: kliknij na Nazwę firmy > Podstawowe dane i ustawienia firmy > Podstawowe dane",
    "rus": "Найдите его на приборной панели Netvisor: нажмите на Название компании > Основные данные и настройки компании > Основные данные",
    "lit": "Rask Netvisor puslapyje: spausk ant Company name > Company basic data and settings > Basic data",
    "nor": "Finn det i Netvisor-dashbordet: klikk på Firmanavn > Selskapets grunnleggende data og innstillinger > Grunnleggende data"
   },
   "findUserIdAndKey": {
    "eng": "Find these in Netvisor dashboard: click on Company name > API and API Identifiers. There, create a new API identifier and copy the User ID and Key",
    "lav": "Meklējiet Netvisor darba panelī: klikšķiniet uz Company name > API and API Identifiers. Šeit izveidojiet jaunu API identifikatoru un nokopējiet User ID un Key",
    "est": "Mine Netvisori töölauale: vali Ettevõtte nimi > API ja API määrajad. Sealt loo uus API määraja ja kopperi Kasutaja ID ja võti",
    "fin": "Löydä tunnukset Netvisor - hallintapaneelista: napsauta Yrityksen nimi > API ja API-tunnisteet. Luo uusi API-tunniste ja kopioi käyttäjätunnus, sekä avain",
    "pol": "Znajdź te informacje w panelu Netvisor: kliknij na Nazwa firmy > API i Identyfikatory API. Utwórz nowy identyfikator API, skopiuj identyfikator użytkownika i klucz.",
    "rus": "Найдите их на приборной панели Netvisor: нажмите на Название компании > API и Идентификаторы API. Там создайте новый идентификатор API и скопируйте идентификатор пользователя и ключ.",
    "lit": "Raskite juos Netvisor: Company name > API and API Identifiers. Ten sukurkite API identifier ir nukopijuokite User ID ir Key",
    "nor": "Finn disse i Netvisor-dashbordet: klikk på Firmanavn > API- og API-identifikatorer. Der oppretter du en ny API-identifikator og kopierer bruker-ID og nøkkel"
   },
   "delegated": {
    "eng": "Delegated",
    "lav": "Deleģēts",
    "est": "Delegeeritud",
    "fin": "delegoitu",
    "pol": "Oddelegowano",
    "rus": "Делегировано",
    "lit": "Deleguota",
    "nor": "Delegert"
   },
   "integrationNotActive": {
    "eng": "Integration with the accounting software $software is not active",
    "lav": "Integrācija ar grāmatvedības programmatūru $software nav aktivizēta",
    "fin": "Integraatio kirjanpitosovellukseen ei ole aktiivinen",
    "est": "Liidestus tarkvaraga $software ei ole aktiivne",
    "pol": "Integracja z $software nie jest aktywna",
    "rus": "Интеграция с бухгалтерским программным обеспечением $software не активна",
    "lit": "Integracija su apskaitos sistema $software nėra aktyvi",
    "nor": "Integrasjon med regnskapsprogramvaren $software er ikke aktiv"
   },
   "clickToActivate": {
    "eng": "Click here to activate",
    "lav": "Klikšķiniet šeit",
    "fin": "Aktivoi",
    "est": "Aktiveerimiseks vajuta siia",
    "pol": "Kliknij, aby aktywować",
    "rus": "Нажмите здесь, чтобы активировать",
    "lit": "Aktyvuokite spausdami čia",
    "nor": "Klikk her for å aktivere"
   },
   "sendingReportFailed": {
    "eng": "Failed to send report",
    "lav": "Neizdevās nosūtīt pārskatu",
    "est": "Aruande edastamine nurjus",
    "pol": "Raport nie został wysłany",
    "rus": "Не удалось отправить отчет",
    "lit": "Nepavyko išsiųsti ataskaitos",
    "nor": "Kunne ikke sende rapporten",
    "fin": "Raportin lähettäminen epäonnistui"
   },
   "createTravelReport": {
    "eng": "Create travel report",
    "lav": "Izveidot komandējuma atskaiti",
    "pol": "Utwórz raport z podróży",
    "est": "Loo lähetusaruanne",
    "rus": "Создать отчет о командировке",
    "lit": "Sukurti kelionės ataskaitą",
    "nor": "Lag reiserapport",
    "fin": "Luo matkakuluraportti"
   },
   "createdTravelReport": {
    "eng": "Created travel report  nr. $number",
    "lav": "Izveidota komandējuma atskaite nr. $number",
    "pol": "Utworzono raport z podróży, nr $number",
    "est": "Lähetusaruanne loodud - nr. $number",
    "rus": "Создан отчет о командировке № $number",
    "lit": "Kelionės ataskaita nr. $number sukurta",
    "nor": "Laget reiserapport nr. $nummer",
    "fin": "Matkakuluraportti nr. $number on luotu"
   },
   "travelReportExists": {
    "eng": "Travel report already exists (#$number)",
    "rus": "Отчет о командировке уже существует (#$number)",
    "pol": "Raport z tej podróży już istnieje (#$number)",
    "est": "Lähetusaruanne juba eksisteerib (#$number)",
    "lav": "Komandējuma atskaite jau eksistē (#$number)",
    "fin": "Matkakuluraportti on jo ladattu",
    "lit": "Kelionės ataskaita jau egzistuoja (#$number)",
    "nor": "Reiserapport eksisterer allerede (#$number)"
   },
   "ongoingYear": {
    "eng": "Current year",
    "pol": "Bieżący rok",
    "est": "Jooksev aasta",
    "lav": "Tekošais gads",
    "fin": "Nykyinen vuosi",
    "rus": "Текущий год",
    "lit": "Šie metai",
    "nor": "Dette året"
   },
   "unarchive": {
    "eng": "Unarchive",
    "lav": "Atarhivēt",
    "est": "Dearhiveeri",
    "rus": "Разархивировать",
    "lit": "Išarchyvuoti",
    "nor": "Avarkiver",
    "fin": "Poista arkistosta",
    "pol": "Usuń z archiwum"
   },
   "refNum": {
    "eng": "Reference number",
    "pol": "Numer referencyjny",
    "est": "Viitenumber",
    "rus": "Номер ссылки",
    "fin": "Viitenumero",
    "lav": "Atsauces numurs",
    "lit": "Nuorodos numeris",
    "nor": "Referansenummer"
   },
   "submittedOn": {
    "eng": "Submitted on",
    "lav": "Iesniegts",
    "rus": "Подано",
    "est": "Esitatud",
    "lit": "Pateikta",
    "nor": "Sendt den",
    "fin": "Päivämäärä",
    "pol": "Dodany w dniu"
   },
   "removeFromReport": {
    "eng": "Remove from report",
    "lav": "Izņemt no atskaites",
    "rus": "Удалить из отчета",
    "est": "Eemalda aruandest",
    "lit": "Pašalinti iš ataskaitos",
    "nor": "Fjern fra rapport",
    "fin": "Poista raportista",
    "pol": "Usuń z raportu"
   },
   "toPrevCloud": {
    "eng": "Previous Cloud version",
    "lav": "Iepriekšējā mākoņpakalpojuma versija",
    "est": "Eelmine pilvekeskkonna versioon",
    "lit": "Ankstesnė Cloud versija",
    "nor": "Sist skyversjon",
    "fin": "Edellinen palveluversio",
    "pol": "Poprzednia wersja chmury",
    "rus": "Предыдущая облачная версия"
   },
   "removeVat": {
    "eng": "Remove VAT",
    "lav": "Nodzēst PVN",
    "est": "Eemalda KM",
    "lit": "Pašalinti PVM",
    "nor": "Fjern MVA",
    "fin": "Poista ALV",
    "pol": "Usuń VAT",
    "rus": "Убрать НДС"
   },
   "reportType": {
    "eng": "Report type",
    "lav": "Atskaites veids",
    "est": "Aruande liik",
    "esp": "",
    "lit": "Ataskaitos tipas",
    "nor": "Rapporttype",
    "fin": "Raporttityyppi",
    "pol": "Rodzaj raportu",
    "rus": "Тип отчета"
   },
   "alwaysOpenAs": {
    "eng": "Always open as $format",
    "lav": "Vienmēr atvērt kā $format",
    "est": "Ava alati $format formaadis",
    "lit": "Visada atidaryti $format",
    "nor": "Alltid åpne som $format",
    "fin": "Lataa aina $format",
    "pol": "Zawsze otwieraj jako",
    "rus": "Всегда открывается как $format"
   },
   "ifirmaApiKeys": {
    "eng": "API keys from iFirma settings",
    "nor": "API-nøkler fra iFirma-innstillinger",
    "fin": "iFirma API-avaimet",
    "lit": "API raktai iš iFirma nustatymų",
    "pol": "Klucze API z konfiguracji na stronie iFirma",
    "lav": "API atslēgas no iFirma iestatījumiem",
    "rus": "API-ключи из настроек iFirma",
    "est": "API võtmed iFirma seadetest"
   },
   "ifirmaUsername": {
    "eng": "iFirma login email",
    "nor": "iFirma påloggings-e-post",
    "fin": "iFirma sähköposti",
    "pol": "E-mail do logowania się na stronie iFirma",
    "lav": "iFirma pieslēgšanās epasts",
    "rus": "Email для входа в систему iFirma",
    "est": "iFirma sisselogimise e-post"
   },
   "dragAndDropDocs": {
    "eng": "Drag and drop your documents",
    "lav": "Velciet un nometiet failus",
    "fin": "Pudota tiedosto",
    "lit": "Įkelkite dokumentus",
    "est": "Lohista dokumendid",
    "pol": "Przeciągnij i upuść swoje dokumenty",
    "nor": "Dra og slipp dokumentene dine",
    "rus": "Перетаскивайте документы"
   },
   "supportedFormats": {
    "eng": "Supported file formats",
    "lav": "Atbalstītie failu formāti",
    "fin": "Tuetut tiedostotyypit",
    "lit": "Palaikomi failų formatai",
    "est": "Toetatud faili formaadid",
    "pol": "Obsługiwane formaty plików",
    "nor": "Støttede filformater",
    "rus": "Поддерживаемые форматы файлов"
   },
   "browseFiles": {
    "eng": "Browse files",
    "lav": "Pārlūkot failus",
    "fin": "Selaa tiedostoja",
    "lit": "Naršykite failus",
    "est": "Vali fail arvutist",
    "pol": "Przeglądaj pliki",
    "nor": "Bla gjennom filer",
    "rus": "Просмотр файлов"
   },
   "uploadInProgress": {
    "eng": "Upload in progress",
    "lav": "Notiek augšupielāde",
    "fin": "Lataus käynnissä",
    "lit": "Vyksta įkėlimas",
    "est": "Üleslaadimine",
    "pol": "Wysyłanie w toku",
    "nor": "Opplasting pågår",
    "rus": "Идет загрузка"
   },
   "uploadCompleted": {
    "eng": "Document upload completed!",
    "lav": "Dokumentu augšupielāde pabeigta!",
    "fin": "Lataus valmis!",
    "lit": "Dokumentas įkeltas.",
    "est": "Üleslaadimine lõpetatud!",
    "pol": "Dokumenty przesłane!",
    "nor": "Dokumentopplasting fullført!",
    "rus": "Загрузка документов завершена!"
   },
   "reviewDocuments": {
    "eng": "Review documents",
    "lav": "Pārskatīt dokumentus",
    "fin": "Tarkista tiedosto",
    "lit": "Peržiūrėti dokumentus",
    "est": "Kontrolli andmeid",
    "pol": "Sprawdź dokumenty",
    "nor": "Gjennomgå dokumenter",
    "rus": "Проверка документов"
   },
   "unsubmitted": {
    "eng": "Not submitted",
    "lav": "Nav iesniegts",
    "fin": "Ei lisätty",
    "lit": "Nepateiktas",
    "est": "Esitamata",
    "pol": "Nie dodano",
    "nor": "Ikke levert",
    "rus": "Не подано"
   },
   "fileFormatNotSupported": {
    "eng": "File format not supported - this document will not be uploaded",
    "lav": "Nederīgs faila formāts - šis dokuments netiks augšupielādēts",
    "fin": "Tiedostotyyppi ei tuettu - tätä dokumenttia ei tallennettu",
    "lit": "Dokumento formatas netinka - šis dokumentas nebus įkeltas",
    "est": "Antud failiformaati ei toetata - dokumenti ei laeta üles",
    "pol": "Format pliku nie jest obsługiwany - ten dokument nie zostanie załadowany",
    "nor": "Filformat støttes ikke - dette dokumentet vil ikke bli lastet opp",
    "rus": "Формат файла не поддерживается - этот документ не будет загружен"
   },
   "addDocuments": {
    "eng": "Add documents",
    "lav": "Pievienot dokumentus",
    "fin": "Lisää tiedosto",
    "lit": "Pridėti dokumentus",
    "est": "Lisa dokumendid",
    "pol": "Dodaj dokumenty",
    "nor": "Legg til dokumenter",
    "rus": "Добавить документы"
   },
   "manageApproval": {
    "eng": "Manage approval",
    "lav": "Pārvaldīt apstiprināšanu",
    "lit": "Tvarkyti patvirtinimą",
    "est": "Halda kinnitusringi",
    "pol": "Zarządzaj zatwierdzaniem",
    "nor": "Administrer godkjenning",
    "rus": "Управление утверждением",
    "fin": "Hallinnoi hyväksyntää"
   },
   "thisCloudWillBeClosed": {
    "eng": "This environment will be closed on 31 August 2022. Please write to us support@costpocket.ee, if you would like to keep using it.",
    "est": "Oleme plaaninud selle keskkonna sulgeda 31 Augustil 2022. Kui soovite seda keskkonda edasi kasutada, siis palun kirjutage meile: support@costpocket.ee",
    "pol": "Ta wersja Chmury zostanie zamknięta 31 sierpnia 2022 roku. Prosimy napisać na support@costpocket.ee jeśli chcielibyście nadal z niego korzystać.",
    "nor": "Dette miljøet vil bli stengt 31. august 2022. Skriv til oss support@costpocket.ee hvis du vil fortsette å bruke det.",
    "lav": "Šīs mākoņvides darbība tiks apstādināta 31.augustā. Lūdzam rakstīt uz atbalsts@costpocket.ee, ja vēlaties turpināt lietot šo vidi.",
    "rus": "Эта среда будет закрыта 31 августа 2022 года. Пожалуйста, напишите нам support@costpocket.ee, если вы хотите продолжать пользоваться ею.",
    "fin": "Tämä ympäristö suljetaan 31 elokuuta 2022. Mikäli sinulla on kysymyksiä, ottathan yhteyttä osoitteessa support@costpocket.ee jos haluat jatkaa ympäristön käyttämistä.",
    "lit": "Ši aplinka uždaroma Rugpjūčio 31, 2022. Jei norite ja toliau naudotis, rašykite mums support@costpocket.ee"
   },
   "confirmAndSend": {
    "pol": "Potwierdź i wyślij do oprogramowania",
    "eng": "Confirm and send to software",
    "est": "Kinnita ja saada tarkvarasse",
    "lav": "Apstiprināt un nosūtīt grāmatvedībai",
    "fin": "Vahvista ja lähetä ohjelmistoon",
    "rus": "Утвердить и отправить в бухгалтерию",
    "nor": "Bekreft og send til programvare",
    "lit": "Patvirtinti ir siųsti programai"
   },
   "addAttachment": {
    "eng": "Add attachment",
    "lav": "Pievienot pielikumu",
    "est": "Lisa manus",
    "nor": "Legg til vedlegg",
    "rus": "Добавить приложение",
    "pol": "Dodaj załącznik",
    "fin": "Lisää liite",
    "lit": "Įkelti priedą"
   },
   "setToDefault": {
    "eng": "Set to default",
    "lav": "Atjaunot sākotnējo",
    "nor": "Set til standard",
    "rus": "Установить по умолчанию",
    "est": "Taasta algsätted",
    "fin": "Aseta",
    "pol": "Ustawienia domyślne",
    "lit": "Atkurti pradinį"
   },
   "everyone": {
    "eng": "Everyone",
    "lav": "Visi",
    "nor": "Alle",
    "rus": "Все",
    "est": "Kõik",
    "fin": "Kaikki",
    "pol": "Wszyscy",
    "lit": "Visi"
   },
   "addStep": {
    "eng": "Add step",
    "lav": "Pievienot kārtu",
    "nor": "Legg til steg",
    "rus": "Добавить этап",
    "est": "Lisa samm",
    "fin": "Lisää vaihe",
    "pol": "Dodaj etap",
    "lit": "Pridėti žingsnį"
   },
   "none": {
    "eng": "None",
    "lav": "Nav",
    "nor": "Ingen",
    "rus": "Нет",
    "est": "Puudub",
    "fin": "Tyhjä",
    "pol": "Brak",
    "lit": "Nėra"
   },
   "addSubmitters": {
    "eng": "Add submitters",
    "lav": "Pievienot iesniedzējus",
    "nor": "Legg til innsendere",
    "rus": "Добавить подателей",
    "est": "Lisa kasutaja",
    "fin": "Lisää lähettäjiä",
    "pol": "Dodaj użytkowników",
    "lit": "Pridėti teikėjus"
   },
   "sendingComplete": {
    "eng": "Sending documents completed",
    "lav": "Dokumentu sūtīšana ir pabeigta",
    "rus": "Отправка документов завершена",
    "est": "Dokumentide edastamine õnnestus",
    "fin": "Tositteiden lähettäminen valmis",
    "nor": "Sending av dokumenter fullført",
    "pol": "Wysyłanie dokumentów zakończone",
    "lit": "Dokumentų siuntimas užbaigtas"
   },
   "failedToSendSomeDocs": {
    "eng": "Forwarding some documents failed",
    "lav": "Dažus dokumentus neizdevās pārsūtīt",
    "rus": "Пересылка некоторых документов не удалась",
    "est": "Mõne dokumendi edastamine ebaõnnestus",
    "fin": "Joidenkin tositteiden lähettäminen epäonnistui",
    "nor": "Videresending av noen dokumenter mislyktes",
    "pol": "Wysyłka niektórych dokumentów nie powiodła się",
    "lit": "Kai kurių dokumentų persiuntimas nepavyko"
   },
   "sendingDocumentsCount": {
    "eng": "Sending document $current out of $total",
    "lav": "Sūta dokumentu nr. $current no $total",
    "rus": "Отправка документа $current из $total",
    "est": "Saadan dokumenti $current/$total",
    "fin": "Lataus kesken $current out of $total",
    "nor": "Sender dokument $current av $total",
    "pol": "Wysyłanie dokumentu nr $current z $total",
    "lit": "Siunčiamas $current dokumentas iš $total"
   },
   "nonZero": {
    "eng": "$field can't be 0",
    "lav": "$field nedrīkst būt vērtība 0",
    "fin": "$field ei voi olla 0",
    "nor": "$field kan ikke være 0",
    "rus": "не может быть 0",
    "est": "$field ei või olla 0",
    "lit": "$field negali būti 0",
    "pol": "$field nie może być równe 0"
   },
   "nonZeroCount": {
    "eng": "Document must have sum",
    "lav": "Dokumentā ir jābūt norādītai summai",
    "fin": "Dokumentilla pitää olla summa",
    "nor": "Dokumentet må ha sum",
    "rus": "Документ должен иметь сумму",
    "est": "Dokumendil peab olema summa",
    "lit": "Dokumentas privalo turėti sumą",
    "pol": "Dokument musi zawierać sumę"
   },
   "greaterThanZero": {
    "eng": "$field must be greater than 0",
    "lav": "$field ir jābūt lielākam/-ai par 0",
    "fin": "$field pitää olla suurempi kuin",
    "nor": "$field må være større enn 0",
    "swe": "",
    "rus": "$field должно быть больше, чем 0",
    "est": "$field peab olema suurem kui 0",
    "lit": "$field privalo būti didesnis nei 0",
    "pol": "$field musi być większa niż 0"
   },
   "mustExist": {
    "eng": "Missing $field",
    "lav": "Nav norādīts $field",
    "fin": "puuttuva $field",
    "nor": "Manglende $field",
    "rus": "Отсутствующее поле $field",
    "est": "puudub $field",
    "lit": "Trūksta $field",
    "pol": "Brakujące dane $field"
   },
   "vatNotIncluded": {
    "eng": "VAT not included",
    "lav": "PVN nav iekļauts cenā",
    "fin": "ALV ei sisälly",
    "est": "ei sisalda KM",
    "rus": "НДС не включен",
    "lit": "PVM neįskaičiuotas",
    "pol": "Nie zawiera podatku VAT",
    "nor": "MVA ikke inkludert"
   },
   "export": {
    "eng": "Export",
    "lav": "Eksportēt",
    "fin": "Vienti",
    "est": "Eksport",
    "rus": "Экспорт",
    "lit": "Eksportuoti",
    "pol": "Eksportuj",
    "nor": "Eksport"
   },
   "nrDocumentsSelected": {
    "eng": "$number items(s) selected",
    "lav": "Atlasītas $number vienības",
    "fin": "$number dokumenttia valittu",
    "est": "$number dokumenti valitud",
    "rus": "$number выбранных документов",
    "lit": "$number dokumentas(-ai) pasirinkti",
    "pol": "Ilość wybranych dokumentów: $number",
    "nor": "$number dokument(er) valgt"
   },
   "bankAccount": {
    "eng": "Bank account",
    "lav": "Bankas konts",
    "fin": "Pankkitili",
    "est": "Pangakonto",
    "rus": "Банковский счет",
    "lit": "Banko sąskaita",
    "pol": "Konto bankowe",
    "nor": "bankkonto"
   },
   "creditCard": {
    "eng": "Credit card",
    "lav": "Kredītkarte",
    "fin": "Luottokortti",
    "est": "Krediitkaart",
    "rus": "Кредитная карта",
    "lit": "Kreditinė kortelė",
    "pol": "Karta kredytowa",
    "nor": "Kredittkort"
   },
   "debitCard": {
    "eng": "Debit card",
    "lav": "Debetkarte",
    "fin": "Debit-kortti",
    "est": "Deebetkaart",
    "rus": "Дебетовая карта",
    "lit": "Debitinė kortelė",
    "pol": "Karta debetowa",
    "nor": "Debetkort"
   },
   "exportAndCopyCSV": {
    "eng": "Export and copy CSV",
    "lav": "Eksportēt un kopēt CSV",
    "fin": "Tuo .CSV - tiedosto",
    "est": "Ekspordi ja kopeeri CSV",
    "rus": "Экспорт и копирование CSV",
    "lit": "Eksportuokite ir nukopijuokite CSV",
    "pol": "Eksportuj i kopiuj CSV",
    "nor": "Eksporter og kopier CSV"
   },
   "isufficientAccessRights": {
    "eng": "Insufficient access rights",
    "lav": "Piekļuve liegta",
    "fin": "Ei pääsyoikeutta",
    "est": "Õigused puuduvad",
    "rus": "Недостаточные права доступа",
    "lit": "Nepakankamos prieigos teisės",
    "pol": "Niewystarczające prawa dostępu",
    "nor": "Utilstrekkelige tilgangsrettigheter"
   },
   "youHaveUnsubmittedDocuments": {
    "eng": "You have unsubmitted documents. Please review them before submitting report!",
    "est": "Teil on aruandes dokumente, mis pole üle lõpuni esitatud. Palun vaadake need üle enne aruande esitamist!",
    "lav": "Atskaite satur neiesniegtus dokumentus. Lūdzam pirms atskaites iesniegšanas pārbaudīt dokumentu statusu!",
    "fin": "Kaikkia dokumentteja ei ole lähetetty. Tarkistathan dokumentit ennen lähettämistä",
    "rus": "У вас есть неподанные документы. Пожалуйста, просмотрите их перед отправкой отчета!",
    "lit": "Yra nepateiktų dokumentų. Peržiūrėkite prie teikdami ataskaitą!",
    "pol": "W Twoim raporcie znajdują się nieprzesłane dokumenty. Sprawdź je, zanim zatwierdzisz raport!",
    "nor": "Du har dokumenter som ikke er sendt inn. Vennligst les dem før du sender inn rapporten!"
   },
   "someDocsMayBeUnsubmitted": {
    "eng": "Closing window now may leave some documents in unsubmitted state",
    "lav": "Ņemiet vērā, ka, aizverot šo logu, dokumenti tiks saglabāti, bet tie netiks pārvirzīti uz grāmatvedību, kamēr paliks statusā \"Nav iesniegts\"",
    "fin": "Ikkuna sulkeutuu, jotkin dokumentit saattavat jäädä \"lähettämätön tilaan\"",
    "est": "Akna sulgemine võib mõned dokumendid jätta esitamata staatusesse",
    "rus": "Закрытие окна сейчас может оставить некоторые документы в неподанном статусе",
    "lit": "Uždarius langą dalis dokumentų gali likti nepateikti",
    "pol": "Jeśli zamkniesz teraz okno, niektóre dokumenty mogą nie zostać przesłane",
    "nor": "Når vinduet lukkes nå, kan det hende at noen dokumenter ikke er sendt inn"
   },
   "allowMileageOverLimit": {
    "eng": "Allow mileage over limit",
    "lav": "Komandējumu atlīdzība bez limita",
    "est": "Luba läbisõit üle limiidi",
    "rus": "Разрешить пробег сверх лимита",
    "lit": "Neribotas kilometražas",
    "pol": "Zezwalaj na przekroczenie limitu przebiegu",
    "fin": "Salli kilometrikorvaukset yli asetetun rajan",
    "nor": "Tillat kjørelengde over grensen"
   },
   "submitterCanExceedAllowance": {
    "eng": "Submitter can exceed mileage allowance tax-exempt limit 335€/month",
    "lav": "Lietotāji drīkst pārsniegt ar nodokļiem neapliekamās kompensācijas ierobežojumu 335€/mēnesī",
    "est": "Esitaja saab ületada maksuvaba piirmäära 335€/kuus",
    "rus": "Податель заявки может превысить необлагаемый налогом лимит на пробег 335€/месяц",
    "lit": "Teikėjas gali viršyti neapmokestinamą kilometražo limitą, 335€/mėnesį",
    "fin": "Käyttäjä voi ylittää kilometrikorvausten arvonlisäverottoman rajan 335€/kk",
    "nor": "Innsender kan overskride kilometergodtgjørelse skattefri grense på 335€/måned",
    "pol": "Zgłaszający może przekroczyć limit kilometrów zwolnionych z podatku 335€/miesiąc"
   },
   "processingCurrency": {
    "eng": "Processing currency",
    "lav": "Notiek valūtas konvertēšana",
    "est": "Valuuta töötlemine",
    "rus": "Обработка валюты",
    "lit": "Valiuta keičiama",
    "pol": "Przetwarzanie waluty",
    "fin": "Prosessoidaan valuuttaa",
    "nor": "Behandler valuta"
   },
   "currencyConverted": {
    "eng": "Currency converted",
    "lav": "Valūtas konvertēšana pabeigta",
    "est": "Konverteeritud valuuta",
    "rus": "Конвертация валюты завершена",
    "lit": "Valiuta iškeista",
    "pol": "Waluta przeliczona",
    "fin": "Valuutta vaihdettu",
    "nor": "Valuta omregnet"
   },
   "paygAdditionalCost": {
    "eng": "per user per month, from 4th user in the organisation",
    "lav": "par lietotāju, sākot no ceturtā lietotāja organizācijā",
    "pol": "na użytkownika od czwartego użytkownika w firmie",
    "est": "kasutaja kohta kuus alates 4ndast kasutajast ettevõttes",
    "rus": "за одного пользователя начиная с 4-го пользователя в организации",
    "lit": "per vartotoją per mėnesį, nuo 4-to nario organizacijoje",
    "fin": "per käyttäjä organisaation 4:nnestä jäsenestä laskien",
    "nor": "per bruker fra 4. bruker i organisasjonen"
   },
   "tooManyAttempts": {
    "eng": "Too many attempts! Please try again in a moment.",
    "est": "Ületatud katsete arvu. Palun proovi mõne hetke pärast uuesti.",
    "lav": "Pārāk daudz mēģinājumu! Lūdzu, pēc brīža mēģiniet vēlreiz.",
    "rus": "Слишком много попыток! Попробуйте еще раз через некоторое время.",
    "lit": "Per daug užklausų. Bandykite darkart už minutės",
    "pol": "Zbyt wiele prób! Spróbuj ponownie za chwilę.",
    "fin": "Liian monta yritystä! Kokeilisitko hetken päästä uudestaan",
    "nor": "For mange forsøk! Prøv igjen om et øyeblikk."
   },
   "allowedIpAddress": {
    "eng": "Allowed IP address",
    "est": "Lubatud IP aadressid",
    "rus": "Разрешенный IP-адрес",
    "lav": "Atļauta IP adrese",
    "lit": "Leidžiamas IP adresas",
    "pol": "Dozwolony adres IP",
    "fin": "Sallittu IP-osoite",
    "nor": "Tillatt IP-adresse"
   },
   "createApproval": {
    "eng": "Create approval",
    "lav": "Izveidot apstiprinājuma pieprasījumu",
    "lit": "Sukurti patvirtinimą",
    "pol": "Utwórz prośbę o zatwierdzenie",
    "fin": "Luo hyväksyntä",
    "est": "Loo kinnitus",
    "nor": "Opprett godkjenning",
    "rus": "Создать утверждение"
   },
   "pleaseUpdateApp": {
    "eng": "Please update your app in $storeName before $date",
    "lav": "Lūdzu, atjaunojiet Jūsu lietotnes versiju vietnē $storeName līdz $date",
    "fin": "Päivittäisitkö appin $storeName ennen $date",
    "lit": "Atnaujinkite aplikaciją $storeName iki $date",
    "est": "Palun uuenda äppi $storeName enne $date",
    "rus": "Пожалуйста, обновите ваше приложение в $storeName до $date",
    "pol": "Zaktualizuj swoją aplikację w $storeName przed $date",
    "nor": "Oppdater appen din i $storeName før $date"
   },
   "update": {
    "eng": "Update",
    "lav": "Atjaunot",
    "fin": "Päivitys",
    "lit": "Atnaujinti",
    "est": "Uuenda",
    "rus": "Обновление",
    "pol": "Aktualizuj",
    "nor": "Oppdater"
   },
   "horizonApiUsername": {
    "eng": "Horizon API username",
    "lav": "Horizon API lietotājvārds",
    "rus": "Имя пользователя API Horizon",
    "nor": "Horizon API brukernavn",
    "fin": "Horizon API -käyttäjänimi",
    "est": "Horizoni API kasutajanimi",
    "pol": "Horizon API nazwa użytkownika"
   },
   "horizonApiPassword": {
    "eng": "Horizon API user password",
    "lav": "Horizon API lietotāja parole",
    "rus": "Пароль пользователя Horizon API",
    "nor": "Horizon API brukerpassord",
    "fin": "Horizon API -käyttäjän salasana",
    "est": "Horizoni API kasutaja parool",
    "pol": "Horizon API hasło użytkownika"
   },
   "horizonApiKey": {
    "eng": "API name in Horizon",
    "lav": "Horizon API nosaukums",
    "rus": "Имя API в Horizon",
    "nor": "API-navn i Horizon",
    "lit": "API pavadinimas Horizon'e",
    "fin": "API-nimi Horizonissa",
    "est": "API nimi Horizonis",
    "pol": "Nazwa API w Horizon"
   },
   "horizonFileStorageId": {
    "eng": "File storage ID in Horizon",
    "lav": "Horizon failu glabātuves ID",
    "rus": "ИД хранилища файлов в Horizon",
    "nor": "Fillagrings-ID i Horizon",
    "lit": "Dokumentų saugyklos kodas Horizon'e",
    "fin": "Tiedoston tallennustunnus Horizonissa",
    "est": "Faili salvestamise ID Horizonis"
   },
   "horizonDocType": {
    "eng": "Supplier document type in Horizon",
    "lav": "Piegādātāju dokumentu tips",
    "rus": "Тип документа поставщиков в Horizon",
    "nor": "Leverandørdokumenttype i Horizon",
    "lit": "Tiekėjo dokumentų tipas Horizon'e",
    "fin": "Toimittajan asiakirjatyyppi Horizonissa",
    "est": "Tarnija dokumendi tüüp Horizonis",
    "pol": "Typ dokumentu dostawcy w Horizon"
   },
   "horizonAccountCode": {
    "eng": "Account code for Avansa Norēķinu persona",
    "lav": "Avansu norēķinu personu konta numurs",
    "rus": "Код аккаунта для персоны aвансовых платежей",
    "nor": "Kontokode for Avansa Norēķinu persona",
    "fin": "Avansa Norēķinu -henkilön tilikoodi",
    "est": "Konto kood Avansa Norēķinu persona"
   },
   "showDuplicates": {
    "eng": "Duplicates",
    "pol": "Pokaż duplikaty",
    "lav": "Dublikāti",
    "lit": "Dublikatai",
    "est": "Topelt dokumendid",
    "rus": "Дубликаты",
    "nor": "Vis duplikater",
    "fin": "Näytä kahdenkertaiset kopiot"
   },
   "noDuplicatesFound": {
    "eng": "No duplicates found",
    "lav": "Netika atrasts neviens dublikāts",
    "lit": "Dublikatų nerasta",
    "est": "Topelt dokumente ei tuvastatud",
    "rus": "Дубликатов не обнаружено",
    "pol": "Nie znaleziono duplikatów",
    "nor": "Ingen duplikater funnet",
    "fin": "Kahdenkertaisia kopioita ei löytynyt"
   },
   "unset": {
    "eng": "Missing",
    "lav": "Nav iestatīts",
    "pol": "Brak",
    "nor": "Mangler",
    "fin": "Puuttuu",
    "rus": "Отсутствует",
    "lit": "Trūksta",
    "est": "Puudub"
   },
   "summary": {
    "eng": "Summary",
    "lav": "Kopsavilkums",
    "pol": "Podsumowanie",
    "nor": "Sammendrag",
    "fin": "Kooste",
    "rus": "Резюме",
    "lit": "Suvestinė",
    "est": "Kokkuvõte"
   },
   "efExDocNotDistinct": {
    "eng": "This document seems to be duplicate and will not be sent to accounting software",
    "lav": "Šis dokuments ir marķēts kā dublikāts un netiks nosūtīts grāmatvedībai",
    "pol": "Ten dokument został oznaczony jako duplikat i nie zostanie przesłany do programu księgowego",
    "nor": "Dette dokumentet ser ut til å være duplikat og vil ikke bli sendt til regnskapsprogramvare",
    "fin": "Kahdenkertaisia kopioita ei lähetetä kirjanpitoon",
    "rus": "Этот документ кажется дубликатом и не будет отправлен в бухгалтерскую программу",
    "lit": "Šis dokumentas yra dublikatas ir nebus siunčiamas apskaitai",
    "est": "See dokument näib olevat duplikaat ja seda ei saadeta raamatupidamistarkvarasse"
   },
   "creditCardReport": {
    "eng": "Credit card report",
    "lav": "Kredītkartes maksājumu atskaite",
    "nor": "Kredittkortrapport",
    "pol": "Raport płatności kartą kredytową",
    "fin": "Luottokorttiraportti",
    "rus": "Отчет по кредитной карте",
    "lit": "Kreditinės kortelės ataskaita",
    "est": "Krediitkaardi aruanne"
   },
   "enable": {
    "eng": "Enable",
    "lav": "Iestatīt",
    "nor": "Aktiver",
    "pol": "Włącz",
    "fin": "Aseta",
    "rus": "Включить",
    "lit": "Įgalinti",
    "est": "Luba"
   },
   "customPaymentMethods": {
    "eng": "Payment methods",
    "lav": "Maksājumu metodes",
    "nor": "Egendefinerte betalingsmetoder",
    "pol": "Spersonalizowane metody płatności",
    "fin": "Kustomoidut maksutavat",
    "rus": "Персонализированные способы оплаты",
    "lit": "Individualizuoti mokėjimo būdai",
    "est": "Kohandatud makseviisid"
   },
   "creditCardReconciliation": {
    "eng": "Payment card reconciliation",
    "lav": "Maksājumu kartes izraksta saskaņošana",
    "nor": "Kredittkortavstemming",
    "pol": "Uzgadnianie wyciągu z karty kredytowej",
    "fin": "Luottokorttiraportointi",
    "rus": "Сверка платежных карт",
    "lit": "Kreditinės kortelės suderinimas",
    "est": "Pangakaardi aruanne"
   },
   "ccReconciliationExplanation": {
    "eng": "Match expenses with payment card transactions in the statement automatically",
    "lav": "Automātiski saskaņot maksājumu dokumentus ar darījumiem bankas izrakstā",
    "nor": "Match utgifter med kredittkorttransaksjoner i kontoutskriften automatisk",
    "pol": "Automatycznie dopasowuj wydatki do transakcji na wyciągu z karty kredytowej",
    "fin": "Täsmäytä kulut luottokorttitransaktioihin automaattisesti",
    "rus": "Автоматическое сопоставление расходов с платежами в выписке по платежной карте",
    "lit": "Automatiškai suporuoti išlaidas su kreditinės kortelės mokėjimais",
    "est": "Seo pangakaardi kulud automaatselt pangakaardi väljavõttega"
   },
   "availableOnlyWithReports": {
    "eng": "Available only with integrations that support expense reports",
    "lav": "Pieejams tikai ar integrācijām, kas atbalsta pārskatu veidošanu",
    "nor": "Kun tilgjengelig med integrasjoner som støtter utgiftsrapporter",
    "pol": "Dostępne tylko z integracjami obsługującymi raporty wydatków",
    "fin": "Saatavilla vain integraatioihin jotka tukevat kuluraportointia",
    "rus": "Доступно только для интеграций, поддерживающих отчеты о расходах",
    "lit": "Galima naudoti tik su integracijomis, palaikančiomis išlaidų ataskaitas",
    "est": "Saadaval ainult kuluaruandeid toetava liidestusega"
   },
   "enableCustomMethods": {
    "eng": "Enable custom payment methods",
    "lav": "Iespējot personalizētās maksājumu metodes",
    "nor": "Aktiver egendefinerte betalingsmåter",
    "pol": "Włącz spersonalizowane metody płatności",
    "fin": "Lisää kustomoitu maksutapa",
    "rus": "Включить персонализированные способы оплаты",
    "lit": "Įgalinti individualizuotus mokėjimo būdus",
    "est": "Luba kohandatud makseviisid"
   },
   "ccReportsRequirePayments": {
    "eng": "Automatic payment card reconciliation works only when custom payment methods are enabled.",
    "lav": "Lai iespējotu automatizētu maksājumu karšu izrakstu saskņošanu, nepieciešams iestatīt personalizētās maksājumu metodes.",
    "nor": "Automatisk kredittkortavstemming fungerer bare når egendefinerte betalingsmåter er aktivert.",
    "pol": "Automatyczne uzgadnianie kart kredytowych działa tylko wtedy, gdy włączone są spersonalizowane metody płatności.",
    "fin": "Automaattinen luottokorttitäsmäytys toimii vain kun kustomoidut maksutavat ovat käytössä",
    "rus": "Автоматическая сверка платежных карт работает только при включенных персонализированных способах оплаты.",
    "lit": "Automatinis kreditinės kortelės suderinimas veikia, kai įgalinami individualizuoti mokėjimo būdai",
    "est": "Pangakaardi kulude automaatne kokku viimine pangakaardi väljavõttega töötab vaid koos kohandatud kululiikidega."
   },
   "ccReportsRequireReports": {
    "eng": "Automatic payment card reconciliation requires the use of reports.",
    "lav": "Lai iespējotu automatizētu kartes maksājumu izrakstu saskaņošanu, nepieciešams iestatīt maksājumu pārskatu veidošanu.",
    "nor": "Automatisk kredittkortavstemming krever bruk av rapporter.",
    "pol": "Automatyczna weryfikacja zgodności rachunków bankowych wymaga aktywacji usługi raportów.",
    "fin": "Automaattinen luottokorttitäsmäytys edellyttää raporttien aktivoimista",
    "rus": "Автоматическая сверка платежных карт требует использования отчетов.",
    "lit": "Automatinis kreditinės kortelės suderinimas veikia kartu su ataskaitomis",
    "est": "Pangakaardi kulude automaatne kokku viimine pangakaardi väljavõttega töötab vaid aruannetega."
   },
   "paymentMethodExplanation": {
    "eng": "Customise payment options if paid from company funds",
    "lav": "Pielāgot maksājumu metodes darījumiem, kas apmaksāti ar uzņēmuma līdzekļiem",
    "pol": "Dostosuj opcje płatności, jeśli są opłacane ze środków firmowych",
    "fin": "Kustomoi maksutapoja jos kulu maksettiin yrityksen varoista",
    "rus": "Настройка способов оплаты, если оплата производится из средств компании",
    "lit": "Individualizuokite mokėjimo būdus, jei mokama kompanijos lėšomis",
    "est": "Kohandage maksevõimalusi ettevõtte vahenditest maksmisel"
   },
   "addPaymentMethod": {
    "eng": "Add payment method",
    "lav": "Pievienot maksājumu metodi",
    "pol": "Dodaj metodę płatności",
    "fin": "Lisää maksutapa",
    "rus": "Добавить способ оплаты",
    "lit": "Pridėti mokėjimo būdą",
    "est": "Lisa makseviis"
   },
   "cardLfd": {
    "eng": "Card last 4 digits",
    "lav": "Kartes pēdējie 4 cipari",
    "pol": "Ostatnie 4 cyfry numeru karty płatniczej",
    "fin": "Kortin viimeiset neljä numeroa",
    "rus": "Последние 4 цифры карты",
    "lit": "Paskutiniai 4 kortelės skaitmenys",
    "est": "Kaardi viimased 4 numbrit"
   },
   "assignToUser": {
    "eng": "Assign to user",
    "lav": "Piešķirt lietotājam",
    "pol": "Przypisz do użytkownika",
    "fin": "Lisää käyttäjälle",
    "rus": "Присвоить пользователю",
    "lit": "Priskirti vartotojui",
    "est": "Määra kasutajale"
   },
   "ccReportWarning": {
    "eng": "Adding the expense to payment card report",
    "lav": "Šis izdevumu dokuments tiek pievienots kartes maksājumu atskaitei",
    "pol": "Dodanie wydatku do raportu karty kredytowej",
    "fin": "Lisätään kulua luottokorttiraporttiin",
    "rus": "Добавление расходов в отчет по кредитной карте",
    "lit": "Pridėti išlaidą prie kreditinės kortelės ataskaitos",
    "est": "Kulude lisamine pangakaardi aruandesse"
   },
   "selectPaymentMonth": {
    "eng": "Select the month when the payment was made",
    "lav": "Norādiet mēnesi, kurā tika veikts darījums",
    "pol": "Wybierz miesiąc, w którym płatność została dokonana",
    "fin": "Valitse maksukuukausi",
    "rus": "Выберите месяц, когда был произведен платеж",
    "lit": "Norodykite, kurį mėnesį buvo atliktas mokėjimas",
    "est": "Valige kuu, millal makse tehti"
   },
   "skipccReport": {
    "eng": "Skip adding to payment card report",
    "lav": "Nepievienot kartes maksājumu atskaitei",
    "pol": "Pomiń dodawanie do raportu karty kredytowej",
    "fin": "Jätä väliin luottokorttilaskun lataaminen",
    "rus": "Не добавлять в отчет по кредитной карте",
    "lit": "Nepridėti prie kreditinės kortelės ataskaitos",
    "est": "Jäta pangakaardi aruandesse lisamine vahele"
   },
   "statementUploaded": {
    "eng": "Statement uploaded",
    "lav": "Izraksts augšupielādēts",
    "pol": "Wyciąg przesłany",
    "fin": "Luottokorttilasku ladattu",
    "rus": "Выписка загружена",
    "lit": "Ataskaita pateikta",
    "est": "Väljavõte on üles laetud"
   },
   "transactions": {
    "eng": "Transactions",
    "lav": "Darījumi",
    "pol": "Transakcje",
    "fin": "Transaktiot",
    "rus": "Транзакции",
    "lit": "Mokėjimai",
    "est": "Tehingud"
   },
   "uploadStatementToMatch": {
    "eng": "Upload card statement to match payment transactions with cost documents",
    "lav": "Augšupielādējiet konta izrakstu, lai pielīdzinātu izdevumu dokumentus kartes darījumiem",
    "pol": "Prześlij wyciąg z karty kredytowej, aby dopasować transakcje płatnicze do dokumentów kosztowych",
    "fin": "Lataa tiliote täsmäyttääksesi kulutositteet maksuihin",
    "rus": "Загрузить выписку по карте, чтобы сопоставить платежные сделки с расходными документами",
    "lit": "Pateikite kreditinės kortelės ataskaitą norėdami suporuoti mokėjimus su išlaidų dokumentais",
    "est": "Laadige üles panga väljavõte, et sobitada maksetehingud kuludokumentidega"
   },
   "unmatched": {
    "eng": "Unmatched",
    "lav": "Nav pielīdzināti",
    "pol": "Niedopasowane",
    "fin": "Täsmäämätön",
    "rus": "Несравненные",
    "lit": "Nesuporuota",
    "est": "Sidumata"
   },
   "matched": {
    "eng": "Matched",
    "lav": "Pielīdzināti",
    "pol": "Dopasowane",
    "fin": "Täsmäytetty",
    "rus": "Соответствующие",
    "lit": "Suporuota",
    "est": "Seotud"
   },
   "addStatement": {
    "eng": "Upload bank statement",
    "lav": "Augšupielādēt konta izrakstu",
    "pol": "Dodaj wyciąg z karty kredytowej",
    "fin": "Lataa tiliote",
    "rus": "Загрузите выписку с банковского счета",
    "lit": "Pateikti kreditinės kortelės ataskaitą",
    "est": "Laadige panga väljavõte"
   },
   "processingStatement": {
    "eng": "Processing bank statement",
    "lav": "Tiek nolasīta informācija no konta izraksta",
    "pol": "Przetwarzanie wyciągu z karty kredytowej",
    "fin": "Luottokorttiraporrtia käsitellään",
    "rus": "Обработка банковской выписки",
    "lit": "Peržiūrima kreditinės kortelės ataskaita",
    "est": "Panga väljavõtte protsessimine"
   },
   "containsUnmatchedTransactions": {
    "eng": "Report contains unmatched transactions",
    "lav": "Atskaite satur nepielīdzinātus darījumus",
    "pol": "Raport zawiera niedopasowane transakcje",
    "fin": "Raportti sisältää täsmäämättömiä transaktioita",
    "rus": "Отчет содержит несопоставленные записи",
    "lit": "Yra nesuporuotų mokėjimų",
    "est": "Aruanne sisaldab kokku viimata tehinguid"
   },
   "weWillMakeEmptyDocs": {
    "eng": "If submitted, a cost without a document will be automatically created for each unmatched transaction",
    "lav": "Iesniedzot šādu atskaiti, katram nepielīdzinātajam darījumam tiks automātiski izveidots un iesniegts atbilstošs izdevumu dokuments bez attēla",
    "pol": "Przesłanie takiego raportu spowoduje automatyczne utworzenie dokumentu kosztowego bez obrazu dla każej niedopasowanej transakcji",
    "fin": "Mikäli tosite lähetetään, maksut ilman täsmäytettyä dokumenttia tuodaan kirjanpitoon automaattisesti",
    "rus": "В случае подачи отчета, расход без документа будет автоматически создан для каждой несопоставленной записи",
    "lit": "Pateikus dabar, kiekvienas nesuporuotas mokėjimas bus raportuotas kaip išlaida be patvirtinančio dokumento",
    "est": "Iga puuduva dokumendi kohta luuakse ilma kuludokumendita kulu"
   },
   "removeStatement": {
    "eng": "Remove statement",
    "lav": "Dzēst konta izrakstu",
    "pol": "Usuń wyciąg z konta",
    "fin": "Poista tiliote",
    "rus": "Удалить выписку по кредитной карте",
    "lit": "Pašalinti išrašą",
    "est": "Eemalda krediitkaardi väljavõte"
   },
   "matchTransactionToDoc": {
    "eng": "Match transaction with document",
    "lav": "Pielīdzināt darījumu izdevumu dokumentam",
    "pol": "Połącz transakcję z dokumentem",
    "fin": "Täsmäytä transaktiot tositteisiin",
    "rus": "Сопоставить транзакцию с документом",
    "lit": "Suporuoti mokėjimą su dokumentu",
    "est": "Ühendage tehing dokumendiga"
   },
   "iDontHaveDocument": {
    "eng": "I dont have the document",
    "lav": "Man nav šī dokumenta",
    "pol": "Nie posiadam dokumentu",
    "fin": "Minulla ei ole dokumenttia",
    "rus": "У меня нет этого документа",
    "lit": "Neturiu dokumento",
    "est": "See dokument on kadunud"
   },
   "addDocument": {
    "eng": "Add document",
    "lav": "Pievienot dokumentu",
    "pol": "Dodaj dokument",
    "fin": "Lisää dokumentti",
    "rus": "Добавить документ",
    "lit": "Pridėti dokumentą",
    "est": "Lisa dokument"
   },
   "currencyConversion": {
    "eng": "Automatic currency conversion",
    "lav": "Automātiska valūtu konvertācija",
    "pol": "Automatyczne przeliczanie walut",
    "fin": "Automaattinen valuutanvaihto",
    "rus": "Автоматическая конвертация валюты",
    "est": "Automaatne valuuta konverteerimine",
    "lit": "Automatinė valiutos konversija"
   },
   "conversionDay": {
    "eng": "Currency conversion day",
    "lav": "Valūtas konvertācijas diena",
    "pol": "Dzień przeliczenia waluty",
    "fin": "Valuuttapäivä",
    "rus": "День конвертации валюты",
    "est": "Valuuta konverteerimise päev",
    "lit": "Valiutos konvertavimo diena"
   },
   "transactionDay": {
    "eng": "Transaction day",
    "lav": "Diena, kad veikts darījums",
    "pol": "Dzień dokonania transakcji",
    "fin": "Maksupäivä",
    "rus": "День оплаты",
    "est": "Tehinguga sama päev",
    "lit": "Transakcijos diena"
   },
   "workdayAfterTrip": {
    "eng": "First workday after the business travel",
    "lav": "Pirmā darba diena pēc komandējuma beigām",
    "pol": "Pierwszy dzień roboczy po zakończeniu podróży służbowej",
    "fin": "Ensimmäinen työpäivä työmatkan päättymisestä",
    "rus": "Первый рабочий день после окончания командировки",
    "est": "Esimene tööpäev pärast lähetuse lõppu",
    "lit": "Pirma darbo diena po verslo kelionės"
   },
   "previousTransactionDay": {
    "eng": "Day before the transaction",
    "lav": "Diena pirms darījuma",
    "rus": "День перед оплатой",
    "pol": "Dzień przed transakcją",
    "est": "Päev enne tehingut",
    "lit": "Diena prieš transakciją",
    "fin": "Päivä ennen tapahtumaa"
   },
   "explanationTransactionDay": {
    "eng": "Conversion with the official rate on the day of the transaction.",
    "lav": "Valūta tiek konvertēta pēc oficiālās likmes darījuma dienā.",
    "rus": "Конвертация по официальному курсу на день совершения платежа.",
    "pol": "Przewalutowanie na podstawie oficjalnego kursu z dnia dokonania transakcji",
    "est": "Konverteerimine tehingupäeva ametliku kursiga.",
    "lit": "Konversija su oficialiu valiutos kursu transakcijos dieną",
    "fin": "Muuntaminen tapahtumapäivän viralliseen kurssiin."
   },
   "explanationPrevDay": {
    "eng": "Conversion with the official rate on the day before the transaction.",
    "lav": "Valūta tiek konvertēta pēc oficiālās likmes dienu pirms darījuma.",
    "rus": "Конвертация по официальному курсу на день, предшествующий платежу.",
    "pol": "Przewalutowanie na podstawie oficjalnego kursu z dnia poprzedzającego dzień dokonania transakcji",
    "est": "Konverteerimine tehingueelse päeva ametliku kursi alusel.",
    "lit": "Konversija su oficialiu valiutos kursu dieną prieš transakciją",
    "fin": "Muuntaminen tapahtumaa edeltävän päivän viralliseen kurssiin."
   },
   "explanationNextWorkday": {
    "eng": "Documents in travel reports: conversion with the official rate on the next day after the business trip. Other documents: conversion with the official rate on the day of the transaction.",
    "lav": "Dokumentiem komandējumu atskaitēs: valūta tiek konvertēta nākamajā darba dienā pēc komandējuma beigām. Pārējiem dokumentiem: valūta tiek konvertēta pēc oficiālās likmes darījuma dienā.",
    "rus": "Документы в командировочных отчетах: конвертация по официальному курсу на следующий день после командировки. Прочие документы: конвертация по официальному курсу на день совершения платежа.",
    "pol": "Dokumenty w raportach z podróży: przewalutowanie na podstawie oficjalnego kursu z pierwszego dnia po zakończeniu podróży służbowej. Inne dokumenty: przewalutowanie na podstawie oficjalnego kursu z dnia dokonania transakcji.",
    "est": "Dokumendid lähetusaruandes: konverteerimine ametliku kursiga järgmisel päeval pärast lähetust. Muud dokumendid: konverteerimine tehingupäeva ametliku kursiga.",
    "lit": "Dokumentai kelionių ataskaitose: konvertuojami su oficialiu sekančios dienos po kelionės pabaigos valiutos kursu. Kiti dokumentai: konvertuojami su oficialiu valiutos kursu transakcijos dieną.",
    "fin": "Asiakirjat matkaraporteissa: muuntaminen työmatkan jälkeisen seuraavan päivän viralliseen kurssiin. Muut asiakirjat: muuntaminen tapahtumapäivän viralliseen kurssiin."
   },
   "searchCompany": {
    "eng": "Search company",
    "lav": "Meklēt uzņēmumu",
    "rus": "Искать компанию",
    "est": "Otsi ettevõtet",
    "pol": "Wyszukaj firmę",
    "lit": "Ieškoti kompanijos",
    "fin": "Etsi yritystä"
   },
   "convert": {
    "eng": "Convert",
    "lav": "Konvertēt",
    "rus": "Конвертировать",
    "est": "Konverteeri",
    "pol": "Konwertuj",
    "lit": "Iškeisti",
    "fin": "Muunna"
   },
   "optional": {
    "eng": "optional",
    "lav": "nav obligāts",
    "rus": "необязательный",
    "est": "valikuline",
    "pol": "opcjonalnie",
    "lit": "pasirinktinai",
    "fin": "valinnainen"
   },
   "exchangeRate": {
    "eng": "Exchange rate",
    "lav": "Valūtas kurss",
    "rus": "Обменный курс",
    "est": "Valuuta kurss",
    "pol": "Kurs wymiany",
    "lit": "Keitimo kursas",
    "fin": "Vaihtokurssi"
   },
   "searchByContent": {
    "eng": "Search by name, comment, date or employee name",
    "lav": "Meklējiet pēc nosaukuma, komentāra, datuma vai darbinieka vārda",
    "est": "Otsi nime, kommentaari, kuupäeva või töötaja nime järgi",
    "rus": "Поиск по названию, комментарию, дате или имени сотрудника",
    "pol": "Szukaj według nazwy, komentarza, daty lub nazwiska pracownika",
    "lit": "Ieškoti pagal pavadinimą, komentarą, datą arba darbuotoją",
    "fin": "Hae nimellä, kommentilla, päivämäärällä tai työntekijän nimellä"
   },
   "digitizedByUser": {
    "eng": "Digitised by user",
    "lav": "Dokumentu digitalizējis lietotājs",
    "est": "Digiteeritud kasutaja poolt",
    "pol": "Zdigitalizowane przez użytkownika",
    "rus": "Оцифровано пользователем",
    "lit": "Suskaitmeninta vartotojo",
    "fin": "Käyttäjän digitoima"
   },
   "cancelTravel": {
    "eng": "Cancel travel",
    "lav": "Atcelt komandējumu",
    "est": "Tühista reis",
    "pol": "Anuluj podróż",
    "rus": "Отменить поездку",
    "lit": "Atšaukti kelionę",
    "fin": "Peruuta matka"
   },
   "approxTravelCosts": {
    "eng": "Approximate travel costs",
    "lav": "Aptuvenās ceļošanas izmakasas",
    "est": "Ligikaudsed reisikulud",
    "pol": "Szacunkowe koszty podróży",
    "rus": "Приблизительные расходы на поездку",
    "lit": "Apytikslės kelionės išlaidos",
    "fin": "Arvioidut matkakulut"
   },
   "draft": {
    "eng": "Draft",
    "lav": "Nepabeigts",
    "pol": "Szkic",
    "rus": "Черновик",
    "est": "Mustand",
    "lit": "Ruošinys",
    "fin": "Luonnos"
   },
   "saveAsDraft": {
    "eng": "Save as draft",
    "lav": "Saglabāt vēlākai apstrādei",
    "rus": "Сохранить как черновик",
    "est": "Salvesta mustandina",
    "pol": "Zapisz jako szkic",
    "lit": "Išsaugoti ruošinį",
    "fin": "Tallenna luonnoksena"
   },
   "dropMatchingDoc": {
    "eng": "Drop matching document here",
    "lav": "Ievelciet atbilstošo dokumentu šeit",
    "rus": "Бросьте соответствующий документ сюда",
    "est": "Lohista maksega soetud dokument siia",
    "pol": "Upuść pasujący dokument tutaj",
    "lit": "Įkelkite susijusį dokumentą čia",
    "fin": "Siirrä vastaava asiakirja tähän"
   },
   "unmatch": {
    "eng": "Unmatch",
    "lav": "Atzīmēt kā nepielīdzinātu",
    "rus": "Отметить как несопоставленные",
    "est": "Seo lahti",
    "pol": "Oznacz jako niedopasowane",
    "lit": "Išskirti",
    "fin": "Ei täsmää"
   },
   "matchWithTransaction": {
    "eng": "Match with transaction",
    "lav": "Pielīdzināt darījumam",
    "rus": "Сопоставить с сделкой",
    "est": "Seotud maksega",
    "pol": "Dopasuj do transakcji",
    "lit": "Susieti su pavedimu",
    "fin": "Yhdistä tapahtumaan"
   },
   "match": {
    "eng": "Match",
    "lav": "Pielīdzināt",
    "rus": "Сопоставить",
    "est": "Seotud",
    "pol": "Dopasuj",
    "lit": "Susieti",
    "fin": "Täsmäytä"
   },
   "reportContainsDuplicates": {
    "eng": "Report contains duplicate documents which will not be sent to software. Please review them!",
    "lav": "Šī atskaite satur dublikātus, kas netiks nosūtīti grāmatvedībai. Lūdzu, pārskatiet šos dokumentus!",
    "est": "Aruanne sisaldab topelt dokumente, mida ei saadeta raamatupidamisprogrammi edasi. Palun vaatadake need üle.",
    "pol": "Ten raport zawiera duplikaty. Przejrzyj je przed wysłaniem!",
    "rus": "Этот отчет содержит дубликаты. Пожалуйста, просмотрите их перед отправкой!",
    "lit": "Šioje ataskaitoje yra dublikatų, kurie nebus siunčiami apskaitai. Peržiūrėkite prieš pateikdami.",
    "fin": "Tämä raportti sisältää kaksoiskappaleita. Tarkista dokumentit ennen lähettämistä!"
   },
   "paidByUnspecified": {
    "eng": "Paid by unspecified funds",
    "lav": "Apmaksas veids nav norādīts",
    "pol": "Opłacone z nieokreślonych funduszy",
    "rus": "Оплачено из неуказанных средств",
    "lit": "Apmokėta nenurodytomis lėšomis",
    "est": "Makstud täpsustamata vahenditega",
    "fin": "Maksetaan määrittelemättömistä varoista"
   },
   "totalReimbursable": {
    "eng": "Total reimbursable",
    "lav": "Kopā atmaksājams",
    "pol": "Całkowita kwota do zwrotu",
    "rus": "Итого компенсируемая сумма",
    "lit": "Iš viso kompensuojama",
    "est": "Hüvitatav summa",
    "fin": "Korvattava yhteensä"
   },
   "reimbursableAdditionalDocs": {
    "eng": "Reimbursable additional documents",
    "lav": "Atmaksājams par pievienotajām izmaksām bez dokumenta",
    "pol": "Dokumenty dodatkowe podlegające zwrotowi",
    "rus": "Компенсируемые дополнительные документы",
    "lit": "Kompensuojami papildomi dokumentai",
    "est": "Hüvitatavad lisadokumendid",
    "fin": "Korvattavat lisäasiakirjat"
   },
   "docAlreadyImported": {
    "lav": "Šis dokuments jau tika iesūtīts grāmatvedības programmā",
    "eng": "This document has already been imported",
    "pol": "Ten dokument został już dodany",
    "rus": "Этот документ уже был импортирован",
    "lit": "Šis dokumentas jau buvo įkeltas",
    "est": "See dokument on varasemalt imporditud",
    "fin": "Tämä asiakirja on jo tuotu"
   },
   "setReimbursement": {
    "eng": "Set reimbursement type",
    "lav": "Mainīt apmaksas veidu",
    "rus": "Изменить тип компенсации",
    "pol": "Ustaw rodzaj zwrotu kosztów",
    "lit": "Nustatyti kompensavimo tipą",
    "est": "Määra hüvitise tüüp",
    "fin": "Aseta korvaustyyppi"
   },
   "pendingConfirmation": {
    "eng": "Pending administrator confirmation",
    "lav": "Gaida administratora apstiprinājumu",
    "rus": "Ожидается подтверждение администратора",
    "pol": "Oczekuje na potwierdzenie księgowego",
    "lit": "Laukiama administratoriaus patvirtinimo",
    "est": "Ootab administraatori kinnitamist",
    "fin": "Odottaa pääkäyttäjän vahvistusta"
   },
   "markNonDuplicate": {
    "eng": "Not duplicate",
    "lav": "Nav dublikāts",
    "rus": "Не дубликат",
    "pol": "Dokument nie jest duplikatem",
    "est": "Ei ole topelt",
    "lit": "Nėra dublikatas",
    "fin": "Ei kaksoiskappaletta"
   },
   "calendarDocumentsHint": {
    "eng": "Documents added during the selected period",
    "lav": "Dokumenti, kas pievienoti atlasītajā laika periodā",
    "rus": "Документы, добавленные в течение выбранного периода",
    "pol": "Dokumenty dodane w wybranym okresie",
    "est": "Valitud perioodi jooksul lisatud dokumendid",
    "lit": "Dokumentai, pridėti per pasirinktą periodą",
    "fin": "Valitun ajanjakson aikana lisätyt asiakirjat"
   },
   "calendarReceiptsHint": {
    "eng": "Reports created during or for the selected period",
    "lav": "Atskaites, kas izveidotas atlasītajā laika periodā vai par šo periodu",
    "rus": "Отчеты, созданные в течение или за выбранный период",
    "pol": "Raporty utworzone w trakcie lub dla wybranego okresu",
    "est": "Aruanded, mis on loodud valitud perioodi jooksul või selle kohta",
    "lit": "Ataskaitos, sukurtos per arba už pasirinktą periodą",
    "fin": "Luodut raportit valitun ajanjakson aikana"
   },
   "duplicateDetection": {
    "eng": "Duplicate prevention",
    "lav": "Dublikātu novēršana",
    "est": "Topelt arvete tuvastamine",
    "lit": "Dublikatų aptikimas",
    "rus": "Определение дубликатов",
    "pol": "Wykrywanie duplikatów",
    "fin": "Kaksoiskappaleiden ehkäisy"
   },
   "dupplicateDetectionExplanation": {
    "eng": "Detects and prevents forwarding of duplicates to accounting software",
    "lav": "Atpazīt dublikātus un nepieļaut to sūtīsanu uz grāmatvedības programmatūru",
    "est": "Takistab topelt dokumentide edastamist raamatupidamistarkvarasse",
    "lit": "Sustabdo dublikatų siuntimą apskaitai",
    "rus": "Предотвращает пересылку дубликатов документов в бухгалтерскую программу",
    "pol": "Uniemożliwia przesłanie duplikatów do programu księgowego",
    "fin": "Tunnistaa ja estää kaksoiskappaleiden välittämisen kirjanpito-ohjelmistoon"
   },
   "addCarRegCard": {
    "eng": "Add vehicle registration certificate",
    "lav": "Pievienot transportlīdzekļa reģistrācijas apliecību",
    "rus": "Добавить свидетельство о регистрации транспортного средства",
    "est": "Lisage sõiduki registreerimistunnistus",
    "lit": "Pridėti transporto priemonės registracijos sertifikatą",
    "pol": "Dodaj dowód rejestracyjny pojazdu",
    "fin": "Lisää ajoneuvon rekisteröintitodistus"
   },
   "carRegCard": {
    "eng": "Vehicle registration certificate",
    "lav": "Transportlīdzekļa reģistrācijas apliecība",
    "rus": "Свидетельство о регистрации транспортного средства",
    "est": "Sõiduki registreerimistunnistus",
    "lit": "Transporto priemonės registracijos sertifikatas",
    "pol": "Dowód rejestracyjny pojazdu",
    "fin": "Ajoneuvon rekisteröintitodistus"
   },
   "mileageReportOtherEmployee": {
    "eng": "Mileage report for other employee",
    "lav": "Maršruta lapa citam darbiniekam",
    "est": "Sõidupäeviku koostamine teisele töötajale",
    "lit": "Kilometražo ataskaita už kitą darbuotoją",
    "pol": "Raport przebiegu pojazdu dla innego pracownika",
    "rus": "Отчет о пробеге другого сотрудника",
    "fin": "Kilometriraportti toiselle työntekijälle"
   },
   "mileageReports": {
    "eng": "Mileage reports",
    "lav": "Maršruta lapas",
    "est": "Sõidupäevik",
    "lit": "Kilometražo ataskaitos",
    "pol": "Raport przebiegu pojazdu",
    "rus": "Отчеты о пробеге",
    "fin": "Kilometriraportit"
   },
   "newLine": {
    "eng": "New line",
    "lav": "Jauna rinda",
    "lit": "Nauja eilutė",
    "rus": "Новая строка",
    "est": "Uus rida",
    "pol": "Nowy wiersz",
    "fin": "Uusi rivi"
   },
   "addItemRows": {
    "eng": "Add item lines",
    "lav": "Pievienot preču rindas",
    "lit": "Pridėti sąskaitos eilutes",
    "rus": "Добавить строки счета",
    "est": "Lisa arve rida",
    "pol": "Dodaj wiersze faktury",
    "fin": "Lisää laskurivit"
   },
   "showItemRows": {
    "eng": "Show item lines",
    "lav": "Izvērst rindas",
    "lit": "Rodyti sąskaitos eilutes",
    "rus": "Показать строки счета",
    "est": "Kuva arve read",
    "pol": "Pokaż wiersze faktury",
    "fin": "Näytä laskurivit"
   },
   "description": {
    "eng": "Description",
    "lav": "Nosaukums/Apraksts",
    "lit": "Aprašymas",
    "rus": "Название/Описание",
    "est": "Kirjeldus",
    "pol": "Opis",
    "fin": "Kuvaus"
   },
   "price": {
    "eng": "Price",
    "lav": "Cena",
    "lit": "Kaina",
    "rus": "Цена",
    "est": "Hind",
    "pol": "Cena",
    "fin": "Hinta"
   },
   "quantity": {
    "eng": "Quantity",
    "lav": "Daudzums",
    "lit": "Kiekis",
    "rus": "Количество",
    "est": "Kogus",
    "pol": "Ilość",
    "fin": "Määrä"
   },
   "code": {
    "eng": "Code",
    "lav": "Kods",
    "lit": "Kodas",
    "rus": "Код",
    "est": "EAN kood",
    "pol": "Kod",
    "fin": "Koodi"
   },
   "unit": {
    "eng": "Unit",
    "lav": "Vienība",
    "lit": "Vienetas",
    "rus": "Единица",
    "est": "Ühik",
    "pol": "Jednostka",
    "fin": "Yksikkö"
   },
   "rounding": {
    "eng": "Rounding",
    "lav": "Noapaļot",
    "lit": "Apvalinimas",
    "rus": "Округление",
    "est": "Ümardus",
    "pol": "Zaokrąglenie",
    "fin": "Pyöristys"
   },
   "requestTravelOffer": {
    "eng": "Request travel offer",
    "lav": "Pieprasīt ceļojuma piedāvājumu",
    "est": "Loo reisipakkumise päring",
    "pol": "Zapytaj o ofertę podróży",
    "rus": "Запросить предложение о путешествии",
    "lit": "Užsakyti kelionės pasiūlymą",
    "fin": "Pyydä matkatarjous"
   },
   "arrival": {
    "eng": "Arrival",
    "lav": "Ierašanās",
    "est": "Saabumine",
    "pol": "Przyjazd",
    "rus": "Прибытие",
    "lit": "Atvykimas",
    "fin": "Saapuminen"
   },
   "time": {
    "eng": "Time",
    "lav": "Laiks",
    "est": "Aeg",
    "pol": "Czas",
    "rus": "Время",
    "lit": "Laikas",
    "fin": "Aika"
   },
   "departureCity": {
    "eng": "Departure location (city)",
    "lav": "Izbraukšanas vieta (pilsēta)",
    "est": "Väljumise asukoht (linn)",
    "pol": "Miejsce wyjazdu (miasto)",
    "rus": "Место отправления (город)",
    "lit": "Išvykimo vieta (miestas)",
    "fin": "Lähtöpaikka (kaupunki)"
   },
   "returning": {
    "eng": "Returning",
    "lav": "Atgriešanās",
    "est": "Tagasitulek",
    "pol": "Powrót",
    "rus": "Возвращение",
    "lit": "Grįžimas",
    "fin": "Paluu"
   },
   "destinationCity": {
    "eng": "Destination location (city)",
    "lav": "Ierašanās vieta (pilsēta)",
    "est": "Sihtkoht (linn)",
    "pol": "Miejsce docelowe (miasto)",
    "rus": "Место назначения (город)",
    "lit": "Tikslo vieta (miestas)",
    "fin": "Kohteen sijainti (kaupunki)"
   },
   "additionalInfoShort": {
    "eng": "Additional information",
    "lav": "Papildus informācija",
    "est": "Lisainfo",
    "pol": "Dodatkowe informacje",
    "rus": "Дополнительная информация",
    "lit": "Papildoma informacija",
    "fin": "Lisätieto"
   },
   "additionalInfoLong": {
    "eng": "Additional information for travel agency (venue location, car rental needs, number of passengers etc.)",
    "lav": "Papildus informācija ceļojumu aģentūrai (informācija par ierašanās vietu, auto nomu, pasažieru skaitu utt.)",
    "est": "Lisa informatsioon reisibüroole (asukoht, rendiauto vajadus, reisijate arv jne.)",
    "pol": "Dodatkowe informacje dla biura podróży (lokalizacja miejsca, potrzeby wynajmu samochodów itp.)",
    "rus": "Дополнительная информация для туристического агентства (место проведения мероприятия, необходимость аренды автомобиля, количество пассажиров и т.д.)",
    "lit": "Papildoma informacija kelionių agentūrai (renginio vieta, automobilio nuomos poreikis, keleivių skaičius ir t. t.)",
    "fin": "Lisätietoja matkatoimistolle (paikan sijainti, auton vuokraustarpeet, matkustajamäärä jne.)"
   },
   "travelMethod": {
    "eng": "Preferred method of travelling",
    "lav": "Vēlamais transporta veids",
    "est": "Eelistatud reisimisviis",
    "pol": "Preferowany środek transportu",
    "rus": "Предпочтительный способ путешествия",
    "lit": "Pageidaujamas keliavimo būdas",
    "fin": "Suosituin matkustustapa"
   },
   "airplane": {
    "eng": "Airplane",
    "lav": "Lidmašīna",
    "est": "Lennuk",
    "pol": "Samolot",
    "rus": "Самолет",
    "lit": "Lėktuvas",
    "fin": "Lentokone"
   },
   "bus": {
    "eng": "Bus",
    "est": "Buss",
    "lav": "Autobuss",
    "pol": "Autobus",
    "rus": "Автобус",
    "lit": "Autobusas",
    "fin": "Linja-auto"
   },
   "boat": {
    "eng": "Boat",
    "est": "Laev",
    "lav": "Kuģis",
    "pol": "Łódź",
    "rus": "Корабль",
    "lit": "Keltas",
    "fin": "Vene"
   },
   "train": {
    "eng": "Train",
    "est": "Rong",
    "lav": "Vilciens",
    "pol": "Pociąg",
    "rus": "Поезд",
    "lit": "Traukinys",
    "fin": "Juna"
   },
   "requestCreatedAndSent": {
    "eng": "Report saved and travel offer request sent to the travel agency",
    "lav": "Komandējuma pieteikums saglabāts un pieprasījums nosūtīts ceļojumu aģentūrai",
    "est": "Aruanne salvestatud ja reisipakkumine saadetud reisibüroole",
    "pol": "Raport został zapisany i zapytanie ofertowe wysłane do biura podróży",
    "rus": "Отчет сохранен и запрос на предложение поездки отправлен в туристическое агентство",
    "lit": "Ataskaita išsaugota ir užklausa dėl kelionės pasiūlymo išsiųsta kelionių agentūrai",
    "fin": "Raportti tallennettu ja matkatarjouspyyntö lähetetty matkatoimistolle"
   },
   "travelOffer": {
    "eng": "Travel offer",
    "lav": "Ceļojuma piedāvājums",
    "est": "Reisipakkumine",
    "pol": "Oferta podróży",
    "rus": "Предложение по путешествию",
    "lit": "Kelionės pasiūlymas",
    "fin": "Matkatarjous"
   },
   "submitAnyway": {
    "eng": "Submit anyway",
    "lav": "Iesniegt tik un tā",
    "est": "Esitage ikkagi",
    "pol": "Zatwierdź mimo wszystko",
    "rus": "Отправить в любом случае",
    "lit": "Pateikti vis tiek",
    "fin": "Lähetä silti"
   },
   "carRegCardMissing": {
    "eng": "Vehicle registration certificate is missing",
    "lav": "Nav pievienota transportlīdzekļa reģistrācijas apliecība",
    "est": "Sõiduki registreerimistunnistus puudub",
    "pol": "Brak dowodu rejestracyjnego pojazdu",
    "rus": "Отсутствует свидетельство о регистрации транспортного средства",
    "lit": "Trūksta transporto priemonės registracijos liudijimo",
    "fin": "Ajoneuvon rekisteritodistus puuttuu"
   },
   "manageApporovers": {
    "eng": "Manage approvers",
    "lav": "Rediģēt lēmumu pieņēmējus",
    "pol": "Zarządzaj osobami zatwierdzającymi",
    "rus": "Управление утверждающими лицами",
    "est": "Manageeri kinnitajaid",
    "lit": "Tvarkyti tvirtintojus",
    "fin": "Hallinnoi hyväksyjiä"
   },
   "targetSum": {
    "eng": "Sum",
    "lav": "Piemērojamā summa",
    "pol": "Obowiązująca kwota",
    "rus": "Утверждаемая сумма",
    "lit": "Tikslinė suma",
    "est": "Summa",
    "fin": "Tavoite määrä"
   },
   "targetSumDescr": {
    "eng": "Approval rules apply for documents and reports between the amounts indicated below",
    "lav": "Darbību shēma attiecas tikai uz izdevumiem zemāk norādītajā intervālā",
    "pol": "Zasady zatwierdzania zostaną zastosowane, jeśli suma wydatków będzie wyższa (minimalna) lub niższa (maksymalna) niż określona kwota.",
    "rus": "Правила утверждения будут применяться, если сумма выше (минимум) или ниже (максимум) указанной суммы.",
    "lit": "Patvirtinimo taisyklės taikomos dokumentams ir ataskaitoms tarp nurodytų sumų",
    "est": "Kinnitusringi reeglid kehtivad dokumentidele ja aruannetele, mille summad jäävad allpool indikeeritud vahemikku.",
    "fin": "Hyväksymissääntöjä sovelletaan asiakirjoihin ja raportteihin alla ilmoitettujen arvojen välillä"
   },
   "targetMinimumSum": {
    "eng": "Target minimum sum",
    "lav": "Piemērojamā minimālā summa",
    "pol": "Minimalna kwota",
    "rus": "Минимальная сумма для утверждения",
    "lit": "Apatinė suma",
    "est": "Minimaalne summa",
    "fin": "Tavoiteltu vähimmäismäärä"
   },
   "targetMaximumSum": {
    "eng": "Target maximum sum",
    "lav": "Piemērojamā maksimālā summa",
    "pol": "Maksymalna kwota",
    "rus": "Максимальная сумма для утверждения",
    "lit": "Viršutinė suma",
    "est": "Maksimaalne summa",
    "fin": "Tavoiteltu enimmäismäärä"
   },
   "targetExpenseTypes": {
    "eng": "Expense types",
    "lav": "Piemērojamie izdevumu veidi",
    "pol": "Obowiązuje w wybranych rodzajach kosztów",
    "rus": "Типы расходов для утверждения",
    "lit": "Tiksliniai išlaidų tipai",
    "est": "Kululiigid",
    "fin": "Kohdistetut kustannustyypit"
   },
   "targetExpenseTypesDescr": {
    "eng": "Approval rules will be applied only to single documents with the selected expense types. Doesn't affect documents inside reports.",
    "lav": "Darbību shēma attieksies tikai uz izmaksu dokumentiem ar atlasītajiem izmaksu veidiem. Neattiecas uz dokumentiem, kas pievienoti pārskatiem.",
    "pol": "Reguły zatwierdzania będą stosowane tylko do pojedynczych dokumentów z wybranymi rodzajami kosztów.",
    "rus": "Правила утверждения будут применяться только к отдельным документам с выбранными типами расходов. Не влияет на документы внутри отчетов.",
    "est": "Kinnituse reeglid rakendatakse ainult üksikutele dokumentidele, millel on määratud kululiik.",
    "lit": "Tvirtinimo taisyklės bus taikomos tik pavieniams dokumentams su pasirinktais išlaidų tipais.",
    "fin": "Hyväksymissääntöjä sovelletaan vain yksittäisiin asiakirjoihin valituilla kulutyypeillä."
   },
   "spendingLimit": {
    "eng": "Spending limit",
    "lav": "Izdevumu limits",
    "pol": "Limit wydatków",
    "rus": "Лимит расходов",
    "est": "Kulutuste limiit",
    "lit": "Išlaidų limitas",
    "fin": "Kulutusraja"
   },
   "monthlySpendingLimit": {
    "eng": "Monthly spending limit",
    "lav": "Mēneša izdevumu limits",
    "pol": "Miesięczny limit wydatków",
    "rus": "Ежемесячный лимит расходов",
    "est": "Kuine kulutuste limiit",
    "lit": "Mėnesio išlaidų limitas",
    "fin": "Kuukauden kulutusraja"
   },
   "quarterlySpendingLimit": {
    "eng": "Quarterly spending limit",
    "lav": "Ceturkšņa izdevumu limits",
    "pol": "Kwartalny limit wydatków",
    "rus": "Ежеквартальный лимит расходов",
    "est": "Kvartali kulutuste limiit",
    "lit": "Ketvirčio išlaidų limitas",
    "fin": "Kvartaali kulutusraja"
   },
   "yearlySpendingLimit": {
    "eng": "Yearly spending limit",
    "lav": "Gada izdevumu limits",
    "pol": "Roczny limit wydatków",
    "rus": "Ежегодный лимит расходов",
    "est": "Aastane kulutuste limiit",
    "lit": "Metinis išlaidų limitas",
    "fin": "Vuosittainen kulutusraja"
   },
   "spendingLimitDescr": {
    "eng": "Submitter's expenses over the limit will be auto-rejected",
    "lav": "Lietotāja iesniegtie izdevumu dokumenti virs limita tiks automātiski noraidīti",
    "pol": "Wydatki powyżej limitu będą odrzucane automatycznie",
    "rus": "Расходы подателя, превышающие лимит, будут автоматически отклонены",
    "est": "Dokumendi sisestaja kulutused üle limiidi lükatakse automaatselt tagasi",
    "lit": "Pateikėjo išlaidos virš limito bus automatiškai atmestos",
    "fin": "Rajan ylittävät lähettäjän kulut hylätään automaattisesti"
   },
   "requireAccountantConfirm": {
    "eng": "Require administrator confirmation",
    "lav": "Sūtīt tikai pēc administratora apstiprinājuma",
    "pol": "Wysyłaj tylko po zatwierdzeniu przez księgowego",
    "rus": "Требовать подтверждения администратора",
    "est": "Nõua administraatori kinnitust",
    "lit": "Reikalauti administratoriaus patvirtinimo",
    "fin": "Vaadi järjestelmänvalvojan vahvistus"
   },
   "requireAccountantConfirmDesc": {
    "eng": "Prevents the expense from being sent to accounting by submitter or by automation",
    "lav": "Nepieļauj vienkāršo lietotāju un sistēmas automatizācijas tiešu dokumentu sūtīšanu uz grāmatvedības programmatūru",
    "pol": "Nie pozwala zwykłym użytkownikom i automatyzacji systemu na przesłanie dokumentu do programu księgowego",
    "rus": "Предотвращает отправку документа в бухгалтерию по инициативе подателя или автоматики",
    "lit": "Užkerta kelią išlaidų išsiuntimui į apskaitą pagal teikėją arba automatizuotai",
    "est": "Takistab kulude edastamist raamatupidamistarkvarasse dokumendi esitaja või automatsiooni poolt",
    "fin": "Estää kulujen lähettämisen kirjanpitoon lähettäjän tai automaation kautta"
   },
   "sendingDuplicate": {
    "eng": "Confirm sending duplicate document(s)",
    "lav": "Sūtīt dokumentu/-s, kas marķēts kā dublikāts",
    "rus": "Подтвердите отправку дубликата",
    "pol": "Potwierdź przesłanie duplikatu",
    "est": "Kas oled kindel, et soovid topelt dokumenti edastada?",
    "lit": "Patvirtinti dublikato siuntimą",
    "fin": "Vahvista asiakirjan kaksoiskappaleen lähettäminen"
   },
   "generalSettings": {
    "eng": "General settings",
    "lav": "Vispārīgie iestatījumi",
    "lit": "Bendrieji nustatymai",
    "est": "Üldised seaded",
    "rus": "Общие настройки",
    "pol": "Ustawienia ogólne",
    "fin": "Yleiset asetukset"
   },
   "default": {
    "eng": "Default",
    "lav": "Noklusējuma",
    "lit": "Numatytas",
    "est": "Vaikimisi",
    "rus": "По умолчанию",
    "pol": "Domyślny",
    "fin": "Oletus"
   },
   "setUpAutomatedWorkflow": {
    "eng": "Set up automated workflow",
    "lav": "Automātiskais lēmums",
    "lit": "Nustatyti automatizuotą eigą",
    "est": "Seadista automatiseeritud töövoog",
    "rus": "Настройте автоматизированный сценарий",
    "pol": "Ustaw automatyczny przepływ pracy",
    "fin": "Määritä automaattinen työnkulku"
   },
   "from": {
    "eng": "From",
    "lav": "No",
    "lit": "Nuo",
    "est": "Alates",
    "rus": "От",
    "pol": "Od",
    "fin": "Alkaen"
   },
   "to": {
    "eng": "To",
    "lav": "Līdz",
    "lit": "Iki",
    "est": "Kuni",
    "rus": "До",
    "pol": "Do",
    "fin": "Saakka"
   },
   "needAdminRightsToConfirm": {
    "eng": "You  need admin rights to confirm the integration",
    "lav": "Jums ir nepieciešamas administratora tiesības, lai apstiprinātu integrāciju",
    "est": "Liidestuse kinnitamiseks on vaja administraatori õiguseid",
    "rus": "Вам необходимы права администратора для подтверждения интеграции",
    "pol": "Aby potwierdzić integrację, potrzebujesz uprawnień administratora",
    "lit": "Administratoriaus teisės privalomos norint patvirtinti integraciją",
    "fin": "Tarvitset järjestelmänvalvojan oikeudet integroinnin vahvistamiseen"
   },
   "horizonInvoiceDocType": {
    "eng": "Invoice document type",
    "rus": "Тип документа счета",
    "lav": "Rēķina dokumenta veids",
    "est": "Arve dokumendi tüüp",
    "lit": "Sąskaitų tipai",
    "pol": "Rodzaj dokumentu faktury",
    "fin": "Laskutositteen tyyppi"
   },
   "horizonSelectDocType": {
    "eng": "Select invoice document type",
    "pol": "Wybierz typ dokumentu faktury",
    "rus": "Выберите тип документа счета",
    "lav": "Izvēlieties rēķina dokumenta veidu",
    "est": "Vali arve dokumendi tüüp",
    "lit": "Parinkite sąskaitų tipus",
    "fin": "Valitse laskutositteen tyyppi"
   },
   "pleaseCheckData": {
    "eng": "Something went wrong. Please check inserted data.",
    "lav": "Pieprasījums neizdevās. Lūdzu, pārbaudiet ievadīto datu pareizību!",
    "pol": "Coś poszło nie tak. Sprawdź wprowadzone dane.",
    "rus": "Что-то пошло не так. Пожалуйста, проверьте введенные данные.",
    "est": "Midagi läks valesti. Palun kontrolli sisestatud andmeid.",
    "lit": "Kažkas nepavyko. Patikrinkite įvestus duomenis.",
    "fin": "Jotain meni pieleen. Tarkista lisätyt tiedot."
   },
   "creditCardReports": {
    "eng": "Credit card reports",
    "lav": "Kredītkartes maksājumu atskaites",
    "pol": "Raporty z kart kredytowych",
    "rus": "Отчеты по кредитным картам",
    "est": "Krediitkaardi aruanne",
    "lit": "Kreditinės kortelės ataskaitos",
    "fin": "Luottokorttiraportit"
   },
   "profile": {
    "eng": "Profile",
    "lav": "Profils",
    "rus": "Профиль",
    "est": "Profiil",
    "pol": "Profil",
    "lit": "Profilis",
    "fin": "Profiili"
   },
   "company": {
    "eng": "Company",
    "lav": "Uzņēmums",
    "rus": "Компания",
    "est": "Ettevõte",
    "pol": "Firma",
    "lit": "Kompanija",
    "fin": "Yritys"
   },
   "billingInfo": {
    "eng": "Billing",
    "lav": "Maksājumi",
    "rus": "Оплата",
    "pol": "Płatności",
    "est": "Arveldamine",
    "lit": "Mokėjimai",
    "fin": "Laskutus"
   },
   "accountingSoftware": {
    "eng": "Accounting software integration",
    "lav": "Grāmatvedības sistēmas integrācija",
    "rus": "Бухгалтерское программное обеспечение",
    "est": "Raamatupidamistarkvara",
    "pol": "Oprogramowanie księgowe",
    "lit": "Apskaitos programos integracija",
    "fin": "Kirjanpitoohjelmistojen integrointi"
   },
   "accountingConfig": {
    "eng": "Accounting configuration",
    "lav": "Grāmatvedības iestatījumi",
    "rus": "Бухгалтерская конфигурация",
    "est": "Raamatupidamise seadistamine",
    "pol": "Ustawienia księgowości",
    "lit": "Apskaitos nustatymai",
    "fin": "Kirjanpitoasetukset"
   },
   "integrationsAndServices": {
    "eng": "Other services",
    "lav": "Citi pakalpojumi",
    "rus": "Прочие услуги",
    "pol": "Pozostałe usługi",
    "est": "Muud teenused",
    "lit": "Kitos paslaugos",
    "fin": "Muut palvelut"
   },
   "digitisationPlan": {
    "eng": "Digitisation plan",
    "lav": "Pakalpojumu plāns",
    "rus": "План оцифровки",
    "pol": "Plan cenowy",
    "est": "Digiteerimise plaan",
    "lit": "Skaitmeninimo planas",
    "fin": "Digitalisoinnin suunnitelma"
   },
   "billedCompany": {
    "eng": "Billed company",
    "lav": "Rēķina saņēmējs",
    "rus": "Компания, которая будет оплачивать услугу CostPocket",
    "pol": "Firma, na którą zostanie wystawiona faktura za usługę",
    "est": "Arve saaja",
    "lit": "Kompanija, kuriai išrašoma sąskaita",
    "fin": "Laskutettu yritys"
   },
   "addEmail": {
    "eng": "Add email",
    "lav": "Pievienot epastu",
    "rus": "Добавить электронную почту",
    "est": "Lisa e-mail",
    "pol": "Dodaj e-mail",
    "lit": "Pridėti el. paštą",
    "fin": "Lisää sähköposti"
   },
   "dataSentToAccounting": {
    "eng": "Data sent to accounting",
    "lav": "Grāmatvedības datu iestatījumi",
    "rus": "Данные, отправляемые в бухгалтерию",
    "est": "Andmed saadatud raamatupidamisse",
    "pol": "Dane przesłane do księgowości",
    "lit": "Duomenys siunčiami į apskaitą",
    "fin": "Tiedot lähetetään kirjanpitoon"
   },
   "configureInput": {
    "eng": "Document submission steps",
    "lav": "Iesniedzējam vaicātie dati",
    "rus": "Настройте параметры ввода подающего лица",
    "est": "Dokumendi esitamise sammud",
    "pol": "Skonfiguruj dane wymagane od przesyłającego dokument",
    "lit": "Konfigūruoti išlaidų pateikimo žingsnius",
    "fin": "Asiakirjan toimittamisen vaiheet"
   },
   "turnOffToClaimVAT": {
    "eng": "Turn off to claim foreign VAT back",
    "lav": "Izslēdziet, ja nevēlaties ārzemju PVN atmaksu",
    "rus": "Выключить для возврата иностранного НДС",
    "est": "Lülita välja, et saada välismaa KM tagastust.",
    "pol": "Wyłącz, aby ubiegać się o zwrot zagranicznego podatku VAT",
    "lit": "Išjungti norint susigrąžinti užsienyje sumokėtą PVM",
    "fin": "Poista käytöstä, jos haluat hakea ulkomaisen arvonlisäveron takaisin"
   },
   "sendUserToSoftware": {
    "eng": "Send submitter name to software",
    "lav": "Sūtīt iesniedzēja vārdu",
    "rus": "Отправка имени подателя в бухгалтерскую программу",
    "est": "Saada dokumendi sisestaja nimi raamatupidamistarkvarasse",
    "pol": "Wysyłaj nazwę użytkownika do oprogramowania",
    "lit": "Siųsti teikėjo vardą į sistemą",
    "fin": "Lähetä lähettäjän nimi ohjelmistoon"
   },
   "sendUserNameDescr": {
    "eng": "Turn off if submitter data is not needed, e.g. accountant submits the documents",
    "lav": "Izslēdziet, ja iesniedzēja datus nav nepieciešams sūtīt uz grāmatvedības programmatūru, piemēram, ja dokumentus iesniedz grāmatvedis",
    "rus": "Выключите, если данные отправителя не нужны, например, если бухгалтер подает документы",
    "est": "Lülita välja, kui dokumendi sisestaja andmed ei ole vajalikud näiteks: raamatupidaja sisestab kuludokumente",
    "pol": "Wyłącz, jeśli dane przesyłającego nie są potrzebne, np. kiedy księgowy składa dokumenty",
    "lit": "Išjunkite, jei pateikėjo duomenys nėra svarbūs, pvz. apskaitininkas pateikia dokumentus",
    "fin": "Kytke pois päältä, jos lähettäjän tietoja ei tarvita. Esim. kirjanpitäjä toimittaa asiakirjat"
   },
   "forwardingRules": {
    "eng": "Forwarding rules",
    "lav": "Pārsūtīšanas nosacījumi",
    "rus": "Условия пересылки",
    "est": "Edastamise reeglid",
    "pol": "Zasady przesyłania",
    "lit": "Persiuntimo taisyklės",
    "fin": "Edelleenlähetyksen säännöt"
   },
   "singleDocsAndReports": {
    "eng": "Single documents & reports",
    "lav": "Dokumenti un atskaites",
    "rus": "Единичные документы и отчеты",
    "est": "Üksikud dokumendid ja aruanded",
    "pol": "Pojedyncze dokumenty & raporty",
    "lit": "Pavieniai dokumentai ir ataskaitos",
    "fin": "Yksittäiset asiakirjat ja raportit"
   },
   "mileageRateAndLimit": {
    "eng": "Mileage rate and limit",
    "lav": "Kilometrāžas likme un limits",
    "rus": "Коэффициент и лимит пробега",
    "pol": "Stawka i limit kilometrów",
    "est": "Läbisõidumäär ja limiit",
    "lit": "Kilometražo tarifas ir limitas",
    "fin": "Kilometrimäärä ja raja"
   },
   "skipAllInput": {
    "eng": "Skip all input",
    "lav": "Izlaist visu",
    "rus": "Пропустить все запросы",
    "est": "Jäta kõik vahele",
    "pol": "Pomiń wszystko",
    "lit": "Praleisti visas užklausas",
    "fin": "Ohita kaikki tiedot"
   },
   "defaultExpenseTypes": {
    "eng": "Default expense types",
    "lav": "Izmaksu veidi pēc noklusējuma",
    "rus": "Типы расходов по умолчанию",
    "est": "Vaikimisi kululiigid",
    "pol": "Domyślne typy wydatków",
    "lit": "Standartiniai išlaidų tipai",
    "fin": "Oletuskustannustyypit"
   },
   "exploreTutorials": {
    "eng": "Explore tutorials",
    "lav": "Kā lietot",
    "rus": "Посмотреть инструкции",
    "est": "Vaata juhendeid",
    "pol": "Przeglądaj tutoriale",
    "lit": "Naršyti instrukcijas",
    "fin": "Tutustu ohjeisiin"
   },
   "getHelpGiveFeedback": {
    "eng": "Get help or give feedback",
    "lav": "Saņem palīdzību vai izsaki viedokli",
    "rus": "Получить помощь или оставить отзыв",
    "est": "Saa abi või anna tagasisidet",
    "pol": "Uzyskaj pomoc lub podziel się opinią",
    "lit": "Gaukite pagalbos arba pateikite atsiliepimą",
    "fin": "Pyydä apua tai anna palautetta"
   },
   "writeMessage": {
    "eng": "Write us a message",
    "lav": "Raksti savu ziņu šeit",
    "rus": "Пишите сообщение здесь",
    "est": "Kirjuta meile sõnum",
    "pol": "Napisz do nas",
    "lit": "Rašykite mums",
    "fin": "Kirjoita meille viesti"
   },
   "sendMessage": {
    "eng": "Send message",
    "lav": "Sūtīt ziņojumu",
    "rus": "Отправить сообщение",
    "est": "Saada sõnum",
    "pol": "Wyślij wiadomość",
    "lit": "Siųsti žinutę",
    "fin": "Lähetä viesti"
   },
   "website": {
    "eng": "Website",
    "lav": "Tīmekļa vietne",
    "rus": "Сайт",
    "est": "Kodulehekülg",
    "pol": "Strona internetowa",
    "lit": "Interneto svetainė",
    "fin": "Verkkosivu"
   },
   "legal": {
    "eng": "Legal",
    "lav": "Noteikumi",
    "rus": "Правила",
    "est": "Juriidiline",
    "pol": "Informacje prawne",
    "lit": "Teisinė info",
    "fin": "Laillinen"
   },
   "grants": {
    "eng": "Grants",
    "lav": "Granti",
    "rus": "Гранты",
    "est": "Toetused",
    "pol": "Dotacje",
    "lit": "Subsidijos",
    "fin": "Apurahat"
   },
   "unspecified": {
    "eng": "Unspecified",
    "lav": "Nav norādīts",
    "rus": "Неуказанный",
    "est": "Määramata",
    "pol": "Nieokreślony",
    "lit": "Nenurodyta",
    "fin": "Määrittelemätön"
   },
   "claimForeignVAT": {
    "eng": "Claim foreign VAT",
    "lav": "Atgūt ārvalstu PVN",
    "rus": "Заявлять иностранный НДС",
    "est": "Taotle tagasi välisriigi käibemaks",
    "pol": "Ubiegaj się o zagraniczny podatek VAT",
    "lit": "Susigrąžinti užsienyje sumokėtą PVM",
    "fin": "Lunasta ulkomainen ALV"
   },
   "reportForeignVATSeparately": {
    "eng": "Report foreign VAT amount separately",
    "lav": "Deklarēt ārzemju PVN summas atsevisķi",
    "rus": "Отдельно указывать сумму иностранного НДС",
    "est": "Digiteerime välisriigi KM kuludokumentidelt",
    "pol": "Zgłaszaj zagraniczny podatek VAT oddzielnie",
    "lit": "Atskirai deklaruoti užsienio sąskaitų PVM",
    "fin": "Ilmoita ulkomaisen arvonlisäveron määrä erikseen"
   },
   "enableInvoiceLines": {
    "eng": "Item line digitisation",
    "est": "Luba arve ridade digiteerimine",
    "lav": "Preču rindu digitalizēšana",
    "rus": "Включить оцифровку строк счетов",
    "pol": "Włącz digitalizację wierszy faktury",
    "lit": "Įjungti eilučių skaitmeninimą PDF formato sąskaitoms",
    "fin": "Ota laskurivien digitointi käyttöön"
   },
   "defaultPaymentMethods": {
    "eng": "Default payment methods",
    "lav": "Noklusējuma maksājumu metodes",
    "rus": "Способы оплаты по умолчанию",
    "pol": "Domyślne metody płatności",
    "est": "Vaikimisi makse meetodid",
    "fin": "Oletusmaksutavat"
   },
   "insertValidAPI": {
    "eng": "Invalid API name",
    "lav": "Nepareizs API nosaukums",
    "est": "Kehtetu API nimi",
    "pol": "Nieprawidłowa nazwa API",
    "lit": "Neteisingas API pavadinimas",
    "fin": "Virheellinen API-nimi",
    "rus": "Неверное название API"
   },
   "passwordOrUserDoesNotMatch": {
    "eng": "Username or password does not match",
    "lav": "Nesakrīt API lietotājvārds vai parole",
    "est": "Kasutajanimi või parool ei ühti",
    "pol": "Nazwa użytkownika lub hasło nie jest poprawne",
    "fin": "Käyttäjätunnus tai salasana ei täsmää",
    "rus": "Имя пользователя или пароль не совпадает"
   },
   "selectMonth": {
    "eng": "Select month",
    "lav": "Mēnesis",
    "rus": "Выберите месяц",
    "est": "Vali kuu",
    "pol": "Wybierz miesiąc",
    "lit": "Pasirinkite mėnesį",
    "fin": "Valitse kuukausi"
   },
   "debitCardReport": {
    "eng": "Debit card report",
    "lav": "Debetkaršu pārskats",
    "rus": "Отчет по дебетовым картам",
    "est": "Deebetkaardi aruanne",
    "pol": "Raport z karty debetowej",
    "lit": "Debitinės kortelės ataskaita",
    "fin": "Pankkikorttiraportti"
   },
   "paymentCardReport": {
    "eng": "Payment card report",
    "lav": "Kartes maksājumu atskaite",
    "rus": "Отчет по платежным картам",
    "pol": "Raport z karty płatniczej",
    "lit": "Mokėjimo kortelės ataskaita",
    "est": "Pangakaardi aruanne",
    "fin": "Maksukorttiraportti"
   },
   "enableApprovalRounds": {
    "eng": "Enable approval rounds",
    "lav": "Ieslēgt izdevumu apstiprināšanu",
    "rus": "Включить процедуру согласования",
    "lit": "Įjungti tvirtinimo procesą",
    "pol": "Aktywuj zatwierdzanie wydatków",
    "est": "Aktiveeri kinnitusring",
    "fin": "Ota hyväksyntäkierrokset käyttöön"
   },
   "enableApprovalsWarning": {
    "eng": "Workflows were set up but the process was not enabled yet",
    "lav": "Apstiprināšanas funckija vēl nav ieslēgta, lai gan ir izveidota/-s darbību shēmas",
    "rus": "Рабочие процессы настроены, но процесс еще не включен",
    "lit": "Tvirtinimo eigos yra nustatytos, tačiau procesas dar nėra įjungtas",
    "pol": "Proces zatwierdzania został skonfigurowany, ale nie jest jeszcze aktywowany",
    "est": "Töövoog oli seadistatud, aga protsess ei olnud veel aktiveeritud",
    "fin": "Työnkulku määritelty, mutta prosessia ei ole otettu vielä käyttöön"
   },
   "otherOptions": {
    "eng": "Other options",
    "lav": "Citas iespējas",
    "rus": "Другие опции",
    "lit": "Kiti pasirinkimai",
    "est": "Muud võimalused",
    "fin": "Muut vaihtoehdot",
    "pol": "Inne opcje"
   },
   "commentAndApprove": {
    "eng": "Add comment and approve",
    "lav": "Pievienot komentāru un apstiprināt",
    "rus": "Добавить комментарий и утвердить",
    "lit": "Patvirtinti su komentaru",
    "est": "Lisa kommentaar ja kinnita",
    "fin": "Lisää kommentti ja hyväksy",
    "pol": "Dodaj komentarz i zatwierdź"
   },
   "horizonApiUrl": {
    "eng": "Horizon API url",
    "lav": "Horizon API saite",
    "rus": "URL-адрес Horizon API",
    "est": "Horizon API url",
    "fin": "Horizon API url",
    "lit": "Horizon API adresas",
    "pol": "Horizon API ulr"
   },
   "sepcifyOneOfOptions": {
    "eng": "Specify one of the options below",
    "lav": "Aizpildiet vienu no zemāk esošajiem laukiem",
    "fin": "Määritä yksi alla olevista vaihtoehdoista",
    "rus": "Укажите один из приведенных ниже вариантов",
    "pol": "Wybierz jedną z poniższych opcji",
    "est": "Määrake üks allolevatest valikutest",
    "lit": "Nurodykite vieną iš parinkčių žemiau"
   },
   "invalidApiUrl": {
    "eng": "Invalid API url",
    "lav": "Nepareiza API saite",
    "fin": "Virheellinen API url",
    "rus": "Недопустимый URL-адрес API",
    "pol": "Nieprawidłowy adres API",
    "est": "Kehtetu API URL",
    "lit": "Netinkamas API adresas"
   },
   "freeMealsWereIncluded": {
    "eng": "Food provided",
    "lav": "Apmaksātas ēdienreizes",
    "fin": "Tarjottu ruokaa",
    "rus": "Обеспечение питанием",
    "pol": "Zapewnione wyżywienie",
    "est": "Tasuta toitlustamine",
    "lit": "Maitinimas apmokėtas"
   },
   "setForEveryDay": {
    "eng": "Set for every day",
    "lav": "Atzīmēt visām dienām",
    "rus": "Установить на каждый день",
    "fin": "Aseta joka päivälle",
    "pol": "Ustaw na każdy dzień",
    "est": "Määrake igaks päevaks",
    "lit": "Nustatyti kiekvienai dienai"
   },
   "accessRequestSent": {
    "eng": "A request to join the company has been sent to company administrators. You will be notified if the administrator provides you access.",
    "lav": "Organizācijas administrācijai ir nosūtīts pieprasījums pievienoties. Apstiprinājuma gadījumā Jūs tiksiet informēts/-a.",
    "pol": "Prośba o dołączenie do firmy została wysłana do administratorów firmy. Zostaniesz powiadomiony, jeśli administrator zapewni Ci dostęp do firmy.",
    "est": "Teie liitumise soov on edastatud ettevõtte administraatorile. Kui admin on teie juurdepääsu kinnitanud, siis teile saadetakse e-mailile teavitus.",
    "rus": "Запрос на присоединение к компании был отправлен администраторам компании. Вы будете уведомлены, если администратор предоставит Вам доступ к компании.",
    "fin": "Pyyntö liittyä yritykseen on lähetetty yrityksen ylläpitäjille. Saat ilmoituksen järjestelmänvalvojan hyväksyttyä pyyntösi.",
    "lit": "Prašymas prisijungti prie įmonės išsiųstas įmonės administratoriams. Jums bus pranešta, kai administratorius suteiks jums prieigą."
   },
   "emailAlreadyAdded": {
    "eng": "$email is already added",
    "lav": "$email jau ir pievienots",
    "pol": "$email został już dodany",
    "est": "$email on juba lisatud",
    "rus": "$email уже добавлен",
    "fin": "$email on jo lisätty",
    "lit": "$email jau pridėtas"
   },
   "newVersionWarning": {
    "eng": "A new update is available. Please reload the page to use the latest version!",
    "lav": "Ir veikts lapas atjauninājums. Lūdzam pārlādēt lapu, lai lietotu jaunāko versiju!",
    "pol": "Dostępna jest nowa aktualizacja. Załaduj ponownie stronę, aby skorzystać z najnowszej wersji!",
    "est": "Uus värskendus on saadaval. Uusima versiooni kasutamiseks laadige leht uuesti!",
    "rus": "Доступно новое обновление. Пожалуйста, перезагрузите страницу, чтобы использовать последнюю версию!",
    "fin": "Uusi päivitys on saatavilla. Lataa sivu uudelleen käyttääksesi uusinta versiota!",
    "lit": "Atnaujinimas. Perkraukite puslapį ir naudokite naujausią versiją!"
   },
   "pastInvoices": {
    "eng": "Past invoices",
    "lav": "Rēķinu vēsture",
    "pol": "Dotychczasowe faktury",
    "est": "Varasemad arved",
    "rus": "Прошлые счета",
    "fin": "Aiemmat laskut",
    "lit": "Ankstesnės sąskaitos"
   },
   "selectPaymentCard": {
    "eng": "Select payment card",
    "lav": "Maksājumu karte",
    "pol": "Wybierz kartę płatniczą",
    "est": "Valige maksekaart",
    "rus": "Выберите платежную карту",
    "fin": "Valitse maksukortti",
    "lit": "Parinkite mokėjimo kortelę"
   },
   "selectUserForReport": {
    "eng": "Select user",
    "lav": "Lietotājs",
    "pol": "Wybierz użytkownika",
    "est": "Valige kasutaja",
    "rus": "Выберите пользователя",
    "fin": "Valitse käyttäjä",
    "lit": "Parinkite vartotoją"
   },
   "doYouWantToResubmit": {
    "eng": "This document is already forwarded to accounting software. Do you want to submit it again?",
    "lav": "Šis dokuments jau ir nosūtīts uz grāmatvedības programmatūru. Vai vēlaties to iesniegt vēlreiz?",
    "pol": "Ten dokument został już przesłany do programu księgowego. Czy chcesz przesłać go ponownie?",
    "est": "See dokument on juba raamatupidamistarkvarasse edastatud. Kas soovite selle uuesti esitada?",
    "rus": "Этот документ уже отправлен в бухгалтерскую программу. Хотите отправить его еще раз?",
    "fin": "Tämä asiakirja on jo välitetty kirjanpito-ohjelmistoon. Haluatko lähettää sen uudelleen?",
    "lit": "Šis dokumentas jau perduotas į apskaitos programą. Ar norite jį pateikti dar kartą?"
   },
   "allowanceNotSpecified": {
    "eng": "You have not specified daily allowance. Do you still want to submit the report?",
    "lav": "Nav norādīta dienas naudas summa. Vai vēlaties iesniegt atskaiti bez tās?",
    "est": "Te ei ole täpsustanud päevarahade suurust. Kas sa soovid endiselt aruannet esitada?",
    "rus": "Вы не указали суточные. Вы все еще хотите подать отчет?",
    "fin": "Et ole määrittänyt päivärahaa. Haluatko silti lähettää raportin?",
    "pol": "Nie określiłeś dziennej diety. Czy nadal chcesz przesłać raport?",
    "lit": "Nenurodėte dienpinigių. Ar vis dar norite pateikti ataskaitą?"
   },
   "addFilters": {
    "eng": "Add filters",
    "lav": "Pievienot filtrus",
    "est": "Lisage filtreid",
    "rus": "Добавить фильтры",
    "fin": "Lisää suodattimia",
    "pol": "Dodaj filtry",
    "lit": "Pridėti filtrus"
   },
   "autoSubmitEmailedDocs": {
    "eng": "Auto-submit documents sent by email",
    "lav": "Automātiski iesniegt dokumentus no epasta",
    "est": "E-posti teel saadetud dokumentide automaatne esitamine",
    "rus": "Автоподача документов, присланных по электронной почте",
    "fin": "Lähetä sähköpostilla lähetetyt asiakirjat automaattisesti",
    "pol": "Automatyczne przesyłanie dokumentów wysyłanych e-mailem",
    "lit": "Automatiškai pateikti dokumentus atsiųstus e. paštu"
   },
   "autoSubmitDescription": {
    "eng": "With auto-submit off, the submitter can add data and must submit documents manually",
    "lav": "Ja automātiskā iesniegšana ir izslēgta, lietotājs var pievienot datus pirms dokumenta iesniegšanas, kā arī dokumenta iesniegšana jāveic manuāli",
    "est": "Kui automaatne esitamine on välja lülitatud, saab esitaja andmeid lisada ja CostPocketis dokumendid käsitsi esitama",
    "rus": "При выключенной автоподаче пользователь может дополнить данные и должен подать документы после их пересылки по электронной почте",
    "fin": "Kun automaattinen lähetys on pois päältä, lähettäjä voi lisätä tietoja ja asiakirjat on lähetettävä manuaalisesti",
    "pol": "Jeśli automatyczne przesyłanie jest wyłączone, osoba przesyłająca może dodawać oraz edytować dane, po czym dokumenty muszą zostać ręcznie przesłane do programu księgowego",
    "lit": "Jei automatinis pateikimas išjungtas, dokumentai pridedami ir pateikiami rankiniu būdu"
   },
   "paymentMethodName": {
    "eng": "Payment method name (e.g., bank name)",
    "lav": "Maksājuma metodes nosaukums (piem., bankas nosaukums)",
    "fin": "Maksutavan nimi (esim. pankin nimi)\"",
    "rus": "Название метода оплаты (например, название банка)",
    "pol": "Nazwa metody płatności (np. nazwa banku)",
    "est": "Makseviisi nimi (nt. panga nimi)",
    "lit": "Mokėjimo būdo pavadinimas (pvz., banko pavadinimas)"
   },
   "paid": {
    "eng": "Paid",
    "lav": "Apmaksāts",
    "rus": "Оплачено",
    "fin": "Maksettu",
    "pol": "Opłacone",
    "est": "Makstud",
    "lit": "Apmokėta"
   },
   "targetCriteria": {
    "eng": "Document selection",
    "lav": "Apstiprināmo dokumentu atlases kritēriji",
    "rus": "Критерии утверждения документов",
    "fin": "Asiakirjan hyväksymiskriteerit",
    "pol": "Kryteria zatwierdzania dokumentów",
    "est": "Dokumendi valik",
    "lit": "Dokumentų atranka"
   },
   "addCriteria": {
    "eng": "Add rule",
    "lav": "Pievienot kritēriju",
    "rus": "Добавить критерий",
    "fin": "Lisää kriteeri",
    "pol": "Dodaj kryteria",
    "est": "Lisa reegel",
    "lit": "Pridėti taisyklę"
   },
   "automationRules": {
    "eng": "Automation rules",
    "lav": "Automatizācijas nosacījumi",
    "rus": "Правила автоматизации",
    "fin": "Automaation säännöt",
    "pol": "Zasady automatyzacji",
    "est": "Automatiseerimise reeglid",
    "lit": "Automatizavimo taisyklės"
   },
   "account": {
    "eng": "Account",
    "lav": "Konts",
    "pol": "Konto",
    "fin": "Tili",
    "est": "Konto",
    "lit": "Sąskaita",
    "rus": "Конт"
   },
   "dimension": {
    "eng": "Dimension",
    "lav": "Dimensija",
    "pol": "Wymiar",
    "fin": "Ulottuvuus",
    "est": "Dimensioon",
    "lit": "Dimensija",
    "rus": "Дименсия"
   },
   "itemCode": {
    "eng": "Item code",
    "lav": "Vienības kods",
    "pol": "Kod produktu",
    "fin": "Tuotekoodi",
    "est": "Artikkel",
    "lit": "Prekės kodas",
    "rus": "Код товара"
   },
   "costObjective": {
    "eng": "Cost object",
    "lav": "Objekts",
    "pol": "Obiekt kosztów",
    "fin": "Kululaji",
    "est": "Kuluobjekt",
    "lit": "Kaštų objektas",
    "rus": "Объект затрат"
   },
   "project": {
    "eng": "Project",
    "lav": "Projekts",
    "pol": "Projekt",
    "fin": "Projekti",
    "est": "Projekt",
    "lit": "Projektas",
    "rus": "Проект"
   },
   "VATCode": {
    "eng": "VAT code",
    "lav": "PVN kods",
    "pol": "Numer VAT",
    "fin": "ALV numero",
    "est": "KM kood",
    "lit": "PVM kodas",
    "rus": "Код НДС"
   },
   "engineCapacity": {
    "eng": "Vehicle engine capacity",
    "lav": "Transportlīdzekļa motora tilpums",
    "pol": "Pojemność silnika pojazdu",
    "fin": "Ajoneuvon moottorin kapasiteetti",
    "est": "Sõiduki mootori töömaht",
    "lit": "Variklio tūris",
    "rus": "Объем двигателя автомобиля"
   },
   "ownedByPerson": {
    "eng": "Personal",
    "lav": "Personīgais",
    "pol": "Osobiste",
    "fin": "Henkilökohtainen",
    "est": "Isiklik",
    "lit": "Privatus",
    "rus": "Личный"
   },
   "ownedByCompany": {
    "lav": "Uzņēmuma",
    "eng": "Company",
    "pol": "Firma",
    "fin": "Yritys",
    "est": "Ettevõte",
    "lit": "Kompanijos",
    "rus": "Компания"
   },
   "noInvoicesToDisplay": {
    "eng": "No invoices",
    "lav": "Nav rēķinu",
    "pol": "Brak dokumentów",
    "fin": "Ei laskuja",
    "est": "Arveid pole",
    "lit": "Nėra sąskaitų",
    "rus": "Нет счетов"
   },
   "salaryModelName": {
    "eng": "Salary model",
    "lav": "Algas modelis",
    "fin": "Palkkamalli",
    "lit": "Algos modelis",
    "pol": "Model wynagrodzeń",
    "est": "Palgamoodul",
    "rus": "Модель зарплаты"
   },
   "personalCodeIssuer": {
    "eng": "Personal code issuer country",
    "lav": "Personas koda izsniedzēja valsts",
    "lit": "Šalis, suteikusi asmens kodą",
    "fin": "Henkilötunnuksen myöntäjämaa",
    "pol": "Kraj, który wydał numer pesel",
    "est": "Isikukoodi väljastanud riik",
    "rus": "Страна, выдавшая персональный код"
   },
   "fileTooLarge": {
    "eng": "Uploaded file exceeded maximum size of $size MB",
    "lav": "Fails ir lielāks par maksimālo atļauto faila izmēru $size MB",
    "rus": "Загруженный файл превысил максимальный размер $size МБ",
    "lit": "Įkeltas failas viršijo maksimalų $size MB dydį",
    "fin": "Ladattu tiedosto ylitti enimmäiskoon $size MB",
    "pol": "Przesłany plik przekroczył maksymalny rozmiar $size MB",
    "est": "Üleslaaditud fail ületas maksimaalse suuruse $size MB"
   },
   "goToWebApp": {
    "eng": "Go to Web App",
    "lav": "Doties uz web lietotni",
    "est": "Minge veebirakendusse",
    "lit": "Eiti į Web programėlę",
    "fin": "Kirjaudu CostPocket sovellukseen",
    "rus": "Перейдите в веб-приложение",
    "pol": "Przejdź do aplikacji internetowej"
   },
   "approveDocsInApp": {
    "eng": "Pending expense documents await your approval. Take action on the Web App.",
    "lav": "Dokumenti gaida Jūsu apstiprinājumu. Apstipriniet tos web lietotnē.",
    "est": "Ootel kuludokumendid ootavad teie kinnitust. Seda toimingut saab teha veebirakenduses.",
    "lit": "Išlaidų dokumentai laukia jūsų patvirtinimo. Imkitės veiksmų Web programėlėje.",
    "fin": "Odottavat kuluasiakirjat odottavat hyväksyntääsi. Kirjaudu CostPocket sovellukseen.",
    "rus": "Документы по расходам ожидают вашего утверждения. Действуйте через веб-приложение.",
    "pol": "Oczekujące dokumenty dotyczące wydatków czekają na zatwierdzenie. Podejmij działania w aplikacji internetowej."
   },
   "editLines": {
    "eng": "Edit item lines",
    "lav": "Rediģēt rindas",
    "fin": "Muokkaa rivejä",
    "rus": "Редактировать строки",
    "pol": "Edytuj wiersze",
    "est": "Muuda ridu",
    "lit": "Keisti eilutes"
   },
   "showSumWithVAT": {
    "eng": "Show sum with VAT included",
    "lav": "Rindas summā iekļaut PVN",
    "fin": "Näytä summa sisältäen arvonlisäveron",
    "rus": "Показать сумму с учетом НДС",
    "pol": "Pokaż sumę z VAT",
    "est": "Näita summat koos käibemaksuga",
    "lit": "Rodyti sumą su PVM'u"
   },
   "statement": {
    "eng": "Bank statement",
    "lav": "Konta izraksts",
    "pol": "Wyciąg bankowy",
    "est": "Panga väljavõte",
    "rus": "Банковская выписка",
    "fin": "Tiliote",
    "lit": "Banko išrašas"
   },
   "extRef": {
    "eng": "External reference",
    "lav": "Ārējā atsauce",
    "est": "Väline viide",
    "rus": "Внешняя ссылка",
    "fin": "Ulkoinen viite",
    "lit": "Išorinė nuoroda",
    "pol": "Odniesienie zewnętrzne"
   },
   "psd2SwedBankProviderInfo": {
    "eng": "Select a bank from the list. If not found, it's not supported yet. Agree to service terms, then proceed to your bank's authorisation page to grant the card transaction history access to CostPocket.",
    "lit": "Pasirinkite banką. Jei nerandate, vadinasi, jis dar nepalaikomas. Sutikite su paslaugos sąlygomis, tada pereikite į banko autorizacijos puslapį, kad suteiktumėte 'CostPocket' prieigą prie kortelės operacijų istorijos.",
    "fin": "Valitse pankki luettelosta. Jos sitä ei löydy, se ei ole vielä tuettu. Hyväksy palvelun ehdot ja siirry sitten pankkisi valtuutussivulle myöntämään korttitapahtumahistorian käyttöoikeus CostPocketiin.",
    "lav": "Izvēlieties banku no saraksta. Ja banka nav atrasta, tātad tā vēl nav pieejama pakalpojumam. Dodiet piekrišanu pakalpojuma noteikumiem, pēc tam dodieties uz bankas autorizācijas lapu, lai piešķirtu piekļuvi kartes darījumu vēsturei CostPocket.",
    "est": "Valige nimekirjast pank. Kui soovitud panka ei ole nimekirjas, ei ole see veel toetatud. Nõustuge teenuse tingimustega, seejärel suunatakse Teid oma panka, et anda CostPocketile juurdepääs kaarditehingute ajaloole.",
    "rus": "Выберите банк из списка. Если банк не найден, значит, он еще не поддерживается. Согласитесь с условиями обслуживания, затем перейдите на страницу авторизации вашего банка, чтобы предоставить CostPocket доступ к истории операций по карте.",
    "pol": "Wybierz bank z listy. Jeśli nie zostanie znaleziony, nie jest jeszcze obsługiwany. Zaakceptuj warunki usługi, a następnie przejdź do strony autoryzacji swojego banku, aby przyznać CostPocket dostęp do historii transakcji kartą."
   },
   "psd2SwedBankAccountInfo": {
    "eng": "Manage connected bank accounts; their card transactions sync up to 4 times daily with CostPocket.",
    "lit": "Tvarkykite prijungtas banko sąskaitas; jų kortelių operacijos su 'CostPocket' sinchronizuojamos iki 4 kartų per dieną.",
    "fin": "Hallitse yhdistettyjä pankkitilejä; niiden korttitapahtumat synkronoidaan jopa 4 kertaa päivässä CostPocketiin.",
    "lav": "Pārvaldiet savienotos bankas kontus; to karšu darījumi tiek sinhronizēti ar CostPocket līdz 4 reizēm dienā.",
    "est": "Halda ühendatud pangakontosid; kaarditehinguid sünkroonitakse CostPocketiga kuni 4 korda päevas.",
    "rus": "Управляйте подключенными банковскими счетами; транзакции по их картам синхронизируются с CostPocket до 4 раз в день."
   },
   "psd2SwedBankStatementInfo": {
    "eng": "Your card transactions are imported to CostPocket from the beginning of current month. We will automatically import transactions to the statement once every 6 hours from your bank.",
    "lit": "Sinchronizuokite kortelių operacijas už tam tikrą laikotarpį. Jei laikotarpis ilgesnis nei 3 mėnesiai, reikia gauti banko sutikimą.",
    "fin": "Synkronoi korttitapahtumat tietyksi ajanjaksoksi. Yli 3 kuukauden jaksoille tarvitaan suostumus pankin kautta.",
    "lav": "Sinhronizēt karšu darījumus konkrētam periodam. Periodiem, kas ilgāki par 3 mēnešiem, ir nepieciešama piekrišana, ko sniedz jūsu banka.",
    "est": "Teie kaarditehingud imporditakse CostPocketisse jooksva kuu algusest. Uued tehingud imporditakse iga 6 tunni järel.",
    "rus": "Синхронизируйте операции по карте за определенный период. Для периодов более 3 месяцев необходимо согласие вашего банка.",
    "pol": "Transakcje kartą są importowane do CostPocket od początku bieżącego miesiąca. Transakcje będą automatycznie importowane z banku do wyciągu raz na 6 godzin."
   },
   "validAgreements": {
    "eng": "Valid agreements",
    "lit": "Galiojantys susitarimai",
    "fin": "Voimassa olevat sopimukset",
    "lav": "Derīgās vienošanās",
    "est": "Kehtivad lepingud",
    "rus": "Действительные соглашения",
    "pol": "Aktualne porozumienia"
   },
   "consentValidUntil": {
    "eng": "Consent valid until",
    "lit": "Sutikimas galioja iki",
    "fin": "Suostumus voimassa",
    "lav": "Piekrišana derīga līdz",
    "est": "Nõusolek kehtib kuni",
    "rus": "Согласие действительно до",
    "pol": "Zgoda ważna do"
   },
   "revokeConsent": {
    "eng": "Revoke consent",
    "lit": "Atšaukti sutikimą",
    "fin": "Peruuta suostumus",
    "lav": "Atcelt piekrišanu",
    "est": "Nõusoleku tühistamine",
    "rus": "Отозвать согласие",
    "pol": "Cofnij zgodę"
   },
   "agree": {
    "eng": "Agree",
    "lit": "Sutikti",
    "fin": "Hyväksy",
    "lav": "Piekrist",
    "est": "Kinnita",
    "rus": "Согласиться",
    "pol": "Zgadzam się"
   },
   "startDate": {
    "eng": "Start date",
    "lit": "Pradžios data",
    "fin": "Aloituspäivä",
    "lav": "Sākuma datums",
    "est": "Alguskuupäev",
    "rus": "Начальная дата",
    "pol": "Data rozpoczęcia"
   },
   "endDate": {
    "eng": "End date",
    "lit": "Galutinė data",
    "fin": "Loppupäivä",
    "lav": "Beigu datums",
    "est": "Lõppkuupäev",
    "rus": "Конечная дата",
    "pol": "Data końcowa"
   },
   "syncAccount": {
    "eng": "Import transactions",
    "lit": "Sinchronizuoti sąskaitą",
    "fin": "Synkronoi tili",
    "lav": "Sinhronizēt kontu",
    "est": "Tehingute importimine",
    "rus": "Синхронизировать банковский счет",
    "pol": "Zsynchronizuj konto"
   },
   "waitingForStatementToBeReady": {
    "eng": "Waiting for the account statement from the bank...",
    "fin": "Odotamme tiliotetta pankista...",
    "lit": "Laukiame sąskaitos išrašo iš banko...",
    "lav": "Gaida konta izrakstu no bankas...",
    "est": "Ootame pangast konto väljavõtet...",
    "rus": "В ожидании выписки по счету из банка...",
    "pol": "Czekam na wyciąg z konta z banku..."
   },
   "importedStatementSuccess": {
    "eng": "Successfully imported statement with $transactionCount transaction(s)",
    "lit": "Sėkmingai importuotas išrašas su $transactionCount sandoriu (-iais)",
    "fin": "Onnistuneesti tuotu tiliote, jossa on $transactionCount -tapahtuma(t).",
    "lav": "Veiksmīgi importēts izraksts ar $transactionCount darījumu(-u) skaitu",
    "est": "$transactionCount tehingu(te)ga väljavõte edukalt imporditud",
    "rus": "Успешно импортирована выписка с $transactionCount транзакцией(ями).",
    "pol": "Pomyślnie zaimportowano wyciąg z transakcjami $transactionCount"
   },
   "maxStatementPeriod": {
    "eng": "Max statement period is 730 days",
    "lit": "Maksimali išrašo trukmė yra 730 dienų",
    "fin": "Maksimi tilikausi on 730 päivää",
    "lav": "Maksimālais pārskata periods ir 730 dienas",
    "pol": "Maksymalny okres wyciągu to 730 dni",
    "est": "Maksimaalne väljavõtteperiood on 730 päeva",
    "rus": "Максимальный период выписки - 730 дней"
   },
   "transactionsOverview": {
    "eng": "Card transactions",
    "lav": "Kartes darījumi",
    "rus": "Обзор операций по карте",
    "fin": "Korttitapahtumat",
    "lit": "Kortelės pervedimai",
    "pol": "Transakcje kartą",
    "est": "Kaarditehingud"
   },
   "accountConnected": {
    "eng": "Bank account connected. Click to manage.",
    "lav": "Savienojums ar bankas kontu izvedots. Klikšķiniet, lai pārvaldītu savienojumu.",
    "rus": "Банковский счет подключен. Нажмите для управления.",
    "fin": "Pankkitili yhdistetty. Hallitse asetuksia nappia painamalla.",
    "lit": "Banko sąskaita prijungta. Spustelėkite valdyti nustatymus",
    "pol": "Konto bankowe połączone. Kliknij, aby zarządzać.",
    "est": "Pangakonto ühendatud. Klõpsake haldamiseks."
   },
   "importFromFile": {
    "eng": "Import from file",
    "lav": "Augšuplādēt no faila",
    "rus": "Импорт из файла",
    "fin": "Tuo tiedostosta",
    "lit": "Importuoti iš failo",
    "pol": "Importuj z pliku",
    "est": "Laadi üles failist"
   },
   "reportingInterval": {
    "eng": "Reporting interval",
    "lav": "Iesniegšanas biežums",
    "rus": "Интервал составления отчетов",
    "fin": "Raportointiväli",
    "lit": "Ataskaitų teikimo intervalas",
    "pol": "Częstotliwość raportowania",
    "est": "Aruande intervall"
   },
   "weekly": {
    "eng": "Weekly",
    "lav": "Reizi nedēļā",
    "rus": "Еженедельно",
    "fin": "Viikoittain",
    "lit": "Kas savaitę",
    "pol": "Tygodniowo",
    "est": "Iganädalane"
   },
   "monthly": {
    "eng": "Monthly",
    "lav": "Reizi mēnesī",
    "rus": "Ежемесячно",
    "fin": "Kuukausittain",
    "lit": "Kas mėnesį",
    "pol": "Miesięcznie",
    "est": "Igakuine"
   },
   "yearly": {
    "eng": "Yearly",
    "lav": "Reizi gadā",
    "rus": "Ежегодно",
    "fin": "Vuosittain",
    "lit": "Kas metus",
    "pol": "Rocznie",
    "est": "Aastane"
   },
   "connectToBank": {
    "eng": "Connect to bank",
    "lav": "Savienojums ar banku",
    "rus": "Подключиться к банку",
    "fin": "Yhdistä pankkiin",
    "lit": "Prijunkite prie banko",
    "pol": "Połącz się z bankiem",
    "est": "Ühenda pangaga"
   },
   "cardUnidentifiable": {
    "eng": "Unknown card",
    "lav": "Karte netika atpazīta",
    "rus": "Неизвестная карта",
    "fin": "Tuntematon kortti",
    "lit": "Neatpažinta kortelė",
    "pol": "Nieznana karta",
    "est": "Tundmatu pangakaart"
   },
   "withinSubmittedReport": {
    "eng": "Already reported",
    "lav": "Jau iesniegts",
    "rus": "Уже подано",
    "fin": "Raportoitu aiemmin",
    "lit": "Jau pateiktas",
    "pol": "Raport przesłany do programu księgowego",
    "est": "Aruanne saadetud raamatupidamisprogrammi"
   },
   "methodNotFound": {
    "eng": "Missing payment method",
    "lav": "Nav izveidota maksājumu metode",
    "lit": "Nėra mokėjimo metodo",
    "rus": "Отсутствует способ оплаты",
    "fin": "Maksutapa puuttuu",
    "pol": "Brak metody płatności",
    "est": "Makseviis puudub"
   },
   "multipleMethodsFound": {
    "eng": "Multiple payment methods",
    "lav": "Atbilst vairākas maksājumu metodes",
    "rus": "Многочисленные способы оплаты",
    "fin": "Useita maksutapoja",
    "lit": "Keli mokėjimo metodai",
    "pol": "Wiele metod płatności",
    "est": "Mitu makseviisi"
   },
   "noTransactions": {
    "eng": "No transactions",
    "lav": "Nav darījumu",
    "rus": "Нет сделок",
    "fin": "Ei suorituksia",
    "lit": "Nėra mokėjimų",
    "pol": "Brak transakcji",
    "est": "Tehingud puuduvad"
   },
   "disableUnsubmittedDescription": {
    "eng": "Turn off to avoid notifications every time documents are emailed to CostPocket, but not submitted to the accounting software",
    "est": "Lülita välja, kui ei soovi saada teavitust iga kord kui dokument emailiga CostPocketisse saadetakse, aga seda ei edastata raamatupidamisse",
    "rus": "Выключите, чтобы не получать сообщения каждый раз, когда документы отправляются по электронной почте в CostPocket, но не передаются в бухгалтерскую программу.",
    "lav": "Izslēdziet, ja nevēlaties saņemt paziņojumus katru reizi, kad iesūtat dokumentus CostPocket sistēmā no e-pasta un tie netiek automātiski pārsūtīti uz grāmatvedības sistēmu.",
    "lit": "Išjunkite, norėdami išvengti pranešimų apie dokumentus atsiųstus į CostPocket e-paštu, bet nepateiktus apskaitos programai.",
    "pol": "Wyłącz tę opcję, aby uniknąć powiadomień za każdym razem, gdy dokumenty zostaną przesłane drogą mailową do CostPocket, ale nie przesłane do oprogramowania księgowego",
    "fin": "Kytke pois päältä välttääksesi ilmoitukset, kun asiakirjat lähetetään sähköpostitse CostPocketille (ei välitetä automaattisesti kirjanpito-ohjelmistoon)"
   },
   "disableUnsubmittedNotif": {
    "eng": "Receive email notifications about pending documents",
    "est": "Teavita edastamata dokumentidest emaili teel",
    "lav": "Saņemt e-pasta paziņojumus par nepabeigtajiem dokumentiem",
    "rus": "Получайте сообщения по эл. почте о недоработанных документах",
    "lit": "Gauti pranešimus e-paštu apie nepriduotus apskaitai dokumentus",
    "pol": "Otrzymuj powiadomienia e-mail o oczekujących dokumentach",
    "fin": "Vastaanota sähköposti-ilmoituksia odottavista asiakirjoista"
   },
   "youHaveUnmatchedReceipts": {
    "eng": "Report contains unmatched documents",
    "lav": "Atskaite satur nepielīdzinātus dokumentus",
    "pol": "Raport zawiera niedopasowane dokumenty",
    "est": "Aruanne sisaldab sidumata dokumente",
    "fin": "Raportti sisältää täsmäämättömiä asiakirjoja",
    "rus": "Отчет содержит несопоставленные документы",
    "lit": "Ataskaitoje yra nesuderintų dokumetų"
   },
   "return": {
    "eng": "Return",
    "est": "Mine tagasi",
    "fin": "Mene takaisin",
    "pol": "Wróć",
    "lav": "Atgriezties",
    "rus": "Вернуться",
    "lit": "Atgal"
   },
   "approvalsAndAdminConf": {
    "eng": "Services ''Require administrator confirmation'' and ''Approval rounds'' can't be used together.",
    "est": "Teenuseid ''Nõua administraatori kinnitust'' ja ''Kinnitusring'' ei saa korraga kasutada.",
    "fin": "Palveluita ''Edellytä järjestelmänvalvojan vahvistus'' ja ''Hyväksyntäkierrokset'' ei voida käyttää yhdessä.",
    "rus": "Услуги ''Требовать подтверждения администратора'' и ''Утверждение расходов'' не могут быть использованы совместно.",
    "lav": "Pakalpojumi \"Izdevumu apstiprināšana\" un \"Sūtīt tikai pēc administratora apstiprinājuma\" nevar tikt izmantoti vienlaicīgi.",
    "pol": "Usługi „Wymagaj potwierdzenia administratora” i „Zatwierdzanie wydatków” nie mogą być używane jednocześnie.",
    "lit": "Paslaugų ''Reikalauti administratoriaus patvirtinimo'' ir ''Tvirtinimo etapai'' negalima naudoti kartu."
   },
   "currencyNotSupported": {
    "eng": "Unsupported or missing currency detected",
    "lav": "Nav norādīta valūta vai nav iespējama tās konvertācija",
    "fin": "Valuuttaa ei tueta tai se puuttuu",
    "est": "Tuvastati toetamata või puuduv valuuta",
    "rus": "Обнаружена неподдерживаемая или отсутствующая валюта",
    "pol": "Wykryto nieobsługiwaną lub brakującą walutę",
    "lit": "Aptikta nepalaikoma arba trūkstama valiuta"
   },
   "currencyRateSource": {
    "eng": "Currency exchange rates are primarily sourced from the $primarySource, with $secondarySource serving as an alternative for unsupported currencies.",
    "lav": "Primārais avots valūtas kursa noteikšanai ir $primarySource un $secondarySource kā alternatīva, ja konkrētās valūtas konvertācijas likmi nepiedāvā primārias avots.",
    "rus": "Курсы валют в основном берутся из $primarySource, а $secondarySource служит альтернативой для неподдерживаемых валют.",
    "lit": "Valiutų kursai gaunami iš $primarySource, o $secondarySource yra alternatyva nepalaikomoms valiutoms.",
    "fin": "Valuuttakurssit saadaan ensisijaisesti $primarySourcesta, ja $secondarySource toimii vaihtoehtona ei-tuetuille valuutoille.",
    "est": "Valuuta vahetuskursid pärinevad peamiselt $primarySource'st, kuid seal mitte eksisteerivate valuutade jaoks kasutame alternatiivset allikat ($secondarySource).",
    "pol": "Kursy wymiany walut pochodzą głównie z $primarySource, przy czym $secondarySource stanowi alternatywę dla nieobsługiwanych walut."
   },
   "attachmentUploadWarning": {
    "eng": "Uploading attachments. Data in these documents will not be digitsed.",
    "lav": "Pielikumu augšupielāde. Dati no šiem dokumentiem netiks digitalizēti.",
    "fin": "Lataa liitteitä. Näiden asiakirjojen tietoja ei digitalisoida.",
    "pol": "Wgrywanie załączników. Dane zawarte w tych dokumentach nie będą digitalizowane.",
    "est": "Manuste üleslaadimine. Neid dokumente ei digiteerita.",
    "rus": "Загрузка приложений. Данные в этих документах не будут оцифрованы.",
    "lit": "Priedai įkeliami. Šie dokumentai nebus skaitmeninami."
   },
   "unselect": {
    "eng": "Unselect",
    "lav": "Noņemt atlasi",
    "fin": "Poista valinta",
    "pol": "Odznacz",
    "est": "Tühista valik",
    "rus": "Снять выбор",
    "lit": "Atšaukti"
   },
   "markApplicable": {
    "eng": "Mark applicable",
    "lav": "Dokuments ir derīgs",
    "est": "Märgi kuludokumendiks",
    "fin": "Merkitse kulutositteeksi",
    "rus": "Отметить документ, как соответствующий",
    "lit": "Pažymėti taikoma"
   },
   "notificationSchedule": {
    "eng": "Notifications",
    "est": "Teavitused",
    "fin": "Ilmoitukset",
    "rus": "Сообщения",
    "lav": "Paziņojumi",
    "lit": "Pranešimai",
    "pol": "Powiadomienia"
   },
   "notificationScheduleDescr": {
    "eng": "Notify at",
    "est": "Teavita kell",
    "fin": "Ilmoita osoitteessa",
    "rus": "Сообщите в",
    "lav": "Paziņojumus sūtīt plkst.",
    "lit": "Pranešti",
    "pol": "Powiadomienie na adres"
   },
   "reminderIntervalDays": {
    "eng": "Reminder interval in days",
    "est": "Teavituse intervall päevades",
    "fin": "Muistutusväli päivinä",
    "rus": "Интервал напоминания в днях",
    "lav": "Atgādinājumu intervāls dienu izteiksmē",
    "lit": "Priminimų intervalas dienomis"
   },
   "notificationTime": {
    "eng": "Notification time",
    "est": "Teavituse kellaaeg",
    "fin": "Ilmoitusaika",
    "rus": "Время сообщения",
    "lav": "Paziņojumu laiks",
    "lit": "Pranešimų laikas",
    "pol": "Czas powiadomienia"
   },
   "Days": {
    "eng": "Days",
    "est": "Päeva",
    "pol": "Dni",
    "lav": "Dienas",
    "rus": "Дни",
    "fin": "Päivät",
    "swe": "Dagar",
    "lit": "Dienos",
    "esp": "Días"
   },
   "immediate": {
    "eng": "Immediately",
    "est": "Koheselt",
    "fin": "Välitön",
    "rus": "Немедленно",
    "lav": "Tūlītēji",
    "lit": "Iš karto",
    "pol": "Natychmiast"
   },
   "enableCustomSchedule": {
    "eng": "Customise the notification schedule",
    "est": "Muuda teavituste aegasid",
    "fin": "Muokkaa ilmoitusaikataulua",
    "rus": "Настройте график сообщений",
    "lav": "Personalizēt paziņojumu grafiku",
    "lit": "Keiskite pranešimų grafiką",
    "pol": "Dostosowywanie harmonogramu powiadomień"
   },
   "disabled": {
    "eng": "Disabled",
    "est": "Välja lülitatud",
    "fin": "Poistettu käytöstä",
    "rus": "Отключено",
    "lav": "Izslēgts",
    "lit": "Išjungta",
    "pol": "Wyłączony"
   },
   "deafultNotification": {
    "eng": "When off: notify once a day immediately after approval request",
    "est": "Välja lülitatuna: teavita kord päevas kohe peale kinnituse soovi",
    "fin": "Pois päältä: Ilmoita kerran päivässä heti hyväksymispyynnön jälkeen",
    "lav": "Kad izslēgts: sūta paziņojumus vienu reizi dienā uzreiz pēc apstiprinājuma pieprasījuma",
    "rus": "Когда отключено: сообщение один раз в день сразу после запроса на утверждение",
    "pol": "Gdy wyłączone: powiadamiaj raz dziennie natychmiast po zatwierdzeniu żądania."
   },
   "day(s)": {
    "eng": "day(s)",
    "est": "päev(a)",
    "fin": "Päivää",
    "lit": "diena(-os)",
    "lav": "diena(s)",
    "rus": "день (дни)",
    "pol": "Dzień/dni"
   },
   "selectAll": {
    "eng": "Select all",
    "lav": "Atlasīt visu",
    "est": "Vali kõik",
    "fin": "Valitse kaikkki",
    "lit": "Pažymėti visus",
    "rus": "Выбрать все",
    "pol": "Wybierz wszystko"
   },
   "mergeEinvLines": {
    "eng": "Merge e-invoice lines by groups",
    "fin": "Yhdistä laskurivit ryhmittäin",
    "est": "Summeeri e-arve ridade summad kokku",
    "lit": "Sujungti sąskaitų faktūrų eilutes pagal grupes",
    "rus": "Объединить строки счетов по группам",
    "lav": "Apvienot rēķinu rindiņas pēc grupām",
    "pol": "Scalanie linii faktur według grup"
   },
   "existingEInvoicingContract": {
    "eng": "You already have an e-invoicing contract with another provider (E-Financials/E-arveldaja). Cancel it first to activate e-invoicing with CostPocket.",
    "fin": "Sinulla on jo verkkolaskutussopimus toisen palveluntarjoajan kanssa. Peruutathan sen ensin aktivoidaksesi verkkolaskutuksen CostPocketissa.",
    "est": "Teil on juba kehtiv e-arvete leping teise teenusepakkuja juures (E-arveldaja). CostPocketi e-arvete aktiveerimiseks tühistage see esmalt, võttes ühendust oma teenusepakkujaga.",
    "lit": "Jau esate sudarę e. sąskaitų išrašymo sutartį su kitu paslaugų teikėju. Pirmiausia ją nutraukite, kad galėtumėte aktyvinti išrašymą su CostPocket.",
    "lav": "Jums jau ir noslēgts e-rēķinu līgums ar citu operatoru. Atceliet to, pirms aktivizējat pakalpojumu CostPocket.",
    "rus": "У вас уже есть договор об электронном выставлении счетов с другим оператором. Сначала откажитесь от него, чтобы активировать электронное выставление счетов в CostPocket.",
    "pol": "Masz już umowę na e-fakturowanie z innym dostawcą. Anuluj ją najpierw, aby aktywować e-fakturowanie w CostPocket."
   },
   "clientMismatchWarning": {
    "eng": "This document may not be intended for your company, based on digitised data. Please review.",
    "est": "Vastavalt tuvastatud andmetele on võimalik, et see dokument ei väljastatud teie ettevõttele.",
    "fin": "Digitoidun tiedon perusteella asiakirjaa ei välttämättä ole tarkoitettu yrityksellesi. Tarkistathan asiakirjan.",
    "lit": "Pagal suskaitmenintus duomenis, šis dokumentas gali būti skirtas ne jūsų įmonei. Prašome peržiūrėti.",
    "lav": "Pamatojoties uz digitalizētajiem datiem, šis dokuments, iespējams, nav paredzēts Jūsu uzņēmumam. Lūdzu, pārskatiet.",
    "rus": "На основании оцифрованных данных этот документ может не относиться к вашей компании. Пожалуйста, проверьте.",
    "pol": "Ten dokument może nie być przeznaczony dla Twojej firmy, w oparciu o dane cyfrowe Prosimy o zapoznanie się."
   },
   "someNeedReview": {
    "eng": "Some need review",
    "est": "Mõni vajab üle vaatamist",
    "fin": "Jotkut tarvitsevat tarkistusta",
    "lit": "Reikia peržiūrėti",
    "lav": "Nepieciešams pārskatīt rezultātus",
    "rus": "Необходимо пересмотреть результаты",
    "pol": "Niektóre wymagają przejrzenia"
   },
   "docIssuedBetween": {
    "eng": "Document issued",
    "lav": "Dokuments izsniegts periodā",
    "rus": "Документ, выданный в периоде",
    "fin": "Välillä myönnetty dokumentti",
    "est": "Kuludokumendi kuupäev",
    "lit": "Dokumentas išduotas",
    "pol": "Dokument wystawiony"
   },
   "docAddedBetween": {
    "eng": "Document added",
    "lav": "Dokuments pievienots periodā",
    "rus": "Документ, добавлен в периоде",
    "fin": "Dokumentti lisätty välillä",
    "est": "Kuludokument lisatud",
    "lit": "Dokumentas pateiktas",
    "pol": "Dokument dodany"
   },
   "reportAddedBetween": {
    "eng": "Report added",
    "lav": "Pārskats pievienots periodā",
    "rus": "Показать отчёты, которые были добавлены в указанный период",
    "fin": "Raportti lisätty välillä",
    "est": "Aruanne loodud",
    "lit": "Ataskaita pateikta",
    "pol": "Raport dodany"
   },
   "reportDatedBetween": {
    "eng": "Report dated",
    "lav": "Pārskata datums attiecas uz periodu",
    "rus": "Показать отчёты, которые датированы в указанный период",
    "fin": "Raportti päivätty välillä",
    "est": "Aruande periood",
    "lit": "Ataskaitos periodas",
    "pol": "Data raportu lub dodany raport"
   },
   "reportDatedOrAdded": {
    "eng": "Reporting date or report added",
    "lav": "Pārskats pievienots vai datums attiecas uz periodu",
    "rus": "Показать отчёты, созданные или добавленные в указанный период",
    "fin": "Raportti päivätty tai lisätty välillä",
    "est": "Aruanne loodud või aruande periood",
    "lit": "Ataskaitos periodas arba ataskaita pateikta",
    "pol": "Data raportu lub dodany raport"
   },
   "deleteXDocuments": {
    "eng": "Confirm deleting $number documents",
    "est": "Kinnita $number dokumendi kustutamine",
    "lav": "Dzēst $number dokumentus",
    "lit": "Ištrinti $number dokumentus",
    "fin": "Vahvista $number asiakirjojen poistaminen",
    "rus": "Подтвердите удаление $number документов",
    "pol": "Potwierdzenie usunięcia $number dokumentów"
   },
   "bank": {
    "eng": "Bank",
    "lav": "Banka",
    "lit": "Bankas",
    "est": "Pank",
    "fin": "Pankki",
    "rus": "Банк",
    "pol": "Bank"
   },
   "card": {
    "eng": "Card",
    "lav": "Karte",
    "lit": "Kortelė",
    "fin": "Kortti",
    "rus": "Карточка",
    "est": "Kaart",
    "pol": "Karta"
   },
   "yourDecisionPrepared": {
    "eng": "Your decision is inactive",
    "lav": "Jūsu lēmums nav aktīvs",
    "fin": "Päätöksesi ei ole aktiivinen",
    "rus": "Ваше решение неактивно",
    "est": "Sinu otsus ei ole aktiivne",
    "lit": "Jūsų sprendimas negalioja",
    "pol": "Twoja decyzja jest nieaktywna"
   },
   "decisionPrepared": {
    "eng": "decision is inactive",
    "lav": "lēmums nav aktīvs",
    "fin": "päätös ei ole aktiivinen",
    "rus": "решение неактивно",
    "est": "ei ole aktiivne",
    "lit": "sprendimas negalioja",
    "pol": "decyzja jest nieaktywna"
   },
   "oneFreeMeal": {
    "eng": "1 meal provided",
    "lav": "1 apmaksāta ēdienreize",
    "fin": "1 ateria tarjottu",
    "rus": "1 разовое питание",
    "est": "1 söögikorda juba tasutud",
    "lit": "Maitinimas 1 kartą",
    "pol": "1 posiłek zapewniony"
   },
   "twoFreeMeals": {
    "eng": "2 meals provided",
    "lav": "2 apmaksātas ēdienreizes",
    "fin": "2 ateriaa tarjottu",
    "rus": "2 разовое питание",
    "est": "2 söögikorda juba tasutud",
    "lit": "Maitinimas 2 kartus",
    "pol": "2 posiłki zapewnione"
   },
   "changeCostPocketEmail": {
    "eng": "Change document inbox to $address",
    "est": "Muuda e-posti aadress $address",
    "fin": "Muuta asiakirjan postilaatikko $address",
    "rus": "Изменить адрес для входящих документов на $address",
    "lav": "Mainīt e-pasta adresi uz $address",
    "lit": "Keisti dokumentų dėžutės adresą į $address",
    "pol": "Zmiana skrzynki odbiorczej dokumentu na $address"
   },
   "costpocketWarningMonth": {
    "eng": "Change is available only once a month.",
    "est": "Muuta saab vaid kord kuus.",
    "fin": "Vaihto on saatavilla vain kerran kuukaudessa.",
    "rus": "Изменения возможны только раз в месяц.",
    "lav": "Izmaiņas var veikt reizi mēnesī.",
    "lit": "Keitaimas galimas vieną kartą per mėnesį",
    "pol": "Zmiana jest możliwa raz w miesiącu"
   },
   "costpocketWarningEmail": {
    "eng": "The previous address $address remains valid.",
    "est": "Eelmine aadress $address jääb ikka kasutatavaks.",
    "fin": "Edellinen osoite $address pysyy voimassa.",
    "rus": "Предыдущий адрес $address остается в силе.",
    "lav": "Vecā adrese $address saglabājas aktīva.",
    "lit": "Ankstesnis adresas $address išlieka galiojantis",
    "pol": "Poprzedni adres $address pozostaje ważny."
   },
   "costpocketEmailAddress": {
    "eng": "Document inbox for CostPocket",
    "est": "Dokumentide aadress CostPocketis",
    "fin": "Asiakirjojen postilaatikko CostPocketilla",
    "rus": "CostPocket - почтовый ящик для документов",
    "lav": "CostPocket dokumentu digitalizēšanas iesūtne",
    "lit": "Dokumentų dėžutės adresas su CostPocket",
    "pol": "Prefiks skrzynki odbiorczej dokumentu musi składać się z co najmniej 3 znaków."
   },
   "costpocketErrorLength": {
    "eng": "Document inbox prefix must be at least 3 characters long.",
    "est": "Dokumentide aadress peab olema vähemalt 3 tähemärki.",
    "fin": "Asiakirjan postilaatikon osoitteen on oltava vähintään 3 merkkiä pitkä.",
    "rus": "Адрес электронной почты должен содержать не менее 3 символов.",
    "lav": "Dokumentu digitalizēšanas e-pasta adresei ir jāsastāv no vismaz 3 simboliem.",
    "lit": "Dėžutės priešdėlis turi būti iš bent trijų ženklų ilgio",
    "pol": "Prefiks skrzynki odbiorczej dokumentu musi składać się z co najmniej 3 znaków."
   },
   "costpocketErrorChar": {
    "eng": "Document inbox prefix can only contain Latin alphabet letters, digits or dots.",
    "est": "Dokumentide aadress võib sisaldada ainult ladina tähemärke, numbreid või punkte.",
    "fin": "Asiakirjan postilaatikon osoite voi sisältää vain latinalaisten aakkosten kirjaimia, numeroita tai pisteitä.",
    "rus": "Адрес входящего электронного почтового ящика может содержать только буквы латинского алфавита, цифры или точки.",
    "lav": "Dokumentu digitalizēšanas e-pasta adrese drīkst saturēt vienīgi latīņu alfabēta simbolus, ciparus un punktus.",
    "lit": "Dėžutės priešdėlyje gali būti tik lotyniškos abėcėlės raidės, skaitmenys arba taškai.",
    "pol": "Prefiks skrzynki odbiorczej dokumentu może zawierać tylko litery alfabetu łacińskiego, cyfry lub kropki."
   },
   "aliasMonthError": {
    "eng": "Document inbox is allowed to be updated once a month!",
    "est": "Dokumentide aadressi saab muuta vaid kord kuus!",
    "fin": "Asiakirjojen postilaatikon saa päivittää kerran kuukaudessa!",
    "rus": "Обновление электронной почты разрешено раз в месяц!",
    "lav": "Dokumentu digitalizēšanas e-pasta maiņa ir iespējama vienu reizi mēnesī.",
    "lit": "Dėžutės pavadinimą galima keisti vieną kartą per mėnesį",
    "pol": "Skrzynka odbiorcza dokumentów może być aktualizowana raz w miesiącu!"
   },
   "aliasUnavailableError": {
    "eng": "This document inbox name is unavailable!",
    "est": "See aadress on võetud!",
    "fin": "Tämän asiakirjan postilaatikon nimi ei ole käytettävissä!",
    "rus": "Название этого почтового ящика для документов недоступно.",
    "lav": "Šis dokumentu digitalizēšanas e-pasta vārds nav pieejams.",
    "lit": "Šis dėžutės pavadinimas negalimas",
    "pol": "Ta nazwa skrzynki odbiorczej dokumentu jest niedostępna!"
   },
   "noPendingApprovals": {
    "eng": "No pending approvals",
    "lav": "Nav aktīvu apstiprinājuma pieprasījumu",
    "fin": "Ei odottavia hyväksyntöjä",
    "rus": "Нет активных запросов на утверждение",
    "est": "Ootel kinnitused puuduvad",
    "lit": "Nėra laukiančių patvirtinimų",
    "pol": "Brak oczekujących zatwierdzeń"
   },
   "pendingApprovals": {
    "eng": "Pending approvals",
    "lav": "Dokumenti gaida apstiprinājumu",
    "pol": "Oczekujące zatwierdzenia",
    "fin": "Odottavat hyväksynnät",
    "rus": "Документы, ожидающие утверждения",
    "est": "Ootel kinnitused",
    "lit": "Laukiantys patvirtinimai"
   },
   "myPendingApprovals": {
    "eng": "My pending approvals",
    "lav": "Man jāapstiprina",
    "fin": "Odottavat hyväksyntäni",
    "rus": "Мне нужно утвердить",
    "est": "Minu ootel kinnitused",
    "lit": "Mano laukiantys patvirtinimai",
    "pol": "Moje oczekujące zatwierdzenia"
   },
   "editCostpocketAddress": {
    "eng": "Edit inbox prefix",
    "est": "Muuda CostPocketi aadressi",
    "lav": "Mainīt digitalizēšanas e-pasta nosaukumu",
    "rus": "Редактировать префикс эл-почты для оцифровки",
    "fin": "Muokkaa postilaatikon etuliitettä",
    "lit": "Redaguoti dėžutės priešdėlį",
    "pol": "Edycja prefiksu skrzynki odbiorczej"
   },
   "hide": {
    "eng": "Hide",
    "lav": "Paslēpt",
    "rus": "Спрятать",
    "est": "Peida",
    "fin": "Piilota",
    "lit": "Paslėpti",
    "pol": "Ukryj"
   },
   "datesMismatch": {
    "eng": "Document date and transaction date don't match",
    "est": "Dokumendi ja tehingu kuupäevad ei klapi",
    "rus": "Дата документа и дата транзакции не совпадают",
    "lav": "Dokumenta un darījuma datumi nesakrīt",
    "fin": "Asiakirjan päivämäärä ja tapahtumapäivä eivät täsmää",
    "lit": "Dokumento ir pavedimo datos nesutampa",
    "pol": "Data dokumentu i data transakcji nie są zgodne"
   },
   "dateNotInPeriod": {
    "eng": "Date is not in report period",
    "est": "Kuupäev ei ole aruande perioodis",
    "rus": "Дата не входит в отчетный период",
    "lav": "Datums ir ārpus pārskata perioda",
    "fin": "Päivämäärä ei ole raportointikaudella",
    "lit": "Data nepatenka į ataskaitos periodą",
    "pol": "Data nie mieści się w okresie raportu"
   },
   "sumsMismatch": {
    "eng": "Document sum and transaction sum don't match",
    "est": "Dokumendi ja tehingu summad ei klapi",
    "rus": "Сумма документа и сумма транзакции не совпадают",
    "lav": "Dokumenta un darījuma summas nesakrīt",
    "fin": "Tositesumma ja tapahtumasumma eivät täsmää",
    "lit": "Dokumento ir pavedimo sumos nesutampa",
    "pol": "Suma dokumentu i suma transakcji nie są zgodne"
   },
   "inviteSent": {
    "eng": "Invite sent",
    "est": "Kutse saadetud",
    "lav": "Uzaicinājums nosūtīts",
    "rus": "Приглашение отправлено",
    "fin": "Kutsu lähetetty",
    "lit": "Pakvietimas išsiųstas",
    "pol": "Zaproszenie wysłane"
   },
   "resendInvite": {
    "eng": "Resend invite",
    "est": "Saada kutse uuesti",
    "rus": "Повторно отправить приглашение",
    "lav": "Nosūtīt uzaicinājumu atkārtoti",
    "fin": "Lähetä kutsu uudelleen",
    "lit": "Pakartotinai išsiųsti kvietimą",
    "pol": "Wyślij zaproszenie ponownie"
   },
   "department": {
    "eng": "Department",
    "lav": "Departaments",
    "pol": "Department",
    "est": "Osakond",
    "rus": "Департамент",
    "fin": "Osasto",
    "lit": "Departamentas"
   },
   "listIsEmpty": {
    "eng": "The list is empty",
    "lav": "Saraksts ir tukšs",
    "est": "Nimekiri on tühi",
    "rus": "Список пуст",
    "fin": "Tämä lista on tyhjä",
    "lit": "Sąrašas yra tuščias",
    "pol": "Lista jest pusta"
   },
   "doYouWantToResubmitMany": {
    "eng": "Some documents are already forwarded to accounting software. Do you want to submit them again?",
    "est": "Mõned dokumendid on juba edastatud raamatupidamisse. Kas soovid need uuesti edastada?",
    "lav": "Daži dokumenti jau ir nosūtīti grāmatvedībai. Vai vēlaties tos nosūtīt vēlreiz?",
    "rus": "Некоторые документы уже переданы в бухгалтерскую программу. Вы хотите отправить их снова?",
    "fin": "Osa asiakirjoista on jo välitetty kirjanpito-ohjelmistoon. Haluatko lähettää ne uudelleen?",
    "lit": "Kai kurie dokumentai jau perduoti į apskaitos programą. Ar norite juos pateikti dar kartą?",
    "pol": "Niektóre dokumenty zostały już przesłane do oprogramowania księgowego. Czy chcesz przesłać je ponownie?"
   },
   "accountingCode": {
    "eng": "Code",
    "lav": "Kods",
    "est": "Kood",
    "rus": "Код",
    "fin": "Koodi",
    "lit": "Kodas",
    "pol": "Kod"
   },
   "accountingClass": {
    "eng": "Class",
    "lav": "Klase",
    "est": "Klass",
    "rus": "Класс",
    "fin": "Luokka",
    "lit": "Klasė",
    "pol": "Klasa"
   },
   "accountingContext": {
    "eng": "Accounting context",
    "lav": "Grāmatvedības konfigurācija",
    "est": "Raamatupidamise kontoplaan",
    "rus": "Конфигурация бухгалтерского учета",
    "fin": "Kirjanpidon konteksti",
    "lit": "Apskaitos kontekstas",
    "pol": "Kontekst księgowy"
   },
   "efExDocAlreadyAccounting": {
    "eng": "This document is already sent to accounting software",
    "est": "See dokument on juba saadetud raamatupidamisse",
    "lav": "Šis dokuments jau ir nosūtīts uz grāmatvedības sistēmu.",
    "rus": "Этот документ уже отправлен в бухгалтерскую программу.",
    "fin": "Tämä asiakirja on jo lähetetty kirjanpito-ohjelmistoon",
    "lit": "Šis dokumentas jau išsiųstas apskaitos programai",
    "pol": "Dokument ten jest już wysyłany do oprogramowania księgowego"
   },
   "agreeToReceiveEmails": {
    "eng": "Receive updates on new features and promotions via email",
    "lav": "Vēlos saņemt CostPocket jaunumus e-pastā",
    "est": "Saadame e-maili teel teavitus süsteemi uuendusest ja pakkumistest",
    "fin": "Saat sähköpostiisi päivityksiä uusista ominaisuuksista ja tarjouksista",
    "lit": "Gauti pranešimus apie naujas funkcijas ir akcijas el. paštu",
    "rus": "Получать информацию о новых функциях и рекламные сообщения по электронной почте",
    "pol": "Otrzymuj aktualizacje dotyczące nowych funkcji i promocji za pośrednictwem e-maila"
   },
   "digitizerComment": {
    "eng": "Comment from digitizer:",
    "est": "Kommentaar digiteerijalt:",
    "fin": "Kommentti digitoijalta:",
    "lav": "Digitalizētāja komentārs:",
    "lit": "Komentaras nuo skaitmenintojo",
    "rus": "Комментарий от оцифровщика:",
    "pol": "Komentarz od digitizer"
   },
   "issueDateNotInFuture": {
    "eng": "Select a date in the past or today",
    "est": "Valitud kuupäev ei tohi olla tulevikus",
    "lav": "Izvēlieties pagātnes vai šodienas datumu",
    "lit": "Pasirinkite šiandienos ar ankstesnę datą",
    "fin": "Valitse päivämäärä menneisyydestä tai tänään",
    "rus": "Выберите прошлую или сегодняшнюю дату",
    "pol": "Wybierz przeszłą lub dzisiejszą datę"
   },
   "addRow": {
    "eng": "Add row",
    "lav": "Pievienot rindu",
    "est": "Uus rida",
    "lit": "Pridėti eilutę",
    "fin": "Lisää rivi",
    "rus": "Добавить строку",
    "pol": "Dodaj wiersz"
   },
   "sepcifyOneProjectCode": {
    "eng": "Please specify only one project code",
    "est": "Palun lisage ainult üks projekti kood",
    "lav": "Lūdzu, norādiet tikai vienu projekta kodu",
    "lit": "Parinkite tik vieno projekto kodą",
    "fin": "Anna vain yksi projektikoodi",
    "rus": "Пожалуйста, укажите только один код проекта",
    "pol": "Podaj tylko jeden kod projektu"
   },
   "assignToReports": {
    "eng": "Documents need to be attached to the reports",
    "lav": "Dokumentus nepieciešams pievienot pārskatiem",
    "esp": "Dokumente tuleb lisada aruannetele",
    "lit": "Dokumentai privalo būti prisegti prie ataskaitų",
    "fin": "Asiakirjat on liitettävä raportteihin",
    "rus": "Эти документы должны быть добавлены к отчетам",
    "est": "Dokumendid tuleb lisada aruandesse",
    "pol": "Do raportów należy dołączyć dokumenty"
   },
   "overlappingTripDates": {
    "eng": "Month overlaps with another report",
    "est": "Kuu kattub teise aruandega",
    "lav": "Pārskata periods pārklājas ar cita pārskata periodu",
    "fin": "Kuukausi on päällekkäinen toisen raportin kanssa",
    "pol": "Miesiąc pokrywa się z innym raportem",
    "rus": "Месяц отчета пересекается с другим отчетом",
    "lit": "Mėnuo persidengia su kita ataskaita"
   },
   "confirmAllAdded": {
    "eng": "Confirm that all documents and information have been added.",
    "est": "Kinnita, et kõik dokumendid ja info on lisatud.",
    "rus": "Убедитесь, что все документы и информация добавлены.",
    "lav": "Apstiprinu, ka visi dokumenti un informācija ir pievienota.",
    "fin": "Vahvista, että kaikki asiakirjat ja tiedot on lisätty.",
    "pol": "Upewnij się, że wszystkie dokumenty i informacje zostały dodane",
    "lit": "Patvirtinkite, kad visi dokumentai ir informacija yra pridėti."
   },
   "onylBusinessAccounts": {
    "eng": "Integrations are available only to business accounts",
    "est": "Liideseid saavad teha vaid juriidilised isikud",
    "rus": "Интеграции доступны только для учетных записей организаций.",
    "lav": "Integrācijas ir pieejamas tikai organizāciju tipa kontiem.",
    "fin": "Integraatiot ovat saatavilla vain yritystileille",
    "pol": "Integracje są dostępne tylko dla kont biznesowych",
    "lit": "Integracijos galimos tik verslo paskyroms"
   },
   "registerCompany": {
    "eng": "Register a company",
    "est": "Registreeri ettevõte",
    "lav": "Reģistrēt organizāciju",
    "rus": "Зарегистрировать организацию",
    "fin": "Rekisteröi yritys",
    "lit": "Registruoti kompaniją"
   },
   "itemRows": {
    "eng": "Item lines",
    "lav": "Preču rindas",
    "rus": "Линии товаров",
    "est": "Arve read",
    "fin": "Tuoterivit",
    "pol": "Linie pozycji",
    "lit": "Prekių eilutės"
   },
   "addFromFile": {
    "eng": "Add from file",
    "lav": "Pievienot no faila",
    "rus": "Добавить из файла",
    "est": "Lisa failist",
    "fin": "Lisää tiedostosta",
    "pol": "Dodaj z pliku",
    "lit": "Pridėti iš dokumento"
   },
   "enabledForFields": {
    "eng": "Enabled for",
    "lav": "Attiecināms uz",
    "rus": "Включено для",
    "est": "Kehtib",
    "fin": "Käytössä:",
    "pol": "Włączone dla",
    "lit": "Įgalinta"
   },
   "syncedBankAccounts": {
    "eng": "Connected bank accounts",
    "lav": "Pievienotie bankas konti",
    "rus": "Подключенные банковские счета",
    "est": "Ühendatud pangakontod",
    "fin": "Yhdistetyt pankkitilit",
    "pol": "Połączone konta bankowe",
    "lit": "Prijungtos bankų sąskaitos"
   },
   "approverInput": {
    "eng": "Approver input",
    "lav": "Atļauts rediģēt",
    "est": "Kinnitaja peab lisama",
    "fin": "Hyväksyjän kommentit",
    "rus": "Может редактировать",
    "pol": "Dane zatwierdzającego",
    "lit": "Tvirtintojo pastabos"
   },
   "approverInputDescr": {
    "eng": "Define editable accounting data for approvers without administrator rights.",
    "lav": "Nosaka, kurus grāmatvedības datus lēmuma pieņēmējs var redigēt, ja tam nav administratora tiesību.",
    "est": "Määrake väljad, mida mitte administraatorist kinnitaja saab muuta.",
    "fin": "Määritä, mitä kirjanpitotietojen hyväksyjät voivat muokata, kun heillä ei ole järjestelmänvalvojan oikeuksia.",
    "rus": "Укажите, какие учетные данные могут редактировать лица, не имеющие прав администратора.",
    "pol": "Definiowanie edytowalnych danych księgowych dla osób zatwierdzających bez uprawnień administratora",
    "lit": "Apibrėžkite redaguojamus apskaitos duomenis tvirtintojams, neturintiems administratoriaus teisių."
   },
   "psd2EnableBankingProviderInfo": {
    "eng": "Connect with a bank. You must provide consent in your bank portal, which can be revoked at any time.",
    "fin": "Ota yhteyttä pankkiin. Sinun on annettava suostumus pankkiportaalissasi. Voit peruuttaa suostumuksen milloin tahansa.",
    "est": "Ühenda CostPocket oma pangaga. Selleks küsime kinnitust läbi internetipanga. Kinnituse saab iga hetk tühistada.",
    "rus": "Подключитесь к банку. Вам необходимо будет предоставить согласие на портале вашего банка. Вы можете отозвать согласие в любое время.",
    "lav": "Pieslēdzieties ar savu banku. Jums vajadzēs sniegt savu piekrišanu bankas lapā. Piekrišanu varēsiet atsaukt jebkurā laikā.",
    "pol": "Połącz się z bankiem. Wymagane będzie wyrażenie zgody w portalu bankowym. Zgodę można wycofać w dowolnym momencie.",
    "lit": "Prisijunkite prie banko. Banko portale turėsite suteikti prieigą prie sąskaitos, kurią galėsite bet kada atšaukti."
   },
   "psd2EnableBankingAccountInfo": {
    "eng": "Select the accounts that you want to connect with. For the selected accounts CostPocket will automatically synchronize transactions 4 times a day.",
    "fin": "Valitse tilit, joihin haluat muodostaa yhteyden. Valituille tileille CostPocket synkronoi tapahtumat automaattisesti 4 kertaa päivässä.",
    "est": "Vali pangakontod, millele soovite ühenduse teha. CostPocket sünkroniseerib märgitud kontode kandeid 4 korda päevas.",
    "rus": "Выберите счета, с которыми вы хотите установить связь. Для выбранных счетов CostPocket будет автоматически синхронизировать транзакции 4 раза в день.",
    "lav": "Izvēlieties kontus kurus vēlaties pieslēgt. Izvēlētajiem kontiem CostPocket veiks automātisku darījumu sinhronizāciju 4 reizes dienā.",
    "pol": "Wybierz konta, z którymi chcesz się połączyć. Dla wybranych kont CostPocket będzie automatycznie synchronizować transakcje 4 razy dziennie.",
    "lit": "Pasirinkite sąskaitas prie kurių norite prisijungti. Prijungtų sąskaitų operacijas sinchronizuojame 4 kartus per dieną."
   },
   "psd2EnableBankingStatementInfo": {
    "eng": "Synchronize card transaction statement from a specific period.",
    "est": "Sünkroniseeri kaarditehinguid kindlas ajavahemikuks.",
    "lav": "Sinhronizēt karšu darījumus konkrētam periodam.",
    "rus": "Синхронизируйте операции по карте за определенный период.",
    "fin": "Synkronoi korttitapahtumat tietyksi ajanjaksoksi.",
    "lit": "Sinchronizuokite kortelių operacijas už tam tikrą laikotarpį.",
    "pol": "Synchronizacja wyciągu transakcji kartą z określonego okresu."
   },
   "unsupportedBySoftware": {
    "eng": "Not supported by integrated accounting software",
    "lav": "Nav pieejams integrētajā grāmatvedības sistēmā.",
    "fin": "Ei saatavilla integroidussa kirjanpitojärjestelmässä",
    "est": "Pole toetatud teie raamatupidamistarkvara poolt.",
    "rus": "Не поддерживается интегрированным бухгалтерским программным обеспечением.",
    "lit": "Nepalaikoma su integruota apskaitos sistema"
   },
   "downloadTemplate": {
    "eng": "Download CSV template",
    "lav": "Lejupielādēt CSV veidni",
    "fin": "Lataa CSV pohja",
    "est": "Laadi alla CSV näidis",
    "rus": "Скачать шаблон CSV",
    "pol": "Pobierz szablon CSV",
    "lit": "Parsisųskite CSV šabloną"
   },
   "accountingContextUpload": {
    "eng": "Fill in the accounting context template and upload all data in one file",
    "lav": "Aizpildiet CSV veidni ar grāmatvedības vienībām un augšuplādējiet datus no viena faila.",
    "fin": "Täytä kirjanpidon kontekstimalli ja lataa kaikki tiedot yhteen tiedostoon",
    "est": "Täitke raamatupidamise kontoplaani näidis ja laadige kõik andmed ühe failina üles.",
    "rus": "Заполните шаблон контекста бухгалтерского учета и загрузите все данные одним файлом.",
    "pol": "Wypełnij szablon kontekstu księgowego i prześlij wszystkie dane w jednym pliku.",
    "lit": "Užpildykite apskaitos konteksto šabloną ir įkelkite visus duomenis vienu dokumentu"
   },
   "syncBankAccounts": {
    "eng": "By linking your organization's bank accounts, you can sync and match transactions with expense documents using the payment card reconciliation feature",
    "fin": "Linkittämällä organisaatiosi pankkitilit voit synkronoida ja yhdistää tapahtumat kulutositteisiin maksukorttien täsmäytysominaisuuden avulla",
    "lav": "Savienojums ar organizācijas bankas kontiem ļauj veikt automatizētu bankas darījumu pielīdzināšanu izdevumu dokumentiem.",
    "est": "Ühendades ettevõtte pangakontod, saate siduda pangakanded ja kuludokumendid kasutades pangakaardi aruandeid",
    "rus": "Связав банковские счета вашей организации, вы можете синхронизировать и сопоставлять транзакции с расходными документами с помощью функции сверки платежных карт.",
    "pol": "Łącząc konta bankowe organizacji, można synchronizować i dopasowywać transakcje z dokumentami wydatków za pomocą funkcji uzgadniania kart płatniczych"
   },
   "enableCardReconciliation": {
    "eng": "Enable payment card reconciliation",
    "lav": "Ieslēgt karšu izrakstu saskaņošanu",
    "fin": "Ota maksukorttien täsmäytys käyttöön",
    "est": "Lülita sisse pangakaardi aruanded",
    "rus": "Включить сверку платежных карт",
    "pol": "Włącz uzgadnianie kart płatniczych",
    "lit": "Įgalinti mokėjimo kortelių sutikrinimą"
   },
   "addCards": {
    "eng": "Add payment cards",
    "lav": "Pievienot maksājumu kartes",
    "fin": "Lisää maksukortit",
    "est": "Lisa pangakaarte",
    "rus": "Добавьте платежные карты",
    "pol": "Dodaj płatności kartą",
    "lit": "Pridėti mokėjimo kortelę"
   },
   "addCardsDesc": {
    "eng": "To use payment card reconciliation feature, add payment card information of your organization to custom payment methods",
    "lav": "Lai lietotu maksājumu karšu izrakstu saskaņošanu, pievienojiet uzņēmuma kartes personalizētajām maksājumu metodēm \"Maksājumu metodes\" sadaļā.",
    "fin": "Jos haluat käyttää maksukorttien täsmäytysominaisuutta, lisää organisaatiosi maksukorttitiedot mukautettuihin maksutapoihin",
    "est": "Pangakaardi aruannete kasutamiseks lisage pangakaardi andmed oma ettevõtte makseviiside alla.",
    "rus": "Чтобы воспользоваться функцией сверки платежных карт, добавьте информацию о платежной карте вашей организации в раздел Персонализированные способы оплаты.",
    "pol": "Aby skorzystać z funkcji uzgadniania płatności kartą płatniczą, dodaj informacje o karcie płatniczej swojej organizacji do niestandardowych metod płatności"
   },
   "enableReconciliationDesc": {
    "eng": "The payment card reconciliation feature adds expenses paid by card to dedicated reports and matches transactions with cost documents",
    "lav": "To ieslēdzot, izmaksu dokumenti, kas apmaksāti ar karti, tiks pievienoti specializētiem pārskatiem un bankas darījumi automātiski tiks pielīdzināti izmaksām konkrētajā periodā.",
    "fin": "Maksukorttien täsmäytysominaisuuden avulla maksukortilla maksetut kulut lisätään erillisiin raportteihin, mikä mahdollistaa automaattisen tapahtuman täsmäytyksen kustannusasiakirjojen kanssa",
    "est": "Pangakaardi aruannetega lisame pangakanded otse õigetesse aruannetesse, et need saaks automaatselt kuludokumentidega siduda.",
    "rus": "Благодаря функции сверки платежных карт расходы, оплаченные платежными картами, добавляются в специальные отчеты, что позволяет автоматически сопоставлять транзакции с расходными документами.",
    "pol": "Dzięki funkcji uzgadniania płatności kartą płatniczą, wydatki opłacone kartą są dodawane do dedykowanych raportów, umożliwiając automatyczne dopasowanie transakcji do dokumentów kosztowych"
   },
   "image": {
    "eng": "Image",
    "lav": "Attēls",
    "rus": "Изображение",
    "est": "Pilt",
    "fin": "Kuva",
    "pol": "Obraz",
    "lit": "Nuotrauka"
   },
   "rememberMyChoice": {
    "eng": "Remember my choice",
    "lav": "Iegaumēt manu izvēli",
    "rus": "Запомнить мой выбор",
    "est": "Mäleta valikut",
    "fin": "Muista valintani",
    "pol": "Zapamiętaj mój wybór",
    "lit": "Prisiminti pasirinkimą"
   },
   "more": {
    "eng": "More",
    "lav": "Vairāk",
    "rus": "Другие",
    "est": "Rohkem",
    "fin": "Lisää",
    "pol": "Więcej",
    "lit": "Daugiau"
   },
   "docNotFound": {
    "eng": "Document is not found",
    "est": "Dokumenti ei leitud",
    "pol": "Dokument nie został znaleziony",
    "lav": "Dokuments nav atrasts",
    "rus": "Документ не найден",
    "lit": "Dokumentas nerastas",
    "fin": "Dokumenttia ei löydy"
   },
   "reportNotFound": {
    "eng": "Report is not found",
    "est": "Aruannet ei leitud",
    "pol": "Raport nie został znaleziony",
    "rus": "Отчет не найден",
    "lav": "Pārskats nav atrasts",
    "lit": "Ataskaita nerasta",
    "fin": "Raporttia ei löydy"
   },
   "backToHome": {
    "eng": "Back to Home",
    "est": "Tagasi esilehele",
    "pol": "Powrót do strony głównej",
    "lav": "Atgriezties uz sākumu",
    "rus": "Вернуться к началу",
    "lit": "Grįžti į pradžią",
    "fin": "Takaisin etusivulle"
   },
   "itemLineDigiHuman": {
    "eng": "Option for PDF item line digitisation at $price per line",
    "lav": "Opcija digitalizēt preču rindas, $price par rindu",
    "pol": "Digitalizacja wierszy pozycji PDF, cena $price za wiersz",
    "rus": "Оцифровка строк PDF-счетов, $price за строку",
    "est": "Digiteerime arve read PDF arvetelt, $price rea kohta",
    "lit": "Skaitmeninti sąskaitų eilutes, $price per eilutę",
    "fin": "Digitoi PDF- dokumentin rivit, $price riviltä"
   },
   "itemLineDigiRobo": {
    "eng": "Digitise item lines",
    "lav": "Dokumentu preču rindu digitalizācija",
    "pol": "Digitalizacja linii",
    "rus": "Оцифровка строк счетов",
    "est": "Robot digiteerib arve read",
    "lit": "Skaitmeninti sąskaitų eilutes",
    "fin": "Digitoi dokumentin rivit"
   },
   "itemLineDigiHumanPlanActive": {
    "eng": "PDF item line digitisation at $price per line",
    "pol": "Digitalizacja wierszy pozycji PDF, cena $price za wiersz",
    "est": "Digiteerime arve read PDF arvetelt, $price rea kohta",
    "lav": "Opcija digitalizēt preču rindas, $price par rindu",
    "rus": "Оцифровка строк счетов, $price за строку",
    "fin": "Digitoi PDF- dokumentin rivit, $price riviltä",
    "lit": "PDF sąskaitų eilučių skaitmeninimas už $price per eilutę"
   },
   "selectBank": {
    "eng": "Select a bank to connect with",
    "lav": "Izvēlies banku, ar kuru pieslēgties",
    "est": "Valige pank, millega liides luua",
    "pol": "Wybierz bank, z którym chcesz się połączyć",
    "rus": "Выберите банк для подключения",
    "lit": "Pasirinkite banką prisijungimui",
    "fin": "Valitse pankki, johon haluat muodostaa yhteyden"
   },
   "connect": {
    "eng": "Connect",
    "lav": "Savienot",
    "est": "Ühendage",
    "pol": "Połącz",
    "rus": "Подключить",
    "lit": "Prijungti",
    "fin": "Yhdistä"
   },
   "synchronize": {
    "eng": "Synchronize transactions",
    "lav": "Sinhronizēt darījumus",
    "est": "Tehingute sünkroonimine",
    "pol": "Synchronizacja transakcji",
    "rus": "Синхронизировать транзакции",
    "lit": "Sinchronizuoti",
    "fin": "Täsmäytä tapahtumat"
   },
   "autoaccounting": {
    "eng": "Automated accounting",
    "lav": "Grāmatvedības automatizācija",
    "fin": "Automatisoitu kirjanpito",
    "est": "Automaatne raamatupidamine",
    "lit": "Automatizuota apskaita",
    "pol": "Zautomatyzowana księgowość",
    "rus": "Автоматизированный бухгалтерский учет"
   },
   "autoaccountingDescr": {
    "eng": "CostPocket categorizes your expenses by analyzing historical data submitted to your accounting software",
    "lav": "CostPocket analizē un automātiski aizpilda grāmatvedības datus, balstoties uz iepriekš iesniegto informāciju",
    "fin": "CostPocket luokittelee kuludokumentit analysoimalla aiemmin kirjanpito-ohjelmistoon lähetettyjä tietoja",
    "est": "Analüüsides teie raamatupidamistarkvarasse esitatud ajaloolisi andmeid, lisab CostPocket dokumentidele vajalikud raamatupidamuslikud väljas: artikli, kulukonto, kMk, dimensioonid...",
    "lit": "CostPocket suskirsto jūsų išlaidas į kategorijas, pagal istorinius duomenis pateiktus apskaitai",
    "pol": "CostPocket kategoryzuje wydatki, analizując dane historyczne przesłane do oprogramowania księgowego",
    "rus": "CostPocket классифицирует ваши расходы, анализируя исторические данные, переданные в вашу бухгалтерскую программу."
   },
   "notAvailableForIntegration": {
    "eng": "Currently not available with $integration integration",
    "lav": "Pagaidām nav pieejams ar integrāciju $integration",
    "est": "Hetkel pole $integration integratsiooniga saadaval",
    "lit": "Šiuo metu neveikia su $integration integracija",
    "pol": "Obecnie niedostępne z integracją $integration",
    "fin": "Ei tällä hetkellä saatavilla $integration integraatiolla",
    "rus": "На данный момент недоступно с интеграцией $integration"
   },
   "individualMileageRate": {
    "eng": "Custom mileage rate (€/km)",
    "lav": "Individuāla likme (€/km)",
    "est": "Kohandatud läbisõidumäär (€/km)",
    "fin": "Mukautettu kilometrimaksu (€/km)",
    "pol": "Niestandardowa stawka za kilometr (€/km)",
    "rus": "Индивидуальная ставка за пробег (€/км)",
    "lit": "Individualus kilometražo tarifas (€/km)"
   },
   "einvoiceReceiving": {
    "eng": "E-invoice receiving",
    "est": "E-arvete vastuvõtmine",
    "fin": "Verkkolaskujen vastaanottaminen",
    "rus": "Получение электронных счетов",
    "lav": "E-rēķinu saņemšana",
    "lit": "E-sąskaitų gavimas",
    "pol": "Odbieranie faktur elektronicznych"
   },
   "createCopy": {
    "eng": "Create a copy",
    "lav": "Kopēt datus",
    "rus": "Создать копию",
    "est": "Tee koopia",
    "fin": "Luo kopio",
    "lit": "Sukurti kopiją",
    "pol": "Utwórz kopię"
   },
   "saveAndCopy": {
    "eng": "Save and create a copy",
    "lav": "Saglabāt un kopēt datus",
    "rus": "Сохранить и создать копию",
    "est": "Salvesta ja tee koopia",
    "fin": "Tallenna ja luo kopio",
    "lit": "Išsaugoti ir sukurti kopiją",
    "pol": "Zapisz i utwórz kopię"
   },
   "creatingCopy": {
    "eng": "Copying data to new entry...",
    "lav": "Kopē datus uz jaunu ierakstu...",
    "rus": "Копирование данных в новую запись...",
    "est": "Kopeerin andmeid eelmisest sõidust...",
    "fin": "Kopioidaan tietoja uuteen tietueeseen...",
    "lit": "Duomnys kopijuojami į naują įrašą",
    "pol": "Kopiowanie danych do nowego wpisu..."
   },
   "copyCreated": {
    "eng": "Copy created",
    "lav": "Jauns ieraksts izveidots",
    "rus": "Создана новая запись",
    "est": "Koopia loodud",
    "fin": "Kopio luotu",
    "lit": "Kopija sukurta",
    "pol": "Utworzona kopia"
   },
   "selectToActivate": {
    "eng": "Select an option to activate",
    "lav": "Izvēlieties vienu no variantiem, lai pieslēgtu pakalpojumu",
    "rus": "Выберите опцию для активации",
    "lit": "Aktyvuokite pasirinkdami vieną iš variantų",
    "fin": "Valitse jokin vaihtoehdoista palvelun yhdistämiseksi",
    "est": "Tehke valik aktiveerimiseks",
    "pol": "Wybierz opcję do aktywacji"
   },
   "reportSettings": {
    "eng": "Report settings",
    "lav": "Pārskatu iestatījumi",
    "est": "Aruande seaded",
    "rus": "Настройки отчетов",
    "lit": "Ataskaitų nustatymai",
    "fin": "Raporttiasetukset",
    "pol": "Ustawienia raportu"
   },
   "downloadOriginal": {
    "eng": "Download original",
    "lav": "Lejupielādēt oriģinālu",
    "est": "Laadige alla originaal",
    "rus": "Скачать оригинал",
    "lit": "Parsisiųsti originalą",
    "fin": "Lataa alkuperäinen",
    "pol": "Pobierz oryginał"
   },
   "reportReminders": {
    "eng": "Reminders about incomplete reports",
    "lav": "Atgādinājumi par neiesniegtiem pārskatiem",
    "est": "Meeldetuletus programmi edastamata kuluaruande kohta",
    "rus": "Напоминания о неподанных отчетах",
    "lit": "Priminimai apie nepabaigtas ataskaitas",
    "fin": "Muistutukset lähettämättömistä raporteista",
    "pol": "Przypomnienia o niekompletnych raportach"
   },
   "otherReports": {
    "eng": "Other reports",
    "lav": "Citi pārskati",
    "rus": "Другие отчеты",
    "lit": "Kitos ataskaitos",
    "fin": "Muut raportit",
    "est": "Teised aruanded",
    "pol": "Inne raporty"
   },
   "oneDayAfterReturn": {
    "eng": "1 day after the return",
    "lav": "1 dienu pēc atgriešanās",
    "rus": "Через 1 день после возвращения",
    "lit": "1 diena po sugrįžimo",
    "fin": "1 päivä palautuksen jälkeen",
    "est": "1 päev peale lähetust",
    "pol": "1 dzień po zwrocie"
   },
   "daysAfterReturn": {
    "eng": "$number days after the return",
    "lav": "$number dienas pēc atgriešanās",
    "rus": "$number дней после возвращения",
    "lit": "$number dienų po sugrįžimo",
    "fin": "$numero päivää palautuksen jälkeen",
    "est": "$number päeva pärast peale lähetust",
    "pol": "$ liczba dni po zwrocie"
   },
   "beginningOfMonth": {
    "eng": "1 day after the month-end",
    "lav": "1 dienu pēc mēneša beigām",
    "est": "Päev enne kuu lõppu",
    "rus": "Через 1 день после окончания месяца",
    "lit": "1 diena po mėnesio pabaigos",
    "fin": "1 päivä kuukauden lopun jälkeen",
    "pol": "1 dzień po zakończeniu miesiąca"
   },
   "endOfMonth": {
    "eng": "5 days before the month-end",
    "lav": "5 dienas pirms mēneša beigām",
    "est": "5 päeva enne kuu lõppu",
    "rus": "За 5 дней до окончания месяца",
    "lit": "5 dienos prieš mėnesio pabaigą",
    "fin": "5 päivää ennen kuukauden loppua",
    "pol": "5 dni przed końcem miesiąca"
   },
   "noReminders": {
    "eng": "No reminders",
    "lav": "Izslēgt",
    "rus": "Отключить",
    "nor": "",
    "lit": "Be priminimų",
    "fin": "Ei muistutuksia",
    "est": "Ei soovi meeldetuletusi",
    "pol": "Brak przypomnień"
   },
   "sendDocumentsToBookkeeping": {
    "eng": "Send single documents to bookkeeping",
    "lav": "Sūtīt dokumentus uz grāmatvedības sistēmu pa vienam",
    "fin": "Lähetä yksittäiset kuludokumentit kirjanpitoon",
    "pol": "Wysyłanie pojedynczych dokumentów do księgowości",
    "lit": "Siųsti pavienius dokumentus į apskaitą"
   },
   "sendCardReportsToBookkeeping": {
    "lav": "Sūtīt uz grāmatvedības sistēmu maksājumu karšu atskaites",
    "eng": "Send payment card reports to bookkeeping",
    "fin": "Lähetä yrityskorttiraportit kirjanpitoon",
    "pol": "Wysyłanie raportów kart płatniczych do działu księgowości",
    "lit": "Siųsti mokėjimo kortelės ataskaitas į apskaitą"
   },
   "configureVisibleColumns": {
    "eng": "Configures visible data in the table for everyone in the company",
    "lav": "Konfigurē redzamos tabulas datus visiem lietotājiem uzņēmumā",
    "fin": "Konfiguroi näkyvät taulukkotiedot kaikille yrityksen käyttäjille",
    "rus": "Настройка видимых данных в таблице для всех пользователей компании",
    "est": "Seadistab tabelis nähtavad andmed kõigi ettevõtte töötajate jaoks",
    "pol": "Konfiguruje widoczne dane w tabeli dla wszystkich w firmie",
    "lit": "Nustato duomenų stulpelius lentelėje visiems"
   },
   "downloadAll": {
    "eng": "Download all",
    "lav": "Lejupielādēt visus",
    "est": "Laadige kõik alla",
    "fin": "Lataa kaikki",
    "rus": "Скачать все",
    "pol": "Pobierz wszystko",
    "lit": "Parsisiųsti visus"
   },
   "downloadSelected": {
    "eng": "Download selected",
    "lav": "Lejupielādēt atzīmētos",
    "est": "Laadige aktiivsed alla",
    "fin": "Lataus valittu",
    "rus": "Скачать выбранные",
    "pol": "Pobierz wybrane",
    "lit": "Parsisiųsti parinktus"
   },
   "oneReport": {
    "eng": "Report",
    "lav": "Pārskats",
    "lit": "Ataskaita",
    "pol": "Raport",
    "fin": "Raportti",
    "rus": "Отчет",
    "est": "Aruanne"
   },
   "psd2SelectBank": {
    "eng": "Connect to bank",
    "lav": "Savienojums ar banku",
    "rus": "Подключиться к банку",
    "fin": "Yhdistä pankkiin",
    "lit": "Prisijunkite prie banko",
    "pol": "Połącz się z bankiem",
    "est": "Ühenda pangaga"
   },
   "consentRevokeWarning": {
    "eng": "Consent will be revoked for all accounts in bank $bankName",
    "est": "Nõusolek tühistatakse kõikide kontode puhul pangas $bankName.",
    "lit": "Sutikimas bus atšauktas visoms $bankName banko sąskaitoms",
    "rus": "Согласие будет отозвано для всех счетов в банке $bankName.",
    "lav": "Piekrišana tiks atsaukta visiem $bankName kontiem.",
    "fin": "Suostumus peruutetaan kaikilta pankin $bankName tileiltä"
   },
   "lastSync": {
    "eng": "Last synchronization'",
    "est": "Viimane sükroonimine",
    "lit": "Paskutinis sinchronizavimas",
    "lav": "Pēdējā sinhronizācija",
    "rus": "Последняя синхронизация",
    "fin": "Viimeisin synkronointi"
   },
   "year": {
    "eng": "Year",
    "lav": "Gads",
    "est": "Aasta",
    "rus": "Год",
    "fin": "Vuosi"
   },
   "syncTransctions": {
    "eng": "Synchronize transactions",
    "pol": "Synchronizacja transakcji",
    "est": "Tehingute sünkroonimine",
    "lav": "Sinhronizēt darījumus",
    "rus": "Синхронизировать транзакции",
    "fin": "Täsmäytä tapahtumat",
    "lit": "Sinchronizuoti"
   },
   "splitByPaymentType": {
    "eng": "Split reports by payment type",
    "fin": "Jaa raportit maksutavan perusteella"
   }
  }